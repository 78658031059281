import React from 'react';
import styled, { css } from 'styled-components';
import { fontSize, Legend as UILegend, fontStyles } from 'ui';
import { RawLink, Stack, Box } from '@tymate/margaret';
import { Status, Deadline, AvatarAndName } from 'components';
import { formatDate, getIconFromType } from 'utils';
import Truncate from 'react-truncate';
import { useBreakpoint } from 'hooks';
import { Trans } from 'react-i18next';

const Title = styled.h2`
  font-size: ${fontSize.h3};
  font-family: ${({ theme }) => theme.fonts.title};
  margin: 0;
`;

const Card = styled(RawLink)`
  border-radius: ${({ theme }) => theme.borderRadiusLarge};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator},
    0 4px 4px -4px ${({ theme }) => theme.separatorLight};
  background-color: #ffffff;
  padding: ${({ theme }) => theme.spacing()};
  min-height: 150px;
  display: flex;
  flex-direction: column;

  ${({ state }) =>
    state === 'archived' &&
    css`
      background-color: rgba(0, 0, 0, 0.02);
      box-shadow: none;

      ${Title} {
        color: ${({ theme }) => theme.textLight};
      }
    `}

  ${({ variant }) =>
    variant === 'dashboard' &&
    css`
      min-height: 0;
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator},
        0 4px 4px -4px ${({ theme }) => theme.separatorLight};
    `}
`;

const ItemCount = styled.div`
  ${fontStyles.bodySmall};
  display: flex;
  color: ${({ theme }) => theme.textLighter};
  align-items: center;
  > * + * {
    margin-left: ${({ theme }) => theme.spacing(0.25)};
  }
  > svg {
    margin-right: ${({ theme }) => theme.spacing(0.15)};
  }
`;

const CreatedDate = styled.p`
  ${fontStyles.bodySmall};
  margin: 0;
  color: ${({ theme }) => theme.textLight};
`;

const StatusMessage = styled.div`
  ${fontStyles.body}
  color: ${({ theme }) => theme.error};
  margin: ${({ theme }) => theme.spacing(0.5)} 0 0 0;
`;

const PersonalRequestTag = styled(Box)`
  ${fontStyles.bodySmall}
  padding: ${({ theme }) => theme.spacing(0.125)}
  ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.borderRadiusPill};
  color: ${({ theme }) => theme.success};
  background-color: ${({ theme }) => theme.successBackground};
`;

const Legend = styled(UILegend)`
  text-align: justify;
`;

const RequestCard = ({ request, state, variant, to, t }) => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'mobile';

  const documents = request.items.filter(({ type }) => type === 'DOCUMENT');
  const attachments = request.items.filter(({ type }) => type === 'ATTACHMENT');
  const messages = request.items.filter(({ type }) => type === 'MESSAGE');
  const acknowledges = request.items.filter(
    ({ type }) => type === 'ACKNOWLEDGE',
  );
  const prompts = request.items.filter(({ type }) => type === 'PROMPT');
  const signatures = request.items.filter(({ type }) => type === 'SIGNATURE');

  return (
    <Card to={to} state={state} variant={variant}>
      <Stack
        direction={{ default: 'column', tablet: 'row' }}
        alignX={{ tablet: 'space-between' }}
      >
        <Stack
          marginBottom={0.5}
          alignY="center"
          gutterSize={0.5}
          direction={{ default: 'column', tablet: 'row' }}
          alignX={{ default: 'flex-start', tablet: 'flex-start' }}
        >
          <Status status={request.status} />
          {request?.canAnswer?.value && (
            <PersonalRequestTag>{t('personal_request')} </PersonalRequestTag>
          )}
          <Deadline deadline={request.deadline} />
          {documents.length > 0 && (
            <ItemCount>
              {getIconFromType('DOCUMENT')}
              {documents.length}
            </ItemCount>
          )}
          {attachments.length > 0 && (
            <ItemCount>
              {getIconFromType('ATTACHMENT')}
              {attachments.length}
            </ItemCount>
          )}
          {messages.length > 0 && (
            <ItemCount>
              {getIconFromType('MESSAGE')}
              {messages.length}
            </ItemCount>
          )}
          {acknowledges.length > 0 && (
            <ItemCount>
              {getIconFromType('ACKNOWLEDGE')}
              {acknowledges.length}
            </ItemCount>
          )}
          {prompts.length > 0 && (
            <ItemCount>
              {getIconFromType('PROMPT')}
              {prompts.length}
            </ItemCount>
          )}
          {signatures.length > 0 && (
            <ItemCount>
              {getIconFromType('SIGNATURE')}
              {signatures.length}
            </ItemCount>
          )}
        </Stack>
        <CreatedDate>
          {t('created_at', {
            date: formatDate(request?.createdAt, 'd MMMM YYY'),
          })}
        </CreatedDate>
      </Stack>

      <Stack
        direction="column"
        alignY="center"
        gutterSize={0.5}
        marginTop={isMobile && 0.5}
      >
        <Stack
          direction="row"
          alignY="center"
          alignX="space-between"
          gutterSize={0.5}
          size="full"
        >
          <AvatarAndName
            user={request?.recipient}
            variant="requestCard"
            size="small"
            role={t('request:recipient')}
          />
          <AvatarAndName
            user={request?.author}
            variant="requestCard"
            size="small"
            role={t('request:author')}
          />
        </Stack>
        <span>
          <Title>{request.name}</Title>
        </span>
      </Stack>

      {variant !== 'dashboard' && (
        <Legend marginVertical={0.5}>
          <Truncate lines={3} trimWhitespace={true} ellipsis="...">
            {request.description}
          </Truncate>
        </Legend>
      )}

      {request?.status === 'REJECTED' && (
        <StatusMessage>
          <Trans i18nKey="request:rejection_explanation">
            <strong>Rejection motive : </strong>
            {{ motive: request?.statusMessage }}
          </Trans>
        </StatusMessage>
      )}
    </Card>
  );
};

export default RequestCard;
