import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import fr from './locales/fr.json';
import en from './locales/en.json';
import es from './locales/es.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      fr,
      es,
    },
    supportedLngs: ['en', 'fr', 'es'],
    load: 'languageOnly',

    debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false, // not needed for react
    },

    react: {
      bindI18n: 'languageChanged loaded',
      useSuspense: true,
    },
  });

export default i18n;
