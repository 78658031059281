import React from 'react';
import styled from 'styled-components';
import { Buttons, Modal, Stack } from '@tymate/margaret';
import { fontStyles, SubText } from 'ui';
import { useTranslation } from 'react-i18next';
import { Box } from '@tymate/margaret';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, PhoneField } from './Forms';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Button from './Button';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';

const CardTitle = styled(Box)`
  ${fontStyles.h3}

  margin: 0;
  font-weight: 700;
`;

CardTitle.defaultProps = {
  as: 'h3',
};

const TextLight = styled.p`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLighter};
  margin: 0;
`;

const START_SIGNATURE = gql`
  mutation startSignature($input: StartSignatureInput!) {
    startSignature(input: $input) {
      errors {
        message
        path
      }
      request {
        id
      }
    }
  }
`;

const SignaturePhoneInputModal = ({
  onRequestClose,
  onSubmittedPhone,
  currentItem,
  isOpen,
}) => {
  const { t } = useTranslation('signature');

  const [startSignature] = useMutation(START_SIGNATURE);

  const handleStartSignature = async values => {
    try {
      await startSignature({
        variables: {
          input: {
            requestItemId: currentItem.requestItemId || currentItem.id,
            ...values,
          },
        },
      });
      onSubmittedPhone();
    } catch (err) {}
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Stack direction="column" gutterSize={1.5} alignX="stretch">
        <CardTitle style={{ marginBottom: -16 }}>
          {t('phone_verification')}
        </CardTitle>
        <SubText style={{ marginBottom: 0 }}>
          {t('phone_verification_explanation')}
        </SubText>

        <Formik
          onSubmit={handleStartSignature}
          initialValues={{
            recipientPhone: '',
          }}
          validationSchema={Yup.object().shape({
            recipientPhone: Yup.string()
              .required(t('errors:required'))
              .test(
                'phone',
                t('errors:invalid_phone'),
                (value, context) => value == null || isValidPhoneNumber(value),
              ),
          })}
        >
          {({ isSubmitting, errors, values }) => (
            <Form>
              <Stack gutterSize={1} marginBottom={1.5}>
                <PhoneField
                  name="recipientPhone"
                  label={t('recipient_phone')}
                  placeholder={t('phone_placeholder')}
                />
              </Stack>

              <TextLight>{t('phone_not_stored')}</TextLight>

              <Buttons paddingTop={1} alignX="flex-end">
                <Button type="button" onClick={onRequestClose}>
                  {t('cancel')}
                </Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  variant="primary"
                >
                  {t('send_code')}
                </Button>
              </Buttons>
            </Form>
          )}
        </Formik>
      </Stack>
    </Modal>
  );
};

export default SignaturePhoneInputModal;
