import React from 'react';
import { Modal, Stack } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import { Button } from 'components';

const SubscriptionModal = ({ isOpen, onRequestClose }) => {
  const { t } = useTranslation('dashboard');
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Stack direction="column" gutterSize={2} alignX="center">
        <div>{t('reached_request_limit')}</div>
        <Button
          to="/my-account/invoices"
          onClick={onRequestClose}
          variant="primary"
        >
          {t('go_to_invoices')}
        </Button>
      </Stack>
    </Modal>
  );
};

export default SubscriptionModal;
