import { useContext, useState } from 'react';
import gql from 'graphql-tag';
import { get, isNull, pickBy } from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import { media, Spinner, Stack } from '@tymate/margaret';
import { Trans, useTranslation } from 'react-i18next';
import {
  Header,
  OrganizationCard,
  Button,
  ListFilters,
  EmptyState,
} from 'components';
import { AppContext } from 'contexts';
import { Container, Card } from 'ui';
import {
  Diagram2,
  PlusCircleDotted,
  PlusCircleFill,
  SortAlphaDown,
  SortAlphaUpAlt,
  SortDown,
  SortUpAlt,
} from 'react-bootstrap-icons';
import { Tabs, Tab, TabNavButton } from 'ui/tabs';
import { useDeepCompareEffect } from 'react-use';
import usePostHog from 'hooks/usePosthog';

const OrganizationsListGrid = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${({ theme }) => theme.spacing(2)};
  `}

  ${media.desktop`
    grid-template-columns: repeat(3, 1fr);
  `}
`;

const UPDATE_MEMBER_PREFERENCES = gql`
  mutation updateMemberPreferences($input: UpdateMemberPreferencesInput!) {
    updateMemberPreferences(input: $input) {
      member {
        favorite
        discarded
      }
    }
  }
`;

const GET_TENANTS = gql`
  query tenants(
    $order: TenantOrderCriteria
    $search: String
    $discarded: Boolean
    $favorite: Boolean
  ) {
    tenants(
      order: $order
      name: $search
      discarded: $discarded
      favorite: $favorite
    ) {
      id
      name
      slug
      updatedAt
      logoUrl
      currentMember {
        discarded
        favorite
      }
      canUpdate {
        value
      }
      canUpdatePreferences {
        value
      }
      works {
        totalCount
      }
      members {
        nodes {
          id
          user {
            id
            avatarUrl
            lastName
            firstName
          }
        }
      }
    }
  }
`;

const GET_USER_BILLING_ACCOUNT = gql`
  query OrganizationsList_currentUserBillingAccount {
    currentUser {
      id
      billingAccount {
        id
      }
    }
  }
`;

const OrganizationsList = () => {
  usePostHog();
  const { t } = useTranslation('organizations', 'filters');
  const { user } = useContext(AppContext);
  const [updateMemberPreferences] = useMutation(UPDATE_MEMBER_PREFERENCES);
  const [filters, setFilters] = useState({
    order: null,
    search: undefined,
    discarded: false,
  });

  const orderOptions = [
    {
      value: '-updatedAt',
      label: (
        <Trans i18nKey="filters:updated_date-desc">
          <SortDown size={18} />
          Updated at
        </Trans>
      ),
    },
    {
      value: 'updatedAt',
      label: (
        <Trans i18nKey="filters:updated_date-asc">
          <SortUpAlt size={18} />
          Updated at
        </Trans>
      ),
    },
    {
      value: 'name',
      label: (
        <Trans i18nKey="filters:name-asc">
          <SortAlphaDown size={18} />
          Name
        </Trans>
      ),
    },
    {
      value: '-name',
      label: (
        <Trans i18nKey="filters:name-desc">
          <SortAlphaUpAlt size={18} />
          Name
        </Trans>
      ),
    },
  ];

  const { loading, data, refetch } = useQuery(GET_TENANTS, {
    variables: pickBy({ ...filters }, value => !isNull(value)),
  });
  const tenants = get(data, 'tenants', []);
  const { data: currentBillingAccountData } = useQuery(
    GET_USER_BILLING_ACCOUNT,
  );
  const billingAccount = currentBillingAccountData?.currentUser?.billingAccount;

  const handleArchiveOrganization = async tenantId => {
    try {
      await updateMemberPreferences({
        variables: {
          input: {
            tenantId: tenantId,
            discarded: true,
          },
        },
      });
      refetch();
    } catch (err) {
      console.dir(err);
    }
  };
  const handleUnarchiveOrganization = async tenantId => {
    try {
      await updateMemberPreferences({
        variables: {
          input: {
            tenantId: tenantId,
            discarded: false,
          },
        },
      });
      refetch();
    } catch (err) {
      console.dir(err);
    }
  };
  const handleFavoriteOrganization = async tenantId => {
    try {
      await updateMemberPreferences({
        variables: {
          input: {
            tenantId: tenantId,
            favorite: true,
          },
        },
      });
      refetch();
    } catch (err) {
      console.dir(err);
    }
  };
  const handleUnfavoriteOrganization = async tenantId => {
    try {
      await updateMemberPreferences({
        variables: {
          input: {
            tenantId: tenantId,
            favorite: false,
          },
        },
      });
      refetch();
    } catch (err) {
      console.dir(err);
    }
  };
  const handleUpdateFilters = payload => {
    setFilters({ ...filters, ...payload });
  };

  const filtersNotEmpty = () =>
    Boolean(filters?.order) ||
    Boolean(filters?.search) ||
    Boolean(filters?.favorite || Boolean(filters?.discarded));

  useDeepCompareEffect(() => {
    refetch();
  }, [refetch, filters]);

  return (
    <>
      <Header
        title={t('title')}
        actions={
          user?.canCreateTenant?.value && (
            <Button
              variant="primary"
              to="new"
              icon={<PlusCircleDotted style={{ marginRight: 8 }} size={18} />}
            >
              {t('new_organization')}
            </Button>
          )
        }
        mobileActions={
          user?.canCreateTenant?.value && (
            <Button
              variant="icon"
              to="new"
              icon={<PlusCircleFill size={22} />}
            />
          )
        }
      />

      <Container variant="main">
        {!loading &&
        tenants?.length === 0 &&
        Boolean(user) &&
        !filtersNotEmpty() ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Card>
              {Boolean(billingAccount) && (
                <Stack direction="column" gutterSize={2} alignX="center">
                  <div>{t('create_first_organisation')}</div>
                  <Button variant="primary" to="new">
                    {t('new_organization')}
                  </Button>
                </Stack>
              )}
              {!Boolean(billingAccount) && (
                <Stack direction="column" gutterSize={2} alignX="center">
                  <div>{t('create_billing_account')}</div>
                  <Button variant="primary" to="/my-account/invoices">
                    {t('choose_offer')}
                  </Button>
                </Stack>
              )}
            </Card>
          </div>
        ) : (
          <>
            <ListFilters
              orderOptions={orderOptions}
              onChange={handleUpdateFilters}
            >
              <Tabs hasNoPadding>
                <Tab>
                  <TabNavButton
                    isActive={filters?.favorite}
                    onClick={() => {
                      handleUpdateFilters({ favorite: true, discarded: false });
                    }}
                  >
                    {t('favorited')}
                  </TabNavButton>
                </Tab>
                <Tab>
                  <TabNavButton
                    isActive={!filters?.discarded && !filters.favorite}
                    onClick={() => {
                      let { favorite, ...newfilters } = filters;
                      newfilters.discarded = false;
                      setFilters(newfilters);
                    }}
                  >
                    {t('open')}
                  </TabNavButton>
                </Tab>
                <Tab>
                  <TabNavButton
                    isActive={filters?.discarded}
                    onClick={() => {
                      let { favorite, ...newfilters } = filters;
                      newfilters.discarded = true;
                      setFilters(newfilters);
                    }}
                  >
                    {t('archived')}
                  </TabNavButton>
                </Tab>
              </Tabs>
            </ListFilters>

            {loading ? (
              <Spinner />
            ) : (
              <>
                {tenants?.length === 0 ? (
                  <EmptyState
                    label={t('no_organization')}
                    icon={<Diagram2 />}
                  />
                ) : (
                  <OrganizationsListGrid>
                    {tenants.map(organization => (
                      <OrganizationCard
                        t={t}
                        organization={organization}
                        key={organization.id}
                        onArchiveTenant={handleArchiveOrganization}
                        onUnarchiveTenant={handleUnarchiveOrganization}
                        onFavoriteTenant={handleFavoriteOrganization}
                        onUnfavoriteTenant={handleUnfavoriteOrganization}
                      />
                    ))}
                  </OrganizationsListGrid>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default OrganizationsList;
