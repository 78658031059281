import { Buttons, Spinner, Stack } from '@tymate/margaret';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  PatchPlus,
  Check2All,
  CheckCircleFill,
  XCircleFill,
} from 'react-bootstrap-icons';
import styled, { css } from 'styled-components';
import { fontStyles } from 'ui';
import AvatarAndName from './AvatarAndName';
import Button from './Button';

const RecipientsGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(13em, 1fr));
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

const FeedbackCard = styled(Stack)`
  align-items: center;
  text-align: center;
  padding: 2em 1em;
  max-width: max-content;
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
`;

const FeedbackCardButtons = styled(Buttons)`
  margin-top: inherit;
  justify-content: center;
`;

const RecipientWrapper = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid rgba(0, 0, 0, 18%);
  gap: ${({ theme }) => theme.spacing(1.5)};
  border-radius: 5px;
`;

const RecipientStatus = styled(Stack)`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLight};
  > div {
    svg {
      font-size: 1.375rem;
    }
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.backgroundPrimary};
    border-radius: 100%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ variant }) =>
    variant === 'success' &&
    css`
      > div {
        color: ${({ theme }) => theme.secondary};
        background-color: ${({ theme }) => theme.backgroundSecondary};
      }
    `}

  ${({ variant }) =>
    variant === 'error' &&
    css`
      > div {
        color: ${({ theme }) => theme.error};
        background-color: ${({ theme }) => theme.errorBackground};
      }
    `}
`;

const ConfirmationFeedbackTitle = styled.h4`
  ${fontStyles.h4}
  margin-bottom: 0;
`;

const ConfirmationFeedbackLabel = styled.p`
  ${fontStyles.body};
  color: ${({ theme }) => theme.textLighter};
  margin-bottom: 0;
`;
const ConfirmationFeedbackIcon = styled.div`
  color: ${({ theme }) => theme.textLightest};
  font-size: 8em;
`;

const ConfirmationTitle = styled.h3`
  ${fontStyles.h3};
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
`;
ConfirmationTitle.defaultProps = {
  as: 'h2',
};

const NewRequestConfirmation = ({
  recipients,
  recipientsSubmissionStatus,
  isSubmitting,
  submissionStatus,
  restartPath,
  resetHandler = () => {},
  retryHandler = () => {},
  requestsPath,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('newRequest');
  return (
    <div>
      {submissionStatus !== 'idle' && recipients.length > 0 && (
        <Stack
          direction="column"
          gutterSize={2}
          style={{ marginBottom: '1em' }}
        >
          <ConfirmationTitle>{t('stepper.confirm.subtitle')}</ConfirmationTitle>
          <RecipientsGrid>
            {recipients.map((recipientItem, index) => (
              <RecipientWrapper
                key={`recapRecipientWrapper${index}`}
                alignX="space-between"
                alignY="center"
              >
                <AvatarAndName
                  // variant="withEmail"
                  avatarSize="tiny"
                  size="small"
                  legendSize="small"
                  user={recipientItem}
                />
                {recipientsSubmissionStatus[recipientItem?.id] ===
                  'pending' && <Spinner variant="button" />}
                {recipientsSubmissionStatus[recipientItem?.id] ===
                  'success' && (
                  <RecipientStatus
                    gutterSize={0.25}
                    alignY="center"
                    variant="success"
                  >
                    <div>
                      <CheckCircleFill size={20} />
                    </div>
                  </RecipientStatus>
                )}
                {recipientsSubmissionStatus[recipientItem?.id] === 'error' && (
                  <RecipientStatus
                    gutterSize={0.25}
                    alignY="center"
                    variant="error"
                  >
                    <div>
                      <XCircleFill size={20} />
                    </div>
                  </RecipientStatus>
                )}
              </RecipientWrapper>
            ))}
          </RecipientsGrid>
        </Stack>
      )}

      {!isSubmitting && (
        <>
          {submissionStatus === 'idle' && (
            <FeedbackCard
              direction="column"
              gutterSize={1}
              alignX="center"
              alignY="center"
            >
              <ConfirmationFeedbackIcon>
                <PatchPlus />
              </ConfirmationFeedbackIcon>
              <ConfirmationFeedbackTitle>
                {t('stepper.confirm.idle.title')}
              </ConfirmationFeedbackTitle>
              <ConfirmationFeedbackLabel>
                {t('stepper.confirm.idle.label')}
              </ConfirmationFeedbackLabel>
              <FeedbackCardButtons>
                <Button
                  type="button"
                  variant="primary"
                  to={`${requestsPath}new/recap`}
                >
                  {t('stepper.confirm.idle.verify')}
                </Button>
              </FeedbackCardButtons>
            </FeedbackCard>
          )}
          {submissionStatus === 'pending' && (
            <>
              <Spinner />
            </>
          )}
          {submissionStatus === 'success' && (
            <FeedbackCard
              direction="column"
              gutterSize={1}
              alignX="center"
              alignY="center"
            >
              <ConfirmationFeedbackIcon>
                <Check2All />
              </ConfirmationFeedbackIcon>
              <ConfirmationFeedbackTitle>
                {t('stepper.confirm.success.title')}
              </ConfirmationFeedbackTitle>
              <ConfirmationFeedbackLabel>
                {t('stepper.confirm.success.label')}
              </ConfirmationFeedbackLabel>
              <FeedbackCardButtons>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    navigate(requestsPath);
                  }}
                >
                  {t('stepper.confirm.success.all_requests')}
                </Button>
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => {
                    resetHandler();
                    navigate(restartPath);
                  }}
                >
                  {t('stepper.confirm.success.new_request')}
                </Button>
              </FeedbackCardButtons>
            </FeedbackCard>
          )}
          {submissionStatus === 'error' && (
            <FeedbackCard
              direction="column"
              gutterSize={1}
              alignX="center"
              alignY="center"
            >
              <ConfirmationFeedbackIcon>
                <XCircleFill />
              </ConfirmationFeedbackIcon>
              <ConfirmationFeedbackTitle>
                {t('stepper.confirm.error.title')}
              </ConfirmationFeedbackTitle>

              <FeedbackCardButtons>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    navigate(restartPath);
                  }}
                >
                  {t('stepper.confirm.error.back_to_form')}
                </Button>
                <Button type="button" onClick={retryHandler} variant="primary">
                  {t('stepper.confirm.error.retry')}
                </Button>
              </FeedbackCardButtons>
            </FeedbackCard>
          )}
        </>
      )}
    </div>
  );
};

export default NewRequestConfirmation;
