import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Modal,
  SegmentedControls,
  Stack,
  Buttons,
  List,
  ButtonReset,
  Spinner,
} from '@tymate/margaret';
import { get } from 'lodash';
import { fontStyles, SubText } from 'ui';
import { TextField, Button, AvatarAndName, Form } from 'components';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Box } from '@tymate/margaret';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import * as Yup from 'yup';
import { At, Person } from 'react-bootstrap-icons';
import { useDebounce } from 'react-use';
import Search from './Search';

const CardTitle = styled(Box)`
  ${fontStyles.h3}

  margin: 0;
  font-weight: 700;
`;

CardTitle.defaultProps = {
  as: 'h3',
};

const SelectRecipientButton = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.borderRadiusLarge};

  &:hover {
    background-color: ${({ theme }) => theme.background};
  }
`;

const GET_TENANT = gql`
  query ContactInvitModal_getTenantMembers($slug: String!) {
    tenant(slug: $slug) {
      id
      name
      logoUrl
      members {
        edges {
          node {
            id
            role
            user {
              firstName
              lastName
              email
              avatarUrl
              id
            }
          }
        }
      }
      workContacts {
        edges {
          node {
            user {
              id
              avatarUrl
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;

const ContactInvitModal = ({ onRequestClose, onSubmitInvitation, isOpen }) => {
  const scrollRef = useRef();
  const { organizationSlug } = useParams();
  const { t } = useTranslation(['users', 'errors', 'roles', 'contacts']);

  const [selectedMode, setSelectedMode] = useState('CONTACT');

  const [contactSearch, setContactSearch] = useState('');
  const [debouncedContactSearch, setDebouncedContactSearch] = useState('');

  const { data: usersData, loading } = useQuery(GET_TENANT, {
    variables: { slug: organizationSlug },
  });

  const contacts = get(usersData, 'tenant.workContacts.edges', []).filter(
    ({ node }) => {
      const searchExpression = debouncedContactSearch.toLowerCase();
      return (
        node.user.firstName.toLowerCase().includes(searchExpression) ||
        node.user.lastName.toLowerCase().includes(searchExpression) ||
        node.user.email.toLowerCase().includes(searchExpression)
      );
    },
  );

  const handleSelectMode = mode => {
    setSelectedMode(mode);
  };

  useDebounce(
    () => {
      setDebouncedContactSearch(contactSearch);
    },
    300,
    [contactSearch],
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Stack direction="column" gutterSize={1.5} alignX="center">
        <SegmentedControls
          value={selectedMode}
          onSelect={handleSelectMode}
          options={[
            {
              value: 'CONTACT',
              label: t('select_contact'),
            },
            {
              value: 'EMAIL',
              label: t('add_contact'),
            },
          ]}
        />

        <Stack size="full" direction="column" gutterSize={2} alignX="stretch">
          {selectedMode === 'EMAIL' && (
            <>
              <CardTitle style={{ marginBottom: 0 }}>
                {t('add_contact')}
              </CardTitle>

              <Formik
                onSubmit={onSubmitInvitation}
                initialValues={{
                  role: 'CONTACT',
                  user: {
                    firstName: '',
                    lastName: '',
                    email: '',
                  },
                }}
                validationSchema={Yup.object().shape({
                  user: Yup.object().shape({
                    firstName: Yup.string().required(t('errors:required')),
                    lastName: Yup.string().required(t('errors:required')),
                    email: Yup.string()
                      .email(t('errors:email'))
                      .required(t('errors:required')),
                  }),
                })}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Stack gutterSize={1} marginBottom={1}>
                      <TextField
                        name="user.firstName"
                        label={t('firstName')}
                        icon={<Person size={20} />}
                      />

                      <TextField
                        wrapperStyle={{ marginTop: 0 }}
                        name="user.lastName"
                        label={t('lastName')}
                        icon={<Person size={20} />}
                      />
                    </Stack>

                    <div>
                      <TextField
                        name="user.email"
                        label={t('email')}
                        icon={<At size={20} />}
                      />
                    </div>

                    <Buttons paddingTop={2}>
                      <Button variant="primary" isLoading={isSubmitting}>
                        {t('invit')}
                      </Button>
                      <Button
                        variant="text"
                        onClick={onRequestClose}
                        type="button"
                      >
                        {t('cancel')}
                      </Button>
                    </Buttons>
                  </Form>
                )}
              </Formik>
            </>
          )}

          {selectedMode === 'CONTACT' && (
            <>
              <CardTitle style={{ marginBottom: -16 }}>
                {t('select_contact')}
              </CardTitle>
              <Search
                value={contactSearch}
                onChange={setContactSearch}
                size="full"
              />
              <Scrollbars autoHeight autoHeightMax={250} ref={scrollRef}>
                <List direction="column">
                  {loading && <Spinner />}
                  {contacts.map(({ node }) => (
                    <Stack as="li" key={node?.user?.id} size="full">
                      <SelectRecipientButton
                        alignX="space-between"
                        size="full"
                        alignY="center"
                        as={ButtonReset}
                        onClick={() => {
                          onSubmitInvitation({
                            userId: node?.user?.id,
                            role: 'CONTACT',
                          });
                          onRequestClose();
                        }}
                      >
                        <AvatarAndName
                          user={node?.user}
                          variant="withEmail"
                          avatarSize="large"
                        />
                      </SelectRecipientButton>
                    </Stack>
                  ))}
                  {contacts.length === 0 && (
                    <SubText style={{ marginTop: 0 }}>
                      {t('contacts:no_contacts_found')}
                    </SubText>
                  )}
                </List>
              </Scrollbars>
            </>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default ContactInvitModal;
