import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Modal, Spinner, Stack } from '@tymate/margaret';
import { fontStyles, SubText } from 'ui';
import { useTranslation } from 'react-i18next';
import { Box } from '@tymate/margaret';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import { useBreakpoint } from 'hooks';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Button from './Button';

const CardTitle = styled(Box)`
  ${fontStyles.h3}

  margin: 0;
  font-weight: 700;
`;

CardTitle.defaultProps = {
  as: 'h3',
};

const DocumentContainer = styled(Stack)`
  background-color: ${({ theme }) => theme.disabledBackground};
  padding: ${({ theme }) => theme.spacing(0.5)};
  max-width: 100%;
  overflow: hidden;
`;

const PageNumber = styled.p`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLighter};
  margin: 0;
`;

const PageButton = styled.a`
  ${fontStyles.bodyLarge}
  cursor: pointer;
  color: ${({ theme }) => theme.primary};

  ${({ variant }) =>
    variant === 'disabled' &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.disabledBackground};
    `}
`;

const SignaturePreviewModal = ({
  onRequestClose,
  currentItem,
  onSignDocumentClick,
  isOpen,
}) => {
  const breakpoint = useBreakpoint();
  const { t } = useTranslation('signature');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const computePageWidth = () => {
    switch (breakpoint) {
      case 'desktop':
      case 'tablet':
        return 450;
      default:
        return 300;
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} size="big">
      <Stack direction="column" gutterSize={1.5} alignX="stretch">
        <CardTitle style={{ marginBottom: -16 }}>{t('preview')}</CardTitle>
        <SubText style={{ marginBottom: 0 }}>
          {currentItem?.document?.fileName}
        </SubText>

        {currentItem?.document && (
          <>
            <DocumentContainer direction="row" alignX="center">
              <Document
                file={currentItem.document.url}
                loading={<Spinner />}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page width={computePageWidth()} pageNumber={pageNumber} />
              </Document>
            </DocumentContainer>
            <Stack
              direction="row"
              size="full"
              alignX="space-between"
              alignY="center"
              style={{ marginTop: 5 }}
            >
              <PageNumber>
                {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
              </PageNumber>
              <div>
                <PageButton
                  onClick={previousPage}
                  variant={pageNumber === 1 && 'disabled'}
                >
                  <MdKeyboardArrowLeft size={24} />
                </PageButton>
                <PageButton
                  onClick={nextPage}
                  variant={pageNumber >= numPages && 'disabled'}
                >
                  <MdKeyboardArrowRight size={24} />
                </PageButton>
              </div>
            </Stack>
            <Stack
              direction="row"
              size="full"
              alignX="flex-end"
              alignY="center"
            >
              <Button type="button" onClick={() => onRequestClose()}>
                {t('cancel')}
              </Button>
              <Button variant="primary" onClick={() => onSignDocumentClick()}>
                {t('sign_document')}
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </Modal>
  );
};

export default SignaturePreviewModal;
