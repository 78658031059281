import { useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useRoutes } from 'hooks';
import Organization from './Organization';
import OrganizationSettings from './Settings';
import NewWork from './NewWork';
import Work from './Work';

const GET_TENANT_BREADCRUMB_DATA = gql`
  query getTenantBreadcrumbData($slug: String!) {
    tenant(slug: $slug) {
      id
      name
      logoUrl
    }
  }
`;

const OrganizationIndex = () => {
  const { addRouteName, addRouteAvatarUrl } = useRoutes();
  const { organizationSlug } = useParams();

  const { data } = useQuery(GET_TENANT_BREADCRUMB_DATA, {
    variables: { slug: organizationSlug },
  });

  const tenantName = data?.tenant?.name;
  const tenantAvatarUrl = data?.tenant?.logoUrl;

  useEffect(() => {
    addRouteName({
      path: `/organizations/${organizationSlug}`,
      name: tenantName,
    });
    addRouteAvatarUrl({
      path: `/organizations/${organizationSlug}`,
      url: tenantAvatarUrl,
    });
  }, [
    addRouteAvatarUrl,
    addRouteName,
    organizationSlug,
    tenantAvatarUrl,
    tenantName,
  ]);

  return (
    <Routes>
      <Route path=":workSlug/*" element={<Work />} />
      <Route path="settings/*" element={<OrganizationSettings />} />
      <Route path="new-folder" element={<NewWork />} />
      <Route path="*" element={<Organization />} />
    </Routes>
  );
};

export default OrganizationIndex;
