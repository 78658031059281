// eslint disable no-console

import React from 'react';
import { API_BASE } from '../constants';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from '@apollo/client';
import introspectionQueryResultData from '../generated/fragmentTypes.json';
import { AuthenticationService } from 'services';
import { Observable, relayStylePagination } from '@apollo/client/utilities';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';

const GraphQLProvider = ({ children }) => {
  const request = operation => {
    const token = AuthenticationService.instance.getAccessToken();
    const headers = token
      ? {
          authorization: `Bearer ${AuthenticationService.instance.getAccessToken()}`,
        }
      : {};

    operation.setContext({
      headers,
    });
  };

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  });

  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle;
        Promise.resolve(operation)
          .then(oper => request(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) {
            handle.unsubscribe();
          }
        };
      }),
  );

  const getIsUnauthorized = ({ graphQLErrors, networkError }) =>
    (graphQLErrors || []).filter(({ message }) => message === 'Unauthorized')
      .length > 0 || networkError?.statusCode === 401;

  const client = new ApolloClient({
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      // The useQuery hook uses Apollo Client's watchQuery function
      // https://www.apollographql.com/docs/react/api/core/ApolloClient/#example-defaultoptions-object
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
    },
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (getIsUnauthorized({ graphQLErrors, networkError })) {
          AuthenticationService.instance.clearTokens();
        }
      }),
      onError(({ graphQLErrors, networkError, operation }) => {
        if (getIsUnauthorized({ graphQLErrors, networkError })) {
          AuthenticationService.instance.clearTokens();
        }

        if (Boolean(graphQLErrors))
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
                locations,
              )}, Path: ${path}`,
            ),
          );

        if (Boolean(networkError)) {
          console.log(`[Network error]: ${networkError}`);
        }
      }),
      requestLink,
      createUploadLink({
        uri: `${API_BASE}/graphql`,
      }),
    ]),
    cache: new InMemoryCache({
      fragmentMatcher,
      typePolicies: {
        Query: {
          fields: {
            RequestConnection: relayStylePagination(),
          },
        },
      },
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
