import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import gql from 'graphql-tag';
import { PopoverMenu, Stack, ButtonReset } from '@tymate/margaret';
import { fontStyles } from 'ui';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { ChevronRight, FolderFill } from 'react-bootstrap-icons';
import { useBreakpoint } from 'hooks';

const PopoverHeader = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.separator};
  color: ${({ theme }) => theme.textLight};
  font-weight: bold;
`;

const PopoverContent = styled(Stack)``;

const PopoverFooter = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  justify-content: flex-end;
  box-shadow: 0 -1px 0 0 ${({ theme }) => theme.separator};
`;

const FolderLink = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.textLight};
  ${fontStyles.bodySmall};
  text-align: left;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundPrimary};
  }

  svg {
    color: ${({ theme }) => theme.textLighter};
  }
`;

const GET_FOLDER = gql`
  query getMoveEntityFolders($slug: String!) {
    folder(slug: $slug) {
      id
      slug
      name
      parentFolder {
        id
        slug
        name
      }
      descendantFolders {
        edges {
          node {
            id
            slug
            name
          }
        }
      }
    }
  }
`;

const MOVE_ENTITIES = gql`
  mutation moveEntities($input: MoveEntitiesInput!) {
    moveEntities(input: $input) {
      errors {
        message
        path
      }
      parentFolder {
        id
      }
    }
  }
`;

const MoveEntitiesDropdown = ({
  initialFolderSlug,
  onMoveSuccess,
  documentIds,
  folderIds,
  originParentFolderId,
}) => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'mobile';
  const { t } = useTranslation('vault');

  const [currentFolderSlug, setCurrentFolderSlug] = useState(initialFolderSlug);

  const { data: folderData, refetch: refetchFolder } = useQuery(GET_FOLDER, {
    variables: { slug: currentFolderSlug },
  });

  const [moveEntities] = useMutation(MOVE_ENTITIES);

  const currentFolderName = Boolean(folderData?.folder?.parentFolder)
    ? folderData?.folder?.name
    : t('vault');

  const currentFolder = folderData?.folder;

  const parentFolder = currentFolder?.parentFolder;
  const parentFolderSlug = parentFolder?.slug;

  const descendantFolders = [
    Boolean(parentFolder) && {
      node: { name: '..', slug: '..' },
    },
    ...(currentFolder?.descendantFolders?.edges || []),
  ].filter(Boolean);

  const handleMoveEntities = async () => {
    try {
      if (currentFolder?.id === originParentFolderId) {
        onMoveSuccess();
        refetchFolder();
        return null;
      }
      await moveEntities({
        variables: {
          input: {
            documentIds: documentIds,
            folderIds: folderIds,
            destinationFolderId: currentFolder?.id,
            parentFolderId: originParentFolderId,
          },
        },
      });
      onMoveSuccess();
      refetchFolder();
    } catch (error) {
      console.dir(error);
    }
  };
  useEffect(() => {
    refetchFolder();
  }, [refetchFolder]);

  return (
    <PopoverMenu
      alignment="right"
      style={{ width: isMobile ? '15em' : '20em', maxHeight: 'none' }}
    >
      <PopoverHeader>{currentFolderName}</PopoverHeader>

      <Scrollbars autoHeight>
        <PopoverContent direction="column">
          {descendantFolders
            .map(({ node }) => ({ ...node }))
            .filter(({ id }) => !folderIds.includes(id))
            .map(({ name, slug }, index) => (
              <FolderLink
                as={ButtonReset}
                onClick={e => {
                  e.preventDefault();
                  if (
                    Boolean(folderData?.folder?.parentFolder) &&
                    index === 0
                  ) {
                    setCurrentFolderSlug(parentFolderSlug);
                  } else {
                    setCurrentFolderSlug(slug);
                  }
                }}
                size="full"
                key={slug}
                alignX="space-between"
                alignY="center"
                type="button"
              >
                <Stack gutterSize={0.5}>
                  <FolderFill />
                  <span>{name}</span>
                </Stack>

                <ChevronRight size={14} style={{ minWidth: 14 }} />
              </FolderLink>
            ))}
        </PopoverContent>
      </Scrollbars>
      <PopoverFooter>
        <Button size="small" variant="primary" onClick={handleMoveEntities}>
          {t('move_here')}
        </Button>
      </PopoverFooter>
    </PopoverMenu>
  );
};

export default MoveEntitiesDropdown;
