import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  AccessibleUntil,
  Button,
  Deadline,
  Attachment,
  AvatarAndName,
  SignatureStatus,
  Status,
} from 'components';
import { Trans, useTranslation } from 'react-i18next';
import { useApp, useGetCompletion } from 'hooks';
import { getIconFromType } from 'utils';
import { fontStyles, SubText } from 'ui';
import { get } from 'lodash';
import { Box, Modal, Buttons, Stack, List } from '@tymate/margaret';
import {
  TextareaField,
  DropzoneField,
  RadioButtonField,
  Checkbox,
} from './Forms';
import { FieldArray, useFormikContext } from 'formik';
import { FormField, FormLabel } from 'ui/forms';
import Linkify from 'react-linkify';
import yousignLogo from 'images/yousign-logo.png';
import SignatureProcedure, {
  displaySignatureButton,
} from './SignatureProcedure';
import {
  Check2,
  InfoCircle,
  Journal,
  JournalCheck,
} from 'react-bootstrap-icons';
import { getEmailStatusIcon } from 'utils';

const Description = styled.div`
  ${fontStyles.body};
  color: ${({ theme }) => theme.textLight};
  margin: 0;
  white-space: pre-wrap;
  text-align: justify;

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
  }
`;

const Information = styled(Stack)`
  ${fontStyles.body};
  color: ${({ theme }) => theme.textLighter};
  height: 22px;
`;

const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing()};
`;

const Item = styled(Card)`
  position: relative;

  p {
    color: ${({ theme }) => theme.textLight};
  }
  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
  }
`;

// const bounce = keyframes`
//   from {
//     transform: translateY(0);
//   }
//   to{
//     transform: translateY(-15px);
//   }
// `;

const Icon = styled.div`
  color: ${({ theme }) => theme.primary};
  font-size: 1.25rem;
  line-height: 1.25rem;
`;

// const RequiredIcon = styled.div`
//   // width: 1.5rem;
//   // height: 1.5rem;
//   transition: 300ms;
//   font-size: 0.7rem;
//   line-height: 0;
//   border-radius: 50%;
//   padding: ${({ theme }) => theme.spacing(0.5)};
//   // background: ${({ theme }) => theme.separator};
//   color: ${({ theme }) => theme.tertiaryLighter};

//   position: absolute;
//   top: -1em;
//   right: -1em;
//   ${({ completionState }) =>
//     completionState === 'default' &&
//     css`
//       color: ${({ theme }) => theme.tertiaryLighter};
//       background: ${({ theme }) => theme.warning};
//     `}

//   ${({ completionState }) =>
//     completionState === 'completed' &&
//     css`
//       background: ${({ theme }) => theme.success};
//     `}
//   ${({ completionState }) =>
//     completionState === 'missing' &&
//     css`
//       background: ${({ theme }) => theme.warning};
//       color: ${({ theme }) => theme.tertiaryLighter};
//       animation: ${bounce} 0.35s ease infinite alternate;
//     `}
// `;
const Title = styled.h1`
  ${fontStyles.h1}
  font-family: ${({ theme }) => theme.fonts.title};
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const ItemTitle = styled.h2`
  ${fontStyles.h3}
  font-family: ${({ theme }) => theme.fonts.title};
  margin: 0;
`;

const StatusMessage = styled.div`
  ${fontStyles.body}
  color: ${({ theme }) => theme.error};
  margin: ${({ theme }) => theme.spacing(0.5)} 0 0 0;
`;

const AnswerStatus = styled.div`
  ${fontStyles.bodySmall};

  color: ${({ theme }) => theme.textLighter};

  > svg {
    margin-right: ${({ theme }) => theme.spacing(0.1)};
    color: ${({ theme }) => theme.success};
  }
`;

const YousignLogo = styled.img`
  max-width: 2em;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const YousignText = styled.p`
  margin: 0;
`;

const SignatureSigner = styled(Stack)``;

const Alert = styled(Stack)`
  background-color: ${({ theme }) => theme.background};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ theme }) => theme.spacing()};
  font-size: 0.8rem;

  ${({ variant }) =>
    variant === 'senderSignature' &&
    css`
      background-color: ${({ theme }) => theme.separatorLighter};
      box-shadow: none;
      margin: 0;
      color: ${({ theme }) => theme.textLighter};
    `}
`;

const PersonalRequestTag = styled(Box)`
  ${fontStyles.bodySmall}
  padding: ${({ theme }) => theme.spacing(0.125)}
  ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.borderRadiusPill};
  color: ${({ theme }) => theme.success};
  background-color: ${({ theme }) => theme.successBackground};
`;

const RequestForm = ({
  request,
  refetchRequest,
  signatureAs,
  isLoadingDocuments,
  onDocumentDownload,
  startConfirmation,
}) => {
  const { t } = useTranslation(['request', 'signature']);
  const { user } = useApp();
  const { setFieldValue, values } = useFormikContext();
  const completionPercentage = useGetCompletion(request?.items);

  const [
    acknowledgeConfirmationName,
    setAcknowledgeConfirmationName,
  ] = useState();

  const [signatureData, setSignatureData] = useState({
    start: false,
    item: null,
  });

  const launchSignature = item => {
    setSignatureData({
      start: true,
      item: item,
    });
  };

  const onSignatureFinished = () => {
    setSignatureData({ start: false, item: null });
  };

  return (
    <div>
      <Modal
        isOpen={Boolean(acknowledgeConfirmationName)}
        onRequestClose={() => setAcknowledgeConfirmationName(false)}
      >
        <Title>{t('acknowledge_title')}</Title>
        <SubText>{t('acknowledge_legend')}</SubText>

        <Buttons>
          <Button
            variant="primary"
            type="button"
            onClick={() => {
              setFieldValue(acknowledgeConfirmationName, true);
              setAcknowledgeConfirmationName(null);
            }}
          >
            {t('acknowledge_confirm')}
          </Button>

          <Button
            type="button"
            onClick={() => setAcknowledgeConfirmationName(null)}
          >
            {t('acknowledge_dismiss')}
          </Button>
        </Buttons>
      </Modal>

      <SignatureProcedure
        request={request}
        refetchRequest={refetchRequest}
        signatureAs={signatureAs}
        data={signatureData}
        onProcedureFinished={onSignatureFinished}
      />

      <Stack marginBottom={1}>
        <AvatarAndName
          user={request?.recipient}
          variant="withRole"
          role={t('recipient')}
        />
      </Stack>
      <Stack direction="column" marginBottom={1} gutterSize={-0.5}>
        <Title>{request?.name}</Title>
        <Stack
          direction={{
            default: 'column',
            tablet: 'row',
          }}
          alignY="center"
          size="full"
          gutterSize={0.5}
          gap={1}
        >
          <Status status={request?.status} />
          {request?.canAnswer?.value && request?.canManage?.value && (
            <PersonalRequestTag>{t('personal_request')}</PersonalRequestTag>
          )}
        </Stack>
        {request?.status === 'REJECTED' && (
          <StatusMessage>
            <Trans i18nKey="request:rejection_explanation">
              <strong>Rejection motive : </strong>
              {{ motive: request?.statusMessage }}
            </Trans>
          </StatusMessage>
        )}
      </Stack>
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a
            target="blank"
            rel="noopener noreferer nofollow"
            href={decoratedHref}
            key={key}
          >
            {decoratedText}
          </a>
        )}
      >
        <Description>{request?.description}</Description>
      </Linkify>

      {request?.documents?.length > 0 && (
        <List direction="column" gutterSize={0.5} marginVertical={1}>
          {request?.documents.map(file => (
            <Attachment file={file} withUpload key={file.id} />
          ))}
        </List>
      )}

      <Stack marginTop={0.5} paddingVertical={0.5} alignX="space-between">
        <Deadline deadline={request?.deadline} variant="requestDetail" />

        {request?.canManage?.value && (
          <Information marginVertical={0} alignY="center" gutterSize={0.125}>
            {getEmailStatusIcon(request?.emailStatus)}
            <span>
              {t(`email_status.${t(request?.emailStatus.toLowerCase())}`)}
            </span>
          </Information>
        )}

        <Information marginVertical={0} alignY="center" gutterSize={0.125}>
          {completionPercentage === 100 ? <JournalCheck /> : <Journal />}
          <span>{t('completed_at', { percent: completionPercentage })}</span>
        </Information>
      </Stack>

      <Stack marginTop={0.5} paddingVertical={0.5} alignX="space-between">
        <AccessibleUntil deadline={request?.deadline} variant="requestDetail" />
      </Stack>

      <FieldArray
        name="items"
        render={arrayHelpers => (
          <>
            <List
              direction="column"
              gutterSize={2}
              alignX="stretch"
              marginVertical={1}
            >
              {request?.items.map(
                (
                  {
                    name,
                    description,
                    type,
                    document,
                    choices,
                    message,
                    required,
                    id,
                    answer,
                  },
                  index,
                ) => {
                  const item = request?.items?.[index];

                  return (
                    <Item key={index}>
                      <Stack alignY="center" alignX="space-between">
                        <Stack alignY="center" gutterSize={0.5}>
                          <Icon>{getIconFromType(type)}</Icon>
                          <Stack alignY="center" gutterSize={0.5}>
                            <ItemTitle>{name}</ItemTitle>
                            {/* {required && (
                              <RequiredIcon
                                completionState={
                                  Boolean(item.answer?.acknowledged) ||
                                  Boolean(item.answer?.documents?.length) ||
                                  Boolean(item.answer?.message) ||
                                  Boolean(item.answer?.choice) ||
                                  Boolean(item.recipientSignature)
                                    ? 'completed'
                                    : startConfirmation
                                    ? 'missing'
                                    : 'default'
                                  // startConfirmation ? (answer ? "filled" : "missing") : ("default")
                                }
                              >
                                <Asterisk />
                              </RequiredIcon>
                            )} */}
                          </Stack>
                        </Stack>
                        {answer && (
                          <AnswerStatus>
                            <Check2 size={18} />
                            {t('answer_saved')}
                          </AnswerStatus>
                        )}
                      </Stack>
                      <Linkify
                        componentDecorator={(
                          decoratedHref,
                          decoratedText,
                          key,
                        ) => (
                          <a
                            target="blank"
                            rel="noopener noreferer nofollow"
                            href={decoratedHref}
                            key={key}
                          >
                            {decoratedText}
                          </a>
                        )}
                      >
                        <Description
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          {description}
                        </Description>
                      </Linkify>

                      {Boolean(document) && (
                        <FormField>
                          <FormLabel>
                            {t(
                              type === 'SIGNATURE'
                                ? 'to_be_signed_document'
                                : 'attachment',
                            )}
                          </FormLabel>
                          <Attachment
                            file={document}
                            withUpload
                            withViewStatus={false}
                            onDownload={() => onDocumentDownload(document)}
                          />
                        </FormField>
                      )}

                      {type === 'PROMPT' && (
                        <FormField>
                          <FormLabel>{t('request_answer')}</FormLabel>
                          <Card>
                            <List gutterSize={0.5} direction="column">
                              {choices.map(({ choice, id }) => (
                                <li key={id}>
                                  <RadioButtonField
                                    name={`items.${index}.promptChoiceId`}
                                    label={choice}
                                    value={id}
                                  />
                                </li>
                              ))}
                            </List>
                          </Card>
                        </FormField>
                      )}

                      {type === 'DOCUMENT' && (
                        <DropzoneField
                          label={t('request_answer')}
                          name={`items[${index}].documents`}
                          isFetching={isLoadingDocuments?.[id]}
                        />
                      )}

                      {type === 'MESSAGE' && (
                        <TextareaField
                          minRows={3}
                          name={`items[${index}].message`}
                          label={t('request_answer')}
                        />
                      )}

                      {type === 'ACKNOWLEDGE' && (
                        <FormField>
                          <FormLabel>{t('request_answer')}</FormLabel>
                          <Card>
                            <Checkbox
                              disabled={item?.acknowledged}
                              name={`items[${index}].answer`}
                              label={item?.message}
                              value={get(
                                values,
                                `items[${index}].acknowledged`,
                                false,
                              )}
                              onChange={() =>
                                setAcknowledgeConfirmationName(
                                  `items[${index}].acknowledged`,
                                )
                              }
                              onClick={() => {}}
                            />
                          </Card>
                        </FormField>
                      )}

                      {type === 'SIGNATURE' && (
                        <Stack
                          direction="column"
                          alignY="center"
                          gutterSize={1.5}
                          marginTop={2}
                        >
                          <Stack direction="column" gutterSize={1}>
                            <FormLabel style={{ marginBottom: 0 }}>
                              {t('signature:signers')}
                            </FormLabel>
                            {Boolean(item?.isTwoWaySignature) &&
                              Boolean(item?.senderSignature) && (
                                <SignatureSigner
                                  direction="row"
                                  alignY="center"
                                  gutterSize={1}
                                >
                                  <AvatarAndName
                                    user={request?.author}
                                    size="small"
                                  />
                                  <SignatureStatus
                                    status={item?.senderSignature?.status}
                                  />
                                </SignatureSigner>
                              )}

                            <SignatureSigner
                              direction="row"
                              alignY="center"
                              gutterSize={1}
                            >
                              <AvatarAndName
                                user={request?.recipient}
                                size="small"
                              />
                              <SignatureStatus
                                status={
                                  item?.recipientSignature?.status || 'PENDING'
                                }
                              />
                            </SignatureSigner>
                            {item?.isTwoWaySignature && (
                              <Stack alignY="center">
                                <Alert
                                  variant="senderSignature"
                                  alignY="center"
                                  gutterSize={0.5}
                                >
                                  <span role="img" aria-label="">
                                    <InfoCircle />
                                  </span>
                                  <div>
                                    {t(
                                      'signature:sender_signature_explanation',
                                    )}
                                  </div>
                                </Alert>
                              </Stack>
                            )}
                          </Stack>

                          {displaySignatureButton(
                            request,
                            item,
                            signatureAs,
                            user,
                          ) && (
                            <>
                              <Button
                                type="button"
                                variant="primary"
                                onClick={() => launchSignature(item)}
                              >
                                {t('sign')}
                              </Button>
                              <Stack direction="row" alignY="center">
                                <YousignLogo src={yousignLogo}></YousignLogo>
                                <YousignText>
                                  {t('misc:signature_by_yousign')}
                                </YousignText>
                              </Stack>
                            </>
                          )}
                        </Stack>
                      )}
                    </Item>
                  );
                },
              )}
            </List>
          </>
        )}
      />
    </div>
  );
};

export default RequestForm;
