import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'components';
import { Stack } from '@tymate/margaret';
import { formatDateDistanceToNow, formatDate } from 'utils';
import { fontStyles } from 'ui';
import { isToday } from 'date-fns';
import { capitalize } from 'lodash';
import {
  Bell,
  Envelope,
  FileEarmarkPlus,
  FolderPlus,
  FolderSymlink,
  JournalCheck,
  JournalPlus,
  JournalX,
  PersonDash,
  PersonPlus,
  Trash,
} from 'react-bootstrap-icons';

const WrapperActivity = styled(Stack)`
  align-items: center;

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.textLighter};
  }

  ${({ variant }) =>
    variant === 'compact' &&
    `
      align-items: flex-start
    `}
`;

const DurationTime = styled.div`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLighter};
`;

const ActivityLabel = styled.div`
  display: block;
  color: ${({ theme }) => theme.text};

  strong {
    color: ${({ theme }) => theme.text};
  }

  a {
    color: ${({ theme }) => theme.primary};
  }
`;

const IconWrapper = styled.div`
  min-width: 20px;
`;

const getIconFromItem = (verb, item) => {
  switch (`${verb}-${item}`) {
    case 'create-document':
      return <FileEarmarkPlus size={20} />;
    case 'create-work':
      return <FolderPlus size={20} />;
    case 'contact_invited-user':
    case 'collaborator_invited-user':
      return <PersonPlus size={20} />;
    case 'contact_revoked-user':
    case 'collaborator_revoked-user':
      return <PersonDash size={20} />;
    case 'move-document':
      return <FolderSymlink size={20} />;
    case 'delete-document':
      return <Trash size={20} />;
    case 'create-request':
      return <JournalPlus size={20} />;
    case 'reminder-request':
      return <Envelope size={20} />;
    case 'validated-request':
    case 'finished-request':
      return <JournalCheck size={20} />;
    case 'rejected-request':
      return <JournalX size={20} />;
    default:
      return <Bell size={20} />;
  }
};

const Activity = ({ activity, variant }) => {
  const { t } = useTranslation('activity');
  const activityItem = `${
    activity?.verb
  }-${activity?.item?.__typename.toLowerCase()}`;

  if (
    !activity?.text ||
    activityItem === 'contact_invited-user' ||
    activityItem === 'collaborator_invited-user' ||
    activityItem === 'contact_revoked-user' ||
    activityItem === 'collaborator_revoked-user'
  ) {
    return null;
  }

  return (
    <WrapperActivity gutterSize={0.625} variant={variant}>
      <IconWrapper>
        {getIconFromItem(
          activity?.verb,
          activity?.item?.__typename.toLowerCase(),
        )}
      </IconWrapper>
      {variant !== 'compact' && (
        <Avatar
          firstName={activity?.actor?.firstName}
          lastName={activity?.actor?.lastName}
          imageUrl={activity?.actor?.avatarUrl}
        />
      )}
      <Stack direction="column">
        <ActivityLabel>{activity?.text}</ActivityLabel>
        <DurationTime>
          {isToday(new Date(activity?.createdAt))
            ? t('time_ago', {
                time: formatDateDistanceToNow(activity?.createdAt),
              })
            : capitalize(
                formatDate(activity?.createdAt, t('created_at_format')),
              )}
        </DurationTime>
      </Stack>
    </WrapperActivity>
  );
};

export default Activity;
