import React from 'react';
import styled from 'styled-components';
import { Container, Card, Title } from 'ui';
import { useTranslation, Trans } from 'react-i18next';
import { Button } from 'components';
import { Stack, ButtonReset, theme } from '@tymate/margaret';
import { Check2Circle } from 'react-bootstrap-icons';

const Link = styled(ButtonReset)`
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.primaryLight};
  }
`;

const SubscriptionConfirmation = ({ product, onDismiss }) => {
  const { t } = useTranslation('invoices');

  return (
    <Container variant="main" size="narrow">
      <Card>
        <Title variant="success">
          <Check2Circle style={{ marginRight: theme.spacing(0.5) }} />
          {t('confirmation_title')}
        </Title>

        <Trans i18nKey="invoices:confirmation_body" count={1}>
          <p>
            Votre abonnement « {{ productName: product?.name }} » est désormais
            actif.
          </p>
          <p>
            Retrouvez toutes les informations dans votre compte, dans l’onglet{' '}
            <Link onClick={onDismiss}>Facturation</Link>.
          </p>
          <p>
            Suivez aussi la consommation de vos collectes et retrouvez vos
            factures.
          </p>
        </Trans>

        <Stack marginTop={2} size="full" alignX="center">
          <Button variant="primary" to="/organizations">
            {t('confirmation_go_back')}
          </Button>
        </Stack>
      </Card>
    </Container>
  );
};

export default SubscriptionConfirmation;
