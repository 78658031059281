import styled from 'styled-components';
import { List, ButtonReset, media, RawLink } from '@tymate/margaret';
import { fontSize, fontStyles } from 'ui';

export const Tabs = styled(List)`
  display: flex;
  max-width: 100vw;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  ${media.tablet`
    flex-wrap: wrap;
  `}

  ${({ hasNoPadding }) =>
    hasNoPadding &&
    `
      padding: 0
    `}
`;

Tabs.defaultProps = { padding: 1 };

export const Tab = styled.li`
  display: block;
  + li {
    padding-left: ${({ theme }) => theme.spacing()};
  }
`;

export const TabNavButton = styled(ButtonReset)`
  ${fontSize.bodySmall}

  background: transparent;
  border: 0;
  appearance: none;
  color: ${({ theme }) => theme.textLighter};
  text-decoration: none;
  display: block;
  padding: ${({ theme }) => theme.spacing(0.375)}
    ${({ theme }) => theme.spacing(0.75)};
  outline: none;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  ${fontStyles.bodySmall};
  line-height: 1;

  ${({ isActive, theme }) =>
    isActive &&
    `
      color: ${theme.primary};
      background-color: ${theme.backgroundPrimary};
      border-radius: ${theme.borderRadiusPill};
    `};

  &.active {
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.backgroundPrimary};
    border-radius: ${({ theme }) => theme.borderRadiusPill};
  }

  ${media.tablet`
    width: 100%;
  `}
`;

export const TabNavLink = styled(TabNavButton)``.withComponent(RawLink);
