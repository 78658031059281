import styled, { css } from 'styled-components';
import {
  Link as RawLink,
  useLocation,
  useResolvedPath,
} from 'react-router-dom';
import { Tooltip } from 'components';
import { useTranslation } from 'react-i18next';
import { media, List } from '@tymate/margaret';
import { useBreakpoint } from 'hooks';
import { fontSize } from 'ui';
import { Trans } from 'react-i18next';
import {
  ClockHistory,
  Folder,
  House,
  Inbox,
  People,
  Plus,
} from 'react-bootstrap-icons';

const Wrapper = styled.nav`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  align-items: center;
  height: 49px;
  z-index: 2;

  ${media.tablet`
    box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.08);
    top: 80px;
    width: 72px;
    height: auto;
    flex-direction: column;
    padding-top: ${({ theme }) => theme.spacing()};
  `}
`;

const useMatch = ({ to, exact = false }) => {
  let location = useLocation();
  let resolvedLocation = useResolvedPath(to);

  return exact
    ? location.pathname === resolvedLocation.pathname
    : location.pathname.startsWith(resolvedLocation.pathname);
};

const Link = styled(RawLink)`
  ${fontSize.h2}

  color: ${({ theme }) => theme.textLighter};
  display: flex;
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
  flex-direction: column;
  align-items: center;
  text-decoration: none;

  ${media.tablet`
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
  `}

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.primary};
    `}

    ${({ disabled, theme }) =>
    disabled &&
    `
    color: ${theme.disabled};
    `}
`;

const NavLink = ({ children, ...props }) => {
  const isActive = useMatch(props);

  return (
    <Link {...props} isActive={isActive}>
      {children}
    </Link>
  );
};

const NavLinkLegend = styled.div`
  font-size: 10px;
  line-height: 1.5;
`;

const AddFolderButton = styled(NavLink)`
  display: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 100%;
  height: 40px;
  width: 40px;
  color: ${({ theme }) => theme.primary};
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: ${({ theme }) => theme.spacing(0.75)};

  ${media.tablet`
    display: flex;
    padding: 0;
  `}
`;

const Menu = styled(List)`
  display: flex;
  flex-direction: row;
  width: 100%;

  ${media.tablet`
    flex-direction: column;
    padding-left: ${({ theme }) => theme.spacing()};
    padding-right: ${({ theme }) => theme.spacing()};
  `}
`;

const MenuItem = styled.li`
  flex: 1;
  &::before {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
  }
`;

const TooltipLink = styled(RawLink)`
  color: #fff;
`;

const MenuItemLink = ({ icon, to, legend, mobileLegend, exact, disabled }) => {
  const breakpoint = useBreakpoint();

  if (breakpoint === 'mobile') {
    return (
      <NavLink to={to} exact={exact}>
        {icon}
        <NavLinkLegend>{mobileLegend}</NavLinkLegend>
      </NavLink>
    );
  }

  return (
    <Tooltip tip={legend}>
      <NavLink to={!disabled && to} exact={exact} disabled={disabled}>
        {icon}
      </NavLink>
    </Tooltip>
  );
};

const Sidebar = ({
  shouldShowUsers,
  shouldShowVault,
  shouldShowNewRequest,
  isTenantOwner,
}) => {
  const { t } = useTranslation('folder_sidebar');

  return (
    <Wrapper>
      {shouldShowNewRequest && (
        <AddFolderButton to="requests/new">
          <Plus />
        </AddFolderButton>
      )}

      <Menu alignX="center" alignY="center">
        <MenuItem>
          <MenuItemLink
            legend={t('dashboard')}
            icon={<House />}
            mobileLegend={t('dashboard_mobile')}
            exact
            to=""
            end
          />
        </MenuItem>

        <MenuItem>
          <MenuItemLink
            legend={t('requests')}
            icon={<Inbox />}
            mobileLegend={t('requests_mobile')}
            to="requests"
          />
        </MenuItem>

        {(isTenantOwner || shouldShowVault) && (
          <MenuItem>
            <MenuItemLink
              legend={
                shouldShowVault ? (
                  t('secured_safe')
                ) : (
                  <Trans i18nKey="folder_sidebar:secured_safe_disabled">
                    Your current offer does not allow you to access the safe.{' '}
                    <TooltipLink to="/my-account/invoices">
                      Change my offer
                    </TooltipLink>
                  </Trans>
                )
              }
              icon={<Folder />}
              mobileLegend={t('secured_safe_mobile')}
              to="vault"
              disabled={!shouldShowVault}
            />
          </MenuItem>
        )}

        <MenuItem>
          <MenuItemLink
            legend={t('activities')}
            icon={<ClockHistory />}
            mobileLegend={t('activities_mobile')}
            to="activities"
          />
        </MenuItem>
        {shouldShowUsers && (
          <MenuItem>
            <MenuItemLink
              legend={t('users')}
              icon={<People />}
              mobileLegend={t('users_mobile')}
              to="users"
            />
          </MenuItem>
        )}
      </Menu>
    </Wrapper>
  );
};

export default Sidebar;
