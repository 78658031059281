import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Stack, theme as MgtTheme } from '@tymate/margaret';
import { fontStyles, theme } from 'ui';
import { Check2Circle, EyeSlash } from 'react-bootstrap-icons';

const Wrapper = styled(Stack)`
  ${fontStyles.bodySmall}
  color: ${({ theme }) => theme.textLight};
`;

const AttachmentViewStatus = ({ viewed }) => {
  const { t } = useTranslation('attachment');

  return (
    <Wrapper direction="row" size="full" alignY="center">
      <div style={{ marginRight: MgtTheme.spacing(0.25) }}>
        {viewed ?
          <Check2Circle size={20} color={theme.secondary} /> :
          <EyeSlash size={20} color={theme.textLight} />
        }
      </div>
      <div>{viewed ? t('viewed') : t('not_viewed')}</div>
    </Wrapper>
  );
};

export default AttachmentViewStatus;
