/**
 * Outputs a completion rate between 0 and 100 from the received items
 * 
 * 
 * @param {*} items
 * @param {*} onlyRequired
 * @returns
 */

export const useGetCompletion = (items, onlyRequired = false) => {
  let filteredItems = onlyRequired
    ? items?.filter(item => Boolean(item?.required)) || []
    : items;

  if (!filteredItems || filteredItems?.length === 0) return 100;
  const completionRate = Math.floor(
    (100 *
      filteredItems.filter(item => {
        return (
          Boolean(item.answer?.acknowledged) ||
          Boolean(item.answer?.documents?.length) ||
          Boolean(item.answer?.message) ||
          Boolean(item.answer?.choice) ||
          Boolean(item.recipientSignature) ||
          Boolean(item.type === 'ATTACHMENT')
        );
      }).length) /
      filteredItems.length,
  );
  return completionRate;
};
