import { useState } from 'react';
import styled, { css } from 'styled-components';
import { get, uniqueId } from 'lodash';
import { Field } from 'formik';
import { SubText } from 'ui';
import {
  ErrorMessageWrapper,
  FormField,
  FormLabel,
  InputStyles,
} from 'ui/forms';
import PhoneInput from 'react-phone-number-input';

const PhoneInputElement = styled(PhoneInput)`
  width: 100%;

  .PhoneInputCountry {
    margin: 0;
    padding: 0 ${({ theme }) => theme.spacing(0.5)};
    border: 1px solid ${({ theme }) => theme.separator};
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    ${({ hasError, theme }) =>
      hasError &&
      css`
        &:not([disabled]),
        &:not([disabled]):hover,
        &:not([disabled]):active,
        &:not([disabled]):focus {
          border: 1px solid ${({ theme }) => theme.error};
          border-right: 0;
        }
      `}
  }

  .PhoneInputInput {
    ${InputStyles}

    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .PhoneInputCountryIcon--border {
    box-shadow: none;
  }
`;

const RenderPhoneField = props => {
  const {
    placeholder,
    field,
    label,
    value,
    optional,
    form,
    wrapperStyle,
    ...rest
  } = props;

  const hasError =
    get(form.errors, field.name) && get(form.touched, field.name);
  return (
    <FormField style={wrapperStyle}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {label && <FormLabel>{label}</FormLabel>}
        {optional && <SubText style={{ margin: 0 }}>{optional}</SubText>}
      </div>
      <div style={{ position: 'relative', display: 'flex' }}>
        <PhoneInputElement
          defaultCountry="FR"
          international
          countryCallingCodeEditable={false}
          placeholder={placeholder}
          name={field?.name}
          value={field?.value}
          hasError={hasError}
          onChange={value => {
            form.setFieldValue(field?.name, value || '');
          }}
          onBlur={() => form.setFieldTouched(field?.name, true)}
          {...rest}
        />
      </div>

      {hasError && (
        <ErrorMessageWrapper>
          {get(form.errors, field.name)}
        </ErrorMessageWrapper>
      )}
    </FormField>
  );
};

const PhoneField = props => {
  const [id] = useState(uniqueId());
  return <Field {...props} id={id} component={RenderPhoneField} />;
};

export default PhoneField;
