import React from 'react';
import styled from 'styled-components';
import logo from 'images/logo.png';
import { media } from '@tymate/margaret';
import { LanguageSelector } from 'components';

const Page = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.tablet`
    background-color: ${({ theme }) => theme.background};
  `}
`;

const Card = styled.div`
  width: 27em;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tablet`
    background-color: #fff;
    box-shadow: ${({ theme }) => theme.cardBoxShadow};
    border-radius: ${({ theme }) => theme.borderRadius};
  `}
`;

const Logo = styled.img`
  max-width: 4em;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const AuthLayout = ({ children }) => (
  <Page>
    <Card>
      <Logo src={logo} />
      {children}
    </Card>
    <LanguageSelector style={{ marginTop: '1rem' }} />
  </Page>
);

export default AuthLayout;
