import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FieldArray, useFormikContext } from 'formik';
import { Stack, Buttons, ButtonReset } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Button, ErrorMessage } from 'components';
import { FormField, FormLabel, Input } from 'ui/forms';
import { X } from 'react-bootstrap-icons';

const PromptChoices = styled(Stack)`
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.5)} 0;
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({hasError}) =>
  hasError && css`
  border: 1px solid ${({ theme }) => theme.error};
  box-shadow: none;
  `
}
`;

const PromptChoice = styled(Stack)`
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.75)};
  border-radius: ${({ theme }) => theme.borderRadiusPill};
`;

const PromptChoicesField = ({ name, label }) => {
  const { values } = useFormikContext();
  const [newPrompt, setNewPrompt] = useState('');
  const { t } = useTranslation(['newRequest', 'errors']);
  const {error, touched} = useFormikContext()?.getFieldMeta(name)
  const {submitCount} = useFormikContext()
  const hasError = Boolean(submitCount) && Boolean(error) && Boolean(touched);
  return (
    <FormField>
      {Boolean(label) && <FormLabel>{label}</FormLabel>}

      <FieldArray name={name}>
        {arrayHelpers => (
          <>
            <PromptChoices direction="column" hasError={hasError}>
              {get(values, name, []).length > 0 && (
                <Buttons alignY="center" spacingSize="small">
                  {get(values, name, []).map(({ choice }, index) => (
                    <PromptChoice key={index} alignY="center">
                      <span>{choice}</span>
                      <ButtonReset
                        onClick={() => arrayHelpers.remove(index)}
                        type="button"
                      >
                        <X size={20} />
                      </ButtonReset>
                    </PromptChoice>
                  ))}
                </Buttons>
              )}

              <Stack size="full" marginTop={-0.5}>
                <Input
                  type="text"
                  variant="bare"
                  name={name}
                  placeholder={t('new_prompt_placeholder')}
                  value={newPrompt}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      if (!newPrompt) {
                        return;
                      }
                      setNewPrompt('');
                      arrayHelpers.push({
                        choice: newPrompt,
                      });
                    }
                  }}
                  onChange={e => setNewPrompt(e.target.value)}
                />
                <Button
                  variant="text"
                  disabled={!Boolean(newPrompt)}
                  type="button"
                  onClick={() => {
                    setNewPrompt('');
                    arrayHelpers.push({
                      choice: newPrompt,
                    });
                  }}
                >
                  {t('add_prompt_choice')}
                </Button>
              </Stack>
            </PromptChoices>
            <ErrorMessage name={name}/>
          </>
        )}
      </FieldArray>
    </FormField>
  );
};

export default PromptChoicesField;
