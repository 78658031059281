import { useApp, useBreakpoint } from 'hooks';
import styled from 'styled-components';
import { Routes, Route, Link, Navigate, useLocation } from 'react-router-dom';
import logo from 'images/logo.png';
import logoHorizontal from 'images/logo-horizontal.svg';
import { UserDropdown, Breadcrumb } from 'components';
import Account from 'containers/Account';
import Organizations from 'containers/Organizations';
import { media } from '@tymate/margaret';
import UploadProvider from 'containers/UploadProvider';
import DownloadProvider from 'containers/DownloadProvider';
import { useKeycloak } from '@react-keycloak/web';
import * as Sentry from '@sentry/react';

const Page = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background};
`;

const Header = styled.header`
  position: fixed;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)}
    ${({ theme }) => theme.spacing(1.25)};
  height: 56px;

  ${media.tablet`
    height: 80px;
    justify-content: space-around;
  `}
`;

const Logo = styled(Link)`
  img {
    display: block;
    max-width: 40px;
    height: auto;

    ${media.tablet`
      max-width: 120px;
    `}
  }

  ${({ variant }) =>
    variant === 'disabled' &&
    `
      cursor: default;
      pointer-events: none;
  `}
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const Main = styled.div`
  padding-top: 56px;

  ${media.tablet`
    padding-top: 80px;
  `}
`;

const App = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const { user } = useApp();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'mobile';

  if (!keycloak?.authenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (breakpoint === 'loading') {
    return <Page />;
  }

  return (
    <UploadProvider>
      <DownloadProvider>
        <Page>
          <Header>
            <Nav>
              <Logo to="/">
                <img src={isMobile ? logo : logoHorizontal} alt="Paprwork" />
              </Logo>
              <Breadcrumb />
            </Nav>

            <UserDropdown user={user} />
          </Header>

          <Main>
            <Routes>
              <Route path="organizations/*" element={<Organizations />} />
              <Route path="my-account/*" element={<Account />} />
              <Route path="*" element={<Organizations />} />
            </Routes>
          </Main>
        </Page>
      </DownloadProvider>
    </UploadProvider>
  );
};

export default Sentry.withProfiler(App);
