import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { UploadFilesContext } from 'contexts';
import { Stack, ButtonReset } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import Uppy from '@uppy/core';
import { Dashboard, StatusBar } from '@uppy/react';
import {
  API_BASE,
  MAX_FILE_SIZE,
  FILENAME_INVALID_CHARACTERS,
  FILESIZE_OPTIONS,
} from '../constants';
import XHRUpload from '@uppy/xhr-upload';
import '@uppy/core/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import frenchLocale from '@uppy/locales/lib/fr_FR';
import { useApp } from 'hooks';
import { useDeepCompareEffect } from 'react-use';
import { ChevronUp, X } from 'react-bootstrap-icons';
import {
  generateAvailableName,
  isFolderPath,
  replaceFolderNameWithAvailableName,
} from 'utils';
import { AuthenticationService } from 'services';
import fileSize from 'filesize';
import i18n from 'i18n';

const Widget = styled.div`
  position: fixed;
  bottom: 0;
  right: 32px;
  border-radius: 6px 6px 0 0;
  background-color: #ffffff;
  color: ${({ theme }) => theme.textLight};
  box-shadow: 0 12px 48px 0 ${({ theme }) => theme.separator},
    0 12px 24px 0 ${({ theme }) => theme.separator};
  width: 20rem;

  .uppy-Dashboard-Item-previewInnerWrap {
    background-color: ${({ theme }) => theme.primaryLight} !important;
  }

  .uppy-Dashboard-inner {
    border-radius: 0;
    color: ${({ theme }) => theme.textLight};
    font-family: ${({ theme }) => theme.fonts.body};
  }

  .uppy-Dashboard-AddFiles,
  .uppy-DashboardContent-bar,
  ${'' /* .uppy-StatusBar-statusPrimary, */} .uppy-StatusBar-additionalInfo,
  .uppy-StatusBar-details {
    display: none;
  }

  .uppy-StatusBar.is-complete .uppy-StatusBar-progress {
    background-color: ${({ theme }) => theme.success};
  }

  .uppy-StatusBar {
    width: 20rem;
    max-width: 20rem;
    ${'' /* height: min-content; */}
    padding-left: ${({ theme }) => theme.spacing(0.25)};
    overflow-x: hidden;
    color: ${({ theme }) => theme.textLight};
    font-family: ${({ theme }) => theme.fonts.body};

    &:before {
      display: none;
    }
  }

  .uppy-StatusBar-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.primary};
  }
`;

const WidgetHeader = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
})`
  font-weight: 600;
  line-height: 1.3;

  span {
    font-size: 10px;
    color: ${({ theme }) => theme.textLighter};
    font-weight: 400;
  }

  svg {
    font-size: 20px;
  }
`;

const WidgetHeaderBar = styled(Stack).attrs({
  size: 'full',
  direction: 'row',
  paddingTop: 0.75,
  paddingHorizontal: 0.75,
  alignX: 'stretch',
})``;

const IconButton = styled(ButtonReset)`
  transition: transform 150ms ease;

  ${({ transform }) =>
    Boolean(transform) &&
    `
      transform: ${transform};
    `}
`;

const UploadProvider = ({ children }) => {
  const { notify } = useApp();
  const { t } = useTranslation('upload_widget');
  const accessToken = AuthenticationService.instance.getAccessToken();

  const [widgetIsExpanded, setWidgetIsExpanded] = useState(false);
  const [widgetIsExpandable, setWidgetIsExpandable] = useState(false);
  const [widgetIsShown, setWidgetIsShown] = useState(false);

  const [takenDocumentNames, setTakenDocumentNames] = useState([]);
  const [takenFolderNames, setTakenFolderNames] = useState([]);

  const generateUppy = ({ accessToken, takenDocumentNames }) => {
    const maxSize = MAX_FILE_SIZE;
    const uppy = Uppy({
      id: 'uppy1',
      autoProceed: true,
      debug: process.env !== 'production',
      locale: {
        ...frenchLocale,
        strings: {
          ...frenchLocale?.strings,
          uploading: t('uploading'),
          complete: t('complete'),
          uploadFailed: t('uploadFailed'),
        },
      },
      onBeforeFileAdded: (currentFile, files) => {
        // Prevent uploading dotfiles (.DS_Store, .gitignore, …)
        if (currentFile.name.indexOf('.') === 0) {
          return false;
        }

        // prevent uploading files with size > 1GB
        if (currentFile?.data?.size > maxSize) {
          notify(
            t('errors:file_too_big', {
              size: fileSize(maxSize, {
                ...FILESIZE_OPTIONS,
                round: 2,
                locale: i18n.language,
              }),
            }),
            { type: 'error' },
          );
          return false;
        }

        var cleanPath = currentFile?.meta?.file_path || currentFile?.name;

        if (isFolderPath(cleanPath)) {
          cleanPath = replaceFolderNameWithAvailableName({
            path: cleanPath,
            takenNames: takenFolderNames,
          });
        }

        const newPath = generateAvailableName({
          fileName: cleanPath, // Send whole file path to look for existing names in the same folder only
          takenNames: takenDocumentNames,
        });
        const newName = newPath.split('/').pop();

        const newFile = {
          ...currentFile,
          meta: {
            ...currentFile.meta,
            file_path: newPath,
          },
          name: newName,
        };

        return newFile;
      },
    }).use(XHRUpload, {
      limit: 3,
      timeout: 0, // Removed timeout to prevent requests cancellation for huge file uploads
      endpoint: `${API_BASE}/api/v1/documents`,
      fieldName: 'file',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    uppy.on('files-added', () => {
      setWidgetIsShown(true);
      setWidgetIsExpandable(true);
    });

    uppy.on('upload-success', file => {});

    uppy.on('complete', result => {
      if (Boolean(result?.successful?.[0]?.meta?.request)) {
        result?.successful?.[0]?.meta?.request();
      }
      let hasWrongNames = result?.failed?.find(
        failure => !FILENAME_INVALID_CHARACTERS.test(failure.name),
      );
      if (hasWrongNames) {
        notify(t('errors:invalid_filename'), { type: 'error' });
        setWidgetIsExpanded(true);
      }
    });

    return uppy;
  };

  const uppyRef = useRef(generateUppy({ accessToken, takenDocumentNames }));

  const handleUploadFiles = ({ files, folderId, request }) => {
    setWidgetIsShown(true);
    (files || []).forEach(async file => {
      try {
        uppyRef.current.addFile({
          data: file,
          name: file?.name,
          meta: {
            folder_id: folderId,
            file_path: file?.webkitRelativePath,
            request,
          },
        });
      } catch (err) {}
    });
  };

  useDeepCompareEffect(() => {
    uppyRef.current = generateUppy({ accessToken, takenDocumentNames });
  }, [{ accessToken, takenDocumentNames }]);

  return (
    <UploadFilesContext.Provider
      value={{
        onUploadDocuments: handleUploadFiles,
        uppy: uppyRef.current,
        setTakenDocumentNames,
        setTakenFolderNames,
      }}
    >
      {children}

      {widgetIsShown && (
        <Widget>
          <WidgetHeader>
            <WidgetHeaderBar>
              <Stack direction="column" size="full">
                <div>{t('title')}</div>
              </Stack>

              <Stack alignY="center" gutterSize={0.5}>
                <IconButton
                  type="button"
                  onClick={() => setWidgetIsExpanded(!widgetIsExpanded)}
                  transform={widgetIsExpanded ? 'rotate(180deg)' : 'none'}
                >
                  <ChevronUp size={20} />
                </IconButton>
                <IconButton onClick={() => setWidgetIsShown(false)}>
                  <X size={24} />
                </IconButton>
              </Stack>
            </WidgetHeaderBar>

            <StatusBar
              style={{ width: '100%' }}
              uppy={uppyRef.current}
              id="StatusBar"
              target=".ici"
              hideAfterFinish={false}
              showProgressDetails
              hideUploadButton
              hideRetryButton={false}
              hidePauseResumeButton={false}
              hideCancelButton={false}
              doneButtonHandler={null}
            />
          </WidgetHeader>

          {widgetIsExpanded && widgetIsExpandable && (
            <Dashboard
              waitForThumbnailsBeforeUpload
              uppy={uppyRef.current}
              inline
              hideUploadButton={true}
              width={'20rem'}
              height={320}
              thumbnailWidth={20}
              disableStatusBar
              proudlyDisplayPoweredByUppy={false}
              disableInformer
            />
          )}
        </Widget>
      )}
    </UploadFilesContext.Provider>
  );
};

export default UploadProvider;
