import React from 'react';
import { format, formatDistanceToNow } from 'date-fns';
import { fr, enGB, es } from 'date-fns/locale';
import { omitBy, isNil, pickBy, includes } from 'lodash';
import queryString from 'query-string';
import { snakeCase } from 'lodash';
import {
  CardImage,
  CheckCircleFill,
  Cursor,
  Eye,
  EyeSlash,
  FileCode,
  FileEarmark,
  FileEarmarkFill,
  FileEasel,
  FileMusic,
  FilePdf,
  FileRichtext,
  FileSpreadsheet,
  FileText,
  FileZip,
  Film,
  InfoSquareFill,
  FileArrowUpFill,
  PenFill,
  UiRadios,
  FileArrowDownFill,
} from 'react-bootstrap-icons';
import { PREVIEWABLE_MIME_TYPES, FILENAME_INVALID_CHARACTERS } from '../constants';
import i18next from 'i18next';

const getLocale = () => {
  switch (i18next.language) {
    case 'fr':
      return fr;
    case 'en':
      return enGB;
    case 'es':
      return es;
    default:
      return fr;
  }
};
export const containsInvalidCharacters = (filename) =>{
  return !FILENAME_INVALID_CHARACTERS.test(filename);
}

export const formatDate = (date, frmt) => {
  try {
    return format(new Date(date), frmt, { locale: getLocale() });
  } catch (err) {
    return '';
  }
};

export const formatDateDistanceToNow = date =>
  formatDistanceToNow(new Date(date), { locale: getLocale() });

export const getIconFromType = type => {
  switch (type) {
    case 'MESSAGE':
      return <InfoSquareFill />;
    case 'ACKNOWLEDGE':
      return <CheckCircleFill />;
    case 'PROMPT':
      return <UiRadios />;
    case 'SIGNATURE':
      return <PenFill />;
    case 'ATTACHMENT':
      return <FileArrowUpFill />;
    case 'DOCUMENT':
      return <FileArrowDownFill />;
    default:
      return <FileEarmarkFill />;
  }
};

export const getDocumentIconFromMimeType = (mimeType, size) => {
  const iconSize = size || '1em';
  switch (mimeType) {
    case 'application/pdf':
      return <FilePdf size={iconSize} />;
    case 'text/plain':
      return <FileText size={iconSize} />;
    case 'text/html':
    case 'text/css':
    case 'text/javascript':
      return <FileCode size={iconSize} />;
    case 'application/zip':
    case 'application/x-7z-compressed':
    case 'application/x-bzip':
    case 'application/x-bzip2':
    case 'application/x-rar-compressed':
    case 'application/x-tar':
      return <FileZip size={iconSize} />;
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.oasis.opendocument.text':
      return <FileRichtext size={iconSize} />;
    case 'application/csv':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.oasis.opendocument.spreadsheet':
      return <FileSpreadsheet size={iconSize} />;
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.oasis.opendocument.presentation':
      return <FileEasel size={iconSize} />;
    case 'image/gif':
    case 'image/png':
    case 'image/jpeg':
    case 'image/bmp':
    case 'image/webp':
    case 'image/x-icon':
    case 'image/svg+xml':
    case 'image/tiff':
      return <CardImage size={iconSize} />;
    case 'video/x-msvideo':
    case 'video/mpeg':
    case 'video/ogg':
    case 'video/webm':
    case 'video/mp4':
      return <Film size={iconSize} />;
    case 'audio/aac':
    case 'audio/midi':
    case 'audio/mpeg':
    case 'audio/webm':
    case 'audio/ogg':
    case 'audio/wav':
    case 'audio/x-wav':
      return <FileMusic size={iconSize} />;
    default:
      return <FileEarmark size={iconSize} />;
  }
};

export const getEmailStatusIcon = status => {
  switch (status) {
    case 'click':
      return <Cursor />;
    case 'open':
      return <Eye />;
    case 'unknown':
    case 'bounced':
    default:
      return <EyeSlash />;
  }
};

export const formatInitialRequestValues = request => ({
  items: request?.items.map(
    ({
      name,
      message,
      description,
      type,
      answer,
      document,
      required,
      recipientSignature,
      senderSignature,
      status,
      isTwoWaySignature,
      id,
    }) =>
      omitBy(
        {
          requestItemId: id,
          promptChoiceId: answer?.choice?.id,
          message: answer?.message,
          documents:
            type === 'DOCUMENT'
              ? (answer?.documents ?? []).map(document => ({
                  ...document,
                  documentId: document.id,
                }))
              : null,
          acknowledged: answer?.acknowledged,
          name,
          type,
          required,
          acknowledgeMessage: type === 'ACKNOWLEDGE' ? message : null,
          document: type === 'SIGNATURE' ? document : null,
          recipientSignature: type === 'SIGNATURE' ? recipientSignature : null,
          senderSignature: type === 'SIGNATURE' ? senderSignature : null,
          status: type === 'SIGNATURE' ? status : null,
          isTwoWaySignature: type === 'SIGNATURE' ? isTwoWaySignature : null,
        },
        isNil,
      ),
  ),
});

export const overrideSearchWithParams = ({ location, ...rest }) => {
  const output = queryString.stringify(
    pickBy({
      ...queryString.parse(location.search),
      ...rest,
    }),
  );

  if (!Boolean(output)) {
    return null;
  }

  return `?${output}`;
};

export const getOrderVariableFromLocationSearch = sort => {
  if (!Boolean(sort) || false) {
    return null;
  }

  return {
    direction: sort.charAt(0) === '-' ? 'DESC' : 'ASC',
    column: snakeCase(sort).toUpperCase(),
  };
};

export const formatCurrency = (amount, options) => {
  if (!amount && amount !== 0) {
    return '';
  }

  if (options?.stripCurrencySymbol) {
    return amount / 100;
  }

  const currency = (options?.currency || 'EUR').toUpperCase();

  const output = new Intl.NumberFormat('fr', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  }).format(amount / 100);

  if (options?.stripDecimals) {
    return output.replace(/[,.]00/, '');
  }

  return output;
};

export const formatSymbol = () => {
  return '€';
};

export const isFolderPath = path => {
  return path.includes('/');
};

/*
  Generates a new filename when filename is already taken in takenNames Array
*/
export const generateAvailableName = ({ fileName, takenNames }) => {
  if (!includes(takenNames, fileName)) return fileName;

  const [name, ...fileNameChunks] = (fileName || '').split('.');
  var desiredName = name,
    nameIndex = 0,
    newFileName;

  do {
    nameIndex++;
    desiredName = `${name} (${nameIndex})`;
    newFileName = [desiredName, ...fileNameChunks].join('.');
  } while (includes(takenNames, newFileName));

  return newFileName;
};

export const replaceFolderNameWithAvailableName = ({ path, takenNames }) => {
  var cleanPath = path.replace(/^\/+/, ''); // Remove leading slash
  var chunks = cleanPath.split('/');

  chunks[0] = generateAvailableName({
    fileName: chunks[0],
    takenNames,
  });

  return chunks.join('/');
};

// Returns whether a file is previewable or not
export const isPreviewable = file =>
  PREVIEWABLE_MIME_TYPES.includes(file.mimeType);
