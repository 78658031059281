import React, { useState } from 'react';
import { Dropzone } from 'components';
import { FormField, FormLabel, ErrorMessage } from 'ui/forms';
import { Field, FieldArray } from 'formik';
import { uniqueId, get } from 'lodash';
import { List } from '@tymate/margaret';
import { Attachment } from 'components';
import { useTranslation } from 'react-i18next';
import { useApp } from 'hooks';
import { MAX_FILE_SIZE } from '../../constants';
import { containsInvalidCharacters } from 'utils';

const RenderDropzoneField = props => {
  const { t } = useTranslation('errors');
  const { notify } = useApp();

  const {
    field,
    label,
    form: { setFieldValue, setFieldTouched, errors },
  } = props;

  const handleAddFiles = files => {
    const maxSize = MAX_FILE_SIZE;
    const nextValue = field.value || [];
    let invalidFilenames = false;
    [...(files || [])].forEach(file => {
      if (file.size > maxSize) {
        notify(t('file_too_big'), { type: 'error' });
      }
      if (containsInvalidCharacters(file?.name)) invalidFilenames = true; 
        nextValue.push({ file });
    });
    if (invalidFilenames) {
      notify(t('errors:invalid_filename'), { type: 'error' });
    }
    setFieldValue(field.name, nextValue);
  };

  return (
    <div>
      <FormField>
        <FormLabel>{label}</FormLabel>
        {get(field, 'value', []).length > 0 && (
          <FieldArray
            name={field.name}
            render={({ remove }) => (
              <List direction="column" gutterSize={0.5} marginBottom={1}>
                {(field.value || []).map((file, index) => (
                  <li>
                    <Attachment
                      file={file?.file || file}
                      onRemove={() => remove(index)}
                    />
                  </li>
                ))}
              </List>
            )}
          />
        )}
        <ErrorMessage>{errors[field?.name]}</ErrorMessage>
        <Dropzone
          onAdd={handleAddFiles}
          onClick={() => setFieldTouched(field.name, true)}
          {...props}
        />
      </FormField>
    </div>
  );
};

const DropzoneField = props => {
  const [id] = useState(uniqueId());
  return <Field {...props} id={id} component={RenderDropzoneField} />;
};

export default DropzoneField;
