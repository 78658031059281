import React, { useState } from 'react';
import styled from 'styled-components';
import { Stack, media, Modal } from '@tymate/margaret';
import { fontSize, fontStyles, Legend, theme } from 'ui';
import { Button } from 'components';
import { formatCurrency, formatSymbol } from 'utils';
import { find, orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormField, FormLabel, Input } from 'ui/forms';
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';

const OffersGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${({ theme }) => theme.spacing(2)};
  `}

  ${media.desktop`
    grid-template-columns: repeat(4, 1fr);
  `}
`;

const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex: 1;
  padding: ${({ theme }) => theme.spacing()};
  border: solid 1px ${({ theme }) => theme.separator};
  border-radius: 6px;
`;

const ProductCardHeader = styled.div`
  min-height: 70px;
`;

const ProductCardBody = styled.div`
  flex: 1;
`;

const ProductCardFooter = styled(Stack)`
  margin-top: ${({ theme }) => theme.spacing()};
  align-items: stretch;
`;

const OfferName = styled.p`
  margin: 0;
  ${fontSize.bodyLarge};
  font-weight: bold;
`;

const Price = styled.div`
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  font-size: 2rem;
  font-weight: bold;

  span {
    font-size: 1rem;
  }
`;

const CurrentOffer = styled.p`
  margin: 0;
  height: 40px;
  text-align: center;
  width: 100%;
  line-height: 40px;
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
`;

const Detail = styled(Stack)`
  margin-top: ${({ theme }) => theme.spacing(0.25)};
  ${fontStyles.bodySmall};

  svg {
    height: 18px;
    color: ${({ theme }) => theme.success};
  }

  ${({ variant, theme }) =>
    variant === 'disabled' &&
    `
      color: ${theme.textLighter};

      svg {
        color: ${theme.textLighter};
      }
    `}
`;

const Offers = ({
  stripeProducts = [],
  currentProduct,
  onSelect,
  isUpdatingCurrentOffer,
  currentBillingAccount,
}) => {
  const { t } = useTranslation('invoices');
  const [shouldShowPriceIdModal, setShouldShowPriceIdModal] = useState(false);
  const [priceId, setPriceId] = useState('');

  return (
    <>
      <Modal
        isOpen={shouldShowPriceIdModal}
        onRequestClose={() => setShouldShowPriceIdModal(false)}
      >
        <FormField>
          <FormLabel>Code de mon offre sur mesure</FormLabel>
          <Input
            name="priceId"
            onChange={e => setPriceId(e.target.value)}
            value={priceId}
          />
          <Stack marginTop={2}>
            <Button
              variant="primary"
              onClick={() => {
                onSelect(priceId);
                setShouldShowPriceIdModal(false);
                setPriceId('');
              }}
            >
              {t('save')}
            </Button>
            <Button
              onClick={() => {
                setShouldShowPriceIdModal(false);
                setPriceId('');
              }}
            >
              {t('cancel')}
            </Button>
          </Stack>
        </FormField>
      </Modal>
      <OffersGrid>
        <ProductCard>
          <ProductCardHeader>
            <OfferName>Freemium</OfferName>
            <Price>
              {formatCurrency(0, { stripCurrencySymbol: true })}
              <span>{formatSymbol('eur')}</span>
            </Price>
          </ProductCardHeader>

          <ProductCardBody>
            <Detail gutterSize={0.5} size="full">
              <span>
                <CheckCircleFill />
              </span>
              <span>{t('noCreditCardRequired')}</span>
            </Detail>
            <Detail gutterSize={0.5} size="full">
              <span>
                <CheckCircleFill />
              </span>
              <span>{t('freemiumTokens')}</span>
            </Detail>
            <Detail gutterSize={0.5} size="full" variant="disabled">
              <span>
                <XCircleFill />
              </span>
              <span>{t('accessToVault')}</span>
            </Detail>
            <Detail gutterSize={0.5} size="full" variant="disabled">
              <span>
                <CheckCircleFill />
              </span>
              <span>{t('accessToSignature')}</span>
            </Detail>
          </ProductCardBody>

          <ProductCardFooter>
            {Boolean(currentBillingAccount) && !Boolean(currentProduct) ? (
              <CurrentOffer>{t('currentOffer')}</CurrentOffer>
            ) : (
              <Button
                disabled={isUpdatingCurrentOffer}
                variant="outline"
                size="full"
                onClick={() => onSelect('FREEMIUM')}
              >
                {t('chooseOffer')}
              </Button>
            )}
          </ProductCardFooter>
        </ProductCard>

        {orderBy(
          stripeProducts,
          ({ prices }) => prices?.[0]?.tiers?.[0]?.flatAmount || 0,
        )
          .filter(({ visible }) => Boolean(visible))
          .map(
            ({
              name,
              prices,
              vault,
              signature,
              stripeSignaturePriceId,
              stripeSignatureProduct,
              id,
            }) => {
              const signaturePrice = find(
                stripeSignatureProduct?.prices || [],
                ({ id }) => id === stripeSignaturePriceId,
              );

              return (
                <ProductCard key={id}>
                  <ProductCardHeader>
                    <OfferName>{name}</OfferName>
                    <Price>
                      {formatCurrency(prices?.[0]?.tiers?.[0]?.flatAmount, {
                        currency: prices?.[0]?.currency,
                        stripCurrencySymbol: true,
                        stripDecimals: true,
                      })}
                      <span>
                        {formatSymbol(prices?.[0]?.currency)}
                        {Boolean(prices?.[0]?.tiers?.[0]?.flatAmount) &&
                          t('monthly')}
                      </span>
                    </Price>
                  </ProductCardHeader>

                  <ProductCardBody>
                    <Detail gutterSize={0.5} size="full">
                      <span>
                        <CheckCircleFill />
                      </span>
                      <span>
                        {t('includedAndAdditionnalTokensPrices', {
                          upTo: prices?.[0]?.tiers?.[0]?.upTo,
                          additionnalTokenPrice: formatCurrency(
                            prices?.[0]?.tiers?.[1]?.unitAmount,
                          ),
                        })}
                      </span>
                    </Detail>

                    <Detail
                      gutterSize={0.5}
                      size="full"
                      variant={!vault && 'disabled'}
                    >
                      <span>
                        {vault ? <CheckCircleFill /> : <XCircleFill />}
                      </span>
                      <span>{t('accessToVault')}</span>
                    </Detail>
                    <Detail
                      gutterSize={0.5}
                      size="full"
                      variant={!signature && 'disabled'}
                    >
                      <span>
                        <CheckCircleFill />
                      </span>
                      <span>{t('accessToSignature')}</span>
                    </Detail>
                    {signature && (
                      <Detail gutterSize={0.5} size="full">
                        <span>
                          <CheckCircleFill />
                        </span>
                        <span>
                          {t('signature_prices', {
                            price: formatCurrency(signaturePrice?.unitAmount),
                          })}
                        </span>
                      </Detail>
                    )}
                  </ProductCardBody>

                  <ProductCardFooter>
                    {id === currentProduct?.id ? (
                      <CurrentOffer>{t('currentOffer')}</CurrentOffer>
                    ) : (
                      <Button
                        variant="outline"
                        size="full"
                        onClick={() => onSelect(prices?.[0]?.id)}
                        disabled={isUpdatingCurrentOffer}
                      >
                        {t('chooseOffer')}
                      </Button>
                    )}
                  </ProductCardFooter>
                </ProductCard>
              );
            },
          )}

        <ProductCard>
          <ProductCardHeader>
            <OfferName>{t('custom')}</OfferName>
            <Button
              as="a"
              href="mailto:contact@paprwork.io"
              style={{
                paddingLeft: 0,
                display: 'flex',
                textDecoration: 'none',
                color: theme.primary,
                width: '100%',
                fontWeight: 600,
              }}
            >
              {t('contactUs')}
            </Button>
          </ProductCardHeader>

          <ProductCardBody>
            <Detail gutterSize={0.5} size="full">
              <span>
                <CheckCircleFill />
              </span>
              <span>{t('onDemandTokenPricing')}</span>
            </Detail>
            <Detail gutterSize={0.5} size="full">
              <span>
                <CheckCircleFill />
              </span>
              <span>{t('accessToVault')}</span>
            </Detail>
            <Detail gutterSize={0.5} size="full">
              <span>
                <CheckCircleFill />
              </span>
              <span>{t('accessToSignature')}</span>
            </Detail>
          </ProductCardBody>

          <ProductCardFooter>
            <Button
              onClick={() => setShouldShowPriceIdModal(true)}
              variant="outline"
              size="full"
            >
              {t('withCode')}
            </Button>
          </ProductCardFooter>
        </ProductCard>
      </OffersGrid>
      <Stack alignX="center" size="full" marginTop={1}>
        <Legend size="small">{t('tokenEquivalence')}</Legend>
      </Stack>
      <Stack alignX="center" size="full">
        <Legend size="small">{t('priceComplementaryInfo')}</Legend>
      </Stack>
    </>
  );
};

export default Offers;
