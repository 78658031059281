import React, { useRef } from 'react';
import styled from 'styled-components';
import {
  Dropdown,
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
  RawLink,
  Stack,
} from '@tymate/margaret';
import { fontStyles, fontSize } from 'ui';
import { AvatarsStack, Avatar } from './';
import { formatDateDistanceToNow } from 'utils';
import {
  FolderFill,
  Pencil,
  ThreeDotsVertical,
  Star,
  Archive,
} from 'react-bootstrap-icons';

const Wrapper = styled.div`
  position: relative;
`;

const OrganizationLink = styled(RawLink)`
  border-radius: 12px;
  box-shadow: 0 4px 4px -4px ${({ theme }) => theme.separatorLight};
  border: solid 1px ${({ theme }) => theme.separatorLight};
  background-color: #ffffff;
  padding: ${({ theme }) => theme.spacing()};
  min-height: 182px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: 0;
    color: ${({ theme }) => theme.textLight};
    ${fontStyles.bodySmall}
  }

  ${({ isArchived }) =>
    isArchived &&
    `
      background-color: rgba(0, 0, 0, 0.02);
      box-shadow: none;

      

  `}
`;

const OrganizationName = styled(Stack)`
  ${fontStyles.h3};

  font-family: ${({ theme }) => theme.fonts.title};
  font-weight: bold;
  margin: 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ variant, theme }) =>
    variant === 'main' &&
    `
      padding-right: ${theme.spacing(1.5)}
    `}
`;

const FolderCount = styled.div`
  ${fontStyles.bodySmall}
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.textLight};

  svg {
    ${fontSize.bodyLarge}

    margin-right: ${({ theme }) => theme.spacing(0.5)};
    color: ${({ theme }) => theme.primary};
  }
`;

const Trigger = styled.div`
  position: absolute;
  top: 0;
  right: ${({ theme }) => theme.spacing(0.5)};

  svg {
    ${fontSize.h2}
    color: ${({ theme }) => theme.textLight};
  }
`;

const Icon = styled.span``;

const Actions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing(0.5)};
`;

const OrganizationCard = ({
  organization,
  t,
  onArchiveTenant,
  onUnarchiveTenant,
  onFavoriteTenant,
  onUnfavoriteTenant,
}) => {
  const dropdownRef = useRef();

  return (
    <Wrapper>
      <OrganizationLink
        to={`/organizations/${organization.slug}`}
        isArchived={organization?.currentMember?.discarded}
      >
        <Row variant="main">
          <div>
            <OrganizationName gutterSize={0.5} alignY="center">
              {organization?.logoUrl && (
                <Avatar
                  variant="rounded"
                  size="small"
                  imageUrl={organization?.logoUrl}
                />
              )}
              <span>{organization.name}</span>
            </OrganizationName>
            <p>
              {t('last_updated', {
                relativeDate: formatDateDistanceToNow(organization.updatedAt),
              })}
            </p>
          </div>
        </Row>
        <Row>
          <AvatarsStack users={organization?.members?.nodes} />
          <FolderCount>
            <FolderFill />
            {t('folder_count', { count: organization.works.totalCount })}
          </FolderCount>
        </Row>
      </OrganizationLink>
      {
        <Actions>
          <Dropdown
            ref={dropdownRef}
            trigger={
              <Trigger>
                <ThreeDotsVertical />
              </Trigger>
            }
          >
            <PopoverMenu alignment="right" style={{ width: '15em' }}>
              {organization?.canUpdate?.value && (
                <PopoverItem>
                  <PopoverItemButton
                    as={RawLink}
                    to={`${organization.slug}/settings`}
                  >
                    <Icon>
                      <Pencil />
                    </Icon>
                    {t('edit')}
                  </PopoverItemButton>
                </PopoverItem>
              )}
              {organization?.canUpdatePreferences?.value && (
                <>
                  {!organization?.currentMember?.discarded && (
                    <PopoverItem>
                      <PopoverItemButton
                        onClick={
                          organization?.currentMember?.favorite
                            ? () => {
                                onUnfavoriteTenant(organization.id);
                                dropdownRef.current.close();
                              }
                            : () => {
                                onFavoriteTenant(organization.id);
                                dropdownRef.current.close();
                              }
                        }
                      >
                        <Icon>
                          <Star />
                        </Icon>
                        {organization?.currentMember?.favorite
                          ? t('unfavorite')
                          : t('favorite')}
                      </PopoverItemButton>
                    </PopoverItem>
                  )}
                  <PopoverItem>
                    <PopoverItemButton
                      onClick={
                        organization?.currentMember?.discarded
                          ? () => {
                              onUnarchiveTenant(organization.id);
                              dropdownRef.current.close();
                            }
                          : () => {
                              onArchiveTenant(organization.id);
                              dropdownRef.current.close();
                            }
                      }
                    >
                      <Icon>
                        <Archive />
                      </Icon>
                      {organization?.currentMember?.discarded
                        ? t('unarchive')
                        : t('archive')}
                    </PopoverItemButton>
                  </PopoverItem>
                </>
              )}
              {/* {organization?.canUpdatePreferences && (
              <PopoverItem>
                <PopoverItemButton>
                  <Icon>
                    <Archive />
                  </Icon>
                  {Boolean(false) ? t('archive') : t('unarchive')}
                </PopoverItemButton>
              </PopoverItem>
            )} */}

              {/* <PopoverItem>
          //   <PopoverItemButton>
          //   <Icon>
          //   <ArrowLeftRight />
          //   </Icon>
          //   {t('change_owner')}
          //   </PopoverItemButton>
          // </PopoverItem> */}
            </PopoverMenu>
          </Dropdown>
        </Actions>
      }
    </Wrapper>
  );
};

export default OrganizationCard;
