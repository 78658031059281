import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { Legend, Title } from 'ui';
import { Stack, media } from '@tymate/margaret';
import { useTranslation, Trans } from 'react-i18next';
import requestCompletedImage from 'images/request-completed.svg';
import requestUncompletedImage from 'images/request-uncompleted.svg';
import { Modal, Buttons } from '@tymate/margaret';
import { useGetCompletion } from 'hooks';

const Img = styled.img`
  display: block;
  width: 192px;
  height: 192px;

  ${media.tablet`
    width: 256px;
    height: 256px;
  `}
`;

const CompletionModal = ({
  isOpen,
  onRequestClose,
  request,
  onConfirmRequest,
  isConfirmingRequest,
}) => {
  const { t } = useTranslation('request');

  // const requiredItemsLength = request?.items.filter(item =>
  //   Boolean(item.required),
  // ).length;
  // const requiredCompletionPercentage =
  //   requiredItemsLength === 0
  //     ? 100
  //     : Math.floor(
  //         (100 *
  //           (
  //             request?.items.filter(item => Boolean(item.required)) || []
  //           ).filter(item => {
  //             return (
  //               Boolean(item.answer?.acknowledged) ||
  //               Boolean(item.answer?.documents?.length) ||
  //               Boolean(item.answer?.message) ||
  //               Boolean(item.answer?.choice) ||
  //               Boolean(item.recipientSignature)
  //             );
  //           }).length) /
  //           (request?.items.filter(item => Boolean(item.required)) || [])
  //             .length,
  //       );
  const completionPercentage = useGetCompletion(request?.item);

  // const requiredCompletionPercentage = useGetCompletion(request?.item, true);
  let state = completionPercentage === 100 ? 'Complete' : 'Uncomplete';
  let stateImage =
    completionPercentage === 100
      ? requestCompletedImage
      : requestUncompletedImage;

  // const completeRequired = requiredCompletionPercentage === 100;

  // const translationKey = `request:request${state}.legend${
  //   !completeRequired ? '_missingRequiredItems' : ''
  // }`;
  const translationKey = `request:request${state}.legend`;

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Stack
        direction="column"
        alignX="center"
        style={{ textAlign: 'center' }}
        gutterSize={1}
      >
        <Img src={stateImage} alt={`Request ${state}d`} />
        <Title>{t(`request${state}.title`)}</Title>
        <div>
          <Trans i18nKey={translationKey}>
            Congratulations, you have completed 100% of the necessary
            information needed for the request{' '}
            <strong>{{ requestName: request?.name }}</strong>
            <br />
            <br />
            Do you want to confirm the information?
          </Trans>
        </div>

        <Buttons paddingTop={2}>
          <Button type="button" onClick={onRequestClose}>
            {t(`request${state}.dismiss`)}
          </Button>
          {/* {completeRequired && ( */}
          <Button
            variant="primary"
            type="button"
            onClick={onConfirmRequest}
            isLoading={isConfirmingRequest}
          >
            {t(`request${state}.submit`)}
          </Button>
          {/* )} */}
        </Buttons>
        {/* {completeRequired && ( */}
        <Legend size="small">{t('request:requestComplete.warning')}</Legend>
        {/* )} */}
      </Stack>
    </Modal>
  );
};

export default CompletionModal;
