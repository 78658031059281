import React, { useState } from 'react';
import styled from 'styled-components';
import Select from 'components/Select';
import { useDebounce, useDeepCompareEffect } from 'react-use';
import { useLocation, useNavigate } from 'react-router-dom';
import { snakeCase } from 'lodash';
import { Stack } from '@tymate/margaret';
import LocationAwareSearch from './LocationAwareSearch';
import { useBreakpoint, useSearchParams } from 'hooks';
import { overrideSearchWithParams } from 'utils';
import { useTranslation } from 'react-i18next';

const Content = styled(Stack)`
  justify-content: flex-start;
  margin-bottom: 0;
  margin-left: 0;
`;

const getOrderVariableFromLocationSearch = sort => {
  if (!Boolean(sort) || false) {
    return null;
  }

  return {
    direction: sort.charAt(0) === '-' ? 'DESC' : 'ASC',
    column: snakeCase(sort).toUpperCase(),
  };
};

const ListFilters = ({
  isOrderable = true,
  isSearchable = true,
  orderOptions = [],
  onChange,
  children,
}) => {
  const { t } = useTranslation('misc');
  const location = useLocation();
  const navigate = useNavigate();
  const { order, search } = useSearchParams();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'mobile';
  const [debouncedSearch, setDebouncedSearch] = useState();
  const filters = {
    order: getOrderVariableFromLocationSearch(order),
    search: debouncedSearch,
  };

  const handleReorder = order => {
    const search =
      overrideSearchWithParams({
        location,
        order: order,
      }) || '';

    navigate({
      pathname: location.pathname,
      search,
    });
  };

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    300,
    [search],
  );

  useDeepCompareEffect(() => {
    onChange(filters);
  }, [filters]);

  return (
    <Stack
      direction={{ default: 'column', tablet: 'row' }}
      alignX={{ tablet: 'space-between' }}
      marginBottom={1}
      gutterSize={1}
    >
      <Content>{children}</Content>

      <Stack gutterSize={1}>
        {isSearchable && <LocationAwareSearch size={isMobile && 'full'} />}
        {isOrderable && (
          <Select
            alignment="right"
            value={order}
            onChange={handleReorder}
            options={orderOptions}
            placeholder={t('sort_by')}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ListFilters;
