import React from 'react';
import { Button } from 'components';
import { Buttons, Stack } from '@tymate/margaret';
import { Formik } from 'formik';
import { Form, TextField, AvatarField, PhoneField } from 'components/Forms';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Legend as UILegend } from 'ui';
import styled from 'styled-components';
import { FormLabel } from 'ui/forms';
import { LanguageSelector } from 'components';

const Legend = styled(UILegend)`
  margin-top: ${({ theme }) => theme.spacing(0.25)};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

const ProfileForm = ({ user, onSubmit }) => {
  const { t } = useTranslation(['account', 'auth', 'errors']);

  const handleSubmit = values => onSubmit(values);

  return (
    <Formik
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={{
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phone: user?.phone,
        company: user?.company,
        avatar: user?.avatarUrl,
        destroyAvatar: false,
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required(t('errors:required')),
        lastName: Yup.string().required(t('errors:required')),
        email: Yup.string()
          .email(t('errors:email'))
          .required(t('errors:required')),
        phone: Yup.string()
          .test(
            'phone',
            t('errors:invalid_phone'),
            (value, context) => value == null || isValidPhoneNumber(value),
          )
          .nullable(),
        company: Yup.string().nullable(),
      })}
    >
      {({ handleReset, isSubmitting }) => (
        <Form>
          <AvatarField
            name="avatar"
            label={t('profile_picture')}
            destroyName="destroyAvatar"
          />
          <TextField name="lastName" label={t('auth:lastName')} />
          <TextField name="firstName" label={t('auth:firstName')} />
          <TextField name="email" label={t('auth:email')} disabled />
          <PhoneField
            name="phone"
            label={t('auth:phone')}
            optional={t('auth:optional')}
          />
          <Legend size="small">{t('phone_legend')}</Legend>
          <TextField name="company" label={t('auth:company')} />

          <Stack
            direction="column"
            marginTop={5}
            marginBottom={1.5}
            size="full"
          >
            <FormLabel>{t('language')}</FormLabel>
            <LanguageSelector size="big" />
          </Stack>

          <Buttons>
            <Button variant="primary" isLoading={isSubmitting}>
              {t('save')}
            </Button>
            <Button type="button" onClick={handleReset}>
              {t('cancel')}
            </Button>
          </Buttons>
        </Form>
      )}
    </Formik>
  );
};

export default ProfileForm;
