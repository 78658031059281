import React from 'react';
import styled from 'styled-components';
import logo from 'images/logo.png';
import { fontStyles } from 'ui';
import { media, Stack } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

const Page = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.background};
`;

const Header = styled.header`
  position: fixed;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)}
    ${({ theme }) => theme.spacing(1.25)};
  height: 56px;

  > * + * {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }

  ${media.tablet`
    height: 80px;
  `}
`;

const Logo = styled.div`
  img {
    display: block;
    max-width: 40px;
    height: auto;
  }
`;

const Main = styled.div`
  padding-top: 56px;

  ${media.tablet`
    padding-top: 80px;
  `}
`;

const Baseline = styled.div`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLighter};
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
  span {
    color: ${({ theme }) => theme.primary};
  }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 124px;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing(1.5)};
`;

const ExternalLayout = ({ children, headerChildren }) => {
  const { t } = useTranslation('misc');

  return (
    <Page>
      <Header>
        <Logo>
          <img src={logo} alt="Paprwork" />
        </Logo>

        {headerChildren}

        <Stack direction="row" alignY="center" gutterSize={1.5}>
          <LanguageSelector />
          <Baseline>
            {t('powered_by')} <span>Paprwork</span>
          </Baseline>
        </Stack>
      </Header>

      <Main>{children}</Main>

      <Footer>
        <Logo>
          <img src={logo} alt="Paprwork" />
        </Logo>
        <Baseline>
          {t('powered_by')} <span>Paprwork</span>
        </Baseline>
        <Baseline>{t('compliance')}</Baseline>
      </Footer>
    </Page>
  );
};
export default ExternalLayout;
