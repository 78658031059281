import React from 'react';
import styled, { css } from 'styled-components';
import { Button as MgtButton, Stack, Spinner } from '@tymate/margaret';
import { fontSize } from 'ui';

export const Button = styled(MgtButton)`
  padding: ${({ theme }) => theme.spacing(0.625)}
    ${({ theme }) => theme.spacing(1.5)};
  border-radius: 28px;
  height: ${({ theme }) => theme.buttonHeight};
  appearance: none;
  -webkit-appearance: none;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: initial;
      color: ${({ theme }) => theme.textDisabled};
    `}
  ${({ direction }) =>
    direction === 'reverse' &&
    css`
      flex-direction: row-reverse;
      > svg {
        margin-right: 0;
        margin-left: ${({ theme }) => theme.spacing(0.25)};
      }
      > div {
        margin-left: ${({ theme }) => theme.spacing(1)};
      }
    `}

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      background: ${({ theme }) => theme.primaryGradient};
      background-color: ${({ theme }) => theme.primaryLight};
      box-shadow: ${({ theme }) => theme.buttonShadow};
      color: #fff;

      &:hover {
        background-color: ${({ theme }) => theme.primaryLight};
      }
    `}

  ${({ variant }) =>
    variant === 'link' &&
    css`
      color: ${({ theme }) => theme.primaryPrimary};
      text-decoration: underline;

      &:hover {
        color: ${({ theme }) => theme.primaryLight};
        text-decoration: none;
      }
    `}

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      background: ${({ theme }) => theme.secondaryGradient};
      background-color: ${({ theme }) => theme.secondaryLight};
      box-shadow: ${({ theme }) => theme.buttonShadowSecondary};
      color: #fff;

      &:hover {
        background-color: ${({ theme }) => theme.secondaryLight};
      }
    `}

  ${({ variant }) =>
    variant === 'outline' &&
    css`
      color: ${({ theme }) => theme.primary};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.separator};

      &:hover {
        color: ${({ theme }) => theme.primaryLight};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.separator};
      }

      &:disabled,
      &:disabled:hover {
        background-color: ${({ theme }) => theme.disabledBackground};
        color: ${({ theme }) => theme.disabled};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.separator};
      }
    `}
  ${({ variant }) =>
    variant === 'primaryText' &&
    css`
      background: ${({ theme }) => theme.primaryGradient};
      background-color: ${({ theme }) => theme.primaryLight};
      width: max-content;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:disabled,
      &:disabled:hover {
        color: ${({ theme }) => theme.textDisabled};
      }
    `}

  ${({ variant }) =>
    variant === 'totalOutline' &&
    css`
      color: ${({ theme }) => theme.textLighter};
      box-shadow: 0 0 0 1px ${({ theme }) => theme.textLighter};

      &:hover {
        color: ${({ theme }) => theme.separator};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.separator};
      }

      &:disabled,
      &:disabled:hover {
        background-color: ${({ theme }) => theme.disabledBackground};
        color: ${({ theme }) => theme.disabled};
        box-shadow: 0 0 0 1px ${({ theme }) => theme.separator};
      }
    `}
  ${({ variant }) =>
    // For low priority buttons, no outline and grey text
    variant === 'totalText' &&
    css`
      color: ${({ theme }) => theme.textLighter};
      &:hover {
        color: ${({ theme }) => theme.separator};
      }

      &:disabled,
      &:disabled:hover {
        background-color: ${({ theme }) => theme.disabledBackground};
        color: ${({ theme }) => theme.disabled};
      }
    `}

  ${({ variant }) =>
    variant === 'text' &&
    css`
      color: ${({ theme }) => theme.primary};
      padding-left: 0;
      padding-right: 0;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        color: ${({ theme }) => theme.primaryLight};
      }

      &:disabled,
      &:disabled:hover {
        color: ${({ theme }) => theme.textDisabled};
      }
    `}

  ${({ variant }) =>
    variant === 'icon' &&
    css`
      color: ${({ theme }) => theme.primary};
      padding: 0;
      height: ${({ theme }) => theme.spacing(1.5)};
      min-width: ${({ theme }) => theme.spacing(1.5)};
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: ${({ theme }) => theme.primary};
      }

      &:disabled,
      &:disabled:hover {
        color: ${({ theme }) => theme.textDisabled};
      }

      > svg {
        margin-right: 0;
      }
    `}

    ${({ size }) =>
    size === 'small' &&
    css`
      ${fontSize.bodySmall}
      height: auto;
      padding: ${({ theme }) => theme.spacing(0.375)}
        ${({ theme }) => theme.spacing(0.5)};
      line-height: 1;
    `}

    ${({ variant }) =>
    Boolean(variant) &&
    variant !== 'text' &&
    variant !== 'icon' &&
    css`
      &:disabled,
      &:disabled:hover {
        background: transparent;
        background-color: ${({ theme }) => theme.disabledBackground};
        color: ${({ theme }) => theme.textDisabled};
        cursor: default;
      }
    `}

    ${({ variant }) =>
    (!Boolean(variant) || variant === 'text' || variant === 'icon') &&
    css`
      &:disabled,
      &:disabled:hover {
        background-color: transparent;
        color: ${({ theme }) => theme.textDisabled};
        cursor: default;
      }
    `}
`;

const FileButtonWrapper = styled(Button)`
  font-weight: 600;
  display: flex;
  cursor: pointer;
  align-items: center;

  &:hover {
    background: ${({ theme }) => theme.primaryLight};
  }

  svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }
`;

export const FileButton = ({ icon, label, onAdd, isMobile, isLoading }) => {
  if (isMobile) {
    return (
      <FileButtonWrapper
        variant="icon"
        as="label"
        icon={icon}
        isLoading={isLoading}
      >
        <Stack alignY="center" gutterSize={Boolean(icon) ? 0.25 : 0}>
          {icon}
          <input
            type="file"
            style={{ display: 'none' }}
            accept="image/*,.pdf"
            onChange={e => onAdd(e.target.files)}
            onClick={e => (e.target.value = null)}
          />
        </Stack>
      </FileButtonWrapper>
    );
  }

  return (
    <FileButtonWrapper
      variant="primary"
      as="label"
      icon={icon}
      isLoading={isLoading}
    >
      <Stack alignY="center" gutterSize={Boolean(icon) ? 0.25 : 0}>
        {isLoading && <Spinner variant="button" />}
        {!isLoading && icon}
        <span>{label}</span>
        <input
          type="file"
          style={{ display: 'none' }}
          multiple
          onChange={e => onAdd(e.target.files)}
          onClick={e => (e.target.value = null)}
        />
      </Stack>
    </FileButtonWrapper>
  );
};

export default Button;
