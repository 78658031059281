import { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { get, pickBy } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { Buttons, Spinner, Stack } from '@tymate/margaret';
import {
  Button,
  Header,
  WorkCard,
  ListFilters,
  AvatarAndName,
  EmptyState,
} from 'components';
import { Container } from 'ui';
import { useBreakpoint } from 'hooks';
import { Tabs, Tab, TabNavButton } from 'ui/tabs';
import {
  Folder,
  FolderPlus,
  PlusCircleDotted,
  Sliders,
  SortAlphaDown,
  SortAlphaUpAlt,
  SortDown,
  SortUpAlt,
} from 'react-bootstrap-icons';
import { useDeepCompareEffect } from 'react-use';
import usePostHog from 'hooks/usePosthog';

const GET_TENANT = gql`
  query getTenantAndWorks(
    $slug: String!
    $archived: Boolean
    $order: WorkOrderCriteria
    $search: String
    $cursor: String
  ) {
    tenant(slug: $slug) {
      id
      name
      logoUrl
      canCreateWork {
        value
      }
      canUpdate {
        value
      }
      owner {
        id
        firstName
        lastName
        avatarUrl
      }
      works(
        first: 10
        archived: $archived
        order: $order
        name: $search
        after: $cursor
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            slug
            name
            createdAt
            updatedAt
            canUpdate {
              value
            }
            canDelete {
              value
            }
            discardedAt
            roles {
              nodes {
                name
                users {
                  nodes {
                    id
                    firstName
                    lastName
                    avatarUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ARCHIVE_WORK = gql`
  mutation deleteWork($input: DeleteWorkInput!) {
    deleteWork(input: $input) {
      work {
        id
        name
        discardedAt
      }
    }
  }
`;

const RESTORE_WORK = gql`
  mutation restoreWork($input: RestoreWorkInput!) {
    restoreWork(input: $input) {
      work {
        id
        name
        discardedAt
      }
    }
  }
`;

const OrganizationDetail = () => {
  usePostHog();
  const { organizationSlug } = useParams();
  const breakpoint = useBreakpoint();
  const { t } = useTranslation('organization', 'roles', 'filters');
  const [filters, setFilters] = useState({ order: null, search: undefined });

  const orderOptions = [
    {
      value: '-updatedAt',
      label: (
        <Trans i18nKey="filters:updated_date-desc">
          <SortDown size={18} />
          Updated at
        </Trans>
      ),
    },
    {
      value: 'updatedAt',
      label: (
        <Trans i18nKey="filters:updated_date-asc">
          <SortUpAlt size={18} />
          Updated at
        </Trans>
      ),
    },
    {
      value: 'name',
      label: (
        <Trans i18nKey="filters:name-asc">
          <SortAlphaDown size={18} />
          Name
        </Trans>
      ),
    },
    {
      value: '-name',
      label: (
        <Trans i18nKey="filters:name-desc">
          <SortAlphaUpAlt size={18} />
          Name
        </Trans>
      ),
    },
  ];

  const { data, fetchMore, refetch, loading } = useQuery(GET_TENANT, {
    variables: pickBy({ slug: organizationSlug, ...filters }),
  });
  const works = get(data, 'tenant.works.edges', []);

  const [archiveWork] = useMutation(ARCHIVE_WORK);
  const [restoreWork] = useMutation(RESTORE_WORK);

  const hasNextPage = data?.tenant?.works?.pageInfo?.hasNextPage;

  const handleArchiveWork = async workId => {
    try {
      await archiveWork({ variables: { input: { workId } } });
      refetch();
    } catch (err) {
      // console.dir(err);
    }
  };

  const handleUnarchiveWork = async workId => {
    try {
      await restoreWork({ variables: { input: { workId } } });
      refetch();
    } catch (err) {
      // console.dir(err);
    }
  };

  const handleUpdateFilters = payload => {
    setFilters({ ...filters, ...payload });
  };

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: data?.tenant?.works?.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        tenant: {
          ...prev.tenant,
          works: {
            ...prev.tenant.works,
            pageInfo: fetchMoreResult?.tenant?.works?.pageInfo,
            edges: [
              ...prev.tenant.works.edges,
              ...fetchMoreResult?.tenant?.works?.edges,
            ],
          },
        },
      }),
    });
  };

  useDeepCompareEffect(() => {
    refetch();
  }, [refetch, filters]);

  return (
    <>
      <Header
        title={data?.tenant?.name}
        subtitle={t('my_folders')}
        actions={
          <Buttons alignY="center">
            {/* <AvatarAndName
              user={data?.tenant?.owner}
              variant="withRole"
              role={t('roles:owner')}
            /> */}
            {data?.tenant?.canUpdate?.value && (
              <Button variant="outline" to="settings">
                {t('settings')}
              </Button>
            )}
            {data?.tenant?.canCreateWork?.value && (
              <Button
                variant="primary"
                state={{ tenantId: data?.tenant?.id }}
                to="new-folder"
                icon={<PlusCircleDotted style={{ marginRight: 8 }} size={18} />}
              >
                {t('new_folder')}
              </Button>
            )}
          </Buttons>
        }
        mobileActions={
          <Stack gutterSize={1}>
            {data?.tenant?.canCreateWork?.value && (
              <Button
                variant="icon"
                state={{ tenantId: data?.tenant?.id }}
                to="new-folder"
                icon={<FolderPlus size={22} />}
              />
            )}
            {data?.tenant?.canUpdate?.value && (
              <Button
                variant="icon"
                to="settings"
                icon={<Sliders size={22} />}
              />
            )}
          </Stack>
        }
      >
        {breakpoint === 'mobile' && (
          <AvatarAndName
            user={data?.tenant?.owner}
            variant="withRole"
            role={t('owner')}
          />
        )}
      </Header>

      <Container variant="main" size="narrow">
        <ListFilters orderOptions={orderOptions} onChange={handleUpdateFilters}>
          <Tabs hasNoPadding>
            <Tab>
              <TabNavButton
                isActive={!filters?.archived}
                onClick={() => {
                  handleUpdateFilters({ archived: false });
                }}
              >
                {t('open')}
              </TabNavButton>
            </Tab>
            <Tab>
              <TabNavButton
                isActive={filters?.archived}
                onClick={() => {
                  handleUpdateFilters({ archived: true });
                }}
              >
                {t('archived')}
              </TabNavButton>
            </Tab>
          </Tabs>
        </ListFilters>

        {loading ? (
          <Spinner />
        ) : (
          <>
            {works.length === 0 ? (
              <EmptyState label={t('no_work')} icon={<Folder />} />
            ) : (
              works.map(({ node }) => (
                <WorkCard
                  key={node.id}
                  folder={node}
                  onArchive={handleArchiveWork}
                  onUnarchive={handleUnarchiveWork}
                  canUpdate={node?.canUpdate?.value}
                  canDelete={node?.canDelete?.value}
                />
              ))
            )}
          </>
        )}

        {hasNextPage && (
          <div>
            <Buttons alignX="center" marginBottom={1}>
              <Button onClick={handleLoadMore}>{t('see_more')}</Button>
            </Buttons>
          </div>
        )}
      </Container>
    </>
  );
};

export default OrganizationDetail;
