import React, { useRef } from 'react';
import styled from 'styled-components';
import { Modal, Stack, List, ButtonReset, Spinner } from '@tymate/margaret';
import { fontStyles, SubText } from 'ui';
import { AvatarAndName } from 'components';
import { useTranslation } from 'react-i18next';
import { Box } from '@tymate/margaret';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { useQuery } from '@apollo/client';

const CardTitle = styled(Box)`
  ${fontStyles.h3}

  margin: 0;
  font-weight: 700;
`;

CardTitle.defaultProps = {
  as: 'h3',
};

const SelectRecipientButton = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.borderRadiusLarge};

  &:hover {
    background-color: ${({ theme }) => theme.background};
  }
`;

const GET_TENANT = gql`
  query CollaboratorInvitModal_getTenantMembers($slug: String!) {
    tenant(slug: $slug) {
      id
      name
      logoUrl
      members {
        edges {
          node {
            id
            role
            user {
              firstName
              lastName
              email
              avatarUrl
              id
            }
          }
        }
      }
    }
  }
`;

const CollaboratorInvitModal = ({ onRequestClose, isOpen, onSelect }) => {
  const scrollRef = useRef();
  const { organizationSlug } = useParams();
  const { t } = useTranslation(['users', 'errors', 'roles']);

  const { data: usersData, loading } = useQuery(GET_TENANT, {
    variables: { slug: organizationSlug },
  });

  const collaborators = usersData?.tenant?.members?.edges
    .filter(({ node }) => ['ADMIN', 'COLLABORATOR'].includes(node.role))
    .map(({ node }) => ({
      ...node,
    }));

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Stack size="full" direction="column" gutterSize={2} alignX="stretch">
        <CardTitle style={{ marginBottom: -16 }}>
          {t('select_collaborator')}
        </CardTitle>
        <SubText style={{ marginBottom: 0 }}>
          {t('add_collaborator_explanation')}
        </SubText>

        <Scrollbars autoHeight autoHeightMax={250} ref={scrollRef}>
          <List direction="column">
            {loading && <Spinner />}
            {(collaborators ?? []).map(({ user }) => (
              <Stack as="li" key={user.id} size="full">
                <SelectRecipientButton
                  alignX="space-between"
                  size="full"
                  alignY="center"
                  as={ButtonReset}
                  onClick={() => {
                    onSelect({ userId: user.id, role: 'COLLABORATOR' });
                    onRequestClose();
                  }}
                >
                  <AvatarAndName
                    user={user}
                    variant="withEmail"
                    avatarSize="large"
                  />
                </SelectRecipientButton>
              </Stack>
            ))}
          </List>
        </Scrollbars>
      </Stack>
    </Modal>
  );
};

export default CollaboratorInvitModal;
