import React from 'react';
import { theme, fontStyles, Title } from 'ui';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { useTranslation, Trans } from 'react-i18next';
import { Button } from 'components';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Form, TextField } from 'components/Forms';
import { Formik } from 'formik';
import { FormField, FormLabel } from 'ui/forms';
import * as Yup from 'yup';
import { formatCurrency } from 'utils';
import { Shield } from 'react-bootstrap-icons';

const CardElementWrapper = styled(Stack)`
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

const Reinsurance = styled(Stack)``;

const Callout = styled.div`
  border-radius: 1rem;
  background-color: #faf8f5;
  padding: ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.textLight};
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Legend = styled(Stack)`
  ${fontStyles.bodySmall}
  color: ${({ theme }) => theme.textLighter};
`;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: theme.text,
      fontFamily: theme.fonts.body,
      fontSmoothing: 'antialiased',
      padding: '8px 12px',
      fontSize: '16px',
      '::placeholder': {
        color: theme.textLight,
      },
    },
    invalid: {
      color: theme.error,
      iconColor: theme.error,
    },
  },
};

const StripeCardInput = ({
  onCancel,
  onUpdatePaymentMethod,
  variant,
  targetPricing,
}) => {
  const { t } = useTranslation(['invoices', 'errors']);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (billingDetails, { setSubmitting }) => {
    try {
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: billingDetails.name,
        },
      });

      await onUpdatePaymentMethod(paymentMethod.id);
      setSubmitting(false);
    } catch (err) {}
  };

  return (
    <Wrapper>
      <Title>{t('payment_info')}</Title>
      <Stack direction={{ default: 'column', tablet: 'row' }} gutterSize={2}>
        <Formik
          validationSchema={Yup.object().shape({
            name: Yup.string().required(t('errors:required')),
          })}
          initialValues={{
            name: '',
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form style={{ width: 'auto', flex: 3 }}>
              <FormField>
                <FormLabel>{t('credit_card')}</FormLabel>
                <CardElementWrapper
                  paddingVertical={0.5}
                  paddingHorizontal={0.75}
                  size="full"
                >
                  <div style={{ width: '100%' }}>
                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                  </div>
                </CardElementWrapper>
              </FormField>

              <TextField name="name" label={t('name')} />
              <Stack marginTop={1} gutterSize={1}>
                <Button
                  variant="primary"
                  size={variant === 'inline' ? 'small' : null}
                  isLoading={isSubmitting}
                >
                  {t('save')}
                </Button>
                <Button
                  size={variant === 'inline' ? 'small' : null}
                  type="button"
                  onClick={onCancel}
                >
                  {t('cancel')}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>

        <Reinsurance
          gutterSize={0.5}
          direction="column"
          style={{ flex: 2 }}
          size="full"
        >
          {Boolean(targetPricing) && (
            <Callout>
              <Trans i18nKey="invoices:reinsurance_callout">
                Votre souscription sera renouvelée automatiquement et vous serez
                prélevé de{' '}
                <strong>
                  {{ amount: formatCurrency(targetPricing) }}/mois
                </strong>
                .
              </Trans>
            </Callout>
          )}

          <Legend gutterSize={0.5}>
            <div>
              <Shield />
            </div>
            <div>{t('reinsurrance')}</div>
          </Legend>
        </Reinsurance>
      </Stack>
    </Wrapper>
  );
};

export default StripeCardInput;
