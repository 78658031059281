import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { Buttons } from '@tymate/margaret';
import { Formik } from 'formik';
import { Form, TextField } from 'components/Forms';
import * as Yup from 'yup';
import { media } from '@tymate/margaret';
import { At, Person } from 'react-bootstrap-icons';

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};

  ${media.tablet`
  grid-template-columns: 1fr 1fr;

    > div {
      margin-top: 0
    }
  `}
`;

const InvitationForm = ({ onSubmit, t, onCancel }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{ firstName: '', lastName: '', email: '' }}
    validationSchema={Yup.object().shape({
      firstName: Yup.string().required(t('errors:required')),
      lastName: Yup.string().required(t('errors:required')),
      email: Yup.string()
        .email(t('errors:email'))
        .required(t('errors:required')),
    })}
  >
    {({ isSubmitting }) => (
      <Form>
        <InputGrid>
          <TextField
            name="firstName"
            label={t('firstName')}
            icon={<Person size={20} />}
          />
          <TextField
            name="lastName"
            label={t('lastName')}
            icon={<Person size={20} />}
          />
        </InputGrid>
        <TextField name="email" label={t('email')} icon={<At size={20} />} />

        <Buttons>
          <Button variant="primary" isLoading={isSubmitting}>
            {t('send_invitation')}
          </Button>
          <Button type="button" variant="outline" onClick={onCancel}>
            {t('cancel')}
          </Button>
        </Buttons>
      </Form>
    )}
  </Formik>
);

export default InvitationForm;
