import React, { useRef } from 'react';
import styled from 'styled-components';
import { AvatarAndName, Button, Status } from 'components';
import {
  ButtonReset,
  Stack,
  Dropdown,
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
} from '@tymate/margaret';
import { getIconFromType } from 'utils';
import { fontStyles, fontSize, PopoverDividerItem } from 'ui';
import { ThreeDotsVertical } from 'react-bootstrap-icons';

const Wrapper = styled.div`
  position: relative;
`;

const Actions = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing(0.5)};
`;

const Card = styled(ButtonReset)`
  border: 2px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing()};
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: border 300ms;

  ${({ isActive, theme }) =>
    isActive &&
    `
  border: 2px solid ${theme.primary}
  `}
`;

const CardTitle = styled.p`
  margin: 0;
  font-weight: 600;
  text-align: left;
`;

const ItemCount = styled(Stack)`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLighter};
  > * + * {
    margin-left: ${({ theme }) => theme.spacing(0.25)};
  }
  > svg {
    margin-right: ${({ theme }) => theme.spacing(0.15)};
  }
`;

const Trigger = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing(-2)};
  right: ${({ theme }) => theme.spacing(1)};

  svg {
    ${fontSize.h2}
    color: ${({ theme }) => theme.textLighter};
  }
`;

const RequestModelCard = ({
  requestModel,
  isActive,
  onClick,
  onLoad,
  onUpdate,
  onDelete,
  onFormContentStep,
  t,
  isInitialModelSelection,
}) => {
  const dropdownRef = useRef();

  const documents = requestModel.items.filter(
    ({ type }) => type === 'DOCUMENT',
  );
  const messages = requestModel.items.filter(({ type }) => type === 'MESSAGE');
  const acknowledges = requestModel.items.filter(
    ({ type }) => type === 'ACKNOWLEDGE',
  );
  const prompts = requestModel.items.filter(({ type }) => type === 'PROMPT');
  const signatures = requestModel.items.filter(
    ({ type }) => type === 'SIGNATURE',
  );

  return (
    <div>
      <Wrapper>
        <Card onClick={onClick} isActive={isActive} type="button">
          <Stack alignX="space-between" size="full">
            <CardTitle>{requestModel.name}</CardTitle>
            {requestModel.baseModel && <Status status="EXAMPLE" />}
          </Stack>
          <Stack
            gutterSize={1}
            marginBottom={0.5}
            marginTop={0.5}
            alignY="center"
          >
            {documents.length > 0 && (
              <ItemCount alignY="center" gutterSize={0.5}>
                <span>{getIconFromType('DOCUMENT')}</span>
                <span>{documents.length}</span>
              </ItemCount>
            )}
            {messages.length > 0 && (
              <ItemCount alignY="center" gutterSize={0.5}>
                <span>{getIconFromType('MESSAGE')}</span>
                <span>{messages.length}</span>
              </ItemCount>
            )}
            {acknowledges.length > 0 && (
              <ItemCount alignY="center" gutterSize={0.5}>
                <span>{getIconFromType('ACKNOWLEDGE')}</span>
                <span>{acknowledges.length}</span>
              </ItemCount>
            )}
            {prompts.length > 0 && (
              <ItemCount alignY="center" gutterSize={0.5}>
                <span>{getIconFromType('PROMPT')}</span>
                <span>{prompts.length}</span>
              </ItemCount>
            )}
            {signatures.length > 0 && (
              <ItemCount alignY="center" gutterSize={0.5}>
                {getIconFromType('SIGNATURE')}
                {signatures.length}
              </ItemCount>
            )}
          </Stack>
          <AvatarAndName user={requestModel.author} size="small" />
        </Card>
        <Actions>
          <Dropdown
            ref={dropdownRef}
            trigger={
              <Trigger>
                <ThreeDotsVertical />
              </Trigger>
            }
          >
            <PopoverMenu alignment="right" style={{ width: '18em' }}>
              {!isInitialModelSelection && onFormContentStep && (
                <>
                  <PopoverItem>
                    <PopoverItemButton onClick={() => onLoad(true)}>
                      {t('load_and_add')}
                    </PopoverItemButton>
                  </PopoverItem>
                  <PopoverItem>
                    <PopoverItemButton onClick={() => onLoad(false)}>
                      {t('load_and_replace')}
                    </PopoverItemButton>
                  </PopoverItem>
                  <PopoverDividerItem />
                  <PopoverItem>
                    <PopoverItemButton
                      onClick={() => {
                        onUpdate();
                        dropdownRef.current.close();
                      }}
                    >
                      {t('update_model')}
                    </PopoverItemButton>
                  </PopoverItem>
                </>
              )}
              <PopoverItem>
                <PopoverItemButton
                  onClick={() => {
                    onDelete();
                    dropdownRef.current.close();
                  }}
                >
                  {t('delete_model')}
                </PopoverItemButton>
              </PopoverItem>
            </PopoverMenu>
          </Dropdown>
        </Actions>
      </Wrapper>
      {isActive && (
        <Stack
          style={{ flexWrap: 'wrap', gap: '0.5em' }}
          marginTop={0.5}
          gutterSize={0.5}
          alignX="flex-end"
        >
          <Button
            type="button"
            variant="primary"
            size="small"
            onClick={() => onLoad(onFormContentStep ? true : false)} // Concatenate only when user on 2nd step FIXME: Wording & next lines
          >
            {Boolean(onFormContentStep)
              ? t('load_and_add')
              : t('load_model')}
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default RequestModelCard;
