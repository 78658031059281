import React from 'react';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import styled from 'styled-components';

YupPassword(yup); // extend yup

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 4px;
  margin: -1px auto ${({ theme }) => theme.spacing(0)};
  background-color: #cfcfcf;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
`;
const ProgressBar = styled.div`
  border-radius: 4px;
  height: 100%;
  transition: width 300ms linear;
`;

const PasswordStrengthIndicator = ({ password }) => {
  const [passwordStrength, setPasswordStrength] = useState(0);
  useEffect(() => {
    const checkStrenght = async () => {
      const minimalRequirements = await yup
        .string()
        .minLowercase(1)
        .minUppercase(1)
        .minNumbers(1)
        .minSymbols(1)
        .isValid(password);
      if (minimalRequirements) {
        let strengthArray = [
          await yup.string().min(9).isValid(password),
          await yup.string().min(12).isValid(password),
          await yup.string().min(15).isValid(password),
        ];
        let trues = strengthArray.reduce(
          (total, x) => (x === true ? total + 1 : total),
          0,
        );
        setPasswordStrength([45, 65, 85, 100][trues]);
      } else {
        setPasswordStrength(0);
      }
    };
    checkStrenght();
  }, [password]);
  return (
    <ProgressBarWrapper>
      <ProgressBar
        style={{
          width: `${passwordStrength}%`,
          backgroundColor: passwordStrength <= 45 ? 'orange' : 'limegreen',
        }}
      ></ProgressBar>
    </ProgressBarWrapper>
  );
};

export default PasswordStrengthIndicator;
