import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import gql from 'graphql-tag';
import { PopoverMenu, Stack, ButtonReset } from '@tymate/margaret';
import { fontStyles } from 'ui';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { ChevronRight, FolderFill } from 'react-bootstrap-icons';
import { useBreakpoint } from 'hooks';

const PopoverHeader = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.separator};
  color: ${({ theme }) => theme.textLight};
  font-weight: bold;
`;

const PopoverContent = styled(Stack)``;

const PopoverFooter = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  justify-content: flex-end;
  box-shadow: 0 -1px 0 0 ${({ theme }) => theme.separator};
`;

const FolderLink = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.textLight};
  ${fontStyles.bodySmall};
  text-align: left;

  &:hover {
    background-color: ${({ theme }) => theme.backgroundPrimary};
  }

  svg {
    color: ${({ theme }) => theme.textLighter};
  }
`;

const GET_FOLDER = gql`
  query getMoveEntityFolders($slug: String!) {
    folder(slug: $slug) {
      id
      slug
      name
      parentFolder {
        id
        slug
        name
      }
      descendantFolders {
        edges {
          node {
            id
            slug
            name
          }
        }
      }
    }
  }
`;

const UPDATE_FOLDER = gql`
  mutation updateFolder($input: UpdateFolderInput!) {
    updateFolder(input: $input) {
      folder {
        id
        name
        updatedAt
      }
    }
  }
`;

const MOVE_DOCUMENT = gql`
  mutation moveDocument($input: MoveDocumentInput!) {
    moveDocument(input: $input) {
      clientMutationId
    }
  }
`;

const MoveFileDropdown = ({
  initialFolderSlug,
  movedEntityId,
  onMoveSuccess,
  type,
}) => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'mobile';
  const { t } = useTranslation('vault');

  const [currentFolderSlug, setCurrentFolderSlug] = useState(initialFolderSlug);

  const { data: folderData, refetch: refetchFolder } = useQuery(GET_FOLDER, {
    variables: { slug: currentFolderSlug },
  });

  const [moveDocument] = useMutation(MOVE_DOCUMENT);
  const [updateFolder] = useMutation(UPDATE_FOLDER);

  const currentFolderName = Boolean(folderData?.folder?.parentFolder)
    ? folderData?.folder?.name
    : t('vault');

  const currentFolder = folderData?.folder;

  const parentFolder = currentFolder?.parentFolder;
  const parentFolderSlug = parentFolder?.slug;

  const descendantFolders = [
    Boolean(parentFolder) && {
      node: { name: '..', slug: '..' },
    },
    ...(currentFolder?.descendantFolders?.edges || []),
  ].filter(Boolean);

  const handleMoveEntity = async () => {
    try {
      if (type === 'document') {
        await moveDocument({
          variables: {
            input: {
              documentId: movedEntityId,
              folderId: currentFolder?.id,
            },
          },
        });
      } else {
        await updateFolder({
          variables: {
            input: {
              folderId: movedEntityId,
              parentFolderId: currentFolder?.id,
            },
          },
        });
      }
      onMoveSuccess();
      refetchFolder();
    } catch (err) {
      console.dir(err);
    }
  };

  useEffect(() => {
    refetchFolder();
  }, [refetchFolder]);

  return (
    <PopoverMenu
      alignment="right"
      style={{ width: isMobile ? '15em' : '20em', maxHeight: 'none' }}
    >
      <PopoverHeader>{currentFolderName}</PopoverHeader>

      <Scrollbars autoHeight>
        <PopoverContent direction="column">
          {descendantFolders
            .map(({ node }) => ({ ...node }))
            .filter(({ id }) => id !== movedEntityId)
            .map(({ name, slug }, index) => (
              <FolderLink
                as={ButtonReset}
                onClick={e => {
                  e.preventDefault();
                  if (
                    Boolean(folderData?.folder?.parentFolder) &&
                    index === 0
                  ) {
                    setCurrentFolderSlug(parentFolderSlug);
                  } else {
                    setCurrentFolderSlug(slug);
                  }
                }}
                size="full"
                key={slug}
                alignX="space-between"
                alignY="center"
                type="button"
              >
                <Stack gutterSize={0.5}>
                  <FolderFill />
                  <span>{name}</span>
                </Stack>

                <ChevronRight size={14} style={{ minWidth: 14 }} />
              </FolderLink>
            ))}
        </PopoverContent>
      </Scrollbars>
      <PopoverFooter>
        <Button size="small" variant="primary" onClick={handleMoveEntity}>
          {t('move_here')}
        </Button>
      </PopoverFooter>
    </PopoverMenu>
  );
};

export default MoveFileDropdown;
