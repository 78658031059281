import React, { useState, useCallback } from 'react';
import { RoutesContext } from 'contexts';
import { useLocation } from 'react-router-dom';
import { Diagram2 } from 'react-bootstrap-icons';

const pathsNames = [];
const pathsAvatarsUrls = [];

const RoutesProvider = ({ children }) => {
  const [routesNames, setRoutesNames] = useState(pathsNames);
  const [routesAvatarsUrls, setRoutesAvatarsUrls] = useState(pathsAvatarsUrls);
  const { pathname } = useLocation();

  const ignoredPathsRegexes = [
    /organizations(\/[^/]+){1}\/settings\/.+$/,
    /organizations(\/[^/]+){3}\/.+$/,
  ];
  const secondaryBreadcrumbIgnoredPathsRegexes = [
    /organizations(\/[^/]+){0,2}$/,
  ];
  const pathsRegexesWithPlaceholder = [/organizations(\/[^/]+){1,2}$/];
  const pathsRegexesIcons = [
    { regex: /organizations\/new$/, icon: null },
    { regex: /organizations\/[^/]+$/, icon: <Diagram2 size={18} /> },
  ];

  const stringifiedRoutesNames = JSON.stringify(routesNames);
  const addRouteName = useCallback(
    ({ path, name }) => setRoutesNames({ ...routesNames, [path]: name }),
    // eslint-disable-next-line
    [stringifiedRoutesNames],
  );
  const addRouteAvatarUrl = useCallback(
    ({ path, url }) =>
      setRoutesAvatarsUrls([{ path, url }].concat(routesAvatarsUrls)),
    // eslint-disable-next-line
    [],
  );

  const addCurrentRouteName = useCallback(
    name => addRouteName({ path: pathname, name }),
    [addRouteName, pathname],
  );

  return (
    <RoutesContext.Provider
      value={{
        routesNames,
        addRouteName,
        routesAvatarsUrls,
        addRouteAvatarUrl,
        addCurrentRouteName,
        pathsRegexesIcons,
        ignoredPathsRegexes,
        secondaryBreadcrumbIgnoredPathsRegexes,
        pathsRegexesWithPlaceholder,
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
};

export default RoutesProvider;
