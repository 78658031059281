class LocalStorageService {
  /**
   * Public
   */

  /**
   * Returns value from local storage
   * @param key
   * @returns storage item
   */
  static get(key) {
    return localStorage.getItem(key);
  }

  /**
   * Returns whether an item exists or not
   * @param key
   * @returns
   */
  static exists(key) {
    return !!this.get(key);
  }

  /**
   * Sets a value in local storage
   * @param key
   * @param value
   * @returns service instance
   */
  static set(key, value) {
    if (value instanceof Object) {
      const str = JSON.stringify(value);
      return localStorage.setItem(key, str);
    } else {
      return localStorage.setItem(key, value);
    }
  }

  /**
   * Clears a value in local storage
   * @param key
   * @returns service instance
   */
  static clear(key) {
    localStorage.removeItem(key);
  }
}

export default LocalStorageService;
