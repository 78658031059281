import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Box } from '@tymate/margaret';
import { fontStyles } from 'ui';

const Wrapper = styled(Box)`
  ${fontStyles.bodySmall}
  padding: ${({ theme }) => theme.spacing(0.125)}
    ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.borderRadiusPill};
  color: ${({ theme }) => theme.defaultState};
  background-color: ${({ theme }) => theme.defaultStateBackground};
  flex-shrink: 0;

  ${({ status }) =>
    status === 'DONE' &&
    css`
      color: ${({ theme }) => theme.success};
      background-color: ${({ theme }) => theme.successBackground};
    `}

  ${({ status }) =>
    status === 'PENDING' &&
    css`
      color: ${({ theme }) => theme.textLight};
      background-color: ${({ theme }) => theme.separatorLighter};
    `}

  ${({ status }) =>
    status === 'REFUSED' &&
    css`
      color: ${({ theme }) => theme.error};
      background-color: ${({ theme }) => theme.errorBackground};
    `}
`;

const getStatusI18nKey = (status = '') => status.toLowerCase();

const SignatureStatus = ({ status }) => {
  const { t } = useTranslation('signature_statuses');

  return <Wrapper status={status}>{t(getStatusI18nKey(status))}</Wrapper>;
};

export default SignatureStatus;
