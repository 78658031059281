import React from 'react';
import styled from 'styled-components';
import { List } from '@tymate/margaret';
import { Avatar } from 'components';
import { fontStyles } from 'ui';

const Wrapper = styled(List)`
  display: flex;
  align-items: center;
  margin-left: ;
`;

const AvatarsList = styled(List)`
  display: flex;

  > * + * {
    margin-left: -10px;
  }
`;

const MoreUsers = styled.div`
  color: ${({ theme }) => theme.primary};
  background-color: rgba(255, 91, 74, 0.08);
  ${fontStyles.bodySmaller};
  display: flex;
  height: 32px;
  min-width: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`;

const AvatarsStack = ({ users = [] }) => (
  <Wrapper>
    <AvatarsList>
      {users.slice(0, 3).map(({ user }, index) => (
        <li key={index}>
          <Avatar
            size="small"
            firstName={user?.firstName}
            lastName={user?.lastName}
            style={{ border: '2px solid #fff' }}
            imageUrl={user?.avatarUrl}
          />
        </li>
      ))}
    </AvatarsList>
    {users.length > 3 && <MoreUsers>+{users.length - 3}</MoreUsers>}
  </Wrapper>
);

export default AvatarsStack;
