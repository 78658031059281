import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Container, fontStyles } from 'ui';
import { Spinner, Stack } from '@tymate/margaret';
import Button from './Button';
import { MdInsertDriveFile } from 'react-icons/md';

const Information = styled(Stack)`
  ${fontStyles.body};
  color: ${({ theme }) => theme.textLighter};
  height: 22px;
`;

const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing()};
`;

const Title = styled.h1`
  ${fontStyles.h1}
  font-family: ${({ theme }) => theme.fonts.title};
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const Title2 = styled.h2`
  ${fontStyles.h2}
  font-family: ${({ theme }) => theme.fonts.title};
  margin: 0;
`;

const Icon = styled.div`
  color: ${({ theme }) => theme.primary};
  font-size: 1.5rem;
`;

const RequestDocumentDetail = ({ request, document, isLoading }) => {
  const { t } = useTranslation('request_document');

  return (
    <div>
      <Stack
        direction="column"
        alignX="center"
        marginBottom={1}
        gutterSize={-0.5}
      >
        <Title>{t('title')}</Title>
        <Information>{t('description')}</Information>
      </Stack>

      <Container size="small" variant="main">
        <Card>
          <Stack direction="column" alignX="center">
            {isLoading ? (
              <Spinner />
            ) : (
              <Stack
                direction="column"
                alignY="center"
                alignX="center"
                gutterSize={1.5}
              >
                <Stack alignY="center" gutterSize={1}>
                  <Icon>
                    <MdInsertDriveFile />
                  </Icon>
                  <span>
                    <Title2>{document?.fileName}</Title2>
                  </span>
                </Stack>
                <Button
                  type="button"
                  variant="primary"
                  isLoading={isLoading}
                  as="a"
                  target="blank"
                  href={document?.url}
                  style={{
                    textDecoration: 'none',
                    fontWeight: 600,
                  }}
                >
                  {t('download')}
                </Button>
              </Stack>
            )}
          </Stack>
        </Card>
      </Container>
    </div>
  );
};

export default RequestDocumentDetail;
