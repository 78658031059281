import { Stack } from '@tymate/margaret';
import { LANGUAGE_OPTIONS } from '../constants';
import i18next from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const Wrapper = styled(Stack)`
  ${({ size }) =>
    size === 'big' &&
    css`
      font-size: 22px;
    `}
`;

const Lang = styled.div`
  cursor: pointer;
  filter: grayscale(20%) opacity(70%);

  ${({ selected }) =>
    selected &&
    css`
      filter: grayscale(0) opacity(1);
    `}
`;

const LanguageSelector = ({ size = 'normal', style = {} }) => {
  const { t } = useTranslation(['account', 'misc']);

  const handleSelectLang = lang => {
    i18next.changeLanguage(lang.value);
  };

  return (
    <Wrapper
      direction="row"
      alignY="center"
      alignX="space-between"
      gutterSize={size === 'big' ? 2 : 1.5}
      size={size}
      style={style}
    >
      {LANGUAGE_OPTIONS.map(lang => {
        return (
          <Lang
            key={lang.value}
            onClick={() => handleSelectLang(lang)}
            selected={i18next.language === lang.value}
            title={t(lang.i18nLabel)}
          >
            <span className={`flag-icon flag-icon-${lang.flag}`}></span>
          </Lang>
        );
      })}
    </Wrapper>
  );
};

export default LanguageSelector;
