export const API_BASE = process.env.REACT_APP_API_BASE_URL;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;
export const INCLUDED_FREEMIUM_TOKENS = 10;
export const APPSIGNAL_API_KEY = process.env.REACT_APP_APPSIGNAL_API_KEY;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;
export const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE;
export const SENTRY_TRACES_SAMPLE_RATE =
  process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE;
export const SENTRY_TRACING_ORIGIN =
  process.env.REACT_APP_SENTRY_TRACING_ORIGIN;

export const POSTHOG_ENABLED = process.env.REACT_APP_POSTHOG_ENABLED;
export const POSTHOG_API_KEY = process.env.REACT_APP_POSTHOG_API_KEY;
export const POSTHOG_HOST = process.env.REACT_APP_POSTHOG_HOST;
export const POSTHOG_APP = process.env.REACT_APP_POSTHOG_APP;
export const POSTHOG_ENVIRONMENT = process.env.REACT_APP_POSTHOG_ENVIRONMENT;

export const DOWNLOADS_URL = `${API_BASE}/api/v1/downloads`;

// File sizes
export const MAX_FILE_SIZE = 1 * 1024 * 1024 * 1024; // 1GB
export const MAX_REQUEST_MODEL_FILE_SIZE = 100 * 1024 * 1024; // 100MB
export const MAX_SIGNATURE_FILE_SIZE = 45 * 1024 * 1024; // 50MB

//Filename valid pattern
export const FILENAME_INVALID_CHARACTERS = /^[^<>:;"/\\\n\t\r|$%*\\]{1,250}$/;

// filesize lib options
export const FILESIZE_OPTIONS = {
  symbols: {
    B: 'octets',
    kB: 'Ko',
    MB: 'Mo',
    GB: 'Go',
    TB: 'To',
    PB: 'Po',
    EB: 'Eo',
    ZB: 'Zo',
    YB: 'Yo',
  },
  round: 0,
  base: 2,
  standard: 'jedec',
};

// Previewable mime types
export const PREVIEWABLE_MIME_TYPES = [
  'application/pdf',
  'text/plain',
  'text/html',
  'text/css',
  'text/javascript',
  'application/csv',
  'image/gif',
  'image/png',
  'image/jpeg',
  'image/bmp',
  'image/webp',
  'image/x-icon',
  'image/svg+xml',
  'image/tiff',
  'video/x-msvideo',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'video/mp4',
  'audio/aac',
  'audio/midi',
  'audio/mpeg',
  'audio/webm',
  'audio/ogg',
  'audio/wav',
  'audio/x-wav',
];

export const LANGUAGE_OPTIONS = [
  {
    value: 'fr',
    flag: 'fr',
    i18nLabel: 'languages.fr',
  },
  {
    value: 'en',
    flag: 'gb',
    i18nLabel: 'languages.en',
  },
  {
    value: 'es',
    flag: 'es',
    i18nLabel: 'languages.es',
  },
];

export const KEYCLOAK = {
  URL: process.env.REACT_APP_KEYCLOAK_URL,
  REALM: process.env.REACT_APP_KEYCLOAK_REALM_NAME,
  CLIENT_ID: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
};

export const AUTH = {
  PPW_ACCESS_TOKEN: 'ppw_access_token',
  PPW_REFRESH_TOKEN: 'ppw_refresh_token',
  PPW_ID_TOKEN: 'ppw_id_token',
};
