import React, { useRef } from 'react';
import styled from 'styled-components';
import { fontStyles, fontSize, SubText } from 'ui';
import { get } from 'lodash';
import {
  RawLink,
  Dropdown,
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
  Stack,
} from '@tymate/margaret';
import { formatDateDistanceToNow, formatDate } from 'utils';
import { AvatarsStack } from './';
import { useTranslation } from 'react-i18next';
import {
  Archive,
  Pencil,
  People,
  ThreeDotsVertical,
} from 'react-bootstrap-icons';

const Wrapper = styled.div`
  position: relative;

  + * {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 1.25rem;
  margin: 0;
`;

const FolderLink = styled(RawLink)`
  border-radius: 12px;
  box-shadow: 0 4px 4px -4px ${({ theme }) => theme.separatorLight};
  border: solid 1px ${({ theme }) => theme.separatorLight};
  background-color: #ffffff;
  padding: ${({ theme }) => theme.spacing()};
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    color: ${({ theme }) => theme.textLight};
    ${fontStyles.bodySmall}
  }

  ${({ isArchived, theme }) =>
    isArchived &&
    `
      background-color: rgba(0, 0, 0, 0.02);
      box-shadow: none;

      ${Title} {
        color: ${theme.textLight}
      }

  `}
`;

const Trigger = styled.div`
  position: absolute;
  top: 0;
  right: ${({ theme }) => theme.spacing(0.5)};

  svg {
    ${fontSize.h2}
    color: ${({ theme }) => theme.textLight};
  }
`;

const Icon = styled.span``;

const Actions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing(0.5)};
`;

const CreatedDate = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.textLighter};
  ${fontStyles.bodySmall}
  padding-right: 30px;
`;

const WorkCard = ({ folder, onArchive, onUnarchive, canUpdate, canDelete }) => {
  const { t } = useTranslation('organization');
  const dropdownRef = useRef();

  const contactRole = folder?.roles?.nodes.filter(
    ({ name }) => name === 'CONTACT',
  );
  const contacts = get(contactRole, '[0].users.nodes', []);
  const collaboratorRole = folder?.roles?.nodes.filter(
    ({ name }) => name === 'COLLABORATOR',
  );
  const collaborators = get(collaboratorRole, '[0].users.nodes', []);

  return (
    <Wrapper>
      <FolderLink to={folder.slug} isArchived={Boolean(folder.discardedAt)}>
        <Stack
          direction="column"
          alignY="space-between"
          style={{ minHeight: 158 }}
        >
          <Stack direction="column" size="full">
            <Stack
              direction={{ default: 'column', tablet: 'row' }}
              alignX="space-between"
              alignY="center"
              size="full"
            >
              <Title>{folder.name}</Title>

              <CreatedDate>
                {t('created_at', {
                  date: formatDate(folder.createdAt, 'd MMMM YYY'),
                })}
              </CreatedDate>
            </Stack>
            {Boolean(folder.discardedAt) ? (
              <p>
                {t('archived_at', {
                  relativeDate: formatDateDistanceToNow(folder.discardedAt),
                })}
              </p>
            ) : (
              <p>
                {t('last_update_since', {
                  relativeDate: formatDateDistanceToNow(folder.updatedAt),
                })}
              </p>
            )}
          </Stack>
          <Stack gutterSize={1.5}>
            <span>
              {collaborators.length >= 1 && (
                <div>
                  <SubText>
                    {t('collaborator_count', { count: collaborators.length })}
                  </SubText>
                  <AvatarsStack
                    users={collaborators.map(collaborator => ({
                      user: collaborator,
                    }))}
                  />
                </div>
              )}
            </span>
            <span>
              {contacts.length >= 1 && (
                <div>
                  <SubText>
                    {t('contact_count', { count: contacts.length })}
                  </SubText>
                  <AvatarsStack
                    users={contacts.map(contact => ({ user: contact }))}
                  />
                </div>
              )}
            </span>
          </Stack>
        </Stack>
      </FolderLink>

      {(canUpdate || canDelete) && (
        <Actions>
          <Dropdown
            ref={dropdownRef}
            trigger={
              <Trigger>
                <ThreeDotsVertical />
              </Trigger>
            }
          >
            <PopoverMenu alignment="right" style={{ width: '15em' }}>
              {canUpdate && (
                <PopoverItem>
                  <PopoverItemButton
                    as={RawLink}
                    to={`${folder.slug}/settings`}
                  >
                    <Icon>
                      <Pencil />
                    </Icon>
                    {t('edit')}
                  </PopoverItemButton>
                </PopoverItem>
              )}
              {canDelete && (
                <PopoverItem>
                  <PopoverItemButton
                    onClick={
                      Boolean(folder.discardedAt)
                        ? () => {
                            onUnarchive(folder.id);
                            dropdownRef.current.close();
                          }
                        : () => {
                            onArchive(folder.id);
                            dropdownRef.current.close();
                          }
                    }
                  >
                    <Icon>
                      <Archive />
                    </Icon>
                    {Boolean(folder.discardedAt)
                      ? t('unarchive')
                      : t('archive')}
                  </PopoverItemButton>
                </PopoverItem>
              )}
              {canUpdate && (
                <PopoverItem>
                  <PopoverItemButton as={RawLink} to={`${folder.slug}/users`}>
                    <Icon>
                      <People />
                    </Icon>
                    {t('share')}
                  </PopoverItemButton>
                </PopoverItem>
              )}
            </PopoverMenu>
          </Dropdown>
        </Actions>
      )}
    </Wrapper>
  );
};
export default WorkCard;
