import React from 'react';
import { Formik } from 'formik';
import { Form, TextField, TextareaField } from 'components/Forms';
import { Buttons } from '@tymate/margaret';
import { Button } from 'components';
import * as Yup from 'yup';
import { get } from 'lodash';

const WorkForm = ({ t, onSubmit, onReset, work }) => (
  <Formik
    onSubmit={onSubmit}
    initialValues={{
      name: get(work, 'name', ''),
      description: get(work, 'description', ''),
    }}
    validationSchema={Yup.object().shape({
      name: Yup.string().required(t('errors:required')),
      description: Yup.string(),
    })}
  >
    {({ isSubmitting }) => (
      <Form>
        <TextField
          name="name"
          label={t('work_form:name')}
          placeholder={t('work_form:name_placeholder')}
        />
        <TextareaField
          name="description"
          label={t('work_form:description')}
          placeholder={t('work_form:description_placeholder')}
          minRows={2}
        />

        <Buttons>
          <Button variant="primary" isLoading={isSubmitting}>
            {t('save')}
          </Button>
          <Button type="button" onClick={onReset}>
            {t('work_form:cancel')}
          </Button>
        </Buttons>
      </Form>
    )}
  </Formik>
);

export default WorkForm;
