import React from 'react';
import styled from 'styled-components';
import { Button } from 'components';
import { Title } from 'ui';
import { Stack, media } from '@tymate/margaret';
import { useTranslation, Trans } from 'react-i18next';
import requestRejectedImage from 'images/request-rejected.svg';
import { Modal, Buttons } from '@tymate/margaret';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextareaField } from './Forms';

const Img = styled.img`
  display: block;
  width: 192px;
  height: 192px;

  ${media.tablet`
    width: 256px;
    height: 256px;
  `}
`;

const RejectionModal = ({
  isOpen,
  onRequestClose,
  request,
  onRejectRequest,
  isRejectingRequest,
}) => {
  const { t } = useTranslation(['request', 'misc']);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <Formik
        initialValues={{
          message: '',
        }}
        validationSchema={Yup.object().shape({
          message: Yup.string(),
        })}
      >
        {({ values }) => (
          <Form>
            <Stack
              direction="column"
              alignX="center"
              style={{ textAlign: 'center' }}
              gutterSize={1}
            >
              <Img src={requestRejectedImage} alt="" />
              <Title>{t('requestRejection.title')}</Title>

              <div>
                <Trans i18nKey="request:requestRejection.legend">
                  You are about to reject the request{' '}
                  <strong>{{ requestName: request?.name }}</strong>, a new
                  request will be created and sent to the recipient.
                </Trans>
              </div>

              <TextareaField
                minRows={3}
                name="message"
                label={t('request:requestRejection.message')}
                placeholder={t('request:requestRejection.message_placeholder')}
                optional={t('misc:optional')}
              />

              <Buttons>
                <Button type="button" onClick={onRequestClose}>
                  {t('requestRejection.dismiss')}
                </Button>
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => onRejectRequest(values.message)}
                  isLoading={isRejectingRequest}
                >
                  {t('requestRejection.submit')}
                </Button>
              </Buttons>
            </Stack>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RejectionModal;
