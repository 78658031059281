import React, { useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import { capitalize, last, find } from 'lodash';
import { useRoutes, useBreakpoint } from 'hooks';
import {
  List,
  media,
  Dropdown,
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
  RawLink,
  theme,
} from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'components';
import { ChevronDown, ChevronRight } from 'react-bootstrap-icons';

const shine = keyframes`
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 140px;
  }
`;

const Icon = styled.span`
  display: none;

  ${media.tablet`
    display: flex;
  `}
`;

const Wrapper = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;

      ${media.tablet`
        display: none;
      `};
    `}
`;

const BreadcrumbContent = styled(List)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${({ theme }) => theme.spacing()};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

const Crumb = styled.li`
  display: none;
  display: flex;
  line-height: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;

  > svg {
    display: none;

    ${media.tablet`
      display: flex;
      margin-left: ${({ theme }) => theme.spacing(0.25)};
      margin-right: ${({ theme }) => theme.spacing(0.25)};
    `}
  }
`;

const CrumbLink = styled(NavLink)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  align-items: center;
  border-radius: 99rem;
  line-height: 1.5;
  display: none;
  transition: color 100ms ease-in-out;

  :hover {
    color: ${({ theme }) => theme.primary};
  }

  > svg {
    display: none;

    ${media.tablet`
      display: flex;
      margin-right: ${({ theme }) => theme.spacing(0.375)};
    `}
  }

  ${media.tablet`
    display: flex;
  `}
`;

const CrumbLast = styled(CrumbLink)`
  display: flex;

  ${({ variant }) =>
    variant !== 'main' &&
    css`
      box-shadow: none;
      color: ${({ theme }) => theme.textLight};
      padding-left: 0;
      padding-right: 0;

      :hover {
        color: ${({ theme }) => theme.textLight};
      }
    `}
`;

const MobileTrigger = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(0.5)};
  color: ${({ theme }) => theme.textLight};
`;

const Placeholder = styled.div`
  height: 14px;
  width: ${({ theme }) => theme.spacing(6)};
  background-image: linear-gradient(
    90deg,
    ${({ theme }) => theme.separatorLight},
    ${({ theme }) => theme.separatorLighter} 20px,
    ${({ theme }) => theme.separatorLight} 40px,
    ${({ theme }) => theme.separatorLight}
  );
  animation: ${shine} 2s linear infinite;
  border-radius: 2px;
`;

const Breadcrumb = () => {
  const { pathname, key: locationKey } = useLocation();
  const breakpoint = useBreakpoint();
  const mobileBreadcrumbRef = useRef();
  const {
    pathsRegexesIcons,
    ignoredPathsRegexes,
    pathsRegexesWithPlaceholder,
    routesNames,
    routesAvatarsUrls,
  } = useRoutes();
  const urlChunks = pathname.split('/').filter(Boolean);
  const { t } = useTranslation('breadcrumb');

  const getPathIcon = path => {
    const routeCustomAvatarUrl = find(
      routesAvatarsUrls,
      routeName => routeName.path === `/${path}`,
    )?.url;

    if (Boolean(routeCustomAvatarUrl)) {
      return (
        <span style={{ marginRight: 8 }}>
          <Avatar
            variant="rounded"
            size="tiny"
            imageUrl={routeCustomAvatarUrl}
          />
        </span>
      );
    }

    for (const { regex, icon } of pathsRegexesIcons) {
      if (Boolean(path.match(regex))) {
        return icon;
      }
    }
  };

  const getPathTranslation = path => {
    const chunks = path.split('/');
    const chunk = last(chunks);

    const routeCustomName = routesNames[`/${path}`];

    if (Boolean(routeCustomName)) {
      return routeCustomName;
    }

    if (t(path) !== path) {
      return t(path);
    }

    if (t(chunk) !== chunk) {
      return t(chunk);
    }

    for (const regex of pathsRegexesWithPlaceholder) {
      if (Boolean(path.match(regex))) {
        return <Placeholder />;
      }
    }

    return capitalize(chunk);
  };

  const isIgnored = path => {
    for (const regex of ignoredPathsRegexes) {
      if (Boolean(path.match(regex))) {
        return true;
      }
    }

    return false;
  };

  const chunks = urlChunks.reduce((acc, _, index) => {
    const path = urlChunks.slice(0, index + 1).join('/');
    if (isIgnored(path)) {
      return acc;
    }

    return [
      ...acc,
      {
        path: `/${path}`,
        text: getPathTranslation(path),
        icon: getPathIcon(path),
      },
    ];
  }, []);

  useEffect(() => {
    if (!mobileBreadcrumbRef.current) {
      return;
    }

    mobileBreadcrumbRef.current.close();
  }, [locationKey]);

  const pageTitle =
    typeof last(chunks)?.text === 'object' || !Boolean(last(chunks)?.text)
      ? 'Paprwork'
      : `${last(chunks)?.text} – Paprwork`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Wrapper aria-label="Breadcrumb">
        {breakpoint === 'mobile' && chunks.length <= 1 && (
          <MobileTrigger>{last(chunks)?.text}</MobileTrigger>
        )}

        {breakpoint === 'mobile' && chunks.length > 1 && (
          <Dropdown
            ref={mobileBreadcrumbRef}
            trigger={
              <MobileTrigger>
                {last(chunks)?.text}{' '}
                <ChevronDown
                  size={16}
                  style={{
                    marginLeft: theme.spacing(0.25),
                    paddingTop: theme.spacing(0.1),
                  }}
                />
              </MobileTrigger>
            }
          >
            <PopoverMenu>
              {chunks.slice(0, -1).map(({ path, text, icon }, index) => (
                <PopoverItem key={index}>
                  <PopoverItemButton as={RawLink} to={path}>
                    {Boolean(icon) && <Icon>{icon}</Icon>}
                    <span>{text}</span>
                  </PopoverItemButton>
                </PopoverItem>
              ))}
            </PopoverMenu>
          </Dropdown>
        )}

        {breakpoint !== 'mobile' && (
          <BreadcrumbContent>
            {chunks.map(({ path, text, icon }, index) => {
              const isLast = chunks.length - 1 === index;

              return (
                <Crumb isLast={isLast} key={index}>
                  {index !== 0 && <ChevronRight size={12} />}

                  {isLast ? (
                    <CrumbLast as="span" variant={index === 0 && 'main'}>
                      {icon}
                      {text}
                    </CrumbLast>
                  ) : (
                    <CrumbLink to={path}>
                      {icon}
                      {text}
                    </CrumbLink>
                  )}
                </Crumb>
              );
            })}
          </BreadcrumbContent>
        )}
      </Wrapper>
    </>
  );
};

export default Breadcrumb;
