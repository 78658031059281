import styled from 'styled-components';
import { Container, Card, fontSize, fontStyles } from 'ui';
import { useTranslation } from 'react-i18next';
import { FieldArray, Formik } from 'formik';
import { Form, TextField } from 'components/Forms';
import * as Yup from 'yup';
import { Button } from 'components';
import { Buttons, Stack } from '@tymate/margaret';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { useApp } from 'hooks';
import { CLIENT_ID } from '../../constants';
import { formatDateDistanceToNow } from 'utils';
import { chain } from 'lodash';
import { Trash } from 'react-bootstrap-icons';
import usePostHog from 'hooks/usePosthog';

const Title = styled.h2`
  margin-top: 0;
  font-size: 1.25rem;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

const Text = styled.p`
  ${fontStyles.p}
  margin-top: ${({ theme }) => theme.spacing(0.25)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  white-space: pre-line;
`;

const Subtext = styled.p`
  ${fontStyles.p}
  ${fontSize.bodySmall};
  margin-top: ${({ theme }) => theme.spacing(0.25)};
  color: ${({ theme }) => theme.textLighter};
`;

const CREATE_API_CONFIGURATION = gql`
  mutation createApiConfiguration($input: CreateApiConfigurationInput!) {
    createApiConfiguration(input: $input) {
      user {
        id
        lastName
        firstName
        company
        avatarUrl
        apiConfiguration {
          id
          webhookUrls
          apiKey {
            id
            token
            createdAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const GENERATE_API_KEY = gql`
  mutation generateApiKey($input: GenerateApiKeyInput!) {
    generateApiKey(input: $input) {
      user {
        id
        lastName
        firstName
        company
        avatarUrl
        apiConfiguration {
          id
          webhookUrls
          apiKey {
            id
            token
            createdAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const UPDATE_API_WEBHOOKS = gql`
  mutation updateApiConfiguration($input: UpdateApiConfigurationInput!) {
    updateApiConfiguration(input: $input) {
      user {
        id
        lastName
        firstName
        company
        avatarUrl
        apiConfiguration {
          id
          webhookUrls
          apiKey {
            id
            token
            createdAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

const Api = () => {
  usePostHog();
  const { t } = useTranslation(['account', 'errors']);
  const [generateApiKey] = useMutation(GENERATE_API_KEY);
  const [createApiConfiguration] = useMutation(CREATE_API_CONFIGURATION);
  const [updateApiConfiguration] = useMutation(UPDATE_API_WEBHOOKS);
  const { user } = useApp();

  const handleApiSubmit = async () => {
    try {
      if (user?.apiConfiguration) {
        await generateApiKey({
          variables: {
            input: {
              clientId: CLIENT_ID,
              userId: user.id,
            },
          },
        });
      } else {
        await createApiConfiguration({
          variables: {
            input: {
              clientId: CLIENT_ID,
              userId: user.id,
            },
          },
        });
      }
    } catch (err) {
      console.dir(err);
    }
  };

  const handleWebhooksSubmit = async (values, { resetForm }) => {
    const payload = {
      ...values,
      webhookUrls: chain(values.webhookUrls).compact().uniq(),
    };
    try {
      await updateApiConfiguration({
        variables: {
          input: {
            userId: user.id,
            ...payload,
          },
        },
      });
      resetForm();
    } catch (err) {
      console.dir(err);
    }
  };

  return (
    <Container size="narrow" variant="main">
      <Card>
        <Title>{t('api_key')}</Title>
        <Text>
          {user?.apiConfiguration
            ? t('api_key_explanation')
            : t('api_key_empty')}
        </Text>
        <Formik
          onSubmit={handleApiSubmit}
          enableReinitialize
          validationSchema={Yup.object().shape({
            token: Yup.string(),
          })}
          initialValues={{ token: user?.apiConfiguration?.apiKey?.token || '' }}
        >
          {({ isSubmitting, handleReset, status }) => (
            <Form>
              {user?.apiConfiguration && (
                <>
                  <TextField
                    name="token"
                    label={t('token')}
                    type="text"
                    readOnly
                  />
                  <Subtext>
                    {t('api_key_generated_at', {
                      fromNow: formatDateDistanceToNow(
                        user.apiConfiguration.createdAt,
                      ),
                    })}
                  </Subtext>
                </>
              )}
              <Buttons>
                <Button variant="primary" isLoading={isSubmitting}>
                  {user?.apiConfiguration
                    ? t('regenerate_api_key')
                    : t('generate_api_key')}
                </Button>
              </Buttons>
            </Form>
          )}
        </Formik>
      </Card>
      {user?.apiConfiguration && (
        <Card>
          <Title>{t('webhooks')}</Title>
          <Text>{t('webhooks_explanation')}</Text>
          <Formik
            onSubmit={handleWebhooksSubmit}
            enableReinitialize
            validationSchema={Yup.object().shape({
              webhookUrls: Yup.array().of(Yup.string().url(t('errors:url'))),
            })}
            initialValues={{ webhookUrls: user?.apiConfiguration?.webhookUrls }}
          >
            {({ isSubmitting, handleReset, handleSubmit, status, values }) => (
              <Form>
                <FieldArray
                  name="webhookUrls"
                  validateOnChange
                  render={({ push, remove }) => (
                    <>
                      {values.webhookUrls && (
                        <>
                          {values.webhookUrls.map((webhook, index) => (
                            <Stack
                              key={index}
                              marginBottom={1.5}
                              marginTop={1}
                              alignY="center"
                              size="full"
                            >
                              <TextField
                                name={`webhookUrls.${index}`}
                                label={`Webhook ${index + 1}`}
                                type="text"
                              />
                              <Button
                                type="button"
                                onClick={$event => {
                                  remove(index);
                                  handleSubmit($event);
                                }}
                              >
                                <Trash />
                              </Button>
                            </Stack>
                          ))}
                          <Buttons>
                            <Button
                              variant="primary"
                              tybe="submit"
                              isLoading={isSubmitting}
                            >
                              {t('save')}
                            </Button>
                            <Button type="button" onClick={() => push('')}>
                              {t('add_webhook')}
                            </Button>
                          </Buttons>
                        </>
                      )}
                    </>
                  )}
                />
              </Form>
            )}
          </Formik>
        </Card>
      )}
    </Container>
  );
};

export default Api;
