import { useContext } from 'react';
import {
  AuthContext,
  AuthenticationContext,
  AppContext,
  RoutesContext,
  UploadFilesContext,
  DownloadFilesContext,
} from 'contexts';

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
};

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error(
      `useAuthentication must be used within a AuthenticationProvider`,
    );
  }

  return context;
};

export const useUpload = () => {
  const context = useContext(UploadFilesContext);

  if (context === undefined) {
    throw new Error(`useUpload must be used within a UploadFilesContext`);
  }

  return context;
};

export const useDownload = () => {
  const context = useContext(DownloadFilesContext);

  if (context === undefined) {
    throw new Error(`useDownload must be used within a DownloadFilesContext`);
  }

  return context;
};

export const useApp = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error(`useApp must be used within a AppProvider`);
  }

  return context;
};

export const useRoutes = () => {
  const context = useContext(RoutesContext);

  if (context === undefined) {
    throw new Error(`useRoutes must be used within a RoutesProvider`);
  }

  return context;
};
