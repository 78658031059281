import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { ButtonReset, Spinner, Stack, media } from '@tymate/margaret';
import { Trans } from 'react-i18next';
import icSafeLock from 'images/ic-safe-lock.svg';
import icSafeFolder from 'images/ic-safe-folder.svg';
import { CloudArrowUp, FileEarmarkPlus, Folder } from 'react-bootstrap-icons';
import { Button } from 'components';

const UploadThumbnail = styled(Stack)`
  margin-left: auto;
  margin-right: auto;
`;
const DragAndDropWrapper = styled(ButtonReset)`
  border: 2px dashed ${({ theme }) => theme.primary};
  min-height: 150px;
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing()};
  border-radius: ${({ theme }) => theme.borderRadiusLarge};
  width: 100%;

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)};
  `}
`;

const Img = styled.img`
  display: none;
  width: clamp(2em, 8vw, 100%);
  ${media.tablet`
    display: block;
  `}
`;

const DragAndDropContent = styled(Stack)`
  width: 100%;
  line-height: 1.5;
  font-family: inherit;
  color: ${({ theme }) => theme.textLight};
  text-align: left;

  strong {
    // color: ${({ theme }) => theme.primary};
    display: block;
    font-weight: 500;
  }

  ${UploadThumbnail} svg {
    // color: ${({ theme }) => theme.primary};
    font-size: 3rem;
  }
`;

const Dropzone = ({
  onAdd,
  variant,
  onClick,
  t,
  triggerFolderUpload,
  triggerFileUpload,
  ...props
}) => {
  const [isFetching, setIsFetching] = useState();
  const fileInputRef = useRef();
  const folderInputRef = useRef();
  const onDrop = useCallback(
    async acceptedFiles => {
      setIsFetching(true);
      await onAdd(acceptedFiles);
      setIsFetching(false);
    },
    [onAdd],
  );

  const triggerFileUploadDialog = e => {
    e?.stopPropagation();
    let elFileInput = document.querySelector('input#fileInput');
    elFileInput.click();
  };
  const triggerFolderUploadDialog = e => {
    e?.stopPropagation();
    let elFolderInput = document.querySelector('input#folderInput');
    elFolderInput.click();
  };

  // Assign the methods for uploading files and folders to the parent refs
  useEffect(() => {
    if (triggerFolderUpload) {
      triggerFolderUpload.current = triggerFolderUploadDialog;
    }
  }, [triggerFolderUpload]);
  useEffect(() => {
    if (triggerFileUpload) {
      triggerFileUpload.current = triggerFileUploadDialog;
    }
  }, [triggerFileUpload]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <DragAndDropWrapper type="button" {...getRootProps({ onClick })}>
      {variant === 'dataTable' && (
        <Stack alignX="space-between" alignY="center">
          <Img src={icSafeFolder} alt="" />
          <DragAndDropContent
            direction={{ default: 'column', tablet: 'row' }}
            alignX={{ default: 'center', tablet: 'space-between' }}
          >
            {isFetching || props.isFetching ? (
              <Stack size="full" alignX="center">
                <Spinner />
              </Stack>
            ) : (
              <>
                <input
                  ref={folderInputRef}
                  id="folderInput"
                  {...getInputProps()}
                  directory=""
                  webkitdirectory=""
                />
                <input
                  id="fileInput"
                  style={{ display: 'block' }}
                  ref={fileInputRef}
                  {...getInputProps()}
                  multiple
                />
                {isDragActive ? (
                  <Stack
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    direction="column"
                    justify="center"
                    gutterSize={1}
                  >
                    <UploadThumbnail alignY="flex-end" gutterSize={0.75}>
                      <FileEarmarkPlus size={24} />
                      <FileEarmarkPlus size={24} />
                      <CloudArrowUp />
                      <FileEarmarkPlus size={24} />
                      <FileEarmarkPlus size={24} />
                    </UploadThumbnail>
                    <div
                      style={{
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      {/* <p>Glissez votre fichier ici…</p> */}
                      <Trans i18nKey="request:drop_items_active">
                        Glissez vos fichiers ici…
                      </Trans>
                    </div>
                  </Stack>
                ) : (
                  <>
                    <Stack
                      style={{ marginLeft: 'auto', marginRight: 'auto' }}
                      direction="column"
                      justify="center"
                      gutterSize={1}
                    >
                      <UploadThumbnail alignY="flex-end" gutterSize={0.75}>
                        <FileEarmarkPlus size={24} />
                        <FileEarmarkPlus size={24} />
                        <CloudArrowUp />
                        <FileEarmarkPlus size={24} />
                        <FileEarmarkPlus size={24} />
                      </UploadThumbnail>
                      <div
                        style={{
                          textAlign: 'center',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                      >
                        <Trans i18nKey="request:drop_items_explanation">
                          Drop items here to add{' '}
                          <strong>or import them from the device</strong>
                        </Trans>
                      </div>
                      <Stack
                        gutterSize={0.5}
                        direction={{ default: 'column', tablet: 'row' }}
                        alignX="space-between"
                        style={{ width: '100%' }}
                      >
                        <Button
                          variant="primary"
                          icon={<FileEarmarkPlus size={18} />}
                          onClick={e => triggerFileUploadDialog(e)}
                          size="small"
                        >
                          {t('import_files')}
                        </Button>
                        <Button
                          variant="outline"
                          icon={<Folder size={18} />}
                          onClick={e => triggerFolderUploadDialog(e)}
                          style={{ backgroundColor: 'white' }}
                          size="small"
                        >
                          {t('import_folder')}
                        </Button>
                      </Stack>
                    </Stack>
                  </>
                )}
              </>
            )}
          </DragAndDropContent>
          <Img src={icSafeLock} alt="" />
        </Stack>
      )}
      {variant !== 'dataTable' && (
        <>
          {isFetching || props.isFetching ? (
            <Stack size="full" alignX="center">
              <Spinner />
            </Stack>
          ) : (
            <>
              <input {...getInputProps()} />
              {isDragActive ? (
                <Trans i18nKey="request:drop_items_active">
                  Glissez vos fichiers ici…"
                </Trans>
              ) : (
                <DragAndDropContent
                  direction={{ default: 'column', tablet: 'row' }}
                  alignX={{ default: 'center', tablet: 'space-between' }}
                  gutterSize={1}
                  alignY="center"
                >
                  <div>
                    <Trans i18nKey="request:drop_items_explanation">
                      Drop items here to add{' '}
                      <strong>or import them from the device</strong>
                    </Trans>
                  </div>
                  <Stack alignY="center" gutterSize={0.75}>
                    <FileEarmarkPlus size={24} />
                    <FileEarmarkPlus size={24} />
                    <CloudArrowUp />
                    <FileEarmarkPlus size={24} />
                    <FileEarmarkPlus size={24} />
                  </Stack>
                </DragAndDropContent>
              )}
            </>
          )}
        </>
      )}
    </DragAndDropWrapper>
  );
};

export default Dropzone;
