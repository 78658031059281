import React, { useEffect } from 'react';
import styled from 'styled-components';
import { fontStyles } from 'ui';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Button } from 'components';
import { Form, TextareaField } from 'components/Forms';
import { Avatar } from './';
import { Stack, media, List } from '@tymate/margaret';
import { useApp, useBreakpoint } from 'hooks';
import gql from 'graphql-tag';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { formatDate } from 'utils';
import { Scrollbars as RawScrollbars } from 'react-custom-scrollbars';
import Linkify from 'react-linkify';
import { MdSend } from 'react-icons/md';

const Scrollbars = styled(RawScrollbars)`
  > div:first-child {
    overflow-x: auto !important;
  }
`;

const Card = styled.div`
  ${media.desktop`
    border: 1px solid ${({ theme }) => theme.separator};
    border-radius: ${({ theme }) => theme.borderRadius};
    background-color: #fff;
    padding: ${({ theme }) => theme.spacing()};
    position: sticky;
    top: 96px;
  `}
`;

const CardTitle = styled.p`
  ${fontStyles.h3};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-weight: 600;
`;

const SendButton = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(0.5)};
  right: ${({ theme }) => theme.spacing(0.5)};

  button {
    padding: 0;
    height: auto;
  }

  svg:last-child {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
    margin-right: 0;
  }
`;

const TextLight = styled.p`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLighter};
  margin: 0;
`;

const Message = styled.p`
  ${fontStyles.bodySmall};
  margin: 0;

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
  }
`;
const GET_REQUEST = gql`
  query DialogBox_getRequest($slug: String!) {
    request(slug: $slug) {
      id
      requestComments {
        edges {
          node {
            id
            message
            createdAt
            author {
              firstName
              lastName
              avatarUrl
            }
          }
        }
      }
    }
  }
`;
const CREATE_REQUEST_COMMENT = gql`
  mutation createRequestComment($input: CreateRequestCommentInput!) {
    createRequestComment(input: $input) {
      requestComment {
        id
      }
    }
  }
`;
const DialogBox = ({ requestId, requestComments, refetchComments }) => {
  const { user } = useApp();
  const { t } = useTranslation('request');
  const { requestSlug } = useParams();
  const [fetch, { data, refetch }] = useLazyQuery(GET_REQUEST, {
    variables: { slug: requestSlug },
  });
  const breakpoint = useBreakpoint();

  const [createRequestComment] = useMutation(CREATE_REQUEST_COMMENT);

  const comments = Boolean(requestComments)
    ? requestComments
    : data?.request?.requestComments?.edges || [];

  const handleCreateComment = async (values, { resetForm }) => {
    try {
      await createRequestComment({
        variables: {
          input: {
            requestId: Boolean(requestId) ? requestId : data?.request?.id,
            ...values,
          },
        },
      });
      if (Boolean(requestId)) {
        refetchComments();
      } else {
        refetch();
      }
      resetForm();
    } catch (err) {
      console.dir(err);
    }
  };

  useEffect(() => {
    if (Boolean(requestSlug)) {
      fetch();
    }
  }, [fetch, requestSlug]);

  return (
    <Card>
      <CardTitle>{t('comments')}</CardTitle>

      {comments.length > 0 && (
        <Scrollbars
          autoHeight
          autoHide
          autoHeightMax={
            breakpoint === 'desktop'
              ? 300
              : 'calc(100vh - 105px - 24px - 32px - 32px - 16px)'
          }
          style={{ paddingBottom: 16 }}
        >
          <List direction="column" gutterSize={1.5} marginBottom={1.5}>
            {comments.map(({ node }) => (
              <Stack gutterSize={0.5} style={{ width: '100%' }}>
                <Avatar
                  firstName={node?.author?.firstName}
                  lastName={node?.author?.lastName}
                  imageUrl={node?.author?.avatarUrl}
                  size="small"
                />
                <div style={{ width: '100%' }}>
                  <Stack
                    alignX="space-between"
                    marginBottom={0.5}
                    marginTop={0.5}
                    alignY="center"
                    size="full"
                  >
                    <span>
                      <TextLight>
                        {node?.author?.firstName} {node?.author?.lastName}
                      </TextLight>
                    </span>
                    <span>
                      {Boolean(node.createdAt) && (
                        <TextLight>
                          {formatDate(node?.createdAt, 'dd/MM/yy - HH:mm')}
                        </TextLight>
                      )}
                    </span>
                  </Stack>
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        target="blank"
                        rel="noopener noreferer nofollow"
                        href={decoratedHref}
                        key={key}
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    <Message>{node?.message}</Message>
                  </Linkify>
                </div>
              </Stack>
            ))}
          </List>
        </Scrollbars>
      )}

      <Formik
        initialValues={{ message: '' }}
        onSubmit={handleCreateComment}
        validationSchema={Yup.object().shape({
          message: Yup.string().required(t('errors:required')),
        })}
      >
        {({ errors, isSubmitting }) => (
          <Form>
            <Stack marginTop={1.5} gutterSize={0.5}>
              <Avatar
                firstName={user?.firstName}
                lastName={user?.lastName}
                imageUrl={user?.avatarUrl}
                size="small"
              />
              <div style={{ position: 'relative', width: '100%' }}>
                <TextareaField
                  style={{ paddingBottom: 32 }}
                  name="message"
                  minRows={3}
                  placeholder={t('your_message')}
                />
                <SendButton>
                  <Button isLoading={isSubmitting}>
                    {t('send')}
                    <MdSend />
                  </Button>
                </SendButton>
              </div>
            </Stack>
          </Form>
        )}
      </Formik>
    </Card>
  );
};
export default DialogBox;
