import styled from 'styled-components';
import { Header, Activity, Button } from 'components';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { List, Buttons, Spinner } from '@tymate/margaret';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { get, groupBy, keys, capitalize } from 'lodash';
import { Container, fontStyles } from 'ui';
import { formatDate } from 'utils';
import { isToday, startOfDay, isYesterday } from 'date-fns';
import usePostHog from 'hooks/usePosthog';

const Day = styled.h2`
  ${fontStyles.h2}
`;

const GET_WORK_ACTIVITIES = gql`
  query getWorkActivities(
    $slug: String!
    $cursor: String
    $order: FeedActivityOrderCriteria
  ) {
    work(slug: $slug) {
      id
      slug
      activities(first: 15, after: $cursor, order: $order) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            verb
            createdAt
            text
            actor {
              firstName
              lastName
              avatarUrl
              id
            }
            item {
              ... on Request {
                id
                name
                slug
                recipient {
                  id
                  firstName
                  lastName
                }
              }
              ... on Work {
                id
                name
              }
              ... on User {
                id
                lastName
                firstName
                email
              }
            }
          }
        }
      }
    }
  }
`;

const Activities = () => {
  usePostHog();
  const { workSlug } = useParams();
  const { t } = useTranslation('activities');

  const { data, fetchMore, loading } = useQuery(GET_WORK_ACTIVITIES, {
    variables: {
      slug: workSlug,
      order: { column: 'CREATED_AT', direction: 'DESC' },
    },
  });

  const groupedActivities = groupBy(
    get(data, 'work.activities.edges', []),
    ({ node }) => startOfDay(new Date(node?.createdAt)),
  );

  const hasNextPage = data?.work?.activities?.pageInfo?.hasNextPage;

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: data?.work?.activities?.pageInfo?.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        work: {
          ...prev.work,
          activities: {
            ...prev.work.activities,
            pageInfo: fetchMoreResult?.work?.activities?.pageInfo,
            edges: [
              ...prev.work.activities.edges,
              ...fetchMoreResult?.work?.activities?.edges,
            ],
          },
        },
      }),
    });
  };

  return (
    <>
      <Header title={t('activities')} containerSize="narrow" />
      {loading && !data ? (
        <Spinner />
      ) : (
        <Container variant="main" size="narrow">
          <List direction="column">
            {keys(groupedActivities, []).map((day, index) => {
              const dayActivities = groupedActivities[day];
              return (
                <div key={index}>
                  <Day>
                    {isToday(new Date(day))
                      ? t('today')
                      : isYesterday(new Date(day))
                      ? t('yesterday')
                      : capitalize(formatDate(day, 'EEEE d MMMM yyyy'))}
                  </Day>
                  <List direction="column" alignX="stretch" gutterSize={1.5}>
                    {dayActivities.map(({ node }, index) => (
                      <Activity activity={node} key={index} />
                    ))}
                  </List>
                </div>
              );
            })}
          </List>
          {hasNextPage && (
            <div>
              <Buttons alignX="center" marginBottom={1}>
                <Button onClick={handleLoadMore}>{t('see_more')}</Button>
              </Buttons>
            </div>
          )}
        </Container>
      )}
    </>
  );
};
export default Activities;
