import { useApp } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SignatureCodeInputModal,
  SignaturePhoneInputModal,
  SignaturePreviewModal,
} from '.';

export const displaySignatureButton = (request, item, signatureAs, user) => {
  return (
    ['DRAFT', 'ACTIVE'].indexOf(item.status) > -1 &&
    (canSignAsRecipient(request, item, signatureAs) ||
      canSignAsSender(request, item, signatureAs, user))
  );
};

export const canSignAsRecipient = (request, item, signatureAs) => {
  return (
    signatureAs === 'recipient' &&
    Boolean(request?.canAnswer?.value) && // Can answer
    (Boolean(item?.recipientSignature?.status === 'PENDING') || // Signature pending
      !Boolean(item?.recipientSignature)) // Signature procedure not started
  );
};

export const canSignAsSender = (request, item, signatureAs, user) => {
  return (
    signatureAs === 'sender' &&
    Boolean(request?.author?.id === user?.id) && // Is author of the request
    item?.answer && // Recipient already answered
    Boolean(item?.senderSignature.status === 'PENDING') // Signature pending
  );
};

const getNextSignatureStep = (
  currentStep,
  request,
  item,
  signatureAs,
  user,
) => {
  switch (currentStep) {
    case STEPS.PREVIEW:
      if (canSignAsRecipient(request, item, signatureAs)) {
        return Boolean(item?.recipientSignature)
          ? STEPS.CODE_INPUT
          : STEPS.PHONE_INPUT;
      } else {
        return canSignAsSender(request, item, signatureAs, user)
          ? STEPS.CODE_INPUT
          : null;
      }
    case STEPS.PHONE_INPUT:
      return STEPS.CODE_INPUT;
    case STEPS.CODE_INPUT:
    case STEPS.SUCCESS:
    default:
      return STEPS.PREVIEW;
  }
};

export const STEPS = {
  PREVIEW: 'preview',
  PHONE_INPUT: 'phoneInput',
  CODE_INPUT: 'codeInput',
  SUCCESS: 'success',
};

export const defaultData = {
  started: false,
  step: null,
  currentItem: null,
  sendCode: false,
};

const SignatureProcedure = ({
  request,
  refetchRequest,
  signatureAs,
  data: { start, item },
  onProcedureFinished,
}) => {
  const { notify, user } = useApp();
  const { t } = useTranslation('signature');
  const [data, setData] = useState(defaultData);

  const launchSignature = useCallback(
    item => {
      setData(d => ({
        ...d,
        started: true,
        currentItem: item,
        step: getNextSignatureStep(null, request, item, signatureAs, user),
      }));
    },
    [request, signatureAs, user],
  );

  useEffect(() => {
    if (!start) {
      return;
    }

    launchSignature(item);
  }, [start, item, launchSignature]);

  const onClickedSignedDocument = useCallback(() => {
    const nextStep = getNextSignatureStep(
      STEPS.PREVIEW,
      request,
      data.currentItem,
      signatureAs,
      user,
    );

    setData({
      ...data,
      step: nextStep,
      sendCode: nextStep === STEPS.CODE_INPUT,
    });
  }, [data, request, signatureAs, user]);

  const onSubmittedPhone = useCallback(() => {
    const nextStep = getNextSignatureStep(
      STEPS.PHONE_INPUT,
      request,
      data.currentItem,
      signatureAs,
      user,
    );

    setData({
      ...data,
      step: nextStep,
      sendCode: nextStep === STEPS.CODE_INPUT,
    });
  }, [data, request, signatureAs, user]);

  const onSignatureCodeSent = useCallback(() => {
    notify(t('code_has_been_sent'));
    setData(d => ({
      ...d,
      sendCode: false,
    }));
  }, [notify, t]);

  const resetProcedure = useCallback(() => {
    onProcedureFinished();
    setData(defaultData);
  }, [onProcedureFinished]);

  const onValidatedCode = useCallback(() => {
    setTimeout(() => refetchRequest(), 2500); // Give time to server to receive Yousign hook
    resetProcedure();
  }, [refetchRequest, resetProcedure]);

  return (
    <>
      {/* Signature : Document preview */}
      <SignaturePreviewModal
        isOpen={data.step === STEPS.PREVIEW}
        currentItem={data.currentItem}
        onRequestClose={() => resetProcedure()}
        onSignDocumentClick={onClickedSignedDocument}
      />

      {/* Signature : Phone input */}
      <SignaturePhoneInputModal
        isOpen={data.step === STEPS.PHONE_INPUT}
        currentItem={data.currentItem}
        onRequestClose={() => resetProcedure()}
        onSubmittedPhone={onSubmittedPhone}
      />

      {/* Signature : Code input */}
      <SignatureCodeInputModal
        isOpen={data.step === STEPS.CODE_INPUT}
        currentItem={data.currentItem}
        codeType={
          canSignAsSender(request, data.currentItem, signatureAs, user)
            ? 'sender'
            : 'recipient'
        }
        sendCode={data.sendCode}
        onSignatureCodeSent={onSignatureCodeSent}
        onRequestClose={() => resetProcedure()}
        onValidatedCode={onValidatedCode}
      />
    </>
  );
};

export default SignatureProcedure;
