import React from 'react';
import { Box, List, Stack, media } from '@tymate/margaret';
import {
  FileEarmarkText,
  Fonts,
  LayoutTextSidebarReverse,
  ListTask,
  Paperclip,
  PencilSquare,
  PersonCheck,
  VectorPen,
} from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { fontStyles } from 'ui';
import { FormLabel } from 'ui/forms';
import { Deadline } from '.';
import { get } from 'lodash';
import Button from './Button';

const RecipientsGrid = styled(Stack)`
  flex-wrap: wrap;
`;

// const ItemsContainer = styled(Stack)``;
const CardContainer = styled(Stack)`
  ${media.tablet`
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 2em 1em;
  `}
`;
const RecapGrid = styled.div`
  display: grid;
  column-gap: ${({ theme }) => theme.spacing(2)};
  row-gap: ${({ theme }) => theme.spacing(2)};
  grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
`;

const RecipientWrapper = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
  gap: ${({ theme }) => theme.spacing(0.25)};
  ${fontStyles.bodySmall};
  border-radius: 5px;
  color: ${({ theme }) => theme.textLight};
  &:not(:first-child)::before {
    content: ', ';
    display: block;
  }
`;

const Description = styled.div`
  ${fontStyles.body};
  color: ${({ theme }) => theme.textLight};
  white-space: pre-wrap;
  text-align: justify;
`;

const RecapCard = styled(Stack)`
  border-bottom: 1px solid ${({ theme }) => theme.separator};
  padding: ${({ theme }) => theme.spacing()} 0;
`;

const RecapCardTitle = styled(Box)`
  margin-left: ${({ theme }) => theme.spacing(1)};
  font-weight: 500;
`;

const RecapTitle = styled.h3`
  ${fontStyles.h3};
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
`;
RecapTitle.defaultProps = {
  as: 'h2',
};

// const EmptyStateTitle = styled.h4`
//   ${fontStyles.h4}
//   margin-bottom: 0;
// `;
const EmptyStateLabel = styled.p`
  ${fontStyles.body};
  color: ${({ theme }) => theme.textLighter};
  margin-bottom: 0;
`;
const EmptyStateIcon = styled.div`
  color: ${({ theme }) => theme.textLightest};
  font-size: 4em;
`;
const Label = styled.p`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLighter};
  margin-bottom: 0;
  margin-top: 0;
`;
/**
 * Component for showing the recap of a new request
 */
const NewRequestRecap = ({ values, recipients, requestsSlug }) => {
  const { t } = useTranslation('newRequest');

  return (
    <div>
      <RecapTitle style={{ marginBottom: '2em' }}>
        {t('stepper.recap.subtitle')}
      </RecapTitle>
      <RecapGrid>
        <CardContainer direction="column" gutterSize={1.5}>
          <Stack direction="column" size="full">
            <Stack
              size="full"
              alignX="space-between"
              style={{
                borderBottom: '1px solid rgba(0,0,0, 0.2)',
                // marginBottom: '1.5em',
              }}
            >
              <FormLabel>{t('stepper.recap.details')}</FormLabel>
              <Button
                type="button"
                icon={<PencilSquare />}
                size="small"
                variant="link"
                to={`${requestsSlug}new/header`}
              >
                {t('stepper.recap.edit')}
              </Button>
            </Stack>
          </Stack>
          {recipients?.length > 0 && (
            <Stack direction="column">
              <FormLabel>{t('receiver')}</FormLabel>
              {recipients?.length > 1 && (
                <Label>
                  {t('stepper.recap.send_to_recipients', {count: recipients.length})}
                </Label>
              )}
              <RecipientsGrid>
                <RecipientWrapper>
                  {recipients
                    .map((recipientItem, index) => {
                      return recipientItem?.email;
                    })
                    .join(', ')}
                </RecipientWrapper>
              </RecipientsGrid>
            </Stack>
          )}
          <Stack direction="column">
            <FormLabel>{t('request_title')}</FormLabel>
            <Description>{values?.name}</Description>
          </Stack>
          {values?.description && (
            <Stack direction="column">
              <FormLabel>{t('description')}</FormLabel>
              <Description>{values?.description}</Description>
            </Stack>
          )}

          <Stack direction="column">
            <FormLabel>{t('due_date')}</FormLabel>
            <Deadline deadline={values?.deadline} variant="requestDetail" />
          </Stack>
        </CardContainer>
        <CardContainer direction="column" alignX="stretch">
          <Stack
            size="full"
            alignX="space-between"
            style={{
              borderBottom: '1px solid rgba(0,0,0, 0.2)',
              marginBottom: '1.5em',
            }}
          >
            <FormLabel>{t('stepper.recap.items')}</FormLabel>
            <Button
              type="button"
              icon={<PencilSquare />}
              size="small"
              variant="link"
              to={`${requestsSlug}new/content`}
            >
              {t('stepper.recap.edit')}
            </Button>
          </Stack>

          {get(values, 'items', []).length > 0 ? (
            <List
              direction="column"
              gutterSize={0.5}
              alignX="stretch"
              marginVertical={1}
            >
              {get(values, 'items', []).map((item, index) => (
                <RecapCard
                  as="li"
                  key={`recapCard_${index}`}
                  gutterSize={0.5}
                  direction="column"
                >
                  <Stack
                    size="full"
                    gutterSize={1}
                    direction="row"
                    alignY="center"
                    style={{ flexWrap: 'wrap' }}
                  >
                    {item?.type === 'DOCUMENT' && <FileEarmarkText size={22} />}
                    {item?.type === 'ATTACHMENT' && <Paperclip size={22} />}
                    {item?.type === 'MESSAGE' && <Fonts size={22} />}
                    {item?.type === 'PROMPT' && <ListTask size={22} />}
                    {item?.type === 'SIGNATURE' && <VectorPen size={22} />}
                    {item?.type === 'ACKNOWLEDGE' && <PersonCheck size={22} />}
                    <RecapCardTitle>{item?.name}</RecapCardTitle>
                  </Stack>
                </RecapCard>
              ))}
            </List>
          ) : (
            <Stack
              direction="column"
              alignX="center"
              alignY="center"
              marginTop={2}
              marginBottom={2}
              gutterSize={1}
              style={{ flex: 1 }}
            >
              <EmptyStateIcon>
                <LayoutTextSidebarReverse />
              </EmptyStateIcon>
              <EmptyStateLabel>{t('stepper.recap.no_items')}</EmptyStateLabel>
              <Button
                type="button"
                variant="primary"
                to={`${requestsSlug}new/content`}
              >
                {t('stepper.recap.go_to_items')}
              </Button>
            </Stack>
          )}
        </CardContainer>
      </RecapGrid>
    </div>
  );
};

export default NewRequestRecap;
