import { useState, useEffect, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useParams, useNavigate, Link as RawLink } from 'react-router-dom';
import { Title, fontStyles, Card, theme } from 'ui';
import {
  Button,
  DatePicker,
  AvatarAndName,
  RequestModelCard,
  SimpleDropzone,
  PhoneInputModal,
  RequestDraftModal,
  Tooltip,
  NewRequestRecap,
  NewRequestConfirmation,
  Stepper,
} from 'components';
import {
  Buttons,
  media,
  List,
  Stack,
  ButtonReset,
  Spinner,
  Modal,
  Box,
} from '@tymate/margaret';
import { Formik, FieldArray } from 'formik';
import { FormField, FormLabel, ErrorMessageWrapper } from 'ui/forms';
import {
  Form,
  TextField,
  TextareaField,
  AttachmentsField,
  CheckboxField,
} from 'components/Forms';
import yousignLogo from 'images/yousign-logo.png';
import { useTranslation, Trans } from 'react-i18next';
import { get, omit, pickBy, isEmpty } from 'lodash';
import { MdSend } from 'react-icons/md';
import gql from 'graphql-tag';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import RequestRecipientModal from 'components/RequestRecipientModal';
import * as Yup from 'yup';
import { PromptChoicesField, Header } from 'components';
import { useBreakpoint, useApp } from 'hooks';
import { useDeepCompareEffect, useLocalStorage } from 'react-use';
import scrollToElement from 'scroll-to-element';
import { endOfDay } from 'date-fns';
import {
  MAX_SIGNATURE_FILE_SIZE,
  MAX_REQUEST_MODEL_FILE_SIZE,
} from '../../../../../constants';
import {
  InfoCircle,
  PersonPlusFill,
  Trash,
  X,
  ChevronDown,
  VectorPen,
  ListTask,
  PersonCheck,
  ChevronUp,
  ChevronLeft,
  Fonts,
  Paperclip,
  FileEarmarkText,
  List as ListIcon,
  ChevronRight,
  Grid1x2,
  LayoutTextSidebarReverse,
  People,
  CheckCircle,
  PatchPlus,
} from 'react-bootstrap-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import usePostHog from 'hooks/usePosthog';

// ********** STYLED COMPONENENTS ********** //
const Link = styled(RawLink)`
  color: inherit;

  &:hover {
    color: ${({ theme }) => theme.textLight};
  }
`;
const Page = styled.div``;
const Alert = styled(Stack)`
  background-color: ${({ theme }) => theme.background};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ theme }) => theme.spacing()};

  ${({ variant }) =>
    variant === 'tertiary' &&
    css`
      background-color: ${({ theme }) => theme.tertiaryLighter};
      box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separatorLighter};
      color: ${({ theme }) => theme.tertiary};
    `}
`;
const ItemInnerCard = styled(Card)`
  border: none;
  background: transparent;
  > div {
    padding: ${({ theme }) => theme.spacing()};
    ${media.tablet`
    padding: ${({ theme }) => theme.spacing()} ${({ theme }) =>
      theme.spacing(2)};
  `}
  }
`;
const Content = styled.div`
  padding: ${({ theme }) => theme.spacing()};
  ${media.tablet`
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  padding-left: ${({ theme }) => theme.spacing(5)};
  padding-right: ${({ theme }) => theme.spacing(5)};
  `}

  ${media.desktop`
  max-width: 75rem;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  margin-left: auto;
  margin-right: auto;
  `}
`;
const StickyHeaderWrapper = styled(Box)`
  ${media.tablet`
    position: sticky;
    top: 80px;
    z-index: 1;
  `}
`;
const BottomBar = styled(Stack)`
  background-color: #fff;
  box-shadow: 0 -1px 4px 0 ${({ theme }) => theme.separator};
  margin-top: ${({ theme }) => theme.spacing(2)};
  position: fixed;
  bottom: 49px;
  width: 100%;

  ${media.tablet`
  bottom: 0;
  width: calc(100% - 72px);
  `}
`;
const BottomBarInner = styled(Stack)`
  padding: ${({ theme }) => theme.spacing()};
  width: 100%;

  ${media.tablet`
  padding-left: ${({ theme }) => theme.spacing(5)};
  padding-right: ${({ theme }) => theme.spacing(5)};
  padding-top: ${({ theme }) => theme.spacing(1)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  `}

  ${media.desktop`
  max-width: 75rem;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-top: ${({ theme }) => theme.spacing(1.5)};
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
  margin-left: auto;
  margin-right: auto;
  `}
`;
const RecipientsGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(13em, 1fr));
  gap: ${({ theme }) => theme.spacing(0.5)};
`;
const YousignLogo = styled.img`
  max-width: 2.5em;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;
const YousignText = styled.p`
  margin: 0;
`;
const Description = styled.div`
  ${fontStyles.bodyLarge};
  color: ${({ theme }) => theme.textLight};
  margin: 0;

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
  }
`;
const ItemSummaryMarker = styled.div`
  margin-left: auto;
  transition: 300ms;
  font-size: 0.7rem;
`;
const NewItemCardTitle = styled.h3`
  ${fontStyles.h3};
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
`;
const ItemTitle = styled.h4`
  ${fontStyles.h4};
  margin: 0;
  transition: 300ms ease-in-out;
  width: clamp(2em, 100%, calc(100vw));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ variant }) =>
    variant === 'light' &&
    css`
      font-weight: 500;
      color: ${({ theme }) => theme.textLight};
    `}
`;
const ItemType = styled.h4`
  ${fontStyles.h4};
  margin: 0 0 0 0;
  opacity: 0;
  transform: translateY(-1em);
  transition: 300ms ease-in-out;
`;
const ItemLegend = styled.div`
  display: grid;
  align-items: center;
  > * {
    grid-area: 1 / 1;
  }
`;
const RecipientWrapper = styled(Stack)`
  background-color: white;
  padding: ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid rgba(0, 0, 0, 18%);
  gap: ${({ theme }) => theme.spacing(1.5)};
  border-radius: 5px;

  ${media.tablet`
  &:only-child {
    width: clamp(10em, 50%, 100%);
  }`}
`;
const ItemSummary = styled.summary`
  cursor: pointer;
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)};
  &::-webkit-details-marker {
    display: none;
  }
`;
const ItemDetails = styled.details`
  border: 1px solid rgba(0, 0, 0, 18%);
  box-shadow: ${({ $isDragging }) =>
    $isDragging
      ? '1px 6px 12px rgba(0, 0, 0, 20%)'
      : '1px 3px 4px rgba(0, 0, 0, 4%)'};
  transform: ${({ $isDragging }) => $isDragging && 'scale(1.01)'};
  background: #fff;
  border-radius: 4px;

  &[open] ${ItemTitle} {
    opacity: 0;
    transform: translateY(1em);
  }
  &[open] ${ItemType} {
    opacity: 1;
    transform: translateY(0);
  }
  &[open] ${ItemSummary} {
    border-bottom: 1px solid ${({ theme }) => theme.separator};
  }
  &[open] ${ItemSummaryMarker} {
    transform: rotate(180deg);
  }
`;
const NewItemCard = styled(Stack)`
  background: #fff;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(1)};
  border: 2px dashed rgba(0, 0, 0, 18%);
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 4%);
  border-radius: 4px;
`;
const NewItemButton = styled(Button)`
  border-radius: 5px;
  width: 100%;
  font-weight: initial;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
  min-height: ${({ theme }) => theme.spacing(5)};
  transition: 300ms ease-in-out;
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.primary};
  }
`;
const ItemTypeSelectionGrid = styled.div`
  display: grid;
  width: 100%;
  align-items: end;
  grid-gap: ${({ theme }) => theme.spacing()};
  ${media.tablet`
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.spacing(1)};
`}

  ${media.desktop`
    grid-template-columns: repeat(3, 1fr);
  `}
`;
const RecapCardTitle = styled(Box)`
  ${fontStyles.h3}
  margin: 0;
  font-weight: 700;
`;
RecapCardTitle.defaultProps = {
  as: 'h3',
};
const EmptyStateTitle = styled.h4`
  ${fontStyles.h4}
  margin-bottom: 0;
`;
const EmptyStateLabel = styled.p`
  ${fontStyles.body};
  color: ${({ theme }) => theme.textLighter};
  margin-bottom: 0;
`;
const EmptyStateIcon = styled.div`
  color: ${({ theme }) => theme.textLightest};
  font-size: 8em;
`;

const Label = styled.p`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLighter};
  margin-bottom: 0;
  margin-top: 0;
`;

//************ Functions ***********
const getItemStyle = (_, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: '0.5em',
  marginBottom: '1.5em',
  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver, droppableStyle) => ({
  display: 'grid',
  padding: '0',
  borderRadius: '4px',
  border: isDraggingOver
    ? '2px dashed rgba(0,0,0, 0.15)'
    : '2px dashed transparent',
  backgroundColor: isDraggingOver ? 'rgba(0,0,0, 0.05)' : 'initial',
  transition: 'background-color 300ms ease-in-out',
  ...droppableStyle,
});

//***************** GraphQL *****************
const CREATE_REQUEST = gql`
  mutation createRequest($input: CreateRequestInput!) {
    createRequest(input: $input) {
      request {
        id
        slug
      }
    }
  }
`;
const CREATE_JSON_REQUEST_MODEL = gql`
  mutation createJsonRequestModel($input: CreateJsonRequestModelInput!) {
    createJsonRequestModel(input: $input) {
      requestModel {
        name
        id
      }
      errors {
        message
        attribute
      }
    }
  }
`;
const CREATE_REQUEST_MODEL = gql`
  mutation createRequestModel($input: CreateRequestModelInput!) {
    createRequestModel(input: $input) {
      requestModel {
        name
        id
      }
      errors {
        message
        attribute
      }
    }
  }
`;
const UPDATE_REQUEST_MODEL = gql`
  mutation updateRequestModel($input: UpdateRequestModelInput!) {
    updateRequestModel(input: $input) {
      requestModel {
        name
        id
      }
    }
  }
`;
const DELETE_REQUEST_MODEL = gql`
  mutation deleteRequestModel($input: DeleteRequestModelInput!) {
    deleteRequestModel(input: $input) {
      requestModel {
        name
        id
      }
    }
  }
`;
const GET_WORK_ID = gql`
  query getWorkId($slug: String!) {
    work(slug: $slug) {
      id
    }
  }
`;
const GET_REQUEST_MODEL = gql`
  query getRequestModel($slug: String!) {
    tenant(slug: $slug) {
      id
      requestModels {
        edges {
          node {
            baseModel
            id
            name
            description
            author {
              avatarUrl
              email
              firstName
              lastName
              id
            }
            documents {
              fileName
              mimeType
              url
              id
            }
            items {
              name
              description
              type
              required
              ... on DocumentRequestItem {
                name
                type
                document {
                  id
                  fileName
                  mimeType
                  url
                }
              }
              ... on AttachmentRequestItem {
                name
                type
                document {
                  id
                  fileName
                  mimeType
                  url
                }
              }
              ... on SignatureRequestItem {
                name
                type
                isTwoWaySignature
                document {
                  id
                  fileName
                  url
                }
              }
              ... on PromptRequestItem {
                name
                type
                message
                choices {
                  choice
                }
                document {
                  id
                  fileName
                  url
                }
              }
              ... on MessageRequestItem {
                name
                type
                document {
                  id
                  fileName
                  url
                }
              }
              ... on AcknowledgeRequestItem {
                document {
                  id
                  fileName
                  mimeType
                  url
                }
                message
                name
                type
                required
              }
            }
          }
        }
      }
    }
  }
`;
const GET_USER_BILLING_ACCOUNT = gql`
  query NewRequest_currentUserBillingAccount {
    currentUser {
      id
      billingAccount {
        id
        currentUsage
        kind
        stripeProduct {
          signature
        }
      }
    }
  }
`;
const GET_TENANT = gql`
  query getCurrentTenant($slug: String!) {
    tenant(slug: $slug) {
      id
      canAccessSignature {
        value
      }
    }
  }
`;

//***************** Error handling Component *****************
const InvalidSubmissionNotification = ({
  submitCount,
  errors,
  handleHighlightItemsError,
}) => {
  usePostHog();
  const { t } = useTranslation(['newRequest', 'misc', 'errors']);
  const { notify } = useApp();
  const hasError = !isEmpty(errors);
  // Evaluate where the error is coming from and display the appropriate message
  useDeepCompareEffect(() => {
    // Evaluate which items (by index) are incomplete in order to highlight them
    const errorItemsIndexes =
      errors?.items?.reduce(
        (acc, item, index) => (!!item ? [...acc, index] : acc),
        [],
      ) || [];

    if (hasError && Boolean(submitCount)) {
      handleHighlightItemsError(errorItemsIndexes);
      scrollToElement('[data-error]', {
        offset: -160,
        duration: 500,
      });
      // throw Error('error');
    }
  }, [{ hasError, submitCount }]);

  useEffect(() => {
    if (Boolean(submitCount) && hasError) {
      notify(t('create_request_error'), { type: 'error' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount, notify, t]);

  return null;
};

//***************** Local Storage Component *****************
const PersistValues = ({
  newValues,
  getOmittedKeys,
  workId,
  setDraftedValues,
}) => {
  useDeepCompareEffect(() => {
    // Omitted from the local storage draft
    let draftOmittedKeys = [
      'documents',
      'id',
      'author',
      '__typename',
      'deadline',
    ];
    if (!workId) return null;
    const toSave = {
      ...omit(newValues, draftOmittedKeys, 'shouldCreateModel', 'baseModel'),
      documents: [],
      items: (newValues?.items || []).map(item => {
        const nextItem = {
          ...item,
          document: '',
          promptChoices: (item?.promptChoices || []).map(({ choice }) => ({
            choice,
          })),
          isTwoWaySignature: Boolean(item?.isTwoWaySignature),
        };
        const omittedKeys = getOmittedKeys(item);
        return omit(nextItem, omittedKeys, '__typename', 'choices');
      }),
      workId,
    };
    // Only save draft with a name filled
    setDraftedValues(toSave);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newValues]);
  return null;
};

//***************** Main Component *****************
const NewRequest = ({ isTenantOwner }) => {
  const { organizationSlug, workSlug, formStep } = useParams();
  const { notify, user } = useApp();
  const { t } = useTranslation(['newRequest', 'errors', 'misc']);
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'mobile';
  const formikRef = useRef();
  const itemsRef = useRef([]);

  // ***** STATE *****
  const [selectedRequestModelId, setSelectedRequestModelId] = useState(null);
  const [recipientModalIsShown, setRecipientModalIsShown] = useState();
  const [isInitialModelSelection, setIsInitialModelSelection] = useState(true);
  const [modelModalIsShown, setModelModalIsShown] = useState(false);
  const [nextStepPath, setNextStepPath] = useState('.'); // By defaut remain on the same page
  const [previousStepPath, setPreviousStepPath] = useState('..'); // By defaut remain on the same page
  const [requestDraftModalIsShown, setRequestDraftModalIsShown] = useState(
    false,
  );
  const [phoneInputModalIsShown, setPhoneInputModalIsShown] = useState(false);
  const [recipient, setRecipient] = useState();
  const [recipients, setRecipients] = useState([]); // Array of recipients
  const [recipientsSubmissionStatus, setRecipientsSubmissionStatus] = useState(
    {},
  ); // pending | success | error
  const [submissionStatus, setSubmissionStatus] = useState('idle'); // idle | pending | success | error
  // MultiStepform with 1. Request details then 2. Request Content
  const [formCurrentStep, setFormCurrentStep] = useState('HEADER'); // HEADER | CONTENT | RECAP | CONFIRM
  const [createRequest] = useMutation(CREATE_REQUEST);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCreatingModel, setIsCreatingModel] = useState(false);
  const [isFormHeaderValid, setIsFormHeaderValid] = useState(false);

  const INITIAL_VALUES = {
    name: '',
    deadline: '',
    description: '',
    documents: [],
    items: [],
    recipientId: '',
    recipient: {
      mode: 'EMAIL',
      firstName: '',
      lastName: '',
      email: '',
    },
  };
  // Form values, by default
  const [initialValues, setInitialValues] = useState(
    () =>
      JSON.parse(localStorage.getItem('ppw-drafted-request')) || INITIAL_VALUES,
  );

  // Local stored draft data
  const [draftedValues, setDraftedValues] = useLocalStorage(
    'ppw-drafted-request',
    INITIAL_VALUES,
  );

  // ****** Variables ******
  const formSteps = ['HEADER', 'CONTENT', 'RECAP', 'CONFIRM'];
  const stepperLabels = [
    t('stepper.header.title'),
    t('stepper.content.title'),
    t('stepper.recap.title'),
    t('stepper.confirm.title'),
  ];
  const stepperIcons = [
    <People size={18} />,
    <LayoutTextSidebarReverse size={18} />,
    <CheckCircle size={18} />,
    <MdSend size={18} />,
  ];
  // Items UI icons & label mapping
  const requestItemOptions = [
    {
      value: 'ATTACHMENT',
      label: t('request_item.kinds.attachment'),
      icon: <Paperclip size={22} />,
    },
    {
      value: 'DOCUMENT',
      label: t('request_item.kinds.document'),
      icon: <FileEarmarkText size={22} />,
    },
    {
      value: 'MESSAGE',
      label: t('request_item.kinds.message'),
      icon: <Fonts size={22} />,
    },
    {
      value: 'PROMPT',
      label: t('request_item.kinds.prompt'),
      icon: <ListTask size={22} />,
    },
    {
      value: 'ACKNOWLEDGE',
      label: t('request_item.kinds.acknowledge'),
      icon: <PersonCheck size={22} />,
    },
  ];

  const requestsSlug = `/organizations/${organizationSlug}/${workSlug}/requests/`;
  const {
    data: currentOrganizationData,
    loading: loadingCurrentOrganization,
  } = useQuery(GET_TENANT, {
    variables: { slug: organizationSlug },
  });
  const currentOrganization = currentOrganizationData?.tenant;

  const {
    data: currentBillingAccountData,
    loading: loadingBillingAccount,
  } = useQuery(GET_USER_BILLING_ACCOUNT);
  const billingAccount = currentBillingAccountData?.currentUser?.billingAccount;
  const remainingTokens =
    billingAccount?.kind === 'FREE' && isTenantOwner
      ? 10 - billingAccount?.currentUsage
      : null;
  const maxRecipients = remainingTokens === null ? 25 : 1;
  const getOmittedKeys = item => {
    if (item.type === 'PROMPT') {
      return ['isTwoWaySignature'];
    } else if (item.type === 'DOCUMENT') {
      return ['promptChoices', 'message', 'isTwoWaySignature'];
    } else if (item.type === 'ATTACHMENT') {
      return ['promptChoices', 'message', 'isTwoWaySignature', 'required'];
    } else if (item.type === 'MESSAGE') {
      return ['promptChoices', 'message', 'isTwoWaySignature'];
    } else if (item.type === 'SIGNATURE') {
      return ['promptChoices', 'message'];
    } else {
      return ['promptChoices', 'isTwoWaySignature'];
    }
  };

  const modelOmittedKeys = [
    'deadline',
    'recipient',
    'recipientId',
    'id',
    'author',
    'shouldCreateModel',
    '__typename',
    'workId',
  ];

  const { data: workData } = useQuery(GET_WORK_ID, {
    variables: { slug: workSlug },
  });

  const workId = workData?.work?.id;

  const [
    fetch,
    { data: requestModelData, loading: loadingRequestModel, refetch },
  ] = useLazyQuery(GET_REQUEST_MODEL, {
    variables: { slug: organizationSlug },
  });

  const tenantId = requestModelData?.tenant?.id;

  const requestModels = requestModelData?.tenant?.requestModels?.edges;

  const [createRequestModel] = useMutation(CREATE_REQUEST_MODEL);
  const [createJsonRequestModel] = useMutation(CREATE_JSON_REQUEST_MODEL);
  const [updateRequestModel] = useMutation(UPDATE_REQUEST_MODEL);
  const [deleteRequestModel] = useMutation(DELETE_REQUEST_MODEL);

  // ****** EVENT HANDLERS ******
  const handleSubmit = async (values, formikBag) => {
    navigate(nextStepPath);
    if (['HEADER', 'CONTENT'].includes(formCurrentStep)) {
      return;
    }
    setIsSubmitting(true);
    const payload = {
      ...omit(values, [
        'recipient',
        'author',
        '__typename',
        'id',
        'shouldCreateModel',
        'baseModel',
      ]),
      documents: values.documents.map(document =>
        document.id ? { documentId: document.id } : document,
      ),
      items: values.items.map(item => {
        const nextItem = {
          ...item,
          promptChoices: (item?.promptChoices || []).map(choice =>
            omit(choice, '__typename'),
          ),
          document: item?.document
            ? item?.document?.id
              ? { documentId: item?.document?.id }
              : item?.document
            : null,
          isTwoWaySignature: Boolean(item?.isTwoWaySignature),
          required: Boolean(item?.required),
        };
        const omittedKeys = getOmittedKeys(item);
        return omit(nextItem, omittedKeys, '__typename', 'choices');
      }),
    };

    let recipientIds = recipients.map(recipient => recipient.id);
    let updatedStatuses = {};
    recipientIds.forEach(async (recipientId, index) => {
      if (recipientsSubmissionStatus[recipientId] === 'success') {
        return null;
      }
      updatedStatuses[recipientId] = 'pending';
      setRecipientsSubmissionStatus({
        ...recipientsSubmissionStatus,
        ...updatedStatuses,
      });
      try {
        await createRequest({
          variables: {
            input: {
              ...payload,
              recipientId,
              workId,
            },
          },
        });
        // formikBag.setSubmitting(false);
        // setSubmissionStatus('submitted');
        updatedStatuses[recipientId] = 'success';
        setRecipientsSubmissionStatus({
          ...recipientsSubmissionStatus,
          ...updatedStatuses,
        });
        setDraftedValues(INITIAL_VALUES);
      } catch (error) {
        notify(t('submit_request_error'), { type: 'error' });
        updatedStatuses[recipientId] = 'error';
        setRecipientsSubmissionStatus({
          ...recipientsSubmissionStatus,
          ...updatedStatuses,
        });

        // formikBag.setSubmitting(false);
        // formikBag.resetForm();
      }
    });
    setIsSubmitting(false);
  };

  const handleDeleteRequestModel = async requestModelId => {
    try {
      await deleteRequestModel({ variables: { input: { requestModelId } } });
      notify(t('delete_model_success'));
      refetch();
    } catch (err) {
      notify(t('delete_model_error'), { type: 'error' });
    }
  };

  const handleCreateRequestModel = async values => {
    setIsCreatingModel(true);
    try {
      const { data } = await createRequestModel({
        variables: {
          input: {
            ...omit(values, modelOmittedKeys, 'shouldCreateModel', 'baseModel'),
            documents: (values?.documents || []).map(document =>
              document?.id ? { documentId: document?.id } : document,
            ),
            items: (values?.items || []).map(item => {
              const nextItem = {
                ...item,
                document: item?.document
                  ? item?.document?.id
                    ? { documentId: item?.document?.id }
                    : item?.document
                  : null,
                promptChoices: (item?.promptChoices || []).map(
                  ({ choice }) => ({
                    choice,
                  }),
                ),
                isTwoWaySignature: Boolean(item?.isTwoWaySignature),
              };
              const omittedKeys = getOmittedKeys(item);
              return omit(nextItem, omittedKeys, '__typename', 'choices');
            }),
            tenantId,
          },
        },
      });

      if (data.createRequestModel.errors.length > 0) {
        throw data.createRequestModel.errors[0];
      }
      setSelectedRequestModelId(data?.createRequestModel?.requestModel?.id);
      refetch();
      setModelModalIsShown(false);
      notify(t('create_model_success'));
    } catch (err) {
      notify(t('create_model_error'), { type: 'error' });
    }
    setIsCreatingModel(false);
  };

  const handleUpdateRequestModel = async (requestModelId, values) => {
    const payload = {
      ...omit(values, modelOmittedKeys, 'baseModel'),
      documents: values?.documents.map(document =>
        document?.id ? { documentId: document?.id } : document,
      ),
      items: values.items.map(item => {
        const nextItem = {
          ...item,
          document: item?.document
            ? item?.document?.id
              ? { documentId: item?.document?.id }
              : item?.document
            : null,
          promptChoices: (item?.promptChoices || []).map(({ choice }) => ({
            choice,
          })),
          isTwoWaySignature: Boolean(item?.isTwoWaySignature),
        };
        const omittedKeys = getOmittedKeys(item);
        return omit(nextItem, omittedKeys, '__typename', 'choices');
      }),
      requestModelId: requestModelId,
    };
    try {
      await updateRequestModel({
        variables: {
          input: {
            ...payload,
          },
        },
      });
      refetch();
      notify(t('update_model_success'));
    } catch (err) {
      notify(t('update_model_error'), { type: 'error' });
    }
  };

  const handleSelectModel = (node, setValues, concatenateModel = false) => {
    // Keep a copy of the current items if specified as needed for the model selection
    let itemsPersisted = concatenateModel
      ? [...formikRef?.current?.values?.items]
      : [];
    console.log("This is the selected model", node)
    setValues({
      ...pickBy(node),
      description:
        formCurrentStep === 'HEADER'
          ? node?.description || formikRef?.current?.values?.description
          : formikRef?.current?.values?.description, // Forcing description to be empty string when being null to force replacement (else it is ignored by pickBy)
      items: [
        ...itemsPersisted,
        ...node.items.map((item, index) => ({
          ...pickBy({
            ...node.items?.[index],
            document: node.items?.[index].type === "SIGNATURE" ? null : node.items?.[index].document,
            promptChoices: node.items?.[index].choices,
          }),
          description: node.items?.[index]?.description || '', // Forcing description to be empty string when being null to force replacement (else it is ignored by pickBy)
        })),
      ],
      name:
        formCurrentStep === 'HEADER'
          ? node?.name
          : formikRef?.current?.values?.name, //Keep existing or from Template if empty
      deadline: formikRef?.current?.values?.deadline || '', // Keep existing values if already entered
      recipientId: formikRef?.current?.values?.recipientId || '',
      recipient: formikRef?.current?.values?.recipient || {
        mode: 'EMAIL',
        firstName: '',
        lastName: '',
        email: '',
      },
    });
    notify(t('load_model_success')); //notify(t('create_model_success'));
    handleCollapseAll();
  };

  const handleAddJsonRequestModel = async files => {
    const maxSize = MAX_REQUEST_MODEL_FILE_SIZE; // 100MB
    const [file] = files;

    if (file.size > maxSize) {
      notify(t('errors:model_file_too_big'), { type: 'error' });
      return null;
    }

    try {
      const { data } = await createJsonRequestModel({
        variables: {
          input: {
            jsonFile: file,
            tenantId,
          },
        },
      });

      if (data.createJsonRequestModel.errors.length > 0) {
        throw data.createRequestModel.errors[0];
      }

      refetch();
      notify(t('create_model_success'));
    } catch (err) {
      notify(t('create_model_error'), { type: 'error' });
    }
  };

  const handleCollapseAll = (expand = false) => {
    itemsRef?.current?.map(itemRef => itemRef?.toggleAttribute('open', expand));
  };

  const defaultItem = {
    type: 'DOCUMENT',
    name: '',
    promptChoices: [],
    isTwoWaySignature: false,
    required: true,
  };

  const handleAddNewItem = (arrayHelpers, type = 'DOCUMENT') => {
    handleCollapseAll();
    arrayHelpers.push({ ...defaultItem, type });
    // Scroll to the newly added item
    scrollToElement('ul[data-rbd-droppable-id] li:last-of-type', {
      offset: -160,
      duration: 600,
    });
  };

  const handleAddRecipient = recipient => {
    if (!recipient) return;
    if (recipients.find(r => r.id === recipient.id)) {
      notify(t('errors:recipient_already_added'), { type: 'error' });
      return;
    } else {
      setRecipients([...recipients, recipient]);
    }
  };

  const handleRemoveRecipient = recipient => {
    if (!recipient) return;
    const newRecipients = recipients.filter(r => r.id !== recipient.id);
    setRecipients(newRecipients);
  };

  const handleHighlightItemsError = wrongItemIndexes => {
    if (!wrongItemIndexes) return null;
    navigate(requestsSlug + 'new/content');
    handleCollapseAll();
    wrongItemIndexes.forEach(wrongItemIndex => {
      itemsRef?.current[wrongItemIndex]?.toggleAttribute('open', true);
    });
  };

  if (currentOrganization?.canAccessSignature?.value) {
    requestItemOptions.push({
      value: 'SIGNATURE',
      label: t('request_item.kinds.signature'),
      icon: <VectorPen size={22} />,
    });
  }

  const onDragEnd = (result, arrayHelpers) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    handleCollapseAll(); //Fixes newly moved items heriting the "open state" of the items situated their previously
    arrayHelpers.move(result.source.index, result.destination.index);
  };

  // ********** Side Effects  **********
  useEffect(() => {
    let newStatuses = recipients.reduce(
      (acc, recipientItem) =>
        Object.assign(acc, { [recipientItem.id]: 'pending' }),
      {},
    );
    setRecipientsSubmissionStatus(newStatuses);
    setRecipient(recipients.length > 0 ? recipients[0] : null); // Only serve as a way to know if there is at least one recipient
  }, [recipients]);

  const isTwoWaySignatureAllowed = useCallback(() => {
    return user?.email !== recipient?.email && Boolean(user?.phone);
  }, [recipient, user]);

  useEffect(() => {
    if (!isTwoWaySignatureAllowed()) {
      const values = formikRef?.current.values;
      formikRef.current?.setValues({
        ...values,
        items: values.items.map(item =>
          pickBy({
            ...item,
            isTwoWaySignature: item.isTwoWaySignature ? false : null,
          }),
        ),
      });
    }
  }, [recipient, isTwoWaySignatureAllowed]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    let newRequestSlug = requestsSlug + 'new/';
    let steps = ['header', 'content', 'recap', 'confirm'];
    let lastStepIndex = steps.length - 1;
    if (formStep === undefined) {
      setFormCurrentStep('HEADER');
      let nextPath = newRequestSlug + 'content';
      setNextStepPath(nextPath);
    } else if (steps.includes(formStep)) {
      let currentStepIndex = steps.indexOf(formStep),
        nextStepIndex =
          currentStepIndex >= lastStepIndex
            ? lastStepIndex
            : currentStepIndex + 1,
        previousStepIndex = currentStepIndex <= 0 ? 0 : currentStepIndex - 1,
        nextPath = newRequestSlug + steps[nextStepIndex],
        previousPath = newRequestSlug + steps[previousStepIndex];
      setFormCurrentStep(formStep.toUpperCase());
      setNextStepPath(nextPath);
      setPreviousStepPath(previousPath);
    } else {
      let nextPath = newRequestSlug + 'content';
      setFormCurrentStep('HEADER');
      setNextStepPath(nextPath);
      setPreviousStepPath(nextPath);
    }
  }, [formStep, requestsSlug]);

  useEffect(() => {
    setIsFormHeaderValid(
      Boolean(
        formikRef?.current?.values?.deadline &&
        formikRef?.current?.values?.name &&
        recipient,
      ),
    );
  }, [recipient, formikRef?.current?.values]);

  useEffect(() => {
    if (!workId) return null;
    let { items, name, description } = draftedValues;
    if (draftedValues?.workId === workId) {
      if (items?.length > 0 || name !== '' || description !== '') {
        setRequestDraftModalIsShown(true);
      }
    } else {
      setInitialValues(INITIAL_VALUES);
    }

    return () => {
      setRequestDraftModalIsShown(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workId]);

  useEffect(() => {
    scrollToElement('body', { duration: 500, offset: -100 });
    if (formCurrentStep === 'CONTENT') handleCollapseAll();
  }, [formCurrentStep]);

  useEffect(() => {
    // Submission status handling (success, error, pending)
    if (!Boolean(Object.keys(recipientsSubmissionStatus)?.length > 0))
      return null;
    if (isSubmitting) {
      setSubmissionStatus('pending');
    } else {
      if (Object.values(recipientsSubmissionStatus)?.includes('error'))
        setSubmissionStatus('error');
      if (
        Object.values(recipientsSubmissionStatus)?.every(
          status => status === 'success',
        )
      ) {
        setSubmissionStatus('success');
      }
    }
  }, [recipientsSubmissionStatus, isSubmitting]);

  return (
    <Page>
      <Formik
        enableReinitialize={true} // allows update when data is retrieved from localStorage
        validationSchema={Yup.object().shape({
          name: Yup.string().required(t('errors:required')),
          description: Yup.string(),
          deadline: Yup.mixed().required(t('errors:required')),
          recipientId: Yup.string().required(t('errors:required')),
          recipient: Yup.object().shape({
            mode: Yup.string(),
            firstName: Yup.string().when('mode', {
              is: 'EMAIL',
              then: Yup.string().required(t('errors:required')),
              otherwhise: Yup.string(),
            }),
            lastName: Yup.string().when('mode', {
              is: 'EMAIL',
              then: Yup.string().required(t('errors:required')),
              otherwhise: Yup.string(),
            }),
            email: Yup.string().when('mode', {
              is: 'EMAIL',
              then: Yup.string()
                .email(t('errors:email'))
                .required(t('errors:required')),
              otherwhise: Yup.string(),
            }),
          }),
          items: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required(t('errors:required')),
              type: Yup.string().required(t('errors:required')),
              description: Yup.string(),
              message: Yup.mixed().when('type', {
                is: type => type === 'ACKNOWLEDGE',
                then: Yup.string().required(t('errors:required')),
              }),
              required: Yup.boolean(),
              promptChoices: Yup.array().when('type', {
                is: type => type === 'PROMPT',
                then: Yup.array()
                  .min(2, t('errors:choices_minTwoOptions'))
                  .of(
                    Yup.object().shape({
                      choice: Yup.string().required(t('errors:required')),
                    }),
                  ),
              }),
              document: Yup.mixed().when('type', {
                is: type => type === 'SIGNATURE' || type === 'ATTACHMENT',
                then: Yup.object().required(t('errors:required')),
              }),
              twoWaySignature: Yup.boolean(),
            }),
          ),
        })}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({
          setFieldValue,
          setFieldTouched,
          values,
          errors,
          touched,
          // isSubmitting,
          setValues,
          submitCount,
          handleSubmit,
          ...props
        }) => (
          <Form>
            <PersistValues
              newValues={values}
              setDraftedValues={setDraftedValues}
              getOmittedKeys={getOmittedKeys}
              workId={workId}
            />
            <RequestDraftModal
              t={t}
              isOpen={requestDraftModalIsShown}
              onRequestClose={() => {
                setRequestDraftModalIsShown(false);
              }}
              onContinue={() => {
                setInitialValues(draftedValues);
                setIsInitialModelSelection(false); //prevent from adding items before the content step
                notify(t('draftRequest.loaded'));
              }}
              onDiscard={() => {
                setRecipients([]);
                setInitialValues(INITIAL_VALUES);
              }}
            />
            <Modal
              size="big"
              isOpen={modelModalIsShown}
              onRequestClose={() => {
                setModelModalIsShown(false);
                setIsInitialModelSelection(false);
              }}
            >
              <Title>{t('select_model')}</Title>
              {!isInitialModelSelection && values?.items?.length > 0 && (
                <Stack gutterSize={0.5}
                  direction="column">
                  <Buttons>
                    <Button
                      variant="outline"
                      onClick={() => handleCreateRequestModel(values)}
                      type="button"
                      isLoading={isCreatingModel}
                    >
                      {t('save_as_new_template')}
                    </Button>
                  </Buttons>
                  <Stack marginLeft={1} gutterSize={0.25} alignY="center">
                    <InfoCircle color={theme.textLighter} />
                    <Label>
                      {t('save_as_new_template_hint')}
                    </Label>
                  </Stack>
                </Stack>
              )}

              {loadingRequestModel && <Spinner />}

              {!loadingRequestModel && (
                <List
                  size="full"
                  gutterSize={1}
                  direction="column"
                  marginTop={2}
                  marginBottom={1}
                >
                  {(requestModels || []).map(({ node }) => (
                    <li key={node.id} style={{ width: '100%' }}>
                      <RequestModelCard
                        t={t}
                        onFormContentStep={Boolean(
                          formCurrentStep === 'CONTENT',
                        )}
                        requestModel={node}
                        isActive={selectedRequestModelId === node.id}
                        isInitialModelSelection={isInitialModelSelection}
                        onLoad={concatenateModel => {
                          handleSelectModel(node, setValues, concatenateModel);
                          setModelModalIsShown(false);
                          setIsInitialModelSelection(false);
                          handleCollapseAll();
                        }}
                        onClick={() => {
                          if (selectedRequestModelId === node.id) {
                            setSelectedRequestModelId(null);
                          } else {
                            setSelectedRequestModelId(node.id);
                          }
                        }}
                        onReinitialize={() =>
                          handleSelectModel(node, setValues)
                        }
                        onDelete={() => handleDeleteRequestModel(node.id)}
                        onUpdate={() => {
                          handleUpdateRequestModel(node.id, values);
                          setModelModalIsShown(false);
                        }}
                      />
                    </li>
                  ))}
                </List>
              )}
              <Stack direction="column" marginTop={2}>
                <Title>{t('import_model')}</Title>
                <SimpleDropzone onAdd={handleAddJsonRequestModel}>
                  <Trans i18nKey="newRequest:drag_and_drop_instructions">
                    Put you model here to add it
                    <span>or import them from files</span>
                  </Trans>
                </SimpleDropzone>
              </Stack>
            </Modal>

            <StickyHeaderWrapper>
              <Header
                title={t('new_request')} //{t('new_request')}
                actions={
                  <Stepper
                    currentStep={formCurrentStep}
                    steps={formSteps}
                    labels={stepperLabels}
                    icons={stepperIcons}
                    requestsSlug={requestsSlug}
                  />
                }
              />
            </StickyHeaderWrapper>

            <PhoneInputModal
              isOpen={phoneInputModalIsShown}
              onRequestClose={() => setPhoneInputModalIsShown(false)}
            />
            {Boolean(recipientModalIsShown) && Boolean(workId) && (
              <RequestRecipientModal
                onRequestClose={() => setRecipientModalIsShown(false)}
                onSelect={recipient => {
                  handleAddRecipient(recipient);
                  setRecipientModalIsShown(false);
                  setFieldValue('recipient.mode', 'CONTACT');
                  setFieldValue('recipientId', recipient?.id);
                }}
                workId={workId}
                onSubmitInvitation={() => {
                  setRecipientModalIsShown(false);
                }}
              />
            )}

            <Content>
              {formCurrentStep === 'HEADER' ? (
                <Stack direction="column">
                  <FormField>
                    <FormLabel>{t('receiver')}</FormLabel>
                    {recipients?.length > 1 && (
                      <Label>{t('receivers_info')}</Label>
                    )}
                    <RecipientsGrid>
                      {Boolean(recipient) &&
                        recipients.length > 0 &&
                        recipients.map(recipientItem => (
                          <RecipientWrapper
                            alignX="space-between"
                            step="header"
                          >
                            <AvatarAndName
                              variant="withEmail"
                              avatarSize="tiny"
                              size="small"
                              legendSize="small"
                              user={recipientItem}
                            />
                            <ButtonReset
                              type="button"
                              onClick={() => {
                                handleRemoveRecipient(recipientItem);
                              }}
                            >
                              <X size={24} />
                            </ButtonReset>
                          </RecipientWrapper>
                        ))}
                    </RecipientsGrid>
                    <Stack>
                      {recipients?.length < maxRecipients && (
                        <Button
                          variant={recipient ? 'clear' : 'primary'}
                          size={recipient ? 'small' : ''}
                          type="button"
                          style={{ marginTop: '0.2em', marginRight: 'auto' }}
                          icon={<PersonPlusFill />}
                          onClick={() => {
                            setRecipientModalIsShown(true);
                            setFieldTouched('recipientId');
                          }}
                        >
                          {t('add_receiver')}
                        </Button>
                      )}
                    </Stack>
                    {touched.recipientId && errors.recipientId && (
                      <ErrorMessageWrapper>
                        {errors.recipientId}
                      </ErrorMessageWrapper>
                    )}
                  </FormField>

                  <FormField style={{ maxWidth: 'clamp(2em,100%, 20em%)' }}>
                    <FormLabel>{t('due_date')}</FormLabel>
                    <DatePicker
                      onChange={v => {
                        // Setting deadline to end of day by default to avoid
                        // link expiry when creating a Request with deadline
                        // set to today
                        // This "fix" has not been implemented in API to
                        // anticipate a future feature that will allow to set
                        // a Date + a Time as a deadline
                        setFieldValue('deadline', endOfDay(new Date(v)));
                      }}
                      onCalendarClose={() =>
                        setTimeout(() => setFieldTouched('deadline', true))
                      }
                      name="deadline"
                      value={values.deadline}
                      minDate={new Date()}
                      hasError={touched.deadline && Boolean(errors.deadline)}
                    />
                    {touched.deadline && errors.deadline && (
                      <ErrorMessageWrapper>
                        {errors.deadline}
                      </ErrorMessageWrapper>
                    )}
                  </FormField>
                  <FormField>
                    <Button
                      type="button"
                      size="small"
                      variant="outline"
                      style={{
                        marginTop: '0.2em',
                        width: 'max-content',
                      }}
                      onClick={() => {
                        setModelModalIsShown(true);
                      }}
                      disabled={isSubmitting}
                    >
                      {t('start_with_model')}
                    </Button>
                    <Label>{t('start_with_model_description')}</Label>
                    {/* </Stack> */}
                  </FormField>

                  <TextField
                    style={{ background: 'white' }}
                    name="name"
                    label={t('request_title')}
                    placeholder={t('request_title_placeholder')}
                  />

                  <TextareaField
                    marginBottom={20}
                    name="description"
                    label={t('description')}
                    optional={t('search')}
                    minRows={isMobile ? 3 : 5}
                    placeholder={t('firstRequestMessage')}
                  />
                </Stack>
              ) : (
                <>
                  {isFormHeaderValid ? (
                    <>
                      {formCurrentStep === 'CONTENT' && (
                        <div>
                          {get(values, 'items', []).length > 0 && (
                            <Stack
                              direction={isMobile ? 'column' : 'row'}
                              alignX="flex-end"
                              alignY="center"
                              marginBottom={1}
                              style={{ gap: '1em' }}
                            >
                              <Stack
                                direction={isMobile ? 'column' : 'row'}
                                gutterSize={1}
                                alignY="center"
                                alignX="flex-end"
                                style={{ marginTop: '0' }}
                              >
                                <Stack
                                  direction="column"
                                  alignX="flex-end"
                                  marginBottom={0}
                                  marginRight={1}
                                >
                                  <Button
                                    style={{ padding: '0' }}
                                    type="button"
                                    variant="totalText"
                                    direction="reverse"
                                    disabled={
                                      get(values, 'items', []).length === 0
                                    }
                                    onClick={() => handleCollapseAll(false)}
                                    icon={
                                      <ChevronUp size={isMobile ? 16 : 22} />
                                    }
                                    size="small"
                                  >
                                    {t('collapse_items')}
                                  </Button>
                                  <Button
                                    style={{ padding: '0' }}
                                    type="button"
                                    variant="totalText"
                                    direction="reverse"
                                    disabled={
                                      get(values, 'items', []).length === 0
                                    }
                                    onClick={() => handleCollapseAll(true)}
                                    icon={
                                      <ChevronDown size={isMobile ? 16 : 22} />
                                    }
                                    size="small"
                                  >
                                    {t('expand_items')}
                                  </Button>
                                </Stack>
                              </Stack>
                            </Stack>
                          )}

                          <FieldArray
                            name="items"
                            render={arrayHelpers => (
                              <DragDropContext
                                onDragEnd={result =>
                                  onDragEnd(result, arrayHelpers)
                                }
                              >
                                <Droppable droppableId="requestItemList">
                                  {(droppableProvided, droppableSnapshot) => (
                                    <ul
                                      {...droppableProvided.droppableProps}
                                      ref={droppableProvided.innerRef}
                                      style={getListStyle(
                                        droppableSnapshot.isDraggingOver,
                                        droppableProvided.droppableProps.style,
                                      )}
                                    >
                                      {get(values, 'items', []).map(
                                        (item, index) => (
                                          <Draggable
                                            key={index}
                                            draggableId={`draggable-${index}`}
                                            index={index}
                                          >
                                            {(
                                              draggableProvided,
                                              draggableSnapshot,
                                            ) => (
                                              <li
                                                {...draggableProvided.draggableProps}
                                                ref={draggableProvided.innerRef}
                                                style={getItemStyle(
                                                  draggableSnapshot.isDragging,
                                                  draggableProvided
                                                    .draggableProps.style,
                                                )}
                                              >
                                                <div
                                                  {...draggableProvided.dragHandleProps} //props to add on an HTML element
                                                >
                                                  <ListIcon
                                                    size={22}
                                                    style={{ marginTop: '1em' }}
                                                  />
                                                </div>

                                                <ItemDetails
                                                  open
                                                  $isDragging={
                                                    draggableSnapshot.isDragging
                                                  }
                                                  ref={el =>
                                                  (itemsRef.current[
                                                    index
                                                  ] = el)
                                                  }
                                                >
                                                  <ItemSummary>
                                                    <Stack
                                                      size="full"
                                                      gutterSize={1}
                                                      direction="row"
                                                      alignY="center"
                                                    >
                                                      {item?.type ===
                                                        'DOCUMENT' && (
                                                          <FileEarmarkText
                                                            size={22}
                                                          />
                                                        )}
                                                      {item?.type ===
                                                        'ATTACHMENT' && (
                                                          <Paperclip size={22} />
                                                        )}
                                                      {item?.type ===
                                                        'MESSAGE' && (
                                                          <Fonts size={22} />
                                                        )}
                                                      {item?.type ===
                                                        'PROMPT' && (
                                                          <ListTask size={22} />
                                                        )}
                                                      {item?.type ===
                                                        'SIGNATURE' && (
                                                          <VectorPen size={22} />
                                                        )}
                                                      {item?.type ===
                                                        'ACKNOWLEDGE' && (
                                                          <PersonCheck
                                                            size={22}
                                                          />
                                                        )}
                                                      <ItemLegend>
                                                        <ItemTitle>
                                                          {!!item?.name
                                                            ? item?.name
                                                            : t(
                                                              `request_item.untitled`,
                                                            )}
                                                        </ItemTitle>
                                                        <ItemType>
                                                          {t(
                                                            `request_item.kinds.${item?.type.toLowerCase()}`,
                                                          )}
                                                        </ItemType>
                                                      </ItemLegend>
                                                      <ItemSummaryMarker>
                                                        <ChevronDown
                                                          size={22}
                                                        />
                                                      </ItemSummaryMarker>
                                                    </Stack>
                                                  </ItemSummary>
                                                  <ItemInnerCard>
                                                    {values?.items?.[index]
                                                      ?.type ===
                                                      'SIGNATURE' && (
                                                        <Stack
                                                          direction="column"
                                                          marginBottom={1.5}
                                                          gutterSize={1}
                                                        >
                                                          <>
                                                            <Stack
                                                              direction={
                                                                isMobile
                                                                  ? 'column'
                                                                  : 'row'
                                                              }
                                                              size="full"
                                                              alignY="center"
                                                              alignX="space-between"
                                                              gutterSize={
                                                                isMobile ? 1 : 0.5
                                                              }
                                                            >
                                                              <Stack alignY="center">
                                                                <YousignLogo
                                                                  src={
                                                                    yousignLogo
                                                                  }
                                                                ></YousignLogo>
                                                                <YousignText>
                                                                  {t(
                                                                    'misc:signature_by_yousign',
                                                                  )}
                                                                </YousignText>
                                                              </Stack>
                                                              <Stack alignY="center">
                                                                <Alert
                                                                  variant="tertiary"
                                                                  alignY="center"
                                                                  gutterSize={0.5}
                                                                >
                                                                  <span
                                                                    role="img"
                                                                    aria-label=""
                                                                  >
                                                                    <InfoCircle />
                                                                  </span>
                                                                  <div>
                                                                    <Trans i18nKey="misc:signature_billing">
                                                                      Electronic
                                                                      signature is
                                                                      billed.
                                                                      <Link to="/my-account/invoices">
                                                                        More info
                                                                      </Link>
                                                                    </Trans>
                                                                  </div>
                                                                </Alert>
                                                              </Stack>
                                                            </Stack>
                                                            <Stack
                                                              direction="row"
                                                              size="full"
                                                              alignY="center"
                                                              alignX="space-between"
                                                            >
                                                              <Description>
                                                                {t(
                                                                  'misc:signature_explanation',
                                                                )}
                                                              </Description>
                                                            </Stack>
                                                          </>
                                                        </Stack>
                                                      )}

                                                    <TextField
                                                      name={`items.${index}.name`}
                                                      label={t(
                                                        `request_item.fields.${values?.items?.[
                                                          index
                                                        ]?.type.toLowerCase()}.title`,
                                                      )}
                                                      placeholder={t(
                                                        `request_item.fields.${values?.items?.[
                                                          index
                                                        ]?.type.toLowerCase()}.placeholder`,
                                                      )}
                                                    />
                                                    {values?.items?.[index]
                                                      ?.type !==
                                                      'ATTACHMENT' && (
                                                        <TextareaField
                                                          name={`items.${index}.description`}
                                                          label={t(
                                                            'request_item.description',
                                                          )}
                                                          minRows={1}
                                                        />
                                                      )}

                                                    {values?.items?.[index]
                                                      ?.type === 'PROMPT' && (
                                                        <PromptChoicesField
                                                          name={`items.${index}.promptChoices`}
                                                          label={t(
                                                            'prompt_choices',
                                                          )}
                                                        />
                                                      )}
                                                    {values?.items?.[index]
                                                      ?.type ===
                                                      'ACKNOWLEDGE' && (
                                                        <TextField
                                                          name={`items.${index}.message`}
                                                          label={t('message')}
                                                          placeholder={t(
                                                            'request_item.fields.acknowledge.message_placeholder',
                                                          )}
                                                        />
                                                      )}

                                                    {values?.items?.[index]
                                                      ?.type === 'SIGNATURE' &&
                                                      (isTwoWaySignatureAllowed() ? (
                                                        <CheckboxField
                                                          name={`items.${index}.isTwoWaySignature`}
                                                          label={t(
                                                            'two_way_signature',
                                                          )}
                                                        />
                                                      ) : (
                                                        !Boolean(
                                                          loadingBillingAccount,
                                                        ) &&
                                                        !Boolean(
                                                          user?.phone,
                                                        ) && (
                                                          <Alert
                                                            gutterSize={0.5}
                                                          >
                                                            <span
                                                              role="img"
                                                              aria-label=""
                                                            >
                                                              👋
                                                            </span>
                                                            <div>
                                                              <Trans i18nKey="newRequest:missing_signature_phone">
                                                                <Button
                                                                  onClick={() =>
                                                                    setPhoneInputModalIsShown(
                                                                      true,
                                                                    )
                                                                  }
                                                                  style={{
                                                                    display:
                                                                      'inline-block',
                                                                    marginLeft: 10,
                                                                  }}
                                                                  type="button"
                                                                  variant="outline"
                                                                  size="small"
                                                                >
                                                                  Fill my phone
                                                                  number
                                                                </Button>
                                                              </Trans>
                                                            </div>
                                                          </Alert>
                                                        )
                                                      ))}
                                                    <div
                                                      style={{
                                                        display: 'grid',
                                                        gridTemplateColumns: isMobile
                                                          ? '1fr'
                                                          : '1fr auto',
                                                        gap: '0.5em',
                                                      }}
                                                    >
                                                      {values?.items?.[index]
                                                        ?.type !==
                                                        'ATTACHMENT' ? (
                                                        <FormField>
                                                          <AttachmentsField
                                                            name={`items.${index}.document`}
                                                            text={
                                                              values?.items?.[
                                                                index
                                                              ]?.type ===
                                                                'SIGNATURE'
                                                                ? t(
                                                                  'add_esign_pdf',
                                                                ) //t('misc:add_pdf')
                                                                : false
                                                            }
                                                            optional={
                                                              values?.items?.[
                                                                index
                                                              ]?.type !==
                                                              'SIGNATURE'
                                                            }
                                                            accept={
                                                              values?.items?.[
                                                                index
                                                              ]?.type ===
                                                                'SIGNATURE'
                                                                ? '.pdf'
                                                                : undefined
                                                            }
                                                            required={
                                                              values?.items?.[
                                                                index
                                                              ]?.type ===
                                                                'SIGNATURE'
                                                                ? '.pdf'
                                                                : false
                                                            }
                                                            maxSize={
                                                              MAX_SIGNATURE_FILE_SIZE
                                                            }
                                                          />
                                                        </FormField>
                                                      ) : (
                                                        <FormField>
                                                          <AttachmentsField
                                                            name={`items.${index}.document`}
                                                            optional={false}
                                                            required={true}
                                                          />
                                                        </FormField>
                                                      )}
                                                      <Stack
                                                        direction="row"
                                                        alignY="center"
                                                        alignX="flex-end"
                                                        gutterSize={2}
                                                        style={{ marginTop: 0 }}
                                                      >
                                                        <Button
                                                          type="button"
                                                          variant="text"
                                                          style={{
                                                            marginTop: '0',
                                                          }}
                                                          size="small"
                                                          onClick={() =>
                                                            arrayHelpers.remove(
                                                              index,
                                                            )
                                                          }
                                                          icon={
                                                            <Trash size={18} />
                                                          }
                                                        >
                                                          {t('remove_item')}
                                                        </Button>
                                                      </Stack>
                                                    </div>
                                                  </ItemInnerCard>
                                                </ItemDetails>
                                              </li>
                                            )}
                                          </Draggable>
                                        ),
                                      )}
                                      {droppableProvided.placeholder}
                                    </ul>
                                  )}
                                </Droppable>
                                <Buttons
                                  paddingTop={1}
                                  alignX="center"
                                  paddingBottom={3}
                                >
                                  {(remainingTokens === null ||
                                    remainingTokens -
                                    (values?.items || []).length >
                                    0) && (
                                      <NewItemCard
                                        direction="column"
                                        gutterSize={2}
                                        alignX="stretch"
                                      >
                                        <Stack
                                          direction="column"
                                          gutterSize={0.5}
                                        >
                                          <NewItemCardTitle>
                                            {t('add_item')}
                                          </NewItemCardTitle>
                                          {!Boolean(loadingBillingAccount) &&
                                            !Boolean(
                                              billingAccount?.stripeProduct
                                                ?.signature,
                                            ) &&
                                            !Boolean(
                                              loadingCurrentOrganization,
                                            ) &&
                                            !Boolean(
                                              currentOrganization
                                                ?.canAccessSignature?.value,
                                            ) && (
                                              <Alert gutterSize={0.5}>
                                                <span role="img" aria-label="">
                                                  👋
                                                </span>
                                                <div>
                                                  <Trans i18nKey="newRequest:missing_signature">
                                                    Missing electronic signature ?
                                                    <Link to="/my-account/invoices">
                                                      Change my offer
                                                    </Link>
                                                  </Trans>
                                                </div>
                                              </Alert>
                                            )}
                                          <ItemTypeSelectionGrid>
                                            {requestItemOptions.map(
                                              (requestItemOption, index) => (
                                                <NewItemButton
                                                  key={`itemButtons_${index}`}
                                                  type="button"
                                                  variant="outline"
                                                  icon={requestItemOption?.icon}
                                                  onClick={() =>
                                                    handleAddNewItem(
                                                      arrayHelpers,
                                                      requestItemOption?.value,
                                                    )
                                                  }
                                                >
                                                  {requestItemOption?.label}
                                                </NewItemButton>
                                              ),
                                            )}
                                            <NewItemButton
                                              type="button"
                                              variant="outline"
                                              onClick={() => {
                                                setModelModalIsShown(true);
                                              }}
                                              disabled={isSubmitting}
                                              icon={<Grid1x2 size={22} />}
                                            >
                                              {t('select_model_prompt')}
                                            </NewItemButton>
                                          </ItemTypeSelectionGrid>
                                          {remainingTokens !== null && (
                                            <Alert gutterSize={0.5}>
                                              <span role="img" aria-label="">
                                                💡
                                              </span>
                                              <div>
                                                <Trans
                                                  i18nKey="newRequest:tokens_remaining"
                                                  count={remainingTokens}
                                                >
                                                  Tokens remaining :{' '}
                                                  <strong>
                                                    {{ count: remainingTokens }}
                                                  </strong>
                                                  <Link to="/my-account/invoices">
                                                    Change my offer
                                                  </Link>
                                                </Trans>
                                              </div>
                                            </Alert>
                                          )}
                                        </Stack>
                                        {/* <Stack
                                        direction="column"
                                        gutterSize={0.5}
                                      > */}
                                        {/* <NewItemCardTitle>
                                          {t('select_model_instead')}
                                        </NewItemCardTitle>
                                        <span>
                                          {t('select_model_description')}
                                        </span> */}

                                        {/* </Stack> */}
                                      </NewItemCard>
                                    )}
                                </Buttons>
                                {/* <Stack
                                  direction={isMobile ? 'column' : 'row'}
                                  gutterSize={1}
                                  size="full"
                                  marginBottom={1}
                                  alignX="flex-end"
                                  style={{ marginTop: '0' }}
                                ></Stack> */}
                              </DragDropContext>
                            )}
                          />
                        </div>
                      )}
                      {formCurrentStep === 'RECAP' && (
                        <>
                          <NewRequestRecap
                            values={values}
                            recipients={recipients}
                            requestsSlug={requestsSlug}
                            title={t('stepper.recap.subtitle')}
                          />
                        </>
                      )}

                      {formCurrentStep === 'CONFIRM' && (
                        <NewRequestConfirmation
                          recipients={recipients}
                          recipientsSubmissionStatus={
                            recipientsSubmissionStatus
                          }
                          isSubmitting={isSubmitting}
                          submissionStatus={submissionStatus}
                          resetHandler={() => {
                            formikRef.current.resetForm({
                              values: INITIAL_VALUES,
                            });
                            setRecipients([]);
                          }}
                          retryHandler={() => {
                            // TODO: Set submission variables to default
                            handleSubmit();
                          }}
                          restartPath={`${requestsSlug}new`}
                          requestsPath={requestsSlug}
                        />
                      )}
                    </>
                  ) : (
                    <Stack
                      direction="column"
                      alignX="center"
                      alignY="center"
                      marginTop={2}
                      marginBottom={2}
                      gutterSize={1}
                    >
                      <EmptyStateIcon>
                        <PatchPlus />
                      </EmptyStateIcon>
                      <EmptyStateTitle>
                        {t('empty_header_state.title')}
                      </EmptyStateTitle>
                      <EmptyStateLabel>
                        {t('empty_header_state.subtitle')}
                      </EmptyStateLabel>
                      <Button
                        type="button"
                        variant="primary"
                        onClick={() => {
                          navigate(requestsSlug + 'new/header');
                        }}
                        disabled={isSubmitting}
                      >
                        {t('empty_header_state.button')}
                      </Button>
                    </Stack>
                  )}
                </>
              )}
            </Content>
            <InvalidSubmissionNotification
              submitCount={submitCount}
              errors={errors}
              handleHighlightItemsError={handleHighlightItemsError}
            />
            {formCurrentStep !== 'CONFIRM' && (
              <BottomBar>
                <BottomBarInner
                  alignX="space-between"
                  gutterSize={isMobile ? 0.5 : 2}
                >
                  {formCurrentStep !== 'HEADER' && (
                    <Button
                      type="button"
                      variant="clear"
                      // to={previousStepPath}
                      icon={<ChevronLeft />}
                      onClick={() => {
                        navigate(previousStepPath);
                      }}
                      disabled={isSubmitting}
                    >
                      {t('previous_step')}
                    </Button>
                  )}
                  {/* <Button type="button" onClick={() => handleSubmit()}>
                    Trigger submit
                  </Button> */}
                  <Stack
                    direction={isMobile ? 'column' : 'row'}
                    gutterSize={isMobile ? 0.5 : 2}
                    alignX="space-between"
                    style={{ marginLeft: 'auto' }}
                  >
                    {formCurrentStep === 'CONTENT' &&
                      !isMobile &&
                      isFormHeaderValid && (
                        <Button
                          type="button"
                          variant="outline"
                          disabled={isSubmitting}
                          onClick={() => {
                            setModelModalIsShown(true);
                          }}
                        >
                          {t('models')}
                        </Button>
                      )}
                    {formCurrentStep === 'HEADER' ? (
                      <Tooltip
                        style={{ marginLeft: 'auto' }}
                        tip={t('missing_fields')}
                        isHidden={recipient && values?.deadline && values?.name}
                        top
                      >
                        <Button
                          type="button"
                          variant="primary"
                          icon={<ChevronRight />}
                          direction="reverse"
                          onClick={() => {
                            setIsInitialModelSelection(false);
                            navigate(nextStepPath);
                          }}
                          style={{ width: '100%' }}
                          disabled={
                            isSubmitting ||
                            !recipient ||
                            !values?.deadline ||
                            !values?.name
                          }
                        >
                          {
                            t('next_step') //t('continue_to_items')
                          }
                        </Button>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        tip={t('tokens_exceeded')}
                        isHidden={
                          remainingTokens === null ||
                          remainingTokens - (values?.items || []).length >= 0
                        }
                        top
                      >
                        <Button
                          type="submit"
                          direction="reverse"
                          variant="primary"
                          icon={
                            formCurrentStep === 'RECAP' ? (
                              <MdSend />
                            ) : (
                              <ChevronRight />
                            )
                          }
                          style={{ width: '100%' }}
                          isLoading={isSubmitting}
                          disabled={
                            isSubmitting ||
                            get(values, 'items', []).length === 0 ||
                            (remainingTokens !== null &&
                              remainingTokens - (values?.items || []).length <
                              0) ||
                            !isFormHeaderValid
                          }
                        >
                          {formCurrentStep === 'RECAP'
                            ? t('send') //t('send')
                            : t('next_step')}
                        </Button>
                      </Tooltip>
                    )}
                  </Stack>
                </BottomBarInner>
              </BottomBar>
            )}
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default NewRequest;
