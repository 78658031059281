import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Modal,
  SegmentedControls,
  Stack,
  Buttons,
  List,
  ButtonReset,
  Spinner,
} from '@tymate/margaret';
import { fontStyles, SubText } from 'ui';
import { TextField, Button, AvatarAndName } from 'components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Box } from '@tymate/margaret';
import { useBreakpoint } from 'hooks';
import { useMutation, useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { At, Person } from 'react-bootstrap-icons';
import { useDebounce } from 'react-use';
import Search from './Search';

const CardTitle = styled(Box)`
  ${fontStyles.h3}

  margin: 0;
  font-weight: 700;
`;

CardTitle.defaultProps = {
  as: 'h3',
};
const CardLabel = styled.p`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLighter};
  margin-top: 0;
`;

const SelectRecipientButton = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.borderRadiusLarge};

  &:hover {
    background-color: ${({ theme }) => theme.background};
  }
`;

const CREATE_RECIPIENT = gql`
  mutation createRecipient($input: CreateRecipientInput!) {
    createRecipient(input: $input) {
      recipient {
        id
        email
        role
        firstName
        lastName
      }
    }
  }
`;

const GET_WORK_RECIPIENTS = gql`
  query getWorkRecipients($slug: String!) {
    work(slug: $slug) {
      id
      slug
      recipients {
        edges {
          node {
            email
            firstName
            lastName
            role
            id
          }
        }
      }
    }
  }
`;

const RequestRecipientModal = ({
  onRequestClose,
  onSubmitInvitation,
  onSelect,
  workId,
}) => {
  const { workSlug } = useParams();
  const { t } = useTranslation([
    'auth',
    'newRequest',
    'errors',
    'roles',
    'recipients',
  ]);
  const {
    values,
    errors,
    setFieldValue,
    setFieldTouched,
    touched,
  } = useFormikContext();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'mobile';
  const [recipientSearch, setRecipientSearch] = useState('');
  const [debouncedRecipientSearch, setDebouncedRecipientSearch] = useState('');

  const [createRecipient, { loading: isSubmittingRecipient }] = useMutation(
    CREATE_RECIPIENT,
  );
  const [
    fetchRecipients,
    { data: recipientsData, loading: isLoadingRecipients },
  ] = useLazyQuery(GET_WORK_RECIPIENTS, {
    variables: { slug: workSlug },
  });

  const recipients = (recipientsData?.work?.recipients?.edges ?? [])
    .filter(({ node }) => {
      const searchExpression = debouncedRecipientSearch.toLowerCase();
      return (
        node.firstName.toLowerCase().includes(searchExpression) ||
        node.lastName.toLowerCase().includes(searchExpression) ||
        node.email.toLowerCase().includes(searchExpression)
      );
    })
    .map(({ node }) => ({ ...node }));

  const handleClearInvitationFields = () => {
    setFieldValue('recipient.firstName', '');
    setFieldValue('recipient.lastName', '');
    setFieldValue('recipient.email', '');
  };

  const handleSelectMode = mode => {
    setFieldValue('recipient.mode', mode);

    if (mode === 'CONTACT') {
      handleClearInvitationFields();
    }
  };

  const handleCancel = () => {
    handleClearInvitationFields();
    onRequestClose();
  };

  const handleSubmit = async () => {
    setFieldTouched('recipient.firstName');
    setFieldTouched('recipient.lastName');
    setFieldTouched('recipient.email');

    if (
      !touched?.recipient?.firstName ||
      !touched?.recipient?.lastName ||
      !touched?.recipient?.email
    ) {
      setTimeout(handleSubmit);
      return;
    }

    if (
      !errors?.recipient?.firstName &&
      !errors?.recipient?.lastName &&
      !errors?.recipient?.email
    ) {
      try {
        const { data } = await createRecipient({
          variables: {
            input: {
              workId,
              email: values?.recipient?.email,
              firstName: values?.recipient?.firstName,
              lastName: values?.recipient?.lastName,
            },
          },
        });
        onSelect(data?.createRecipient?.recipient);
      } catch (err) {}
    }
  };

  const handleInputKeyDown = e => {
    e.key === 'Enter' && handleSubmit();
  };

  useEffect(() => {
    setFieldTouched('recipient.firstName', false);
    setFieldTouched('recipient.lastName', false);
    setFieldTouched('recipient.email', false);
  }, [setFieldTouched]);

  useEffect(() => {
    fetchRecipients();
  }, [fetchRecipients]);

  useDebounce(
    () => {
      setDebouncedRecipientSearch(recipientSearch);
    },
    300,
    [recipientSearch],
  );

  return (
    <Modal isOpen onRequestClose={onRequestClose}>
      <Stack direction="column" gutterSize={1.5} alignX="center" paddingTop={2}>
        <SegmentedControls
          style={{ width: '100%' }}
          value={values.recipient?.mode}
          onSelect={handleSelectMode}
          options={[
            {
              value: 'CONTACT',
              label: isMobile
                ? t('newRequest:select_recipient_shortened')
                : t('newRequest:select_recipient'),
            },
            {
              value: 'EMAIL',
              label: isMobile
                ? t('newRequest:invit_outsider_shortened')
                : t('newRequest:invit_outsider'),
            },
          ]}
        />

        <Stack size="full" direction="column" gutterSize={1.5} alignX="stretch">
          {values.recipient?.mode === 'EMAIL' && (
            <>
              <CardTitle style={{ marginBottom: 0 }}>
                {t('newRequest:invit_outsider')}
              </CardTitle>
              <CardLabel style={{ marginBottom: 0 }}>
                {t('newRequest:invit_outsider_info')}
              </CardLabel>

              <Stack gutterSize={1}>
                <TextField
                  name="recipient.firstName"
                  label={t('firstName')}
                  icon={<Person size={20} />}
                  onKeyDown={handleInputKeyDown}
                />

                <TextField
                  wrapperStyle={{ marginTop: 0 }}
                  name="recipient.lastName"
                  label={t('lastName')}
                  icon={<Person size={20} />}
                  onKeyDown={handleInputKeyDown}
                />
              </Stack>

              <div>
                <TextField
                  wrapperStyle={{ marginTop: 0 }}
                  name="recipient.email"
                  label={t('email')}
                  icon={<At size={20} />}
                  onKeyDown={handleInputKeyDown}
                />
              </div>

              <Buttons paddingTop={2}>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  isLoading={isSubmittingRecipient}
                >
                  {t('newRequest:invit')}
                </Button>
                <Button variant="text" onClick={handleCancel} type="button">
                  {t('newRequest:cancel')}
                </Button>
              </Buttons>
            </>
          )}

          {values.recipient?.mode === 'CONTACT' && (
            <>
              <CardTitle style={{ marginBottom: -16 }}>
                {t('newRequest:select_recipient')}
              </CardTitle>

              <Search
                value={recipientSearch}
                onChange={setRecipientSearch}
                size="full"
              />
              {isLoadingRecipients ? (
                <Spinner />
              ) : (
                <List direction="column">
                  {recipients.map(recipient => (
                    <Stack as="li" key={recipient.id} size="full">
                      <SelectRecipientButton
                        alignX="space-between"
                        size="full"
                        alignY="center"
                        as={ButtonReset}
                        onClick={() => onSelect(recipient)}
                      >
                        <AvatarAndName
                          user={recipient}
                          variant="withEmail"
                          avatarSize={isMobile ? 'tiny' : 'large'}
                          size={isMobile ? 'small' : undefined}
                          legendSize={isMobile ? 'small' : undefined}
                          rightLabel={
                            recipient.role
                              ? t('roles:contact')
                              : t('roles:outsider')
                          }
                        />
                      </SelectRecipientButton>
                    </Stack>
                  ))}
                  {recipients.length === 0 && (
                    <SubText style={{ marginTop: 0 }}>
                      {t('recipients:no_recipients_found')}
                    </SubText>
                  )}
                </List>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default RequestRecipientModal;
