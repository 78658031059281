import { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  AccessibleUntil,
  Button,
  Deadline,
  Attachment,
  AvatarAndName,
  SignatureStatus
} from 'components';
import { Trans, useTranslation } from 'react-i18next';
import { useApp, useGetCompletion } from 'hooks';
import { getIconFromType, formatDate, getEmailStatusIcon } from 'utils';
import { fontStyles } from 'ui';
import { Stack, List, Box } from '@tymate/margaret';
import { Status } from './';
import { Checkbox } from './Forms';
import { FormField, FormLabel, RadioLabel } from 'ui/forms';
import Linkify from 'react-linkify';
import yousignLogo from 'images/yousign-logo.png';
import SignatureProcedure, {
  displaySignatureButton
} from './SignatureProcedure';
import {
  FolderFill,
  InfoCircle,
  Journal,
  JournalCheck
} from 'react-bootstrap-icons';
import { Link, useParams } from 'react-router-dom';

const Description = styled.div`
  ${fontStyles.body};
  color: ${({ theme }) => theme.textLight};
  margin: 0;
  white-space: pre-wrap;
  text-align: justify;

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
  }
`;

const Information = styled(Stack)`
  ${fontStyles.body};
  color: ${({ theme }) => theme.textLighter};
  height: 22px;
`;

const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing()};
`;

const Item = styled(Card)`
  position: relative;

  p {
    color: ${({ theme }) => theme.textLight};
  }

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
  }
`;

// const bounce = keyframes`
//   from {
//     transform: translateY(0);
//   }
//   to{
//     transform: translateY(-15px);
//   }
// `;

const Icon = styled.div`
  color: ${({ theme }) => theme.primary};
  font-size: 1.25rem;
  line-height: 1.25rem;
`;

// const RequiredIcon = styled.div`
//   // width: 1.5rem;
//   // height: 1.5rem;
//   transition: 300ms;
//   font-size: 0.7rem;
//   line-height: 0;
//   border-radius: 50%;
//   padding: ${({ theme }) => theme.spacing(0.5)};
//   // background: ${({ theme }) => theme.separator};
//   color: ${({ theme }) => theme.tertiaryLighter};

//   position: absolute;
//   top: -1em;
//   right: -1em;
//   ${({ completionState }) =>
//     completionState === 'default' &&
//     css`
//       color: ${({ theme }) => theme.tertiaryLighter};
//       background: ${({ theme }) => theme.warning};
//     `}

//   ${({ completionState }) =>
//     completionState === 'completed' &&
//     css`
//       background: ${({ theme }) => theme.success};
//     `}
//   ${({ completionState }) =>
//     completionState === 'missing' &&
//     css`
//       background: ${({ theme }) => theme.warning};
//       color: ${({ theme }) => theme.tertiaryLighter};
//       animation: ${bounce} 0.35s ease infinite alternate;
//     `}
// `;

const Title = styled.h1`
  ${fontStyles.h1}
  font-family: ${({ theme }) => theme.fonts.title};
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

const WorkLink = styled(Link)`
  ${fontStyles.body}

  display: flex;
  align-items: flex-start;
  font-weight: bold;
  color: ${({ theme }) => theme.textLight};
  margin: 0;
  text-decoration: none;

  :hover {
    color: ${({ theme }) => theme.primary};
  }

  > svg {
    color: ${({ theme }) => theme.textLight};
    margin-right: ${({ theme }) => theme.spacing(0.25)};
  }
`;

const ItemTitle = styled.h2`
  ${fontStyles.h3}
  font-family: ${({ theme }) => theme.fonts.title};
  margin: 0;
`;

const StatusMessage = styled.div`
  ${fontStyles.body}
  color: ${({ theme }) => theme.error};
  margin: ${({ theme }) => theme.spacing(0.5)} 0 0 0;
`;

const YousignLogo = styled.img`
  max-width: 2em;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const YousignText = styled.p`
  margin: 0;
`;

const SignatureSigner = styled(Stack)``;

const Alert = styled(Stack)`
  background-color: ${({ theme }) => theme.background};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  margin-bottom: ${({ theme }) => theme.spacing()};
  font-size: 0.8rem;

  ${({ variant }) =>
    variant === 'senderMustSign' &&
    css`
      background-color: ${({ theme }) => theme.separatorLighter};
      box-shadow: none;
      margin: 0;
      color: ${({ theme }) => theme.textLighter};
    `}
`;

const PersonalRequestTag = styled(Box)`
  ${fontStyles.bodySmall}
  padding: ${({ theme }) => theme.spacing(0.125)}
  ${({ theme }) => theme.spacing(0.5)};
  border-radius: ${({ theme }) => theme.borderRadiusPill};
  color: ${({ theme }) => theme.success};
  background-color: ${({ theme }) => theme.successBackground};
`;

const RequestDetail = ({
  request,
  refetchRequest,
  signatureAs,
  onDocumentDownload,
}) => {
  const { t } = useTranslation('request');
  const { user } = useApp();
  const { organizationSlug } = useParams();
  const completionPercentage = useGetCompletion(request?.items);
  const signatureItems = (request?.items || []).filter(
    ({ type }) => type === 'SIGNATURE',
  );

  const [signatureData, setSignatureData] = useState({
    start: false,
    item: null,
  });

  const launchSignature = item => {
    setSignatureData({
      start: true,
      item: item,
    });
  };

  const onSignatureFinished = () => {
    setSignatureData({ start: false, item: null });
  };

  return (
    <div>
      {signatureItems.length > 0 && (
        <SignatureProcedure
          request={request}
          refetchRequest={refetchRequest}
          signatureAs={signatureAs}
          data={signatureData}
          onProcedureFinished={onSignatureFinished}
        />
      )}

      <Stack marginBottom={1}>
        <AvatarAndName
          user={request?.recipient}
          variant="withEmail&Role"
          role={t('recipient')}
        />
      </Stack>
      <Stack
        direction="column"
        alignX="flex-start"
        marginBottom={1}
        gutterSize={0.5}
      >
        <Title>{request?.name}</Title>
        <Stack
          direction={{
            default: 'column',
            tablet: 'row',
          }}
          alignY="center"
          size="full"
          gutterSize={1}
          gap={1}
        >
          <Status status={request?.status} />
          {request?.canAnswer?.value && (
            <PersonalRequestTag>{t('personal_request')}</PersonalRequestTag>
          )}
          {request?.work && (
            <span>
              <WorkLink
                to={`/organizations/${organizationSlug}/${request.work.slug}`}
              >
                <FolderFill size={20} />
                {request.work.name}
              </WorkLink>
            </span>
          )}
        </Stack>
        {request?.status === 'REJECTED' && (
          <StatusMessage>
            <Trans i18nKey="request:rejection_explanation">
              <strong>Rejection motive : </strong>
              {{ motive: request?.statusMessage }}
            </Trans>
          </StatusMessage>
        )}
      </Stack>
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a
            target="blank"
            rel="noopener noreferer nofollow"
            href={decoratedHref}
            key={key}
          >
            {decoratedText}
          </a>
        )}
      >
        <Description>{request?.description}</Description>
      </Linkify>
      {request?.documents?.length > 0 && (
        <List direction="column" gutterSize={0.5} marginVertical={1}>
          {request?.documents.map(file => (
            <Attachment file={file} withUpload key={file.id} />
          ))}
        </List>
      )}

      <Stack
        marginTop={0.5}
        paddingVertical={0.5}
        direction={{
          default: 'column',
          tablet: 'row',
        }}
        alignX={{ default: 'flex-start', tablet: 'space-between' }}
        gutterSize={0.5}
        variant="multiLine"
      >
        <Deadline deadline={request?.deadline} variant="requestDetail" />

        {request?.canManage && (
          <Information marginVertical={0} alignY="center" gutterSize={0.25}>
            {getEmailStatusIcon(request?.emailStatus)}
            <span>
              {t(`email_status.${t(request?.emailStatus.toLowerCase())}`)}
            </span>
          </Information>
        )}

        {request?.canManage && (
          <Information marginVertical={0} alignY="center" gutterSize={0.25}>
            {completionPercentage === 100 ? <JournalCheck /> : <Journal />}
            <span>{t('completed_at', { percent: completionPercentage })}</span>
          </Information>
        )}
      </Stack>

      <Stack
        paddingVertical={0.5}
        direction={{
          default: 'column',
          tablet: 'row',
        }}
        alignX={{ default: 'flex-start', tablet: 'space-between' }}
        gutterSize={0.5}
        variant="multiLine"
      >
        <AccessibleUntil deadline={request?.deadline} variant="requestDetail" />
      </Stack>

      <List
        direction="column"
        gutterSize={2}
        alignX="stretch"
        marginVertical={1}
      >
        {request?.items.map(
          (
            {
              name,
              description,
              type,
              required,
              document,
              choices,
              message,
              answer,
              status,
              recipientSignature,
              senderSignature,
              isTwoWaySignature,
              signedDocument,
            },
            index,
          ) => {
            const item = request?.items?.[index];

            return (
              <Item key={index}>
                <Stack alignY="center" alignX="space-between">
                  <Stack alignY="center" gutterSize={0.5}>
                    <Icon>{getIconFromType(type)}</Icon>
                    <Stack alignY="center" gutterSize={0.5}>
                      <ItemTitle>{name}</ItemTitle>
                      {/* {required && (
                        <RequiredIcon
                          completionState={
                            Boolean(item.answer?.acknowledged) ||
                            Boolean(item.answer?.documents?.length) ||
                            Boolean(item.answer?.message) ||
                            Boolean(item.answer?.choice) ||
                            Boolean(item.recipientSignature)
                              ? 'completed'
                              : 'default'
                          }
                        >
                          <Asterisk />
                        </RequiredIcon>
                      )} */}
                    </Stack>
                  </Stack>
                </Stack>
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a
                      target="blank"
                      rel="noopener noreferer nofollow"
                      href={decoratedHref}
                      key={key}
                    >
                      {decoratedText}
                    </a>
                  )}
                >
                  <Description
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    {description}
                  </Description>
                </Linkify>

                {Boolean(document) && (
                  <FormField>
                    <FormLabel>
                      {t(
                        type === 'SIGNATURE'
                          ? 'unsigned_document'
                          : 'attachment',
                      )}
                    </FormLabel>
                    <Attachment
                      file={document}
                      withUpload
                      withViewStatus={request?.canManage?.value}
                      onDownload={() => onDocumentDownload(document)}
                    />
                  </FormField>
                )}

                {type === 'PROMPT' && (
                  <FormField>
                    <FormLabel>{t('request_answer')}</FormLabel>

                    <Card>
                      <List gutterSize={0.5} direction="column">
                        {choices.map(({ choice }, index) => (
                          <li key={index}>
                            <RadioLabel
                              isActive={answer?.choice?.choice === choice}
                            >
                              {choice}
                            </RadioLabel>
                          </li>
                        ))}
                      </List>
                    </Card>
                  </FormField>
                )}

                {type === 'DOCUMENT' && answer?.documents && (
                  <FormField>
                    <FormLabel>{t('request_answer')}</FormLabel>
                    <List direction="column" gutterSize={0.5}>
                      {answer?.documents.map((file, index) => (
                        <li key={index}>
                          <Attachment
                            file={file}
                            withUpload
                            onDownload={() => onDocumentDownload(document)}
                          />
                        </li>
                      ))}
                    </List>
                  </FormField>
                )}

                {type === 'SIGNATURE' && (
                  <Stack
                    direction="column"
                    alignY="center"
                    gutterSize={1.5}
                    marginTop={2}
                  >
                    <Stack direction="column" gutterSize={1}>
                      <FormField>
                        <FormLabel>{t('signed_document')}</FormLabel>
                        {signedDocument ? (
                          <Attachment
                            file={signedDocument}
                            withUpload
                            onDownload={() =>
                              onDocumentDownload(signedDocument)
                            }
                          />
                        ) : (
                          <Stack alignY="center">
                            <Alert
                              variant="senderMustSign"
                              alignY="center"
                              gutterSize={0.5}
                            >
                              <span role="img" aria-label="">
                                <InfoCircle />
                              </span>
                              <div>
                                {t(
                                  'signature:sender_must_sign_before_viewing_document',
                                )}
                              </div>
                            </Alert>
                          </Stack>
                        )}
                      </FormField>

                      <FormLabel style={{ marginBottom: 0 }}>
                        {t('signature:signers')}
                      </FormLabel>
                      {Boolean(isTwoWaySignature) && Boolean(senderSignature) && (
                        <SignatureSigner
                          direction="row"
                          alignY="center"
                          gutterSize={1}
                        >
                          <AvatarAndName user={request?.author} size="small" />
                          <SignatureStatus status={senderSignature?.status} />
                        </SignatureSigner>
                      )}

                      <SignatureSigner
                        direction="row"
                        alignY="center"
                        gutterSize={1}
                      >
                        <AvatarAndName user={request?.recipient} size="small" />
                        <SignatureStatus
                          status={recipientSignature?.status || 'PENDING'}
                        />
                      </SignatureSigner>
                    </Stack>

                    {displaySignatureButton(
                      request,
                      item,
                      signatureAs,
                      user,
                    ) && (
                      <>
                        <Button
                          type="button"
                          variant="primary"
                          onClick={() => launchSignature(item)}
                        >
                          {t('sign')}
                        </Button>
                        <Stack direction="row" alignY="center">
                          <YousignLogo src={yousignLogo}></YousignLogo>
                          <YousignText>
                            {t('misc:signature_by_yousign')}
                          </YousignText>
                        </Stack>
                      </>
                    )}
                  </Stack>
                )}

                {type === 'MESSAGE' && (
                  <FormField>
                    <Stack
                      direction={{ default: 'column', tablet: 'row' }}
                      alignX={{
                        default: 'flex-start',
                        tablet: 'space-between',
                      }}
                      alignY="center"
                      marginBottom={0.5}
                    >
                      <FormLabel>{t('request_answer')}</FormLabel>
                      {answer?.updatedAt && (
                        <Information>
                          {formatDate(
                            answer?.updatedAt,
                            'dd MMMM yyyy - HH:mm',
                          )}
                        </Information>
                      )}
                    </Stack>
                    <Card>{answer?.message ? answer?.message : ' '}</Card>
                  </FormField>
                )}

                {type === 'ACKNOWLEDGE' && (
                  <FormField>
                    <FormLabel>{t('request_answer')}</FormLabel>
                    <Card>
                      <Checkbox
                        label={message}
                        value={answer?.acknowledged}
                        onChange={() => {}}
                        onClick={() => {}}
                      />
                    </Card>
                  </FormField>
                )}
              </Item>
            );
          },
        )}
      </List>
    </div>
  );
};

export default RequestDetail;
