import { useState } from 'react';
import styled from 'styled-components';
import { uniqueId } from 'lodash';
import { Field } from 'formik';
import { FormField, FormLabel } from 'ui/forms';
import ReactCodeInput from 'react-verification-code-input';

const ReactCodeInputElement = styled(ReactCodeInput)`
  input[type='tel'] {
    font: inherit;
  }
`;

const RenderVerificationCodeField = props => {
  const { field, label, wrapperStyle, ...rest } = props;

  return (
    <FormField style={wrapperStyle}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {label && <FormLabel>{label}</FormLabel>}
      </div>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ReactCodeInputElement autoFocus {...rest} />
      </div>
    </FormField>
  );
};

const VerificationCodeField = props => {
  const [id] = useState(uniqueId());
  return <Field {...props} id={id} component={RenderVerificationCodeField} />;
};

export default VerificationCodeField;
