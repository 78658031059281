import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { ButtonReset, media, Spinner, Stack } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import { useApp } from 'hooks';

const DragAndDropWrapper = styled(ButtonReset)`
  border: 1px dashed ${({ theme }) => theme.primary};
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing()};
  border-radius: ${({ theme }) => theme.borderRadiusLarge};
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.textLight};

  span,
  strong {
    color: ${({ theme }) => theme.primary};
    font-weight: 500;
  }

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(0.75)};
  `}
`;

const Title = styled.div`
  color: ${({ theme }) => theme.textLight};
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing(0.75)};
`;

const SimpleDropzone = ({ children, title, onClick, onAdd, ...props }) => {
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation(['newRequest']);
  const { notify } = useApp();

  const handleDrop = useCallback(
    async acceptedFiles => {
      setIsFetching(true);
      Array.isArray(acceptedFiles) && acceptedFiles.length
        ? await onAdd(acceptedFiles)
        : notify(t('create_model_error'), { type: 'error' });
      setIsFetching(false);
    },
    [onAdd, notify, t],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: false,
    accept: 'application/json',
  });

  return (
    <>
      {title && <Title> {title} </Title>}
      <DragAndDropWrapper type="button" {...getRootProps({ onClick })}>
        {isFetching || props.isFetching ? (
          <Stack size="full" alignX="center">
            <Spinner />
          </Stack>
        ) : (
          <div>
            <input {...getInputProps()} />
            {isDragActive ? <p>Glisser votre fichier ici</p> : children}
          </div>
        )}
      </DragAndDropWrapper>
    </>
  );
};

export default SimpleDropzone;
