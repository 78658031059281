import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { omit } from 'lodash';
import { Header, OrganizationForm } from 'components';
import { Container, Card } from 'ui';
import usePostHog from 'hooks/usePosthog';

const CREATE_TENANT = gql`
  mutation createTenant($input: CreateTenantInput!) {
    createTenant(input: $input) {
      tenant {
        id
        slug
        name
      }
    }
  }
`;

const NewOrganization = () => {
  usePostHog();
  const { t } = useTranslation('newOrganization');
  const navigate = useNavigate();
  const [createTenant] = useMutation(CREATE_TENANT);

  const handleSubmit = async values => {
    try {
      const { data } = await createTenant({
        variables: {
          input: {
            ...omit(values, 'destroyLogo'),
          },
        },
      });
      navigate(`/organizations/${data?.createTenant?.tenant?.slug}`);
    } catch (err) {
      console.dir(err);
    }
  };

  return (
    <div>
      <Header title={t('title')} containerSize="narrow" />

      <Container variant="main" size="narrow">
        <Card>
          <OrganizationForm
            onSubmit={handleSubmit}
            onReset={() => navigate(-1)}
          />
        </Card>
      </Container>
    </div>
  );
};

export default NewOrganization;
