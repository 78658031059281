import { Stack, media, RawLink } from '@tymate/margaret';
import React from 'react';
import styled, { css } from 'styled-components';

/**
 * Stepper components for newRequest Form
 */

const StepperWrapper = styled(Stack)`
  flex-wrap: wrap;
  width: clamp(10em, 20em, 100%);
  ${media.medium`
        width: clamp(20em, 34em, 100%); 
    `}
`;

const StepperCircle = styled.span`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  background-color: #e8ebed;
  border-radius: 100%;
  transition: 0.3s ease-in-out;
`;

const StepperTitle = styled.span`
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: 500;
  display: none;
  color: ${({ theme }) => theme.textLight};
  transition: 0.3s ease-in-out;
`;

const StepperItem = styled(RawLink)`
  --size: 2rem;
  cursor: pointer;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  transition: 0.3s ease-in-out;

  &:not(:last-child):after {
    content: '';
    height: 2px;
    left: calc(50% + calc(var(--size) / 2));
    width: calc(100% - calc(var(--size) / 2));
    order: -1;
    background-color: #e8ebed;
    top: calc(var(--size) / 2);
    position: relative;
  }

  ${media.medium`
        ${StepperTitle} {
            display: block;
        }
    `}

  ${({ isCompleted }) =>
    isCompleted &&
    css`
      ${StepperCircle}, &:not(:last-child):after {
        background-color: ${({ theme }) => theme.primary};
        color: #fff;
      }
      ${StepperTitle} {
        color: ${({ theme }) => theme.primary};
      }
    `}
`;

const Stepper = ({ steps, currentStep, labels, icons, requestsSlug }) => {
  let structure = {};
  steps.forEach((step, index) => {
    structure[step] = steps.slice(0, index + 1);
  });
  return (
    <StepperWrapper alignX="space-between" gutterSize={2}>
      {steps?.map((step, index) => (
        <StepperItem
          isCompleted={structure[currentStep].includes(step)}
          to={`${requestsSlug}new/${step.toLowerCase()}`}
          key={`step${index}`}
        >
          <StepperCircle>{icons ? icons[index] : index + 1}</StepperCircle>
          {labels && <StepperTitle>{labels[index] ?? ''}</StepperTitle>}
        </StepperItem>
      ))}
    </StepperWrapper>
  );
};

export default Stepper;
