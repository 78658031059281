import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Header, Button, EmptyState } from 'components';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { get, pickBy } from 'lodash';
import { List, Buttons, Stack, Spinner } from '@tymate/margaret';
import { RequestCard, ListFilters } from 'components';
import { Container, fontStyles } from 'ui';
import { Tabs, Tab, TabNavButton } from 'ui/tabs';
import SubscriptionModal from 'components/SubscriptionModal';
import {
  Check2Circle,
  Inbox,
  PlusCircleDotted,
  PlusCircleFill,
  SortDown,
  SortUpAlt,
} from 'react-bootstrap-icons';
import usePostHog from 'hooks/usePosthog';

const CountRequest = styled(Stack)`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLight};
  > div {
    svg {
      font-size: 1.375rem;
    }
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.backgroundPrimary};
    border-radius: 100%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ variant }) =>
    variant === 'secondary' &&
    css`
      > div {
        color: ${({ theme }) => theme.secondary};
        background-color: ${({ theme }) => theme.backgroundSecondary};
      }
    `}
`;

export const GET_WORK_REQUESTS = gql`
  query getWorkRequests(
    $slug: String!
    $order: RequestOrderCriteria
    $search: String
    $cursor: String
    $status: RequestStatus
  ) {
    work(slug: $slug) {
      id
      canCreateRequest {
        value
        reasons {
          details
          fullMessages
        }
        message
      }
      requests(
        first: 10
        order: $order
        name: $search
        status: $status
        after: $cursor
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            slug
            status
            statusMessage
            deadline
            name
            createdAt
            description
            canAnswer {
              value
            }
            items {
              type
            }
            documents {
              id
            }
            recipient {
              id
              firstName
              lastName
              email
            }
            author {
              id
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;

const GET_REQUEST_COUNT = gql`
  query getRequestsCount($slug: String!, $status: RequestStatus) {
    work(slug: $slug) {
      id
      requests(status: $status) {
        totalCount
      }
    }
  }
`;

const RequestsList = ({ isTenantOwner }) => {
  usePostHog();
  const { t } = useTranslation(['requests', 'filters', 'statuses', 'request']);
  const { workSlug } = useParams();
  const [filters, setFilters] = useState();
  const [subscriptionModalIsShown, setSubscriptionModalIsShown] = useState(
    false,
  );
  const [selectedState, setSelectedState] = useState('ALL');

  const orderOptions = [
    {
      value: '-createdAt',
      label: (
        <Trans i18nKey="filters:created_date-desc">
          <SortDown size={18} />
          Created at
        </Trans>
      ),
    },
    {
      value: 'createdAt',
      label: (
        <Trans i18nKey="filters:created_date-asc">
          <SortUpAlt size={18} />
          Created at
        </Trans>
      ),
    },
    {
      value: '-updatedAt',
      label: (
        <Trans i18nKey="filters:updated_date-desc">
          <SortDown size={18} />
          Updated at
        </Trans>
      ),
    },
    {
      value: 'updatedAt',
      label: (
        <Trans i18nKey="filters:updated_date-asc">
          <SortUpAlt size={18} />
          Updated at
        </Trans>
      ),
    },
    {
      value: 'deadline',
      label: (
        <Trans i18nKey="filters:deadline-asc">
          <SortUpAlt size={18} />
          Updated at
        </Trans>
      ),
    },
    {
      value: '-deadline',
      label: (
        <Trans i18nKey="filters:deadline-desc">
          <SortDown size={18} />
          Updated at
        </Trans>
      ),
    },
  ];

  const { data, fetchMore, loading } = useQuery(GET_WORK_REQUESTS, {
    variables: pickBy({ slug: workSlug, ...filters }),
    notifyOnNetworkStatusChange: true,
  });
  const hasNextPage = data?.work?.requests?.pageInfo?.hasNextPage;

  console.log('data', data);

  const requests = get(data, 'work.requests.edges', []);

  const { data: ongoingData } = useQuery(GET_REQUEST_COUNT, {
    variables: { slug: workSlug, status: 'ONGOING' },
  });
  const { data: validatedData } = useQuery(GET_REQUEST_COUNT, {
    variables: { slug: workSlug, status: 'VALIDATED' },
  });

  const ongoingRequestsCount = ongoingData?.work?.requests?.totalCount;

  const validatedRequestsCount = validatedData?.work?.requests?.totalCount;

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        cursor: data?.work?.requests?.pageInfo?.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult.work?.requests?.edges;
        const pageInfo = fetchMoreResult.work?.requests?.pageInfo;

        const result = newEdges.length
          ? Object.assign({}, previousResult, {
              work: {
                ...previousResult.work,
                requests: {
                  ...previousResult.work.requests,
                  edges: [...previousResult.work.requests.edges, ...newEdges],
                  pageInfo,
                },
              },
            })
          : previousResult;

        console.log('result', result);
        return result;
      },
    });
  };

  const handleUpdateFilters = payload => {
    setFilters({ ...filters, ...payload });
  };

  const handleCreateNewRequest = () => {
    const billingAccount = JSON.parse(
      data?.work?.canCreateRequest?.reasons?.details,
    )?.billing_account;
    if (Boolean(billingAccount)) {
      setSubscriptionModalIsShown(true);
    }
  };

  return (
    <>
      <Header
        title={t('all_requests')}
        actions={
          (data?.work.canCreateRequest?.value || isTenantOwner) && (
            <Button
              variant="primary"
              icon={<PlusCircleDotted />}
              to={data?.work?.canCreateRequest?.value && `new`}
              onClick={handleCreateNewRequest}
            >
              {t('new_request')}
            </Button>
          )
        }
        mobileActions={
          (data?.work.canCreateRequest?.value || isTenantOwner) && (
            <Button
              variant="icon"
              icon={<PlusCircleFill size={22} />}
              to="new"
            />
          )
        }
        containerSize="narrow"
      />
      {loading && !data ? (
        <Spinner />
      ) : (
        <Container variant="main" size="narrow">
          <SubscriptionModal
            isOpen={subscriptionModalIsShown}
            onRequestClose={() => setSubscriptionModalIsShown(false)}
          />
          <ListFilters
            orderOptions={orderOptions}
            onChange={handleUpdateFilters}
          >
            <Stack gutterSize={1}>
              <CountRequest gutterSize={0.5} alignY="center">
                <div>
                  <Inbox size={20} />
                </div>
                <span>
                  {t('ongoing_request', { count: ongoingRequestsCount })}
                </span>
              </CountRequest>
              <CountRequest
                gutterSize={0.5}
                alignY="center"
                variant="secondary"
              >
                <div>
                  <Check2Circle size={20} />
                </div>
                <span>
                  {t('validated_request', { count: validatedRequestsCount })}
                </span>
              </CountRequest>
            </Stack>
          </ListFilters>
          <Tabs hasNoPadding style={{ paddingBottom: 16 }}>
            <Tab>
              <TabNavButton
                isActive={selectedState === 'ALL'}
                onClick={() => {
                  setSelectedState('ALL');
                  handleUpdateFilters({ status: null });
                }}
              >
                {t('statuses:all')}
              </TabNavButton>
            </Tab>
            <Tab>
              <TabNavButton
                isActive={selectedState === 'ONGOING'}
                onClick={() => {
                  setSelectedState('ONGOING');
                  handleUpdateFilters({ status: 'ONGOING' });
                }}
              >
                {t('statuses:ongoing')}
              </TabNavButton>
            </Tab>
            <Tab>
              <TabNavButton
                isActive={selectedState === 'DELAYED'}
                onClick={() => {
                  setSelectedState('DELAYED');
                  handleUpdateFilters({ status: 'DELAYED' });
                }}
              >
                {t('statuses:delayed')}
              </TabNavButton>
            </Tab>
            <Tab>
              <TabNavButton
                isActive={selectedState === 'FINISHED'}
                onClick={() => {
                  setSelectedState('FINISHED');
                  handleUpdateFilters({ status: 'FINISHED' });
                }}
              >
                {t('statuses:finished')}
              </TabNavButton>
            </Tab>
            <Tab>
              <TabNavButton
                isActive={selectedState === 'VALIDATED'}
                onClick={() => {
                  setSelectedState('VALIDATED');
                  handleUpdateFilters({ status: 'VALIDATED' });
                }}
              >
                {t('statuses:validated')}
              </TabNavButton>
            </Tab>
            <Tab>
              <TabNavButton
                isActive={selectedState === 'REJECTED'}
                onClick={() => {
                  setSelectedState('REJECTED');
                  handleUpdateFilters({ status: 'REJECTED' });
                }}
              >
                {t('statuses:rejected')}
              </TabNavButton>
            </Tab>
          </Tabs>

          <List direction="column" alignX="stretch" gutterSize={1}>
            {requests.length === 0 ? (
              <EmptyState label={t('no_requests')} />
            ) : (
              requests.map(({ node }) => (
                <RequestCard
                  key={node?.id}
                  request={node}
                  to={node?.slug}
                  t={t}
                />
              ))
            )}
          </List>

          {hasNextPage && (
            <Buttons alignX="center">
              <Button onClick={handleLoadMore}>{t('see_more')}</Button>
            </Buttons>
          )}
        </Container>
      )}
    </>
  );
};
export default RequestsList;
