import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Member } from 'components';
import { Card, SubText, MembersList } from 'ui';
import { useDebounce } from 'react-use';
import Search from 'components/Search';
import usePostHog from 'hooks/usePosthog';

export const GET_WORK_CONTACTS = gql`
  query getWorkContacts($slug: String!) {
    tenant(slug: $slug) {
      workContacts {
        edges {
          node {
            roles {
              nodes {
                work {
                  name
                }
              }
            }
            user {
              id
              avatarUrl
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;

const SettingsContacts = () => {
  usePostHog();
  const { organizationSlug } = useParams();
  const { t } = useTranslation(
    'organizationSettings',
    'contacts',
    'invitations',
  );

  const [contactSearch, setContactSearch] = useState('');
  const [debouncedContactSearch, setDebouncedContactSearch] = useState('');
  const { loading, data, refetch } = useQuery(GET_WORK_CONTACTS, {
    variables: { slug: organizationSlug },
  });

  useDebounce(
    () => {
      setDebouncedContactSearch(contactSearch);
    },
    300,
    [contactSearch],
  );

  if (loading || !data) {
    return null;
  }

  console.log('contactsData', data);

  const contacts = get(data, 'tenant.workContacts.edges', []).filter(
    ({ node }) => {
      const searchExpression = debouncedContactSearch.toLowerCase();
      return (
        node.user.firstName.toLowerCase().includes(searchExpression) ||
        node.user.lastName.toLowerCase().includes(searchExpression) ||
        node.user.email.toLowerCase().includes(searchExpression)
      );
    },
  );

  return (
    <Card title={t('contacts')} style={{ overflow: 'visible' }}>
      <SubText style={{ marginTop: '0.25em', marginBottom: '1em' }}>
        {t('contacts_explanation')}
      </SubText>
      <Search value={contactSearch} onChange={setContactSearch} size="full" />
      <MembersList>
        {contacts.map(({ node }) => (
          <Member
            user={node.user}
            role={'CONTACT'}
            t={t}
            key={node.id}
            shouldHideDeleteOption
            workList={node?.roles?.nodes}
          />
        ))}
        {contacts.length === 0 && (
          <SubText style={{ marginTop: 0 }}>
            {t('contacts:no_contacts_found')}
          </SubText>
        )}
      </MembersList>
    </Card>
  );
};

export default SettingsContacts;
