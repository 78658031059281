import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuthentication, useIsAuthenticated } from 'hooks';

const Login = () => {
  const location = useLocation();
  const { login } = useAuthentication();
  const isAuthenticated = useIsAuthenticated();

  const currentLocationState = location.state;

  if (isAuthenticated) {
    return <Navigate to={currentLocationState?.from?.pathname ?? '/'} />;
  } else {
    const redirectUri = new URL(
      currentLocationState?.from
        ? `${window.location.origin}${currentLocationState?.from?.pathname}${currentLocationState?.from?.search}`
        : window.location.origin,
    );

    login({ redirectUri: redirectUri.href });
  }

  return <></>;
};

export default Login;
