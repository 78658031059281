import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { ButtonReset } from '@tymate/margaret';
import { X } from 'react-bootstrap-icons';

const Toggle = styled(ButtonReset)`
  padding: ${({ theme }) => theme.spacing()};
`;

const CloseButton = styled(ButtonReset)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(2)};
  right: ${({ theme }) => theme.spacing()};
`;

const Menu = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  right: -22em;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 22em;
  max-width: 100vw;
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing()};
  z-index: 12;
`;

const Overlay = styled(ButtonReset)`
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`;

const Drawer = ({ variant, children, trigger }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (isShown) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isShown]);

  return (
    <>
      <Toggle variant={variant} onClick={() => setIsShown(!isShown)}>
        {trigger}
      </Toggle>

      <AnimatePresence>
        {isShown && (
          <Menu
            initial={{
              right: '-22em',
              transition: { ease: 'easeInOut', duration: 0.3 },
            }}
            animate={{
              right: '0em',
              transition: { ease: 'easeInOut', duration: 0.3 },
            }}
            exit={{
              right: '-22em',
              transition: { ease: 'easeInOut', duration: 0.3 },
            }}
          >
            <CloseButton type="button" onClick={() => setIsShown(false)}>
              <X size={28} />
            </CloseButton>
            {children}
          </Menu>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isShown && (
          <Overlay
            as={motion.button}
            onClick={() => setIsShown(false)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.3 }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default Drawer;
