import posthog from 'posthog-js';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  POSTHOG_ENABLED,
  POSTHOG_API_KEY,
  POSTHOG_HOST,
  POSTHOG_APP,
  POSTHOG_ENVIRONMENT,
} from '../constants';

const getRoutePath = (location, params) => {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname;
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

export default function usePostHog(component) {
  useEffect(() => {
    if (POSTHOG_ENABLED) {
      posthog.init(POSTHOG_API_KEY, {
        api_host: POSTHOG_HOST,
        persistence: 'localStorage',
        capture_pageview: false,
        mask_all_text: true,
        loaded: posthog => {
          posthog.register({
            // add extra tags for filtering
            app: POSTHOG_APP,
            environment: POSTHOG_ENVIRONMENT,
          });
        },
      });
    }
  }, []);

  const location = useLocation();
  const params = useParams();
  const path = getRoutePath(location, params);
  window.path = path; // ranafout
  useEffect(() => {
    posthog.capture('$pageview', {
      path,
      params,
    });
  }, [location, params, path]);
}
