import React, { useEffect, useCallback } from 'react';
import { createGlobalStyle } from 'styled-components';
import { AppContext } from 'contexts';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { X } from 'react-bootstrap-icons';
import { useIsAuthenticated } from 'hooks';

const ToastsStyles = createGlobalStyle`
  .Toastify {
    .Toastify__toast-container {
      top: calc(80px + ${({ theme }) => theme.spacing()});
      z-index: 2147483647;
    }

    .Toastify__toast {
      border-radius: 4px;
      padding: ${({ theme }) => theme.spacing()};
      align-items: center;

      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        width: 4px;
      }

      svg {
        font-size: 20px;
      }
    }

    .Toastify__toast--default {
      background-color: #e1eefb;
      color: rgba(0,0,0,0.87);

      &:before {
        background-color: #62acec;
      }

      svg {
        color: #62acec;
      }
    }

    .Toastify__toast--success {
      background-color: #d4edda;
      color: rgba(0,0,0,0.87);

      &:before {
        background-color: #82c886;
      }

      svg {
        color: #82c886;
      }
    }

    .Toastify__toast--error {
      background-color: #fee7e1;
      color: rgba(0, 0, 0, 0.87);

      &:before {
        background-color: #ff8863;
      }

      svg {
        color: #ff8863;
      }
    }
  }
`;

export const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      firstName
      lastName
      email
      phone
      company
      id
      avatarUrl
      canCreateTenant {
        value
      }
      canAccessWrapperApi {
        value
      }
      billingAccount {
        id
      }
      apiConfiguration {
        id
        webhookUrls
        apiKey {
          id
          token
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

const AppProvider = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const [fetch, { data, refetch }] = useLazyQuery(CURRENT_USER);

  const notify = useCallback(
    (message, options) =>
      toast(message, { transition: Slide, type: 'success', ...options }),
    [],
  );

  const user = data?.currentUser;

  useEffect(() => {
    if (isAuthenticated) {
      fetch();
    }
  }, [fetch, isAuthenticated]);

  return (
    <AppContext.Provider
      value={{
        user,
        notify,
        fetchUser: refetch,
      }}
    >
      {children}
      <ToastsStyles />
      <ToastContainer closeButton={<X />} />
    </AppContext.Provider>
  );
};

export default AppProvider;
