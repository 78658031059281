import React from 'react';
import RawDatePicker from 'react-date-picker';
import { createGlobalStyle, css } from 'styled-components';
import { ButtonReset } from '@tymate/margaret';
import { Calendar2Event, XCircle } from 'react-bootstrap-icons';
import i18next from 'i18next';

const DatePickerStyle = createGlobalStyle`
.react-date-picker {
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.body};

  abbr[title] {
    text-transform: none;
    text-decoration: none;
  }

  .react-calendar__tile {
    height: calc(350px / 7);
    transition: background-color 150ms ease;
  }

  .react-date-picker__calendar .react-calendar {
    border: 1px solid ${({ theme }) => theme.separator};
    border-radius: ${({ theme }) => theme.borderRadiusLarge};
    overflow: hidden;
    box-shadow: 0 4px 4px -4px ${({ theme }) => theme.separatorLight};
    transform: translateY(4px);

    
  }

  .react-date-picker__wrapper {
    border: 0;
    width: 100%;
  }

  .react-date-picker__clear-button {
    border-top: 1px solid ${({ theme }) => theme.separator};
    border-bottom: 1px solid ${({ theme }) => theme.separator};
    background-color: #fff;
    svg {
      color: ${({ theme }) => theme.separator}
    }
  }
  
  .react-date-picker__inputGroup {
    border-top: 1px solid ${({ theme }) => theme.separator};
    border-left: 1px solid ${({ theme }) => theme.separator};
    border-bottom: 1px solid ${({ theme }) => theme.separator};
    border-radius: 8px 0 0 8px;
    padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
    background-color: #fff;

    ${({ hasError }) =>
      hasError &&
      css`
        &:not([disabled]),
        &:not([disabled]):hover,
        &:not([disabled]):active,
        &:not([disabled]):focus {
          border-top: 1px solid ${({ theme }) => theme.error};
          border-bottom: 1px solid ${({ theme }) => theme.error};
          border-left: 1px solid ${({ theme }) => theme.error};
        }
      `}

    &:disabled {
      border: 1px solid ${({ theme }) => theme.separator};
    }

    .react-date-picker__inputGroup__input {
      background: none;

      &:disabled {
        background-color: ${({ theme }) => theme.disabled};
        color: #6d6d6d;
      }
    }
  }

  .react-date-picker__calendar-button {
    border-top: 1px solid ${({ theme }) => theme.separator};
    border-right: 1px solid ${({ theme }) => theme.separator};
    border-bottom: 1px solid ${({ theme }) => theme.separator};
    border-radius: 0 8px 8px 0;
    padding-right: ${({ theme }) => theme.spacing(0.5)};
    padding-left: ${({ theme }) => theme.spacing(0.5)};

    svg {
      color: ${({ theme }) => theme.textLighter}
    }

    ${({ hasError }) =>
      hasError &&
      css`
        &:not([disabled]),
        &:not([disabled]):hover,
        &:not([disabled]):active,
        &:not([disabled]):focus {
          border-top: 1px solid ${({ theme }) => theme.error};
          border-right: 1px solid ${({ theme }) => theme.error};
          border-bottom: 1px solid ${({ theme }) => theme.error};
        }
      `}
  }
  
  .react-calendar__tile:disabled,
  .react-calendar__navigation button[disabled] {
    background-color: #fff;
    color: ${({ theme }) => theme.disabled};
    cursor: default;
  }
  

  .react-calendar__tile--now {
    background-color:  ${({ theme }) => theme.primaryLighter};

    &:hover{
      background-color: ${({ theme }) => theme.primaryLight};
    }
  }

  

  .react-date-picker__calendar--open {
    z-index: 567;
  }

  .react-calendar__month-view__days__day--weekend {
    color: ${({ theme }) => theme.text};
  }

  .react-calendar__tile--active {
    &, 
    &:hover {
      background-color: ${({ theme }) => theme.primary};
      color: #fff;
    }
  }
}
`;

const DatePicker = ({
  name,
  value,
  onChange,
  disabled,
  clearIcon,
  hasError,
  ...props
}) => (
  <>
    <DatePickerStyle hasError={hasError} />

    <RawDatePicker
      locale={i18next.language}
      name={name}
      value={value}
      onChange={onChange}
      showLeadingZeros
      clearIcon={
        clearIcon ? (
          <ButtonReset
            phantom
            style={{ opacity: Boolean(value) ? 1 : 0 }}
            onClick={() => onChange('')}
          >
            <XCircle size="16" />
          </ButtonReset>
        ) : null
      }
      calendarIcon={<Calendar2Event size={20} />}
      disabled={disabled}
      {...props}
    />
  </>
);

export default DatePicker;
