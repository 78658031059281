import React, { useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { Buttons, media, Modal, Spinner, Stack } from '@tymate/margaret';
import { fontStyles, SubText, Title as UITitle } from 'ui';
import { Trans, useTranslation } from 'react-i18next';
import { Box } from '@tymate/margaret';
import { Formik } from 'formik';
import { Form, VerificationCodeField } from './Forms';
import Button from './Button';
import { ErrorMessage } from 'ui/forms';
import signedDocumentImage from 'images/signed-document.svg';

const Img = styled.img`
  display: block;
  width: 192px;
  height: 192px;

  ${media.tablet`
    width: 256px;
    height: 256px;
  `}
`;

const Title = styled(UITitle)`
  margin-bottom: 0;
`;

const CardTitle = styled(Box)`
  ${fontStyles.h3}

  margin: 0;
  font-weight: 700;
`;

CardTitle.defaultProps = {
  as: 'h3',
};

const TextLight = styled.p`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLighter};
  margin: 0;
`;

const A = styled.a`
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
  cursor: pointer;
`;

const SEND_SIGNATURE_CODE = gql`
  mutation sendSignatureCode($input: SendSignatureCodeInput!) {
    sendSignatureCode(input: $input) {
      errors {
        message
        path
      }
      sent
    }
  }
`;

const VALIDATE_SIGNATURE_CODE = gql`
  mutation validateSignatureCode($input: ValidateSignatureCodeInput!) {
    validateSignatureCode(input: $input) {
      errors {
        message
        path
      }
      valid
    }
  }
`;

const defaultCodeData = {
  isValidating: false,
  isValid: null,
  code: null,
};

const SignatureCodeInputModal = ({
  onRequestClose,
  onValidatedCode,
  currentItem,
  codeType,
  sendCode,
  onSignatureCodeSent,
  isOpen,
}) => {
  const { t } = useTranslation('signature');

  const [codeData, setCodeData] = useState(defaultCodeData);

  const [sendSignatureCode] = useMutation(SEND_SIGNATURE_CODE);
  const [validateSignatureCode] = useMutation(VALIDATE_SIGNATURE_CODE);

  const handleSendCode = useCallback(async () => {
    try {
      await sendSignatureCode({
        variables: {
          input: {
            requestItemId: currentItem.requestItemId || currentItem.id,
            type: codeType || 'recipient',
          },
        },
      });
      onSignatureCodeSent();
    } catch (err) {}
  }, [codeType, currentItem, sendSignatureCode, onSignatureCodeSent]);

  const handleValidateCode = useCallback(
    async code => {
      try {
        const {
          data: {
            validateSignatureCode: { valid },
          },
        } = await validateSignatureCode({
          variables: {
            input: {
              requestItemId: currentItem.requestItemId || currentItem.id,
              type: codeType || 'recipient',
              code: code,
            },
          },
        });

        setCodeData({
          isValidating: false,
          isValid: valid,
          code: null,
        });
      } catch (err) {}
    },
    [codeType, currentItem, validateSignatureCode],
  );

  const onVerificationCodeComplete = value => {
    setCodeData({
      code: value,
      isValidating: true,
      isValid: null,
    });
  };

  const onSignatureCompleted = () => {
    onValidatedCode();
    setCodeData(defaultCodeData);
  };

  // Send code
  useEffect(() => {
    if (!sendCode) {
      return;
    }

    handleSendCode();
  }, [sendCode, handleSendCode]);

  // Validate code
  useEffect(() => {
    if (!codeData.code) {
      return;
    }

    handleValidateCode(codeData.code);
  }, [codeData.code, handleValidateCode]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      {Boolean(codeData?.isValid) ? (
        <Stack
          direction="column"
          alignX="center"
          style={{ textAlign: 'center' }}
          gutterSize={1.5}
        >
          <div>
            <Img src={signedDocumentImage} alt="" />
          </div>
          <Title>{t('signature_completed')}</Title>

          <div>{t('signature_completed_explanation')}</div>

          <Button
            variant="primary"
            type="button"
            onClick={onSignatureCompleted}
          >
            {t('signature_completed_back_button')}
          </Button>
        </Stack>
      ) : (
        <Stack direction="column" gutterSize={1.5} alignX="stretch">
          <CardTitle style={{ marginBottom: -16 }}>
            {t('code_verification')}
          </CardTitle>
          <SubText style={{ marginBottom: 0 }}>
            {t('code_verification_explanation')}
          </SubText>

          <Formik>
            {({ isSubmitting, errors, values }) => (
              <Form>
                <Stack direction="column" alignX="center" alignY="center">
                  {codeData.isValidating ? (
                    <Stack direction="column" alignX="center" alignY="center">
                      <Spinner />
                      {t('validating')}
                    </Stack>
                  ) : (
                    <>
                      <VerificationCodeField
                        type="number"
                        fields={6}
                        onComplete={onVerificationCodeComplete}
                        wrapperStyle={{ marginBottom: 10 }}
                      />
                      {codeData.isValid === false && (
                        <ErrorMessage
                          style={{ marginTop: 0, marginBottom: 10 }}
                        >
                          {t('wrong_code')}
                        </ErrorMessage>
                      )}

                      {!codeData.isValid && (
                        <TextLight>
                          <Trans i18nKey="signature:resend_code">
                            Didn't receive the code ?
                            <A
                              href={void 0}
                              onClick={e => {
                                e.preventDefault();
                                handleSendCode();
                              }}
                            >
                              Resend code
                            </A>
                          </Trans>
                        </TextLight>
                      )}
                    </>
                  )}
                </Stack>
                <Buttons paddingTop={1} alignX="flex-end">
                  <Button
                    type="button"
                    onClick={() => {
                      setCodeData(defaultCodeData);
                      onRequestClose();
                    }}
                  >
                    {t('close')}
                  </Button>
                </Buttons>
              </Form>
            )}
          </Formik>
        </Stack>
      )}
    </Modal>
  );
};

export default SignatureCodeInputModal;
