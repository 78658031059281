import React, { useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import { capitalize, last } from 'lodash';
import { useRoutes } from 'hooks';
import { List, media } from '@tymate/margaret';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { ChevronRight } from 'react-bootstrap-icons';

const shine = keyframes`
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: 140px;
  }
`;

const Wrapper = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin: ${({ theme }) => theme.spacing(-1)};
  padding: ${({ theme }) => theme.spacing()};
  overflow-x: auto;
  white-space: nowrap;

  ${media.tablet`
    margin-top: ${({ theme }) => theme.spacing(-2)};
    margin-bottom: ${({ theme }) => theme.spacing(-2)};
    padding-top: ${({ theme }) => theme.spacing(2)};
    padding-bottom: ${({ theme }) => theme.spacing(2)};
  `}

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;

      ${media.tablet`
        display: none;
      `};
    `}
`;

const BreadcrumbContent = styled(List)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: ${({ theme }) => theme.spacing()};
`;

const Crumb = styled.li`
  display: flex;
  margin-left: ${({ theme }) => theme.spacing(0.25)};
  margin-right: ${({ theme }) => theme.spacing(0.25)};
  line-height: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;

  > svg {
    display: flex;
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const CrumbLink = styled(NavLink)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
  align-items: center;
  border-radius: 99rem;
  line-height: 1.5;
  display: flex;

  > svg {
    display: flex;
    margin-right: ${({ theme }) => theme.spacing(0.375)};
  }
`;

const CrumbLast = styled(CrumbLink)`
  display: flex;

  ${({ variant }) =>
    variant !== 'main' &&
    css`
      box-shadow: none;
      color: ${({ theme }) => theme.textLight};
      padding-left: 0;
      padding-right: 0;
    `}
`;

const Placeholder = styled.div`
  height: 14px;
  width: ${({ theme }) => theme.spacing(6)};
  background-image: linear-gradient(
    90deg,
    ${({ theme }) => theme.separatorLight},
    ${({ theme }) => theme.separatorLighter} 20px,
    ${({ theme }) => theme.separatorLight} 40px,
    ${({ theme }) => theme.separatorLight}
  );
  animation: ${shine} 2s linear infinite;
  border-radius: 2px;
`;

const GET_FOLDER_NAME = gql`
  query getFolderName($slug: String!) {
    folder(slug: $slug) {
      id
      name
    }
  }
`;

const Chunk = ({ isFirst, isLast, index, icon, text, path }) => {
  const [fetch, { data }] = useLazyQuery(GET_FOLDER_NAME, {
    variables: { slug: last(path.split('/')) },
  });

  useEffect(() => {
    if (isFirst) {
      return;
    }

    fetch();
  }, [fetch, isFirst]);

  return (
    <Crumb isLast={isLast}>
      {!isFirst && <ChevronRight size={12} />}

      {isLast ? (
        <CrumbLast as="span" variant={index === 0 && 'main'}>
          {icon}
          {isFirst ? text : data?.folder?.name}
        </CrumbLast>
      ) : (
        <CrumbLink to={path}>
          {icon}
          {isFirst ? text : data?.folder?.name}
        </CrumbLink>
      )}
    </Crumb>
  );
};

const PageTitle = ({ chunks }) => {
  const lastChunk = last(chunks);
  const slug = last(lastChunk.path.split('/'));
  const isFirst = chunks.length === 1;

  const [fetch, { data }] = useLazyQuery(GET_FOLDER_NAME);

  useEffect(() => {
    if (isFirst) {
      return;
    }

    fetch({
      variables: { slug: slug },
    });
  }, [fetch, isFirst, slug]);

  return (
    <Helmet>
      <title>
        {isFirst
          ? lastChunk?.text
            ? `${lastChunk.text} - Paprwork`
            : 'Paprwork'
          : `${data?.folder?.name} - Paprwork`}
      </title>
    </Helmet>
  );
};

const SecondaryBreadcrumb = () => {
  const { pathname } = useLocation();
  const {
    secondaryBreadcrumbIgnoredPathsRegexes,
    pathsRegexesWithPlaceholder,
    routesNames,
  } = useRoutes();
  const urlChunks = pathname.split('/').filter(Boolean);
  const { t } = useTranslation('breadcrumb');

  const getPathTranslation = path => {
    const chunks = path.split('/');
    const chunk = last(chunks);

    const routeCustomName = routesNames[`/${path}`];

    if (Boolean(routeCustomName)) {
      return routeCustomName;
    }

    if (t(path) !== path) {
      return t(path);
    }

    if (t(chunk) !== chunk) {
      return t(chunk);
    }

    for (const regex of pathsRegexesWithPlaceholder) {
      if (Boolean(path.match(regex))) {
        return <Placeholder />;
      }
    }

    return capitalize(chunk);
  };

  const isIgnored = path => {
    for (const regex of secondaryBreadcrumbIgnoredPathsRegexes) {
      if (Boolean(path.match(regex))) {
        return true;
      }
    }

    return false;
  };

  const chunks = urlChunks.reduce((acc, _, index) => {
    const path = urlChunks.slice(0, index + 1).join('/');
    if (isIgnored(path)) {
      return acc;
    }

    return [
      ...acc,
      {
        path: `/${path}`,
        text: getPathTranslation(path),
      },
    ];
  }, []);

  return (
    <>
      <PageTitle chunks={chunks} />

      <Wrapper aria-label="Breadcrumb">
        <BreadcrumbContent>
          {chunks.map(({ path, text, icon }, index) => {
            const isLast = chunks.length - 1 === index;
            return (
              <Chunk
                path={path}
                text={text}
                icon={icon}
                isLast={isLast}
                isFirst={index === 0}
                key={index}
              />
            );
          })}
        </BreadcrumbContent>
      </Wrapper>
    </>
  );
};

export default SecondaryBreadcrumb;
