import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Container, fontStyles } from 'ui';
import { media, Stack } from '@tymate/margaret';

const Wrapper = styled.div`
  ${({ gridDisplayed }) =>
    gridDisplayed &&
    css`
      ${media.desktop`
        display: grid;
        grid-gap: ${({ theme }) => theme.spacing(2)};
        grid-template-columns: 1fr 26em;
      `}
    `}
`;

const Title = styled.h1`
  ${fontStyles.h1}
  font-family: ${({ theme }) => theme.fonts.title};
  margin: ${({ theme }) => theme.spacing()} 0;
`;

const Information = styled(Stack)`
  ${fontStyles.body};
  color: ${({ theme }) => theme.textLighter};
  text-align: center;
`;

const Unauthorized = () => {
  const { t } = useTranslation('unauthorized');

  return (
    <Container variant="main">
      <Wrapper>
        <Stack direction="column" alignX="center" gutterSize={-0.5}>
          <Title>{t('title')}</Title>
          <Information>{t('description')}</Information>
        </Stack>
      </Wrapper>
    </Container>
  );
};

export default Unauthorized;
