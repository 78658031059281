import React from 'react';
import { Button, Buttons, Modal, Stack } from '@tymate/margaret';
import { Title } from 'ui';

const RequestDraftModal = ({
  t,
  isOpen,
  onRequestClose,
  onContinue,
  onDiscard,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} size="full">
      <Stack
        direction="column"
        gutterSize={1}
        alignX="center"
        style={{ textAlign: 'center' }}
      >
        <Title style={{ marginTop: 15, marginBottom: 0 }}>
          {t('draftRequest.title')}
        </Title>
        {/* <Legend>{t('newRequest:draftRequest.legend')}</Legend> */}
        <Buttons style={{ justifyContent: 'center' }} paddingTop={2}>
          <Button
            type="button"
            variant="secondary"
            onClick={() => {
              onContinue();
              onRequestClose();
            }}
          >
            {t('draftRequest.load')}
          </Button>
          <Button
            type="button"
            variant="text"
            onClick={() => {
              onDiscard();
              onRequestClose();
            }}
          >
            {t('draftRequest.discard')}
          </Button>
        </Buttons>
      </Stack>
    </Modal>
  );
};

export default RequestDraftModal;
