import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Stack } from '@tymate/margaret';
import { fontStyles } from 'ui';
import { formatDate } from 'utils';
import { addDays } from 'date-fns';
import { Unlock } from 'react-bootstrap-icons';

const Wrapper = styled(Stack)`
  ${fontStyles.bodySmall}

  color: ${({ theme }) => theme.textLight};
  height: 22px;

  ${({ variant }) =>
    variant === 'requestDetail' &&
    css`
      ${fontStyles.body};
      color: ${({ theme }) => theme.textLighter};
    `}
`;

const AccessibleUntil = ({ deadline, variant }) => {
  const { t } = useTranslation('misc');

  if (!deadline) {
    return null;
  }

  const accessibleUntil = addDays(new Date(deadline), 90);

  return (
    <Wrapper
      marginVertical={0}
      alignY="center"
      gutterSize={0.25}
      variant={variant}
    >
      <Unlock />

      <span>
        {t('accessible', {
          accessibleUntil: formatDate(accessibleUntil, 'dd MMM yyyy'),
        })}
      </span>
    </Wrapper>
  );
};

export default AccessibleUntil;
