import React from 'react';
import { Formik } from 'formik';
import { Form, TextField, AvatarField } from 'components/Forms';
import { useTranslation } from 'react-i18next';
import { Buttons } from '@tymate/margaret';
import { Button } from 'components';
import * as Yup from 'yup';
import { get } from 'lodash';

const OrganizationForm = ({ onSubmit, tenant, canUpdate, onReset }) => {
  const { t } = useTranslation(['organizationForm', 'errors']);
  return (
    <Formik
      initialValues={{
        name: get(tenant, 'name', ''),
        logo: get(tenant, 'logoUrl', null),
        destroyLogo: false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('errors:required')),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <AvatarField
            name="logo"
            label={t('logo')}
            variant="rounded"
            destroyName="destroyLogo"
          />
          <TextField name="name" label={t('name')} />

          <Buttons paddingTop={0}>
            <Button variant="primary" isLoading={isSubmitting}>
              {t('save')}
            </Button>
            <Button type="button" onClick={onReset}>
              {t('cancel')}
            </Button>
          </Buttons>
        </Form>
      )}
    </Formik>
  );
};

export default OrganizationForm;
