import React from 'react';
import styled, { css } from 'styled-components';
import { fontSize } from 'ui';

const Tip = styled.span`
  ${fontSize.body}

  opacity: 0;
  display: none;
  position: absolute;
  top: 50%;
  left: calc(100% + ${({ theme }) => theme.spacing(0.5)});
  transform: translateY(-50%);
  color: #fff;
  transition: transform 75ms ease;
  text-transform: none;
  flex-wrap: wrap;
  transition: opacity 300ms ease;
  width: 15em;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
    border: 4px solid transparent;
    z-index: 22;
    border-right-color: ${({ theme }) => theme.primary};
  }

  :disabled & {
    display: none;
  }

  ${({ bottom }) =>
    bottom &&
    css`
      top: calc(100% + ${({ theme }) => theme.spacing(0.5)});
      left: auto;
      right: 50%;
      transform: translateY(0);
      transform: translateX(50%);

      &:before {
        content: '';
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateY(-50%);
        border: 4px solid transparent;
        z-index: 22;
        border-bottom-color: ${({ theme }) => theme.primary};
      }
    `}
  ${({ top }) =>
    top &&
    css`
      width: max-content;
      top: -100%;
      left: 50%;
      right: 0;
      transform: translateX(-50%);

      &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: rotateX(180deg);
        border: 4px solid transparent;
        z-index: 22;
        border-bottom-color: ${({ theme }) => theme.primary};
      }
    `}
`;

const Wrapper = styled.span`
  position: relative;
  display: block;

  &:hover ${Tip} {
    opacity: 1;
    display: flex;
  }

  svg {
    display: block;
  }
`;

const TipText = styled.span`
  display: block;
  background-color: ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border-radius: 2px;
`;

const Content = styled.span``;

const Tooltip = ({
  children,
  tip,
  bottom,
  top,
  name,
  isHidden = false,
  ...props
}) => (
  <Wrapper {...props}>
    <Content aria-labelledby={name}>{children}</Content>

    {!isHidden && (
      <Tip id={name} bottom={bottom} top={top}>
        <TipText>{tip}</TipText>
      </Tip>
    )}
  </Wrapper>
);

export default Tooltip;
