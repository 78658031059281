import { Header, WorkForm } from 'components';
import { useTranslation } from 'react-i18next';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Card } from 'ui';
import { Spinner } from '@tymate/margaret';
import usePostHog from 'hooks/usePosthog';

const GET_WORK = gql`
  query getWorkForEdit($slug: String!) {
    work(slug: $slug) {
      id
      name
      description
    }
  }
`;

const UPDATE_WORK = gql`
  mutation updateWork($input: UpdateWorkInput!) {
    updateWork(input: $input) {
      work {
        id
        slug
        name
        description
      }
    }
  }
`;

const EditWork = () => {
  usePostHog();
  const { t } = useTranslation(['update_work', 'work_form']);
  const { organizationSlug, workSlug } = useParams();
  const navigate = useNavigate();

  const [updateWork] = useMutation(UPDATE_WORK);
  const { loading, /*error,*/ data: workData } = useQuery(GET_WORK, {
    variables: { slug: workSlug },
  });

  const handleSubmit = async values => {
    try {
      const {
        data: {
          updateWork: { work },
        },
      } = await updateWork({
        variables: {
          input: {
            workId: workData?.work?.id,
            ...values,
          },
        },
      });

      navigate(`/organizations/${organizationSlug}/${work?.slug}`);
    } catch (err) {
      console.dir(err);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Header title={t('settings')} containerSize="narrow" />

      <Container variant="main" size="narrow">
        <Card>
          <WorkForm
            t={t}
            work={workData?.work}
            onSubmit={handleSubmit}
            onReset={() => navigate(-1)}
          />
        </Card>
      </Container>
    </>
  );
};

export default EditWork;
