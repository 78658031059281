import React from 'react';
import styled from 'styled-components';
import { Stack } from '@tymate/margaret';
import { fontStyles } from 'ui';

const Label = styled.p`
  ${fontStyles.bodyLarge};
  color: ${({ theme }) => theme.textLighter};
  margin: 0;
`;

const Icon = styled.div`
  color: ${({ theme }) => theme.textLightest};
  font-size: 80px;
`;

const EmptyState = ({ label, icon }) => (
  <Stack
    direction="column"
    alignX="center"
    alignY="center"
    marginTop={2}
    marginBottom={2}
    gutterSize={1}
  >
    {Boolean(icon) && <Icon>{icon}</Icon>}
    <Label>{label}</Label>
  </Stack>
);

export default EmptyState;
