import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import {
  Dropdown,
  PopoverMenu,
  PopoverItem,
  PopoverItemButton,
  Stack,
} from '@tymate/margaret';
import { find } from 'lodash';
import { fontSize } from 'ui';
import { CaretDownFill } from 'react-bootstrap-icons';

const Trigger = styled.div`
  ${fontSize.bodySmall}

  position: relative;
  display: flex;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 4px -4px ${({ theme }) => theme.separatorLight},
    0 0 0 1px ${({ theme }) => theme.separator};
  width: 14em;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 2rem;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing()};
  color: ${({ theme }) => theme.textLight};
  background-image: linear-gradient(to bottom, #ffffff 0%, #f4f4f4);

  > * {
    display: flex;
  }

  ${({ size }) =>
    size === 'full' &&
    css`
      width: 100%;
    `}

  svg {
    color: ${({ theme }) => theme.textLight};
  }
`;

const ItemLabel = styled(Stack)`
  svg {
    color: ${({ theme }) => theme.text};
    margin-left: 0;
    margin-right: ${({ theme }) => theme.spacing(0.25)};
  }
`;

const Select = ({ options, value, onChange, placeholder = '', alignment }) => {
  const dropdownRef = useRef();

  return (
    <Dropdown
      ref={dropdownRef}
      trigger={
        <Trigger>
          <ItemLabel direction="row" alignY="center">
            {find(options, option => option.value === value)?.label ??
              placeholder}
          </ItemLabel>
          <span>
            <CaretDownFill size={12} />
          </span>
        </Trigger>
      }
    >
      <PopoverMenu alignment={alignment}>
        {options.map(({ value, label }, index) => (
          <PopoverItem key={index}>
            <PopoverItemButton
              type="button"
              onClick={() => {
                onChange(value);
                dropdownRef.current.close();
              }}
            >
              <ItemLabel direction="row" alignY="center">
                {label}
              </ItemLabel>
            </PopoverItemButton>
          </PopoverItem>
        ))}
      </PopoverMenu>
    </Dropdown>
  );
};

export default Select;
