import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { DownloadFilesContext } from 'contexts';
import Axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { ButtonReset, media, Stack } from '@tymate/margaret';
import { fontStyles, theme } from 'ui';
import { useTranslation } from 'react-i18next';
import { CheckCircle, ChevronUp, X, XCircle } from 'react-bootstrap-icons';
import fileSize from 'filesize';
import { FILESIZE_OPTIONS } from '../constants';
import { CgSpinnerAlt } from 'react-icons/cg';

const Widget = styled.div`
  position: fixed;
  bottom: 0;
  left: 32px;
  border-radius: 6px 6px 0 0;
  background-color: #ffffff;
  color: ${({ theme }) => theme.textLight};
  box-shadow: 0 12px 48px 0 ${({ theme }) => theme.separator},
    0 12px 24px 0 ${({ theme }) => theme.separator};
  width: 20rem;

  ${media.tablet`
    left: 104px;
  `}
`;

const WidgetHeader = styled(Stack).attrs({
  direction: 'column',
  size: 'full',
})`
  font-weight: 600;
  line-height: 1.3;

  span {
    font-size: 10px;
    color: ${({ theme }) => theme.textLighter};
    font-weight: 400;
  }

  svg {
    font-size: 20px;
  }
`;

const WidgetHeaderBar = styled(Stack).attrs({
  size: 'full',
  direction: 'row',
  paddingVertical: 0.75,
  paddingHorizontal: 0.75,
  alignX: 'stretch',
})``;

const IconButton = styled(ButtonReset)`
  transition: transform 150ms ease;

  ${({ transform }) =>
    Boolean(transform) &&
    `
      transform: ${transform};
    `}
`;

const Dashboard = styled.div``;

const DashboardInner = styled.div`
  position: relative;
  background-color: #fafafa;
  max-width: 100%;
  max-height: 100%;
  height: 320px;
  outline: none;
  border-top: 1px solid ${({ theme }) => theme.separator};
`;

const DashboardInnerWrapper = styled(Stack).attrs({
  direction: 'column',
})`
  position: relative;
  overflow: hidden;
`;

const DashboardFiles = styled.div.attrs({
  role: 'list',
})`
  width: 100%;
  margin: 0;
  padding: 0 0 10px 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex: 1 1;
`;

const DashboardFileWrapper = styled(Stack).attrs({
  direction: 'row',
  alignY: 'center',
  alignX: 'space-between',
  gutterSize: 1,
})`
  padding: ${({ theme }) => theme.spacing(1)};
  border-bottom: 1px solid ${({ theme }) => theme.separator};
`;

const FileName = styled.div`
  ${fontStyles.bodySmall};

  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FileSize = styled.div`
  ${fontStyles.bodySmaller};

  color: ${({ theme }) => theme.textLighter};
`;

const FileStatus = styled.div`
  ${fontStyles.bodySmaller};

  color: ${({ theme }) => theme.textLighter};
`;

const ProgressBarWrapper = styled.div`
  width: 24px;
  height: 24px;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  color: ${({ theme }) => theme.primary};
  animation: ${spin} 1s linear infinite;
  width: 24px;
  height: 24px;
`;

const DashboardFile = ({
  downloaded,
  canceled,
  name,
  fileName,
  fileLength,
  url,
  setDownloaded,
  setCanceled,
  downloadMethod,
  downloadData,
  downloadHeaders,
}) => {
  const { t } = useTranslation('download');

  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: downloaded,
    total: fileLength,
    loaded: 0,
    canceled: canceled,
  });

  const [cancelSource, setCancelSource] = useState();

  const performDownload = useCallback(() => {
    if (downloaded || canceled) return;

    const options = {
      onDownloadProgress: progressEvent => {
        const { loaded } = progressEvent;

        setDownloadInfo({
          ...downloadInfo,
          progress: Math.floor((loaded * 100) / fileLength),
          loaded,
          completed: false,
        });
      },
    };

    const cancelToken = Axios.CancelToken;
    const source = cancelToken.source();
    setCancelSource(source);

    Axios.request({
      url: url,
      method: downloadMethod ?? 'get',
      data: downloadData,
      responseType: 'blob',
      cancelToken: source.token,
      headers: downloadHeaders,
      ...options,
    })
      .then(function (response) {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          }),
        );

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName || name || 'download';

        const clickHandler = () => {
          setTimeout(() => {
            URL.revokeObjectURL(url);
            link.removeEventListener('click', clickHandler);
          }, 150);
        };

        link.addEventListener('click', clickHandler, false);
        link.click();

        setDownloaded();
        setDownloadInfo(info => ({
          ...info,
          completed: true,
        }));

        // setTimeout(() => {
        //   removeFile();
        // }, 4000);
      })
      .catch(error => {
        if (Axios.isCancel(error)) {
          setCanceled();
          setDownloadInfo(info => ({
            ...info,
            canceled: true,
          }));
        }
      });
  }, [
    downloaded,
    canceled,
    fileName,
    name,
    fileLength,
    url,
    setDownloaded,
    setCanceled,
    downloadMethod,
    downloadData,
    downloadInfo,
    downloadHeaders,
  ]);

  useEffect(() => {
    performDownload();
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardFileWrapper>
      <Stack direction="column" alignY="center">
        <FileName>{name}</FileName>
        <FileSize>
          {!downloadInfo.completed &&
            !downloadInfo.canceled &&
            downloadInfo.loaded > 0 && (
              <>
                {fileSize(downloadInfo.loaded, {
                  ...FILESIZE_OPTIONS,
                  round: 2,
                })}
              </>
            )}

          {!downloadInfo.completed &&
            !downloadInfo.canceled &&
            downloadInfo.loaded === 0 && <>{t('initializing')}</>}

          {downloadInfo.completed && (
            <>
              {t('complete')}
              {' - '}
              {fileSize(downloadInfo.loaded, {
                ...FILESIZE_OPTIONS,
                round: 2,
              })}
            </>
          )}
        </FileSize>
      </Stack>
      {downloadInfo.completed && !downloadInfo.canceled && (
        <FileStatus>
          <CheckCircle size={24} color={theme.secondaryLight} />
        </FileStatus>
      )}{' '}
      {!downloadInfo.completed && !downloadInfo.canceled && (
        <Stack direction="row" alignY="center" gutterSize={0.5}>
          <ProgressBarWrapper>
            {/* <CircularProgressbar
              value={downloadInfo.progress}
              styles={buildStyles({
                pathColor: theme.primary,
                trailColor: theme.separator,
                backgroundColor: theme.background,
              })}
              strokeWidth={12}
            /> */}

            <Spinner>
              <CgSpinnerAlt size={24} />
            </Spinner>
          </ProgressBarWrapper>

          <IconButton onClick={() => cancelSource.cancel()}>
            <X size={24} />
          </IconButton>
        </Stack>
      )}
      {downloadInfo.canceled && (
        <FileStatus>
          <XCircle size={24} color={theme.error} />
        </FileStatus>
      )}
    </DashboardFileWrapper>
  );
};

const DownloadProvider = ({ children }) => {
  const { t } = useTranslation('download');

  const [files, setFiles] = useState(() => []);

  const [widgetIsExpanded, setWidgetIsExpanded] = useState(false);
  const [widgetIsExpandable, setWidgetIsExpandable] = useState(false);
  const [widgetIsShown, setWidgetIsShown] = useState(false);

  useEffect(() => {
    if (files.length > 0) {
      setWidgetIsShown(true);
      setWidgetIsExpandable(true);
      setWidgetIsExpanded(true);
    }
  }, [files]);

  const download = file => {
    setFiles(fileList => [
      ...fileList,
      { ...file, downloadId: uuid(), downloaded: false },
    ]);
  };

  const remove = removeId =>
    setFiles(files => [...files.filter(file => file.downloadId !== removeId)]);

  const removeAll = () => files.forEach(file => remove(file.downloadId));

  const setDownloaded = id => {
    setFiles(files => [
      ...files.map(file => {
        if (file.downloadId === id) file.downloaded = true;
        return file;
      }),
    ]);
  };

  const setCanceled = id => {
    setFiles(files => [
      ...files.map(file => {
        if (file.downloadId === id) file.canceled = true;
        return file;
      }),
    ]);
  };

  return (
    <DownloadFilesContext.Provider
      value={{
        download,
      }}
    >
      {children}

      {widgetIsShown && (
        <Widget>
          <WidgetHeader>
            <WidgetHeaderBar>
              <Stack direction="column" size="full">
                <div>{t('title')}</div>
              </Stack>

              <Stack alignY="center" gutterSize={0.5}>
                <IconButton
                  type="button"
                  onClick={() => setWidgetIsExpanded(!widgetIsExpanded)}
                  transform={widgetIsExpanded ? 'rotate(180deg)' : 'none'}
                >
                  <ChevronUp />
                </IconButton>
                <IconButton
                  onClick={() => {
                    removeAll();
                    setWidgetIsShown(false);
                  }}
                >
                  <X size={24} />
                </IconButton>
              </Stack>
            </WidgetHeaderBar>
          </WidgetHeader>

          {widgetIsExpanded && widgetIsExpandable && (
            <Dashboard>
              <DashboardInner>
                <DashboardInnerWrapper>
                  <DashboardFiles>
                    {files.map((file, idx) => (
                      <DashboardFile
                        key={idx}
                        removeFile={() => remove(file.downloadId)}
                        setDownloaded={() => setDownloaded(file.downloadId)}
                        setCanceled={() => setCanceled(file.downloadId)}
                        {...file}
                      />
                    ))}
                  </DashboardFiles>
                </DashboardInnerWrapper>
              </DashboardInner>
            </Dashboard>
          )}
        </Widget>
      )}
    </DownloadFilesContext.Provider>
  );
};

export default DownloadProvider;
