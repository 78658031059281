import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, Route, useParams, NavLink } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { Buttons, Spinner } from '@tymate/margaret';
import {
  Header,
  Button,
  Member,
  ContactInvitModal,
  CollaboratorInvitModal,
} from 'components';
import { Container, Card, MembersList, SubText } from 'ui';
import { CLIENT_ID } from '../../../../constants';
import { get } from 'lodash';
import { Tabs, Tab, TabNavButton } from 'ui/tabs';
import { GET_TENANT_SETTINGS } from '../Settings';
import { PersonPlusFill } from 'react-bootstrap-icons';
import Search from 'components/Search';
import { useDebounce } from 'react-use';
import { useApp } from 'hooks';
import usePostHog from 'hooks/usePosthog';

const GET_WORK = gql`
  query getWorkUsers($slug: String!) {
    work(slug: $slug) {
      id
      slug
      name
      canChangeOwner {
        value
      }
      owner {
        id
      }
      roles {
        nodes {
          name
          users {
            nodes {
              id
              firstName
              lastName
              avatarUrl
              email
            }
          }
          invitations {
            id
            userId
            role
            invitedAt
            acceptedAt
          }
        }
      }
    }
  }
`;

const INVITE_TO_WORK = gql`
  mutation inviteToWork($input: InviteToWorkInput!) {
    inviteToWork(input: $input) {
      work {
        id
        roles {
          nodes {
            name
            users {
              nodes {
                id
                firstName
                lastName
                avatarUrl
              }
            }
          }
        }
      }
      invitation {
        id
      }
    }
  }
`;

const REVOKE_WORK_ACCESS = gql`
  mutation revokeWorkAccess($input: RevokeWorkAccessInput!) {
    revokeWorkAccess(input: $input) {
      work {
        id
        roles {
          nodes {
            name
            users {
              nodes {
                id
                firstName
                lastName
                avatarUrl
              }
            }
          }
        }
      }
      user {
        id
      }
    }
  }
`;

const CHANGE_WORK_OWNER = gql`
  mutation changeWorkOwner($input: ChangeWorkOwnerInput!) {
    changeWorkOwner(input: $input) {
      work {
        id
        roles {
          nodes {
            name
            users {
              nodes {
                id
                firstName
                lastName
                avatarUrl
              }
            }
          }
        }
      }
    }
  }
`;

const RESEND_INVITATION = gql`
  mutation resendInvitation($input: ResendInvitationInput!) {
    resendInvitation(input: $input) {
      invitation {
        id
      }
    }
  }
`;

const Users = () => {
  usePostHog();
  const { organizationSlug, workSlug } = useParams();
  const { notify } = useApp();
  const { t } = useTranslation('users', 'contacts', 'invitations');
  const [contactInvitModalIsShown, setContactInvitModalIsShown] = useState(
    false,
  );
  const [collabInvitModalIsShown, setCollabInvitModalIsShown] = useState(false);

  const [contactSearch, setContactSearch] = useState('');
  const [debouncedContactSearch, setDebouncedContactSearch] = useState('');

  const { data: workData, refetch, loading } = useQuery(GET_WORK, {
    variables: { slug: workSlug },
  });

  const [inviteToWork] = useMutation(INVITE_TO_WORK, {
    refetchQueries: [
      {
        query: GET_TENANT_SETTINGS,
        variables: {
          slug: organizationSlug,
        },
      },
    ],
  });

  const [revokeWorkAccess] = useMutation(REVOKE_WORK_ACCESS, {
    refetchQueries: [
      {
        query: GET_TENANT_SETTINGS,
        variables: {
          slug: organizationSlug,
        },
      },
    ],
  });
  const [changeWorkOwner] = useMutation(CHANGE_WORK_OWNER, {
    refetchQueries: [
      {
        query: GET_TENANT_SETTINGS,
        variables: {
          slug: organizationSlug,
        },
      },
    ],
    onCompleted: () => {
      refetch();
    },
  });
  const [resendInvitation] = useMutation(RESEND_INVITATION, {
    refetchQueries: [
      {
        query: GET_WORK,
        variables: {
          slug: workSlug,
        },
      },
    ],
  });

  const contactRole = workData?.work?.roles?.nodes.find(
    ({ name }) => name === 'CONTACT',
  );
  const contacts = get(contactRole, 'users.nodes', []).filter(
    ({ email, firstName, lastName }) => {
      const searchExpression = debouncedContactSearch.toLowerCase();
      return (
        firstName.toLowerCase().includes(searchExpression) ||
        lastName.toLowerCase().includes(searchExpression) ||
        email.toLowerCase().includes(searchExpression)
      );
    },
  );

  const collaboratorRole = workData?.work?.roles?.nodes.find(
    ({ name }) => name === 'COLLABORATOR',
  );
  const collaborators = get(collaboratorRole, 'users.nodes', []);

  const handleChangeOwner = async id => {
    try {
      await changeWorkOwner({
        variables: {
          input: {
            workId: workData.work.id,
            ownerId: id,
          },
        },
      });
      refetch();
    } catch (err) {
      // console.dir(err);
    }
  };

  const handleRevokeAccess = async userId => {
    try {
      await revokeWorkAccess({
        variables: {
          input: {
            workId: workData.work.id,
            userId,
          },
        },
      });
    } catch (err) {
      // console.dir(err);
    }
  };

  const handleInvitMember = async values => {
    try {
      await inviteToWork({
        variables: {
          input: {
            clientId: CLIENT_ID,
            workId: workData.work.id,
            role: values.role,
            ...values,
          },
        },
      });
      refetch();
      setContactInvitModalIsShown(false);
    } catch (err) {
      // console.dir(err);
      setContactInvitModalIsShown(false);
    }
  };

  const handleResendInvitation = async invitationId => {
    try {
      await resendInvitation({
        variables: {
          input: {
            clientId: CLIENT_ID,
            invitationId: invitationId,
          },
        },
      });
      notify(t('invitations:resent'));
    } catch (err) {}
  };

  useDebounce(
    () => {
      setDebouncedContactSearch(contactSearch);
    },
    300,
    [contactSearch],
  );

  return (
    <>
      <Header title={t('users')} containerSize="narrow" />
      {loading && !workData ? (
        <Spinner />
      ) : (
        <Container variant="main" size="narrow">
          <Tabs>
            <Tab>
              <TabNavButton as={NavLink} to="" end>
                {t('collaborators')}
              </TabNavButton>
            </Tab>
            <Tab>
              <TabNavButton as={NavLink} to="contacts">
                {t('contacts')}
              </TabNavButton>
            </Tab>
          </Tabs>

          <Routes>
            <Route
              path=""
              element={
                <>
                  <CollaboratorInvitModal
                    isOpen={collabInvitModalIsShown}
                    onRequestClose={() => setCollabInvitModalIsShown(false)}
                    onSelect={handleInvitMember}
                  />

                  <Card title={t('collaborators')}>
                    <p>{t('collaborators_explanation')}</p>
                    <MembersList>
                      {collaborators.map(user => {
                        const invitation = collaboratorRole.invitations.find(
                          i => i.userId === user.id,
                        );
                        return (
                          <Member
                            user={user}
                            role={
                              user?.id === workData?.work?.owner?.id && 'OWNER'
                            }
                            invitation={invitation}
                            onUpdateOwner={handleChangeOwner}
                            onRevokeAccess={handleRevokeAccess}
                            onResendInvitation={handleResendInvitation}
                            t={t}
                            key={user.id}
                            shouldHideDeleteOption={collaborators.length <= 1}
                            canChangeOwner={
                              workData?.work?.canChangeOwner?.value
                            }
                          />
                        );
                      })}
                    </MembersList>
                    <Buttons style={{ marginTop: 0 }}>
                      <Button
                        variant="primary"
                        icon={<PersonPlusFill />}
                        onClick={() => setCollabInvitModalIsShown(true)}
                      >
                        {t('add_collaborator')}
                      </Button>
                    </Buttons>
                  </Card>
                </>
              }
            />

            <Route
              path="contacts"
              element={
                <>
                  <ContactInvitModal
                    isOpen={contactInvitModalIsShown}
                    onRequestClose={() => setContactInvitModalIsShown(false)}
                    onSubmitInvitation={handleInvitMember}
                  />

                  <Card title={t('contacts')}>
                    <p>{t('contacts_explanation')}</p>

                    <Search
                      value={contactSearch}
                      onChange={setContactSearch}
                      size="full"
                    />
                    <MembersList>
                      {contacts.map(user => {
                        const invitation = contactRole.invitations.find(
                          i => i.userId === user.id,
                        );
                        return (
                          <Member
                            user={user}
                            invitation={invitation}
                            onRevokeAccess={handleRevokeAccess}
                            onResendInvitation={handleResendInvitation}
                            t={t}
                            key={user.id}
                          />
                        );
                      })}
                      {contacts.length === 0 && (
                        <SubText style={{ marginTop: 0 }}>
                          {t('contacts:no_contacts_found')}
                        </SubText>
                      )}
                    </MembersList>
                    <Buttons style={{ marginTop: 0 }}>
                      <Button
                        variant="primary"
                        icon={<PersonPlusFill />}
                        onClick={() => setContactInvitModalIsShown(true)}
                      >
                        {t('add_contact')}
                      </Button>
                    </Buttons>
                  </Card>
                </>
              }
            />
          </Routes>
        </Container>
      )}
    </>
  );
};

export default Users;
