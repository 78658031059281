import React from 'react';
import styled, { css } from 'styled-components';
import { Search as IconSearch, XCircle } from 'react-bootstrap-icons';
import { ButtonReset } from '@tymate/margaret';
import { fontSize } from 'ui';
import { useTranslation } from 'react-i18next';

const CancelableInputContainer = styled.div`
  position: relative;
  display: flex;
  background-color: #ffffff;
  border-radius: 999em;
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.08),
    0 0 0 1px ${({ theme }) => theme.separator};
  width: 12em;
  max-width: 100%;

  ${({ size }) =>
    size === 'full' &&
    css`
      width: 100%;
    `}

  svg {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.disabledBackground};
      color: ${({ theme }) => theme.disabled};
    `}
`;

const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ theme }) => theme.spacing(0.25)};
  padding: ${({ theme }) => theme.spacing(0.25)};
  line-height: 0.8;
  z-index: 1;
`;

const SearchInput = styled.input`
  ${fontSize.bodySmall}

  height: 2rem;
  border: 0;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  outline: none;
  position: relative;
  z-index: 2;
  width: 100%;
`;

const Clear = styled(ButtonReset)`
  display: flex;
  position: absolute;
  transition: opacity 150ms ease;
  opacity: 0;
  top: 50%;
  right: ${({ theme }) => theme.spacing(0.5)};
  transform: translateY(-50%);

  ${({ isShown }) =>
    isShown &&
    css`
      z-index: 3;
      opacity: 0.8;
    `}
`;

const Search = ({
  variant,
  onChange,
  placeholder = null,
  size,
  value,
  disabled,
  ...props
}) => {
  const { t } = useTranslation('misc');

  return (
    <CancelableInputContainer size={size} variant={variant} disabled={disabled}>
      <SearchIcon>
        <IconSearch size={14} />
      </SearchIcon>

      <SearchInput
        type="text"
        onChange={e => onChange(e.target.value)}
        value={value}
        placeholder={placeholder || t('search')}
        variant={variant}
        disabled={disabled}
        {...props}
      />

      <Clear isShown={Boolean(value)} onClick={e => onChange('')}>
        <XCircle size={14} />
      </Clear>
    </CancelableInputContainer>
  );
};

export default Search;
