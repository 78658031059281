import React, { useState } from 'react';
import { useDebounce, useDeepCompareEffect } from 'react-use';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { pickBy } from 'lodash';
import Search from './Search';

const LocationAwareSearch = ({ disabled, placeholder, size }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { search, pathname } = location;

  const query = queryString.parse(search);
  const [value, setValue] = useState(query.search || '');
  const [debouncedValue, setDebouncedValue] = useState(query.search || '');

  useDebounce(
    () => {
      setDebouncedValue(value);
    },
    500,
    [value],
  );

  useDeepCompareEffect(() => {
    const newSearch = queryString.stringify(
      pickBy({
        ...query,
        search: debouncedValue,
      }),
    );

    navigate(`${pathname}${newSearch ? '?' + newSearch : ''}`);
  }, [{ debouncedValue }]);

  return (
    <Search
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      size={size}
      onChange={value => setValue(value)}
      onClear={() => setValue('')}
    />
  );
};

export default LocationAwareSearch;
