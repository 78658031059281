import Keycloak from 'keycloak-js';
import { LocalStorageService } from 'services';
import { AUTH, KEYCLOAK } from '../constants';

class AuthenticationService {
  // Public keycloak config
  static keycloakConfig = {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri:
      window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
    checkLoginIframe: false,
  };

  static _instance;
  _kc;

  constructor() {
    this._kc = new Keycloak({
      url: KEYCLOAK.URL,
      realm: KEYCLOAK.REALM,
      clientId: KEYCLOAK.CLIENT_ID,
    });
  }

  static get instance() {
    return this._instance || (this._instance = new this());
  }

  get keycloak() {
    return this._kc;
  }

  /**
   * Service methods
   */

  /*
    Keycloak callbacks
  */
  onEvent(event, error) {
    console.log('onKeycloakEvent', event, error);

    switch (event) {
      case 'onReady':
        return;
      case 'OnInitError':
        return;
      case 'onAuthSuccess':
        return AuthenticationService.instance.onAuthSuccess();
      case 'onAuthError':
        return AuthenticationService.instance.onAuthError();
      case 'onAuthRefreshSuccess':
        return AuthenticationService.instance.onAuthRefreshSuccess();
      case 'onAuthRefreshError':
        return AuthenticationService.instance.onAuthRefreshError();
      case 'onAuthLogout':
        return AuthenticationService.instance.onAuthLogout();
      case 'onTokenExpired':
        return AuthenticationService.instance.onTokenExpired();
      default:
        return null;
    }
  }

  login(options) {
    return AuthenticationService.instance.keycloak.login(options);
  }

  logout() {
    return AuthenticationService.instance.keycloak.logout();
  }

  getAccessToken() {
    return LocalStorageService.get(AUTH.PPW_ACCESS_TOKEN);
  }
  getRefreshToken() {
    return LocalStorageService.get(AUTH.PPW_REFRESH_TOKEN);
  }
  getIdToken() {
    return LocalStorageService.get(AUTH.PPW_ID_TOKEN);
  }

  updateToken(successCallback) {
    return AuthenticationService.instance.keycloak
      .updateToken(900)
      .then(successCallback)
      .catch(err => {
        console.error('onKeycloakError', 'updateToken', err);
      });
  }

  /* 
    Private methods
  */

  // When a user is successfully authenticated
  onAuthSuccess() {
    LocalStorageService.set(
      AUTH.PPW_ACCESS_TOKEN,
      AuthenticationService.instance.keycloak.token,
    );
    LocalStorageService.set(
      AUTH.PPW_REFRESH_TOKEN,
      AuthenticationService.instance.keycloak.refreshToken,
    );
    LocalStorageService.set(
      AUTH.PPW_ID_TOKEN,
      AuthenticationService.instance.keycloak.idToken,
    );
  }

  // There was an error during authentication
  onAuthError() {
    this.clearTokens();
  }

  // When the token is refreshed
  onAuthRefreshSuccess() {
    LocalStorageService.set(
      AUTH.PPW_ACCESS_TOKEN,
      AuthenticationService.instance.keycloak.token,
    );
  }

  // There was an error while trying to refresh the token
  onAuthRefreshError() {}

  // The user is logged out
  onAuthLogout() {
    this.clearTokens();
  }

  // The access token is expired
  // If a refresh token is available the token can be refreshed with updateToken, or in cases where it is not (that is, with implicit flow) you can redirect to login screen to obtain a new access token
  onTokenExpired() {
    this.updateToken(() => {});
  }

  clearTokens() {
    LocalStorageService.clear(AUTH.PPW_ACCESS_TOKEN);
    LocalStorageService.clear(AUTH.PPW_REFRESH_TOKEN);
    LocalStorageService.clear(AUTH.PPW_ID_TOKEN);
  }
}

export default AuthenticationService;
