import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import {
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
  RawLink,
  Dropdown,
  media,
} from '@tymate/margaret';
import { useLocation } from 'react-use';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'components';
import { ChevronDown, Person, Power, Receipt } from 'react-bootstrap-icons';
import { useAuthentication } from 'hooks';

const HeaderAvatar = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.spacing(0.5)} 0
    ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing()};
  align-items: center;

  > svg {
    display: none;

    ${media.tablet`
      display: block;
    `}
  }
`;

const Name = styled.div`
  display: none;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  white-space: nowrap;

  ${media.tablet`
    display:block;
  `}
`;

const Icon = styled.span`
  display: flex;
`;

const UserDropdown = ({ user }) => {
  const dropdownRef = useRef();
  const location = useLocation();
  const { logout } = useAuthentication();
  const { t } = useTranslation('account');
  const key = get(location, 'state.key');

  useEffect(() => {
    if (!Boolean(dropdownRef.current)) {
      return;
    }

    dropdownRef.current.close();
    // eslint-disable-next-line
  }, [key]);

  return (
    <Dropdown
      ref={dropdownRef}
      trigger={
        <HeaderAvatar>
          <Avatar
            firstName={user?.firstName}
            lastName={user?.lastName}
            size="small"
            imageUrl={user?.avatarUrl}
          />
          <Name>
            {user?.firstName} {user?.lastName}
          </Name>
          <ChevronDown size={12} />
        </HeaderAvatar>
      }
    >
      <PopoverMenu alignment="right">
        <PopoverItem>
          <PopoverItemButton as={RawLink} to="/my-account">
            <Icon>
              <Person size={22} />
            </Icon>
            <span>{t('my_account')}</span>
          </PopoverItemButton>
        </PopoverItem>

        {/* <PopoverItem>
          <PopoverItemButton as={RawLink} to="/my-account/security">
            <Icon>
              <ShieldLock size={22} />
            </Icon>
            <span>{t('security')}</span>
          </PopoverItemButton>
        </PopoverItem> */}

        <PopoverItem>
          <PopoverItemButton as={RawLink} to="/my-account/invoices">
            <Icon>
              <Receipt size={22} />
            </Icon>
            <span>{t('invoices')}</span>
          </PopoverItemButton>
        </PopoverItem>

        <PopoverItem>
          <PopoverItemButton onClick={logout}>
            <Icon>
              <Power size={22} />
            </Icon>
            <span>{t('log_out')}</span>
          </PopoverItemButton>
        </PopoverItem>
      </PopoverMenu>
    </Dropdown>
  );
};

export default UserDropdown;
