import React from 'react';
import styled, { css } from 'styled-components';
import { Avatar as MgtAvatar } from '@tymate/margaret';
import { useBreakpoint } from 'hooks';

export const StyledAvatar = styled(MgtAvatar)`
  ${({ imageUrl }) =>
    Boolean(imageUrl) &&
    css`
      background-color: #fff;
      background-image: url('${encodeURI(imageUrl)}');
      flex-shrink: 0;
    `};
`;

const getAvatarSize = ({ isFluid, breakpoint, maxSize }) => {
  if (!isFluid) {
    return null;
  }

  switch (breakpoint) {
    case 'tablet':
      return 'large';
    case 'laptop':
      return 'huge';
    default:
      return null;
  }
};

const Avatar = ({ isFluid, maxSize, ...props }) => {
  const breakpoint = useBreakpoint();
  const size = getAvatarSize({ isFluid, breakpoint, maxSize });

  return <StyledAvatar size={size} {...props} />;
};

export default Avatar;
