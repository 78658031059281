import styled, { css } from 'styled-components';
import { ButtonReset } from '@tymate/margaret';
import { fontSize, fontStyles } from 'ui';
import TextareaAutosize from 'react-textarea-autosize';

export const FormLabel = styled.label`
  ${fontSize.body};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  text-align: left;
  color: ${({ theme }) => theme.text};
  font-weight: 600;
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  + * {
    margin-top: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const InputStyles = css`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  border: 1px solid ${({ theme }) => theme.separator};
  flex: 1;
  border-radius: 4px;
  transition: border-color 100ms ease;
  width: 100%;

  &:not([disabled]):focus,
  &:not([disabled]):active {
    outline: none;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.disabledBackground};
    color: #6d6d6d;
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      &:not([disabled]),
      &:not([disabled]):hover,
      &:not([disabled]):active,
      &:not([disabled]):focus {
        border: 1px solid ${({ theme }) => theme.error};
      }
    `}
  ${({ withIndicator }) =>
    withIndicator &&
    css`
      border-bottom-color: transparent !important;
      border-radius: 4px 4px 0 0;
    `}

  ${({ withIcon, theme }) =>
    withIcon &&
    `
  padding-left: ${theme.spacing(2.25)}
`}

${({ variant }) =>
    variant === 'bare' &&
    css`
      border: 0;
      padding-left: 0;
    `}
`;

export const Input = styled.input`
  ${InputStyles}
`;

export const ErrorMessageWrapper = styled.div`
  ${fontSize.bodySmall};

  color: ${({ theme }) => theme.error};
  position: absolute;
  top: 100%;
`;

export const ErrorMessage = styled.div`
  ${fontSize.body};
  color: ${({ theme }) => theme.error};
`;

export const InputIcon = styled.div`
  position: absolute;
  left: ${({ theme }) => theme.spacing(0.5)};
  top: 50%;
  color: ${({ theme }) => theme.primary};
  font-size: 1.5rem;
  transform: translateY(-50%);
  display: flex;

  ${({ hasError, theme }) =>
    hasError &&
    `
      color: ${theme.error};
    `}
`;

export const IconButton = styled(ButtonReset)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separatorLight};
  border-radius: 100%;

  svg {
    color: ${({ theme }) => theme.textLighter};
    display: flex;
  }
`;

export const Textarea = styled(TextareaAutosize)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 4px;
  background-color: #fff;
  color: ${({ theme }) => theme.text};
  outline: none;
  width: 100%;
  resize: none;

  // &:focus,
  // &:active {
  //   border-color: ${({ theme }) => theme.darkSeparator};
  // }

  ::placeholder {
    color: ${({ theme }) => theme.textLight};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    color: inherit;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.disabledBackground};
    color: #6d6d6d;
    cursor: not-allowed;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      &:not([disabled]),
      &:not([disabled]):hover,
      &:not([disabled]):active,
      &:not([disabled]):focus {
        border: 1px solid ${({ theme }) => theme.error};
      }
    `}
`;

export const Status = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

export const PasswordButton = styled(ButtonReset)`
  position: absolute;
  width: 44px;
  height: 44px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RadioLabel = styled.label`
  border: 1px solid ${({ theme }) => theme.textLighter};
  color: ${({ theme }) => theme.textLighter};
  ${fontStyles.bodySmall};
  font-weight: bold;
  border-radius: ${({ theme }) => theme.borderRadiusPill};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.75)};
  cursor: pointer;

  ${({ isActive, theme }) =>
    isActive &&
    `
      border:  1px solid ${theme.primary};
      color: ${theme.primary};
  `};
`;
