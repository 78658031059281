import React from 'react';
import styled, { css } from 'styled-components';
import { AvatarAndName } from 'components';
import { fontStyles } from 'ui';
import {
  Dropdown,
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
  ConfirmButton,
  Stack,
} from '@tymate/margaret';
import Tooltip from './Tooltip';
import {
  ArrowClockwise,
  Check2Circle,
  Pencil,
  ThreeDotsVertical,
  Trash,
} from 'react-bootstrap-icons';
import { formatDateDistanceToNow } from 'utils';

const MemberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.textLighter};

  ${({ size }) =>
    size === 'small' &&
    css`
      ${fontStyles.bodySmall};
    `}
`;

const Trigger = styled.div`
  color: ${({ theme }) => theme.textLighter};
  font-size: 1.25rem;

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      color: ${({ theme }) => theme.primary};
    `}
`;

const MoreWorks = styled.div`
  color: ${({ theme }) => theme.primary};
  background-color: rgba(255, 91, 74, 0.08);
  ${fontStyles.bodySmall};
  display: flex;
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`;

const InvitationStatus = styled(Stack)`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.textLighter};

  ${({ variant }) =>
    variant === 'accepted' &&
    css`
      color: ${({ theme }) => theme.success};
    `}
`;

const Member = ({
  t,
  user,
  role,
  invitation,
  onUpdateOwner,
  onRevokeAccess,
  onResendInvitation,
  shouldHideDeleteOption,
  canChangeOwner,
  workList = [],
}) => {
  const displayedWorks = workList
    .slice(0, 2)
    .map(({ work }) => work.name)
    .join(', ');

  const tipWorks = workList
    .slice(2)
    .map(({ work }) => work.name)
    .join(', ');

  return (
    <MemberWrapper>
      <AvatarAndName user={user} variant="withEmail" legendSize="small" />

      {invitation && (
        <InvitationStatus
          direction="row"
          alignY="center"
          alignX="center"
          gutterSize={0.75}
          variant={invitation.acceptedAt && 'accepted'}
        >
          {invitation.acceptedAt ? (
            <>
              <Check2Circle size={18} />
              <Stack direction="column" alignX="center" alignY="center">
                <span>{t('invitations:accepted')}</span>
                {invitation.acceptedAt && (
                  <span>
                    {t('invitations:time_ago', {
                      time: formatDateDistanceToNow(invitation.acceptedAt),
                    })}
                  </span>
                )}
              </Stack>
            </>
          ) : (
            <>
              <ConfirmButton
                confirmElement={
                  <Trigger variant="primary" as="div">
                    <ArrowClockwise>{t('invitations:resend')}</ArrowClockwise>
                  </Trigger>
                }
                modalTitle={t('invitations:resend_title')}
                modalContent={t('invitations:resend_explanation', {
                  user: `${user.firstName} ${user.lastName}`,
                })}
                modalCancelTitle={t('invitations:cancel')}
                modalSubmitTitle={t('invitations:ok')}
                onSubmit={() => onResendInvitation(invitation.id)}
              />
              <Stack direction="column" alignX="center" alignY="center">
                <span>{t('invitations:pending')}</span>
                {invitation?.invitedAt && (
                  <span>
                    {t('invitations:time_ago', {
                      time: formatDateDistanceToNow(invitation.invitedAt),
                    })}
                  </span>
                )}
              </Stack>
            </>
          )}
        </InvitationStatus>
      )}

      <Stack>
        {role === 'OWNER' && <Label size="small">{t('owner')}</Label>}
        {workList && (
          <Stack alignY="center">
            <Label size="small">{displayedWorks}</Label>
            {workList.length > 2 && (
              <Tooltip tip={tipWorks} bottom>
                <MoreWorks>+{workList.length - 2}</MoreWorks>
              </Tooltip>
            )}
          </Stack>
        )}

        {canChangeOwner && role !== 'OWNER' && (
          <Dropdown
            trigger={
              <Trigger>
                <ThreeDotsVertical />
              </Trigger>
            }
          >
            <PopoverMenu alignment="right" style={{ width: '15em' }}>
              {canChangeOwner && (
                <PopoverItem>
                  <ConfirmButton
                    confirmElement={
                      <PopoverItemButton as="div">
                        <Pencil />
                        {t('become_owner')}
                      </PopoverItemButton>
                    }
                    modalTitle={t('become_owner')}
                    modalContent={t('become_owner_explanation')}
                    modalCancelTitle={t('cancel')}
                    modalSubmitTitle={t('save')}
                    onSubmit={() => onUpdateOwner(user.id)}
                  />
                </PopoverItem>
              )}
              {!shouldHideDeleteOption && (
                <PopoverItem>
                  <ConfirmButton
                    confirmElement={
                      <PopoverItemButton as="div">
                        <Trash />
                        {t('delete_user')}
                      </PopoverItemButton>
                    }
                    modalTitle={t('delete_user')}
                    modalContent={t('delete_user_explanation')}
                    modalCancelTitle={t('cancel')}
                    modalSubmitTitle={t('save')}
                    onSubmit={() => onRevokeAccess(user.id)}
                  />
                </PopoverItem>
              )}
            </PopoverMenu>
          </Dropdown>
        )}

        {!canChangeOwner && role !== 'OWNER' && !shouldHideDeleteOption && (
          <ConfirmButton
            confirmElement={
              <Trigger as="div">
                <Trash />
              </Trigger>
            }
            modalTitle={t('delete_user')}
            modalContent={t('delete_user_explanation')}
            modalCancelTitle={t('cancel')}
            modalSubmitTitle={t('save')}
            onSubmit={() => onRevokeAccess(user.id)}
          />
        )}
      </Stack>
    </MemberWrapper>
  );
};

export default Member;
