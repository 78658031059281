import { useKeycloak } from '@react-keycloak/web';

const useIsAuthenticated = () => {
  const { keycloak } = useKeycloak();
  const token = keycloak?.token;

  return !!keycloak?.authenticated && !!token;
};

export default useIsAuthenticated;
