import React from 'react';
import { Stack } from '@tymate/margaret';
import { Name, Legend as UILegend, fontStyles } from 'ui';
import { Avatar as CAvatar } from 'components';
import styled, { css } from 'styled-components';

const Avatar = styled(CAvatar)`
  min-width: 2rem;

  ${({ avatarSize }) =>
    avatarSize === 'large' &&
    css`
      min-width: 3rem;
      min-height: 3rem;
    `};
`;

const Legend = styled(UILegend)`
  ${({ variant }) =>
    variant === 'minWidth' &&
    css`
      min-width: fit-content;
      margin-left: ${({ theme }) => theme.spacing(0.5)};
    `}

  ${({ variant }) =>
    variant === 'requestCard' &&
    css`
      font-size: 0.875rem !important;
      color: ${({ theme }) => theme.textLighter};
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      ${fontStyles.bodySmall};
      color: ${({ theme }) => theme.textLighter};
    `}
`;

const AvatarAndName = ({
  user,
  label,
  size,
  avatarSize,
  legendSize,
  variant,
  role,
  rightLabel,
}) => (
  <Stack
    alignX="space-between"
    alignY="center"
    size={rightLabel ? 'full' : null}
  >
    <Stack alignY="center" gutterSize={0.75}>
      {variant !== 'requestCard' && (
        <Avatar
          firstName={user?.firstName}
          lastName={user?.lastName}
          imageUrl={user?.avatarUrl}
          size={size}
          avatarSize={avatarSize}
        />
      )}
      <Stack direction="column">
        {(variant === 'withRole' ||
          variant === 'withEmail&Role' ||
          variant === 'requestCard') && (
          <Legend size={legendSize} variant={variant}>
            {role}
          </Legend>
        )}
        <Name size={size}>
          {user?.firstName} {user?.lastName}
        </Name>
        {label && <Legend size={legendSize}>{label}</Legend>}
        {(variant === 'withEmail' || variant === 'withEmail&Role') && (
          <Legend size={legendSize} variant={variant}>
            {user?.email}
          </Legend>
        )}
      </Stack>
    </Stack>
    {rightLabel && (
      <Legend variant="minWidth">
        <span>{rightLabel}</span>
      </Legend>
    )}
  </Stack>
);

export default AvatarAndName;
