import React from 'react';
import styled from 'styled-components';
import { media, Stack } from '@tymate/margaret';
import { Container, fontStyles } from 'ui';
import { useBreakpoint } from 'hooks';

const HeaderWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.separatorLight};
  padding-top: ${({ theme }) => theme.spacing()};
  padding-bottom: ${({ theme }) => theme.spacing()};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 56px;

  ${media.tablet`
    min-height: 88px;
  `}
`;

const HeaderTitle = styled.h1`
  ${fontStyles.h1}
  font-family: ${({ theme }) => theme.fonts.title};
  margin: 0;
`;

const HeaderSubtitle = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.textLight};
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = ({
  title,
  subtitle,
  tabs,
  containerSize,
  actions,
  leftActions,
  mobileActions,
  children,
}) => {
  const breakpoint = useBreakpoint();
  const shouldShowMobileActions =
    breakpoint === 'mobile' && Boolean(mobileActions);
  const shouldShowActions =
    Boolean(actions) && (breakpoint !== 'mobile' || !Boolean(mobileActions));

  const shouldShowChildrenOnly =
    Boolean(children) && !(Boolean(title) || Boolean(tabs));

  return (
    <HeaderWrapper>
      <Container size={containerSize}>
        {shouldShowChildrenOnly ? (
          children
        ) : Boolean(tabs) ? (
          <TabsWrapper>{tabs}</TabsWrapper>
        ) : (
          <Stack direction="column" size="full" gutterSize={0.5}>
            <Stack
              direction={{
                default: Boolean(mobileActions) ? 'row' : 'column',
                tablet: 'row',
              }}
              alignX="space-between"
              alignY="center"
              size="full"
              gutterSize={1}
            >
              {Boolean(title) && (
                <Stack direction="column">
                  {Boolean(title) && <HeaderTitle>{title}</HeaderTitle>}
                  {Boolean(subtitle) && (
                    <HeaderSubtitle>{subtitle}</HeaderSubtitle>
                  )}
                </Stack>
              )}
              {leftActions}
              {shouldShowMobileActions && mobileActions}
              {shouldShowActions && actions}
            </Stack>
            {children}
          </Stack>
        )}
      </Container>
    </HeaderWrapper>
  );
};

export default Header;
