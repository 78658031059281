import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Header,
  Button,
  AvatarsStack,
  Activity,
  AvatarAndName,
} from 'components';
import { Buttons, media, Stack, List, Spinner } from '@tymate/margaret';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import icSafe from 'images/ic-safe.svg';
import { get, pickBy } from 'lodash';
import { fontStyles, Container, SubText } from 'ui';
import { RequestCard, ListFilters } from 'components';
import { useBreakpoint } from 'hooks';
import { Tabs, Tab, TabNavButton } from 'ui/tabs';
import { Scrollbars } from 'react-custom-scrollbars';
import SubscriptionModal from 'components/SubscriptionModal';
import {
  CalendarX,
  PlusCircleDotted,
  PlusCircleFill,
  Sliders,
  SortDown,
  SortUpAlt,
} from 'react-bootstrap-icons';
import usePostHog from 'hooks/usePosthog';

const Grid = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing()};
  grid-template-areas: 'requests' 'vault' 'activities';
  ${media.desktop`
    grid-template-columns: 1fr 25em;
    grid-template-rows: auto 1fr;
    grid-gap: ${({ theme }) => theme.spacing(2)};
    grid-template-areas: 
      "requests vault" 
      "requests activities"
  `};
`;

const Card = styled(Stack)`
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separatorLight};
  background-color: #ffffff;
  border-radius: ${({ theme }) => theme.borderRadiusLarge};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(1.5)};
  `}
`;

Card.defaultProps = {
  direction: 'column',
  alignX: 'stretch',
  gutterSize: 1,
};

const Requests = styled(Card)`
  grid-area: requests;
  overflow: hidden;

  ${media.tablet`
    height: calc(100vh - 80px - 88px - 64px);
  `}
`;

const Activities = styled(Card)`
  grid-area: activities;
`;

const Vault = styled(Card)`
  grid-area: vault;
`;

const Title = styled.h2`
  ${fontStyles.h2};
  margin-top: 0;
  margin-bottom: 0;
`;

const VaultImage = styled.img`
  max-width: 100px;
  width: 100%;
`;

const DelayedCount = styled(Card)`
  padding: ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing(0.75)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  color: ${({ theme }) => theme.textLight};
  ${fontStyles.bodySmall};
`;

const Count = styled(Stack)`
  ${fontStyles.h1};
  font-weight: bold;
  color: ${({ theme }) => theme.text};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  > div {
    svg {
      font-size: 1.375rem;
    }
    color: ${({ theme }) => theme.primary};
    background-color: ${({ theme }) => theme.backgroundPrimary};
    border-radius: 100%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const GET_WORK = gql`
  query getWork($slug: String!, $activityOrder: FeedActivityOrderCriteria) {
    work(slug: $slug) {
      id
      slug
      name
      description
      canUpdate {
        value
      }
      owner {
        firstName
        lastName
        avatarUrl
        email
      }
      canCreateRequest {
        value
        reasons {
          details
          fullMessages
        }
        message
      }
      canAccessVault {
        value
      }
      documents {
        totalCount
      }
      roles {
        nodes {
          name
          users {
            nodes {
              id
              firstName
              lastName
              avatarUrl
            }
          }
        }
      }
      activities(first: 10, order: $activityOrder) {
        edges {
          node {
            verb
            text
            createdAt
            actor {
              firstName
              lastName
              id
            }
            item {
              ... on Request {
                id
                name
                slug
                recipient {
                  id
                  firstName
                  lastName
                }
              }
              ... on Work {
                id
                name
              }
              ... on User {
                id
                lastName
                firstName
                email
              }
            }
          }
        }
      }
    }
  }
`;

const GET_WORK_REQUESTS = gql`
  query getWorkRequests(
    $slug: String!
    $order: RequestOrderCriteria
    $status: RequestStatus
  ) {
    work(slug: $slug) {
      id
      requests(first: 10, order: $order, status: $status) {
        edges {
          node {
            id
            slug
            status
            deadline
            name
            statusMessage
            createdAt
            description
            canAnswer {
              value
            }
            items {
              type
            }
            documents {
              id
            }
            recipient {
              id
              firstName
              lastName
              email
            }
            author {
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;

const GET_DELAYED_REQUEST_COUNT = gql`
  query getDelayedWorkRequestCount($slug: String!, $status: RequestStatus) {
    work(slug: $slug) {
      id
      requests(status: $status) {
        totalCount
      }
    }
  }
`;

const Dashboard = ({ isTenantOwner }) => {
  usePostHog();
  const { t } = useTranslation(['dashboard', 'statuses', 'roles', 'request']);
  const breakpoint = useBreakpoint();
  const { workSlug } = useParams();
  const [filters, setFilters] = useState({ status: null });
  const [subscriptionModalIsShown, setSubscriptionModalIsShown] = useState(
    false,
  );
  const [selectedState, setSelectedState] = useState('ALL');

  const orderOptions = [
    {
      value: '-createdAt',
      label: (
        <Trans i18nKey="filters:created_date-desc">
          <SortDown size={18} />
          Created at
        </Trans>
      ),
    },
    {
      value: 'createdAt',
      label: (
        <Trans i18nKey="filters:created_date-asc">
          <SortUpAlt size={18} />
          Created at
        </Trans>
      ),
    },
    {
      value: '-updatedAt',
      label: (
        <Trans i18nKey="filters:updated_date-desc">
          <SortDown size={18} />
          Updated at
        </Trans>
      ),
    },
    {
      value: 'updatedAt',
      label: (
        <Trans i18nKey="filters:updated_date-asc">
          <SortUpAlt size={18} />
          Updated at
        </Trans>
      ),
    },
    {
      value: 'deadline',
      label: (
        <Trans i18nKey="filters:deadline-asc">
          <SortUpAlt size={18} />
          Updated at
        </Trans>
      ),
    },
    {
      value: '-deadline',
      label: (
        <Trans i18nKey="filters:deadline-desc">
          <SortDown size={18} />
          Updated at
        </Trans>
      ),
    },
  ];

  const { loading, data } = useQuery(GET_WORK, {
    variables: pickBy({
      slug: workSlug,
      activityOrder: { column: 'CREATED_AT', direction: 'DESC' },
    }),
  });
  const activities = get(data, 'work.activities.edges', []);

  const { data: requestsData, loading: loadingRequests, refetch } = useQuery(
    GET_WORK_REQUESTS,
    {
      variables: pickBy({
        slug: workSlug,
        ...filters,
      }),
      fetchPolicy: 'no-cache',
    },
  );
  const requests = get(requestsData, 'work.requests.edges', []);

  const contactRole = data?.work?.roles?.nodes.find(
    ({ name }) => name === 'CONTACT',
  );
  const contacts = get(contactRole, 'users.nodes', []);

  const collaboratorRole = data?.work?.roles?.nodes.find(
    ({ name }) => name === 'COLLABORATOR',
  );
  const collaborators = get(collaboratorRole, 'users.nodes', []);

  const { data: delayedRequestData } = useQuery(GET_DELAYED_REQUEST_COUNT, {
    variables: {
      slug: workSlug,
      status: 'DELAYED',
    },
  });

  const delayedRequestCount = delayedRequestData?.work?.requests?.totalCount;

  const handleUpdateFilters = payload => {
    setFilters({ ...filters, ...payload });
  };

  const handleCreateNewRequest = () => {
    const details = data?.work?.canCreateRequest?.reasons?.details ?? '{}';
    const { billingAccount } = JSON.parse(details);
    if (Boolean(billingAccount)) {
      setSubscriptionModalIsShown(true);
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch, filters]);

  return (
    <>
      <Header
        title={data?.work?.name}
        subtitle={data?.work?.description}
        actions={
          <Buttons alignY="center">
            <AvatarAndName
              user={data?.work?.owner}
              variant="withRole"
              role={t('roles:manager')}
            />
            {collaborators.length >= 1 && (
              <div>
                <SubText hasNoMargin>
                  {t('collaborator_count', { count: collaborators.length })}
                </SubText>
                <AvatarsStack
                  users={collaborators.map(collaborator => ({
                    user: collaborator,
                  }))}
                />
              </div>
            )}
            {contacts.length >= 1 && (
              <div>
                <SubText hasNoMargin>
                  {t('contact_count', { count: contacts.length })}
                </SubText>
                <AvatarsStack
                  users={contacts.map(contact => ({ user: contact }))}
                />
              </div>
            )}
            {data?.work.canUpdate?.value && (
              <Button
                variant="outline"
                to="settings"
                data-attr="dashboard-settings"
              >
                {t('settings')}
              </Button>
            )}
          </Buttons>
        }
        mobileActions={
          <Buttons>
            {data?.work.canUpdate?.value && (
              <Button
                variant="icon"
                to="settings"
                data-attr="dashboard-settings"
                icon={<Sliders size={22} />}
              />
            )}
          </Buttons>
        }
      />

      <Container variant="main">
        <SubscriptionModal
          isOpen={subscriptionModalIsShown}
          onRequestClose={() => setSubscriptionModalIsShown(false)}
        />
        <Grid>
          <Requests>
            <Stack gutterSize={1} alignX="space-between" alignY="center">
              <Title>{t('my_requests')}</Title>

              {breakpoint !== 'mobile' && (
                <Buttons>
                  <Button
                    variant="outline"
                    to="requests"
                    data-attr="dashboard-requests-all"
                  >
                    {t('see_all')}
                  </Button>
                  {(data?.work.canCreateRequest?.value || isTenantOwner) && (
                    <Button
                      variant="primary"
                      icon={<PlusCircleDotted />}
                      data-attr="dashboard-requests-new"
                      to={data?.work?.canCreateRequest?.value && `requests/new`}
                      onClick={handleCreateNewRequest}
                    >
                      {t('new_request')}
                    </Button>
                  )}
                </Buttons>
              )}

              {breakpoint === 'mobile' && (
                <Stack gutterSize={0.5}>
                  {(data?.work.canCreateRequest?.value || isTenantOwner) && (
                    <Button
                      variant="icon"
                      icon={<PlusCircleFill size={22} />}
                      to="requests/new"
                      data-attr="dashboard-requests-new"
                    />
                  )}
                </Stack>
              )}
            </Stack>
            <DelayedCount>
              <Count gutterSize={0.75} alignY="center">
                <div>
                  <CalendarX size={18} />
                </div>
                <span>{delayedRequestCount}</span>
              </Count>
              {t('delayed_request', { count: delayedRequestCount })}
            </DelayedCount>
            <ListFilters
              orderOptions={orderOptions}
              onChange={handleUpdateFilters}
              isSearchable={false}
            >
              <Tabs hasNoPadding>
                <Tab>
                  <TabNavButton
                    isActive={selectedState === 'ALL'}
                    data-attr="dashboard-requests-filter-all"
                    onClick={() => {
                      setSelectedState('ALL');
                      handleUpdateFilters({ status: null });
                    }}
                  >
                    {t('statuses:all')}
                  </TabNavButton>
                </Tab>
                <Tab>
                  <TabNavButton
                    isActive={selectedState === 'ONGOING'}
                    data-attr="dashboard-requests-filter-ongoing"
                    onClick={() => {
                      setSelectedState('ONGOING');
                      handleUpdateFilters({ status: 'ONGOING' });
                    }}
                  >
                    {t('statuses:ongoing')}
                  </TabNavButton>
                </Tab>
              </Tabs>
            </ListFilters>
            <Scrollbars autoHide>
              {loadingRequests ? (
                <Spinner />
              ) : (
                <List direction="column" alignX="stretch" gutterSize={1}>
                  {requests.map(({ node }) => (
                    <RequestCard
                      key={node.id}
                      request={node}
                      variant="dashboard"
                      data-attr="dashboard-request-view"
                      to={`requests/${node?.slug}`}
                      t={t}
                    />
                  ))}
                </List>
              )}
            </Scrollbars>

            {breakpoint === 'mobile' && (
              <Buttons alignX="center">
                <Button
                  variant="outline"
                  to="requests"
                  data-attr="dashboard-requests-all"
                >
                  {t('see_all')}
                </Button>
              </Buttons>
            )}
          </Requests>

          {data?.work?.canAccessVault?.value && (
            <Vault>
              <Title>{t('vault')}</Title>
              <Stack alignY="center" gutterSize={1}>
                <VaultImage src={icSafe} alt="" />
                <div>
                  <Trans
                    i18nKey="dashboard:safe_documents_count"
                    count={data?.work?.documents?.totalCount}
                  >
                    You currently have{' '}
                    <strong>
                      {{ count: data?.work?.documents?.totalCount }}
                    </strong>{' '}
                    documents in the safe
                  </Trans>
                </div>
              </Stack>

              <Buttons>
                <Button
                  variant="outline"
                  to="vault"
                  data-attr="dashboard-vault"
                >
                  {t('go_to_vault')}
                </Button>
              </Buttons>
            </Vault>
          )}

          {!loading && (
            <Activities>
              <Stack gutterSize={1} alignX="space-between" alignY="center">
                <Title>{t('last_activities')}</Title>

                {breakpoint !== 'mobile' && (
                  <Button
                    variant="outline"
                    to="activities"
                    data-attr="dashboard-activities-all"
                  >
                    {t('see_all')}
                  </Button>
                )}
              </Stack>

              <Scrollbars autoHide>
                <List direction="column" alignX="stretch" gutterSize={1}>
                  {activities.map(({ node }, index) => (
                    <Activity activity={node} key={index} variant="compact" />
                  ))}
                </List>
              </Scrollbars>

              {breakpoint === 'mobile' && (
                <Buttons alignX="center">
                  <Button
                    variant="outline"
                    to="activities"
                    data-attr="dashboard-activities-all"
                  >
                    {t('see_all')}
                  </Button>
                </Buttons>
              )}
            </Activities>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;
