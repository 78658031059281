import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  RequestForm,
  Header,
  Button,
  RequestDetail,
  AvatarAndName,
  CompletionModal,
  DialogBox,
  Drawer,
} from 'components';
import { Spinner, Buttons, Stack, media } from '@tymate/margaret';
import { fontStyles, SubText, Container, fontSize, Legend } from 'ui';
import { useParams } from 'react-router-dom';
import { useApp } from 'hooks';
import gql from 'graphql-tag';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Form } from 'components/Forms';
import { MdSend } from 'react-icons/md';
import AutoSubmitRequest from 'components/AutoSubmitRequest';
import {
  formatInitialRequestValues,
  formatDate,
  formatDateDistanceToNow,
} from 'utils';
import { useBreakpoint } from 'hooks';
import { differenceInSeconds } from 'date-fns';
import { useInterval } from 'react-use';
import RejectionModal from 'components/RejectionModal';
import { ChatDotsFill, Check2Circle } from 'react-bootstrap-icons';
import { includes } from 'lodash-es';
import usePostHog from 'hooks/usePosthog';

const Card = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: #fff;
  padding: ${({ theme }) => theme.spacing()};
`;

const CardTitle = styled.h3`
  ${fontStyles.h3};
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

const RequestWrapper = styled.div`
  ${({ canComment }) =>
    canComment &&
    css`
      ${media.desktop`
        display: grid;
        grid-gap: ${({ theme }) => theme.spacing(2)};
        grid-template-columns: 1fr 22em;
      `}
    `}
`;

const Trigger = styled.div`
  ${fontSize.h1};
  width: 2em;
  height: 2em;
  border-radius: 100%;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  position: fixed;
  bottom: 65px;
  right: ${({ theme }) => theme.spacing()};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;

  ${media.tablet`
    bottom: ${({ theme }) => theme.spacing()};
  `}
`;

const GET_REQUEST = gql`
  query Request_getRequest($slug: String!) {
    request(slug: $slug) {
      id
      apiId
      status
      statusMessage
      lastReminderDate
      canAnswer {
        value
      }
      canRemind {
        value
      }
      canComment {
        value
      }
      canManage {
        value
      }
      canCancel {
        value
      }
      canValidate {
        value
      }
      canConfirm {
        value
      }
      canEarlyValidate {
        value
      }
      canReject {
        value
      }
      deadline
      name
      description
      emailStatus
      createdAt
      readAt
      author {
        id
        firstName
        lastName
        avatarUrl
      }
      work {
        name
        slug
      }
      recipient {
        id
        firstName
        lastName
        email
      }
      documents {
        id
        fileName
        mimeType
        url
        viewed
      }
      requestComments {
        edges {
          node {
            id
            message
            createdAt
            author {
              firstName
              lastName
              avatarUrl
            }
          }
        }
      }
      items {
        name
        description
        type
        required
        ... on DocumentRequestItem {
          name
          type
          id
          document {
            id
            fileName
            mimeType
            url
            viewed
          }
          answer {
            documents {
              fileName
              mimeType
              url
            }
          }
        }
        ... on AttachmentRequestItem {
          name
          type
          document {
            id
            fileName
            mimeType
            url
            viewed
          }
        }
        ... on SignatureRequestItem {
          id
          name
          type
          isTwoWaySignature
          status
          recipientSignature {
            id
            status
            createdAt
            updatedAt
          }
          senderSignature {
            id
            status
            createdAt
            updatedAt
          }
          signedDocument {
            id
            fileName
            mimeType
            url
          }
          document {
            id
            fileName
            mimeType
            url
            viewed
          }
          answer {
            createdAt
            updatedAt
          }
        }
        ... on PromptRequestItem {
          name
          type
          id
          message
          document {
            id
            fileName
            mimeType
            url
            viewed
          }
          choices {
            id
            choice
          }
          answer {
            choice {
              id
              choice
            }
          }
        }
        ... on MessageRequestItem {
          name
          type
          id
          document {
            id
            fileName
            mimeType
            url
            viewed
          }
          answer {
            message
            updatedAt
          }
        }
        ... on AcknowledgeRequestItem {
          document {
            id
            fileName
            mimeType
            url
            viewed
          }
          id
          message
          name
          type
          answer {
            acknowledged
          }
        }
      }
    }
  }
`;

const VALIDATE_REQUEST = gql`
  mutation validateRequest($input: ValidateRequestInput!) {
    validateRequest(input: $input) {
      request {
        id
        apiId
        slug
        status
        lastReminderDate
        canManage {
          value
        }
        canCancel {
          value
        }
        canValidate {
          value
        }
        canConfirm {
          value
        }
        canReject {
          value
        }
        canRemind {
          value
        }
      }
    }
  }
`;

export const REMIND_REQUEST = gql`
  mutation remindRequest($input: RemindRequestInput!) {
    remindRequest(input: $input) {
      request {
        id
        apiId
        lastReminderDate
        status
      }
    }
  }
`;

export const CONFIRM_REQUEST = gql`
  mutation confirmRequest($input: ConfirmRequestInput!) {
    confirmRequest(input: $input) {
      request {
        id
        apiId
        lastReminderDate
        status
      }
    }
  }
`;

export const EARLY_VALIDATE_REQUEST = gql`
  mutation earlyValidateRequest($input: EarlyValidateRequestInput!) {
    earlyValidateRequest(input: $input) {
      request {
        id
        apiId
        lastReminderDate
        slug
        status
        canManage {
          value
        }
        canCancel {
          value
        }
        canValidate {
          value
        }
        canConfirm {
          value
        }
        canEarlyValidate {
          value
        }
        canReject {
          value
        }
        canRemind {
          value
        }
      }
    }
  }
`;

const APPLY_REQUEST_ITEM_ANSWER = gql`
  mutation applyRequestItemAnswer($input: ApplyRequestItemAnswerInput!) {
    applyRequestItemAnswer(input: $input) {
      errors {
        message
        path
      }
      request {
        id
        apiId
        lastReminderDate
        items {
          name
          description
          type
          required
          ... on DocumentRequestItem {
            id
            name
            type
            document {
              id
              fileName
              mimeType
              url
            }
            answer {
              updatedAt
              documents {
                fileName
                mimeType
                url
              }
            }
          }
          ... on PromptRequestItem {
            id
            name
            type
            message
            document {
              id
              fileName
              mimeType
              url
            }
            choices {
              id
              choice
            }
            answer {
              updatedAt
              choice {
                id
                choice
              }
            }
          }
          ... on MessageRequestItem {
            id
            name
            type
            document {
              id
              fileName
              mimeType
              url
            }
            answer {
              message
              updatedAt
            }
          }
          ... on AcknowledgeRequestItem {
            id
            document {
              id
              fileName
              mimeType
              url
            }
            message
            name
            type
            answer {
              acknowledged
              updatedAt
            }
          }
        }
      }
    }
  }
`;

export const REJECT_REQUEST = gql`
  mutation rejectRequest($input: RejectRequestInput!) {
    rejectRequest(input: $input) {
      request {
        id
        lastReminderDate
        status
        statusMessage
      }
    }
  }
`;

const CREATE_DOCUMENT_EVENT = gql`
  mutation createDocumentEvent($input: CreateDocumentEventInput!) {
    createDocumentEvent(input: $input) {
      event {
        id
      }
    }
  }
`;

const Request = () => {
  usePostHog();
  const { notify } = useApp();
  const { requestSlug } = useParams();
  const { t } = useTranslation('request');
  const [rejectModalIsShown, setRejectModalIsShown] = useState();
  const [confirmModalIsShown, setConfirmModalIsShown] = useState();
  const breakpoint = useBreakpoint();
  const [, forceRender] = useState();

  const { loading, data, refetch } = useQuery(GET_REQUEST, {
    variables: { slug: requestSlug },
  });

  const [rejectRequest, { loading: isRejectingRequest }] = useMutation(
    REJECT_REQUEST,
    {
      refetchQueries: [
        {
          query: GET_REQUEST,
          variables: { slug: requestSlug },
        },
      ],
    },
  );
  const [confirmRequest, { loading: isConfirmingRequest }] = useMutation(
    CONFIRM_REQUEST,
    {
      refetchQueries: [
        {
          query: GET_REQUEST,
          variables: { slug: requestSlug },
        },
      ],
    },
  );
  const [remindRequest, { called: hasReminded }] = useMutation(REMIND_REQUEST);
  const [validateRequest] = useMutation(VALIDATE_REQUEST);
  const [earlyValidateRequest] = useMutation(EARLY_VALIDATE_REQUEST);
  const [applyRequestItemAnswer, { loading: isMutating }] = useMutation(
    APPLY_REQUEST_ITEM_ANSWER,
    {
      refetchQueries: [
        {
          query: GET_REQUEST,
          variables: { slug: requestSlug },
        },
      ],
    },
  );

  const [createDocumentEvent] = useMutation(CREATE_DOCUMENT_EVENT, {
    refetchQueries: [
      {
        query: GET_REQUEST,
        variables: { slug: requestSlug },
      },
    ],
  });

  const handleApplyItem = async ({ variables }) => {
    await applyRequestItemAnswer({ variables });
  };

  const handleRemindRequest = async () => {
    try {
      await remindRequest({
        variables: {
          input: { requestId: data?.request?.id },
        },
      });
    } catch (err) {}
  };

  const handleValidateRequest = async () => {
    try {
      await validateRequest({
        variables: {
          input: { requestId: data?.request?.id },
        },
      });
    } catch (err) {}
  };

  const handleEarlyValidateRequest = async () => {
    try {
      await earlyValidateRequest({
        variables: {
          input: { requestId: data?.request?.id },
        },
      });
    } catch (err) {}
  };

  const handleConfirmRequest = async () => {
    try {
      await confirmRequest({
        variables: {
          input: { requestId: data?.request?.id },
        },
      });
      notify(t('request_confirmed_success'));
    } catch (err) {
      notify(t('request_confirmed_error'), { type: 'error' });
    }
  };

  const handleRejectRequest = async message => {
    try {
      await rejectRequest({
        variables: {
          input: {
            requestId: data?.request?.id,
            message,
          },
        },
      });
      notify(t('request_rejected_success'));
    } catch (err) {
      notify(t('request_rejected_error'), { type: 'error' });
    }
  };

  const handleDocumentDownload = async document => {
    try {
      await createDocumentEvent({
        variables: {
          input: {
            kind: 'VIEW',
            documentId: document.id,
            requestApiId: data?.request.apiId,
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const request = data?.request;
  const durationSinceLastReminder = Boolean(request?.lastReminderDate)
    ? differenceInSeconds(new Date(), new Date(request?.lastReminderDate))
    : -1;

  const canConfirmRequest =
    request?.status === 'ONGOING' &&
    (request?.items || []).length > 0 &&
    request.items.filter(({ answer }) => !Boolean(answer)).length === 0;

  useEffect(() => {
    if (!canConfirmRequest) {
      return;
    }

    setConfirmModalIsShown(true);
  }, [canConfirmRequest]);

  useInterval(() => {
    forceRender(Date.now());
  }, 3000);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {request?.canAnswer?.value && (
        <>
          <CompletionModal
            isOpen={confirmModalIsShown}
            onRequestClose={() => setConfirmModalIsShown(false)}
            request={request}
            onConfirmRequest={handleConfirmRequest}
            isConfirmingRequest={isConfirmingRequest}
          />
          <Formik
            enableReinitialize
            initialValues={formatInitialRequestValues(request)}
          >
            <>
              <Header
                containerSize={request?.canComment?.value ? null : 'narrow'}
                leftActions={
                  <>
                    <AvatarAndName
                      user={request?.author}
                      label={t('created_at', {
                        date: formatDate(
                          request?.createdAt,
                          'dd MMMM yyyy - HH:mm',
                        ),
                      })}
                    />
                    <AutoSubmitRequest
                      request={request}
                      isLoading={isMutating || loading}
                      onApplyItem={handleApplyItem}
                    />
                  </>
                }
                actions={
                  <Stack alignY="center" gutterSize={1}>
                    <p>{t('request_completed')}</p>
                    <Button
                      variant="outline"
                      onClick={handleConfirmRequest}
                      disabled={!request?.canConfirm?.value || isMutating}
                    >
                      {t('send_informations')}
                    </Button>
                  </Stack>
                }
                mobileActions={
                  request?.canConfirm?.value && (
                    <Button
                      variant="icon"
                      icon={<MdSend size={22} />}
                      onClick={handleConfirmRequest}
                    />
                  )
                }
              />
              <Container
                size={request?.canComment?.value ? null : 'narrow'}
                variant="main"
              >
                <RequestWrapper
                  canComment={Boolean(request?.canComment?.value)}
                >
                  <div>
                    <Form>
                      <RequestForm
                        request={request}
                        refetchRequest={refetch}
                        onDocumentDownload={handleDocumentDownload}
                        signatureAs="sender"
                      />
                    </Form>

                    <Card>
                      <Stack
                        alignX="space-between"
                        alignY="center"
                        gutterSize={0.25}
                      >
                        <div>
                          <CardTitle>{t('request_completed')}</CardTitle>
                          <SubText hasNoMargin>
                            {t('send_informations_explanation')}
                          </SubText>
                        </div>
                        <Button
                          variant="outline"
                          onClick={handleConfirmRequest}
                          disabled={!request?.canConfirm?.value || isMutating}
                        >
                          {t('send_informations')}
                        </Button>
                      </Stack>
                    </Card>
                  </div>

                  {Boolean(request?.canComment?.value) && (
                    <div>
                      {breakpoint === 'desktop' ? (
                        <DialogBox
                          requestId={request?.id}
                          requestComments={request?.requestComments?.edges}
                          refetchComments={refetch}
                        />
                      ) : (
                        <Drawer
                          trigger={
                            <Trigger>
                              <ChatDotsFill />
                            </Trigger>
                          }
                        >
                          <DialogBox
                            requestId={request?.id}
                            requestComments={request?.requestComments?.edges}
                            refetchComments={refetch}
                          />
                        </Drawer>
                      )}
                    </div>
                  )}
                </RequestWrapper>
              </Container>
            </>
          </Formik>
        </>
      )}

      {!request?.canAnswer?.value && (
        <div>
          {includes(['FINISHED', 'VALIDATED'], request?.status) && (
            <RejectionModal
              isOpen={rejectModalIsShown}
              onRequestClose={() => setRejectModalIsShown(false)}
              request={request}
              onRejectRequest={handleRejectRequest}
              isRejectingRequest={isRejectingRequest}
            />
          )}
          <Header
            containerSize={request?.canComment?.value ? null : 'narrow'}
            leftActions={
              <AvatarAndName
                user={request?.author}
                label={t('created_at', {
                  date: formatDate(request?.createdAt, 'dd MMMM yyyy - HH:mm'),
                })}
              />
            }
            actions={
              <Stack direction="column" gutterSize={0.5}>
                <Buttons>
                  {request?.canRemind?.value && (
                    <Button variant="outline" onClick={handleRemindRequest}>
                      {t('remind')}
                    </Button>
                  )}
                  {request?.canReject?.value && (
                    <Button
                      variant="outline"
                      onClick={() => setRejectModalIsShown(true)}
                    >
                      {t('reject_request')}
                    </Button>
                  )}
                  {request?.canEarlyValidate?.value && (
                    <Button
                      variant="secondary"
                      onClick={handleEarlyValidateRequest}
                    >
                      {t('close_request')}
                    </Button>
                  )}
                  {request?.canValidate?.value && (
                    <Button variant="secondary" onClick={handleValidateRequest}>
                      {t('close_request')}
                    </Button>
                  )}
                </Buttons>

                {durationSinceLastReminder !== -1 && (
                  <Legend>
                    {durationSinceLastReminder > 5 || !hasReminded ? (
                      durationSinceLastReminder > 3600 * 2 ? (
                        t('reminded_at', {
                          at: formatDate(
                            new Date(request?.lastReminderDate),
                            'dd/MM/yyyy, HH:mm',
                          ),
                        })
                      ) : (
                        t('reminded_ago', {
                          ago: formatDateDistanceToNow(
                            new Date(request?.lastReminderDate),
                          ),
                        })
                      )
                    ) : (
                      <span>
                        <Check2Circle /> {t('just_reminded')}
                      </span>
                    )}
                  </Legend>
                )}
              </Stack>
            }
          />
          <Container
            size={request?.canComment?.value ? null : 'narrow'}
            variant="main"
          >
            <RequestWrapper canComment={Boolean(request?.canComment?.value)}>
              <RequestDetail
                request={request}
                refetchRequest={refetch}
                onDocumentDownload={handleDocumentDownload}
                signatureAs="sender"
              />
              {Boolean(request?.canComment?.value) && (
                <div>
                  {breakpoint === 'desktop' ? (
                    <DialogBox />
                  ) : (
                    <Drawer
                      trigger={
                        <Trigger>
                          <ChatDotsFill />
                        </Trigger>
                      }
                    >
                      <DialogBox />
                    </Drawer>
                  )}
                </div>
              )}
            </RequestWrapper>
          </Container>
        </div>
      )}
    </>
  );
};

export default Request;
