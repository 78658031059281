import React from 'react';

import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';

import { AuthenticationContext } from '../contexts';
import { AuthenticationService } from 'services';

const Inner = ({ children }) => {
  const { initialized } = useKeycloak();
  const {
    instance: { login, logout },
  } = AuthenticationService;

  const value = { login, logout };

  if (!initialized) return <></>;

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

const AuthProvider = ({ children }) => {
  const {
    instance: { keycloak, onEvent },
    keycloakConfig,
  } = AuthenticationService;

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      onEvent={onEvent}
      initOptions={keycloakConfig}
    >
      <Inner>{children}</Inner>
    </ReactKeycloakProvider>
  );
};

export default AuthProvider;
