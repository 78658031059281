import React, { useState } from 'react';
// import styled from 'styled-components';
import { useDebounce } from 'react-use';
// import { useTranslation } from 'react-i18next';
import { isEqual, omit, omitBy, isNil } from 'lodash';
import { useFormikContext } from 'formik';
import { formatInitialRequestValues } from 'utils';

// const Wrapper = styled.div`
//   flex: 1;
//   color: ${({ theme }) => theme.textLight};
// `;

const AutoSubmitRequest = ({ request, isLoading, onApplyItem }) => {
  // const { t } = useTranslation('autoSubmit');
  const [hasMutated, setHasMutated] = useState();
  const { values } = useFormikContext();

  const currentRequestValues = formatInitialRequestValues(request);
  const modifiedItems = (values.items || []).filter(
    (item, index) => !isEqual(item, currentRequestValues?.items?.[index]),
  );

  const hasLocalChanges = modifiedItems.length > 0;

  useDebounce(
    async () => {
      if (!hasLocalChanges) {
        return;
      }

      for (let modifiedItem of modifiedItems) {
        const payload = omit(
          omitBy(
            {
              ...modifiedItem,
              documents:
                modifiedItem.type === 'DOCUMENT'
                  ? (modifiedItem.documents || []).map(({ documentId, file }) =>
                      Boolean(documentId) ? { documentId } : { file },
                    )
                  : null,
              promptChoiceId: Boolean(modifiedItem.promptChoiceId)
                ? Number(modifiedItem.promptChoiceId)
                : null,
            },
            isNil,
          ),
          [
            'name',
            'type',
            'updatedAt',
            'acknowledgeMessage',
            '__typename',
            'required',
          ],
        );

        await onApplyItem({ variables: { input: payload } });
        setHasMutated(true);
      }
    },
    2000,
    [values, hasLocalChanges],
  );

  if (isLoading || hasLocalChanges || hasMutated) {
    return <></>;
  }

  return null;
};

export default AutoSubmitRequest;
