import styled, { css } from 'styled-components';
import {
  Card as MgtCard,
  Container as MgtContainer,
  media,
  viewportSizes,
  Box,
  PopoverMenu as MgtPopoverMenu,
  PopoverItemButton as MgtPopoverItemButton,
} from '@tymate/margaret';
import { keys, toPairs } from 'lodash';

export const theme = {
  text: 'rgba(0, 0, 0, 0.87)',
  textLight: 'rgba(0, 0, 0, 0.6)',
  textLighter: 'rgba(0, 0, 0, 0.38)',
  textLightest: 'rgba(0, 0, 0, 0.2)',
  textLightRGB: 'rgb(158, 158, 158)',

  primary: '#ff5b4a',
  primaryBackground: 'hsla(6, 100%, 65%, 12%)',
  primaryLight: '#ff8c80',
  primaryLightish: 'hsla(6, 100%, 65%, 60%)',
  primaryLighter: 'rgba(255,91,74, 0.08)',
  primaryGradient: 'linear-gradient(180deg, #ff7250 0%, #ff3a2c 100%)',

  secondary: '#66ccaa',
  secondaryLight: '#2ee6a9',
  secondaryGradient: 'linear-gradient(to bottom, #2ee6a9, #29cc96)',

  tertiary: '#1596fe',
  tertiaryLight: '#48a7f5',
  tertiaryLighter: '#e3f2fd',

  separator: 'rgba(0,0,0,0.12)',
  separatorLight: 'rgba(0, 0, 0, 0.08)',
  separatorLighter: 'rgba(0, 0, 0, 0.06)',
  separatorDark: 'rgba(0,0,0,0.48)',

  success: '#29cc96',
  warning: '#ff5b4a',
  error: '#f14c66',
  defaultState: '#00aaff',
  successBackground: 'rgba(41, 204, 150, 0.12)',
  warningBackground: 'rgba(255, 91, 74, 0.08)',
  errorBackground: 'rgb(241, 76, 102, 0.08)',
  defaultStateBackground: 'rgba(0, 170, 255, 0.12)',

  buttonShadow: '0 8px 16px -8px #FF8B80',
  buttonShadowSecondary: '0 4px 8px -4px #66ccaa',
  buttonBorderRadius: '99em',
  buttonHeight: '40px',

  borderRadius: '4px',
  borderRadiusSmall: '2px',
  borderRadiusLarge: '12px',
  borderRadiusPill: '99em',
  borderButtonRadius: '28px',

  boxShadow: '0 4px 16px 0 rgba(0,0,0,0.12)',

  buttonsDefaultAlignX: 'left',

  cardBoxShadow: '0 12px 32px rgba(0, 0, 0, 0.12)',
  cardBackground: '#ffffff',

  disabledBackground: 'rgba(0,0,0,0.08)',

  background: '#FAF8F5',
  backgroundGradient: 'linear-gradient(to top, #faf9f7, #ffffff 100px)',
  backgroundPrimary: 'rgba(255, 91, 74, 0.08)',
  backgroundSecondary: 'rgba(41, 204, 150, 0.12)',

  fonts: {
    title:
      'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
    body:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  },

  fontSizes: {
    h1Mega: {
      sizeMinRem: 1.75,
      sizeMaxRem: 2,
      lineHeightMin: 1.2,
      lineHeightMax: 1.1,
    },
    h1: {
      sizeMinRem: 1.375,
      sizeMaxRem: 1.625,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
    },
    h2: {
      sizeMinRem: 1.25,
      sizeMaxRem: 1.5,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
    },
    h3: {
      sizeMinRem: 1.125,
      sizeMaxRem: 1.25,
      lineHeightMin: 1.4375,
      lineHeightMax: 1.25,
    },
    bodyLarge: {
      sizeMinRem: 1.0625,
      sizeMaxRem: 1.1875,
      lineHeightMin: 1.3,
      lineHeightMax: 1.5,
    },
    body: {
      sizeMinRem: 1,
      sizeMaxRem: 1,
      lineHeightMin: 1.3125,
      lineHeightMax: 1.5,
    },
    bodySmall: {
      sizeMinRem: 0.8125,
      sizeMaxRem: 0.875,
      lineHeightMin: 1.4,
      lineHeightMax: 1.35,
    },
    bodySmaller: {
      sizeMinRem: 0.7125,
      sizeMaxRem: 0.775,
      lineHeightMin: 1.4,
      lineHeightMax: 1.35,
    },
  },
};

const cssLock = ({
  valueUnit = '',
  minValue,
  maxValue,
  minViewportWidth = viewportSizes.tablet,
  maxViewportWidth = viewportSizes.desktop,
}) =>
  `calc((${minValue} * 1${valueUnit}) + (${maxValue} - ${minValue}) * (100vw - ${
    minViewportWidth / 16
  }em) / (${maxViewportWidth / 16} - ${minViewportWidth / 16}))`;

export const fontSize = toPairs(theme.fontSizes).reduce(
  (acc, [key, { sizeMinRem, sizeMaxRem, lineHeightMin, lineHeightMax }]) => ({
    ...acc,
    [key]: css`
      font-size: ${sizeMinRem}rem;

      ${media.tablet`
        font-size: ${cssLock({
          valueUnit: 'rem',
          minValue: sizeMinRem,
          maxValue: sizeMaxRem,
        })};
      `}

      ${media.desktop`
        font-size: ${sizeMaxRem}rem;
      `}
    `,
  }),
  {},
);

export const lineHeight = toPairs(theme.fontSizes).reduce(
  (acc, [key, { sizeMinRem, sizeMaxRem, lineHeightMin, lineHeightMax }]) => ({
    ...acc,
    [key]: css`
      line-height: ${lineHeightMin}em;

      ${media.tablet`
        line-height: ${cssLock({
          valueUnit: 'em',
          minValue: lineHeightMin,
          maxValue: lineHeightMax,
        })};
      `}

      ${media.desktop`
        font-size: ${sizeMaxRem}rem;
        line-height: ${lineHeightMax}em;
      `}
    `,
  }),
  {},
);

export const fontStyles = keys(theme.fontSizes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: css`
      ${fontSize[key]}
      ${lineHeight[key]}
    `,
  }),
  {},
);

export const Card = styled(MgtCard)`
  background-color: ${({ theme }) => theme.cardBackground};
  border-radius: ${({ theme }) => theme.borderRadiusLarge};

  > div {
    padding: ${({ theme }) => theme.spacing()};

    ${media.tablet`
      padding: ${({ theme }) => theme.spacing(2)};
    `}
  }

  + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

export const SubText = styled.p`
  color: ${({ theme }) => theme.textLighter};
  strong {
    color: ${({ theme }) => theme.text};
  }

  ${({ hasNoMargin }) =>
    hasNoMargin &&
    `
      margin: 0
    `}
`;

export const TitleAndActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MembersList = styled.div`
  padding-top: ${({ theme }) => theme.spacing()};
  padding-bottom: ${({ theme }) => theme.spacing()};

  > * + * {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

export const Title = styled.h2`
  ${fontStyles.h2};
  margin-top: 0;

  ${({ variant, theme }) =>
    variant === 'success' &&
    `
      color: ${theme.success};
    `}
`;

export const Name = styled(Box)`
  ${fontStyles.body};
  color: ${({ theme }) => theme.text};
  font-weight: 500;

  ${({ size, theme }) =>
    size === 'small' &&
    `
      font-size: 0.875rem!important;
      color : ${theme.textLight};
      font-weight: normal;
  `}
`;

Name.defaultProps = {
  as: 'div',
};

export const Legend = styled(Box)`
  ${fontStyles.body};
  color: ${({ theme }) => theme.textLight};

  ${({ size }) =>
    size === 'small' &&
    css`
      ${fontStyles.bodySmall};
      color: ${({ theme }) => theme.textLighter};
    `}
`;

Legend.defaultProps = {
  as: 'div',
};

export const Container = styled(MgtContainer)`
  ${({ size }) =>
    size === 'narrow' &&
    css`
      width: 52rem;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      width: 39rem;
    `}

  ${({ variant }) =>
    variant === 'main' &&
    css`
      padding-top: ${({ theme }) => theme.spacing(1.5)};
      padding-bottom: ${({ theme }) => theme.spacing(1.5)};

      ${media.tablet`
        padding-top: ${({ theme }) => theme.spacing(1.5)};
        padding-bottom: ${({ theme }) => theme.spacing(1.5)};
      `}
    `}
`;

export const PopoverMenu = styled(MgtPopoverMenu)`
  min-width: 15em;
  padding: ${({ theme }) => theme.spacing(0.25)} 0;
  width: auto;
`;

export const PopoverDividerItem = styled.div`
  display: flex;
  height: 0;
  margin: ${({ theme }) => theme.spacing(0.5)} 0;
  overflow: hidden;
  border-top: 1px solid ${({ theme }) => theme.separator};
`;

export const PopoverItemButton = styled(MgtPopoverItemButton)`
  ${({ variant }) =>
    variant === 'error' &&
    css`
      color: ${({ theme }) => theme.error};

      &:hover {
        color: ${({ theme }) => theme.error};
      }
    `}
`;
