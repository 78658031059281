import React, { useState, forwardRef, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  Field,
  Form as RawForm,
  ErrorMessage as RawErrorMessage,
  FieldArray,
} from 'formik';
import { SubText, Legend, theme } from 'ui';
import {
  FormField,
  FormLabel,
  Input,
  ErrorMessageWrapper,
  InputIcon,
  IconButton,
  Textarea,
  PasswordButton,
  RadioLabel,
} from 'ui/forms';
import {
  Media,
  MediaBody,
  MediaImage,
  Stack,
  Box,
  List,
} from '@tymate/margaret';
import { Button, Attachment, Avatar } from 'components';
import { PasswordStrengthIndicator } from 'components/Forms';
import { uniqueId, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useApp, useBreakpoint } from 'hooks';
import { FILESIZE_OPTIONS, MAX_FILE_SIZE } from '../../constants';
import {
  CheckSquareFill,
  Eye,
  EyeSlash,
  Paperclip,
  Square,
  Trash,
} from 'react-bootstrap-icons';
import fileSize from 'filesize';
import i18n from 'i18n';

export const Form = styled(RawForm)`
  width: 100%;
`;

export const ErrorMessage = props => {
  return (
    <RawErrorMessage
      data-error="true"
      {...props}
      component={ErrorMessageWrapper}
    />
  );
};

const RenderTextField = forwardRef((props, ref) => {
  const {
    placeholder,
    field,
    inputValue,
    label,
    optional,
    form,
    wrapperStyle,
    ...rest
  } = props;
  const [isPassword] = useState(props.type === 'password');
  const [withIndicator] = useState(props.withIndicator === true);
  const [type, setType] = useState(props.type || 'text');
  const hasError =
    get(form.errors, field.name) && get(form.touched, field.name);

  return (
    <FormField style={wrapperStyle}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {label && <FormLabel>{label}</FormLabel>}
        {optional && <SubText style={{ margin: 0 }}>{optional}</SubText>}
      </div>
      <div style={{ position: 'relative', display: 'flex' }}>
        {props.icon && <InputIcon>{props.icon}</InputIcon>}
        <Input
          value={inputValue}
          {...field}
          {...rest}
          placeholder={placeholder}
          hasError={hasError}
          withIndicator={withIndicator}
          min={0}
          ref={ref}
          step="any"
          withIcon={props.icon}
          type={type}
        />

        {isPassword && (
          <PasswordButton
            disabled={props.disabled}
            type="button"
            onClick={() => setType(type === 'password' ? 'text' : 'password')}
            tabIndex={-1}
          >
            {type === 'text' ? <Eye /> : <EyeSlash />}
          </PasswordButton>
        )}
      </div>

      {isPassword && withIndicator && (
        <PasswordStrengthIndicator password={field.value} />
      )}
      <ErrorMessage {...field} />
    </FormField>
  );
});

export const TextField = forwardRef((props, ref) => (
  <Field {...props}>
    {fieldProps => <RenderTextField ref={ref} {...props} {...fieldProps} />}
  </Field>
));

const RenderAvatarField = forwardRef((props, ref) => {
  const {
    field,
    destroyName,
    label,
    form: { setFieldValue, setFieldTouched },
  } = props;
  const [temporaryAvatarUrl, setTemporaryAvatarUrl] = useState(field.value);
  const { t } = useTranslation('misc');

  const getTemporaryUrl = file => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setTemporaryAvatarUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (typeof field.value !== 'object') {
      setTemporaryAvatarUrl(field.value);
    }
  }, [field.value]);

  return (
    <FormField>
      <Media>
        <MediaImage>
          <Avatar
            size="large"
            imageUrl={temporaryAvatarUrl}
            variant={props.variant}
          />
        </MediaImage>
        <MediaBody>
          {label && <FormLabel>{label}</FormLabel>}

          <Stack gutterSize={1} marginTop={1} alignY="center">
            <Button
              variant="outline"
              size="small"
              as="label"
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <input
                type="file"
                style={{ display: 'none' }}
                onClick={e => (e.target.value = null)}
                onChange={e => {
                  setFieldTouched(field.name, true);
                  setFieldValue(field.name, e.target.files[0]);
                  getTemporaryUrl(e.target.files[0]);
                  setFieldValue(destroyName, false);
                }}
              />
              {t('change_picture')}
            </Button>
            <IconButton
              type="button"
              onClick={() => {
                setFieldValue(destroyName, true);
                setFieldTouched(field.name, true);
                setFieldValue(field.name, null);
                setTemporaryAvatarUrl(null);
              }}
            >
              <Trash />
            </IconButton>
          </Stack>
        </MediaBody>
      </Media>
      <ErrorMessage {...field} />
    </FormField>
  );
});

export const AvatarField = forwardRef((props, ref) => (
  <Field {...props}>
    {fieldProps => <RenderAvatarField ref={ref} {...props} {...fieldProps} />}
  </Field>
));

export const AttachmentsField = ({
  optional,
  multiple,
  required,
  text,
  maxSize,
  ...props
}) => {
  const { t } = useTranslation(['misc', 'errors']);
  const { notify } = useApp();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'mobile';

  const attachmentRef = useRef();

  const handleAddFiles = (e, { field, form }) => {
    const files = e?.target?.files || [];
    maxSize = maxSize ?? MAX_FILE_SIZE;
    let nextValue;

    [...(files || [])].forEach(file => {
      if (file.size > maxSize) {
        notify(
          t('errors:file_too_big', {
            size: fileSize(maxSize, {
              ...FILESIZE_OPTIONS,
              round: 2,
              locale: i18n.language,
            }),
          }),
          { type: 'error' },
        );
      } else {
        nextValue = multiple ? [...(field.value || []), { file }] : { file };
      }
    });
    form.setFieldValue(field.name, nextValue);
  };

  return (
    <Field {...props}>
      {({ field, form }) => (
        <>
          {(multiple || !Boolean(field.value)) && (
            <Button
              variant={required ? 'primaryText' : 'text'}
              size={isMobile ? 'small' : undefined}
              as="label"
            >
              <input
                {...props}
                type="file"
                style={{ display: 'none' }}
                ref={attachmentRef}
                onChange={e => handleAddFiles(e, { field, form })}
                onClick={e => {
                  form.setFieldTouched(field.name, true);
                  e.target.value = null;
                }}
                multiple={multiple}
              />
              <Paperclip size={22} />{' '}
              <Box marginLeft={0.25}>
                {text
                  ? text
                  : multiple
                  ? t('add_attachments')
                  : t('add_attachment')}
              </Box>
              {optional && (
                <Box marginLeft={0.25} as={Legend}>
                  {t('optional')}
                </Box>
              )}
              {/* {required && (
                <Box marginLeft={0.25} as={Legend}>
                  {t('required')}
                </Box>
              )} */}
            </Button>
          )}

          {!multiple && Boolean(field.value) && (
            <Attachment
              file={field.value?.file || field?.value}
              onRemove={() => form.setFieldValue(field.name, '')}
            />
          )}

          {get(field, 'value', []).length > 0 && (
            <FieldArray
              name={props.name}
              render={({ remove }) => (
                <List direction="column" gutterSize={0.5}>
                  {(field.value || []).map((file, index) => (
                    <li>
                      <Attachment
                        file={file?.file || file}
                        onRemove={() => remove(index)}
                      />
                    </li>
                  ))}
                </List>
              )}
            />
          )}
        </>
      )}
    </Field>
  );
};

const RenderTextareaField = ({ field, label, form, optional, ...props }) => {
  const hasError = Boolean(form.errors[field.name]) && form.touched[field.name];
  const { t } = useTranslation(['misc', 'errors']);
  return (
    <FormField>
      {Boolean(label) && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {label && <FormLabel hasError={hasError}>{label}</FormLabel>}
          {optional && <SubText style={{ margin: 0 }}>{t('optional')}</SubText>}
        </div>
      )}
      <Textarea {...field} {...props} hasError={hasError} />
      <ErrorMessage name={field.name} />
    </FormField>
  );
};

export const TextareaField = props => {
  const [id] = useState(uniqueId());

  return <Field {...props} id={id} component={RenderTextareaField} />;
};

export const RadioButtonField = ({ name, value, label, ...props }) => (
  <Field name={name} {...props}>
    {({ field, form }) => (
      <RadioLabel
        isActive={
          field?.value === value || Number(field?.value) === Number(value)
        }
      >
        <input
          type="radio"
          style={{ display: 'none' }}
          {...props}
          {...field}
          value={value}
        />
        {label}
      </RadioLabel>
    )}
  </Field>
);

export const Checkbox = ({
  onChange,
  name,
  value,
  label,
  disabled,
  onClick,
}) => (
  <FormLabel
    style={{
      userSelect: 'none',
      display: 'flex',
      alignItems: 'flex-start',
      margin: 0,
    }}
  >
    <input
      type="checkbox"
      name={name}
      style={{ display: 'none' }}
      disabled={disabled}
      checked={value === true}
      value={value}
      onChange={e => onChange(!value)}
      onClick={e => onClick(e)}
    />
    {value === true ? (
      <CheckSquareFill
        size={18}
        color={theme.primary}
        style={{ flex: '0 0 18px', marginTop: 3 }}
      />
    ) : (
      <Square
        size={18}
        color={theme.primary}
        style={{ flex: '0 0 18px', marginTop: 3 }}
      />
    )}
    <span style={{ fontWeight: 400, marginLeft: 10 }}>{label}</span>
  </FormLabel>
);

export const CheckboxField = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, form: { touched, errors, setFieldValue }, meta }) => (
      <FormField>
        <Checkbox
          {...props}
          {...field}
          onChange={value => {
            setFieldValue(field.name, value);
          }}
          onClick={() => {}}
        />
      </FormField>
    )}
  </Field>
);
