import React, { useReducer, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  TableWrapper,
  ButtonReset,
  Stack,
  Spinner,
  theme,
} from '@tymate/margaret';
import { orderBy, find, last } from 'lodash';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import LocationAwareSearch from './LocationAwareSearch';
import { useDeepCompareEffect } from 'react-use';
import { useBreakpoint, useSearchParams } from 'hooks';
import { overrideSearchWithParams } from 'utils';
import { ChevronExpand, SortDown } from 'react-bootstrap-icons';

const ASC = 'asc';
const DESC = 'desc';

const generateColumns = css`
  grid-template-columns: ${({ columnsWidths = [] }) => columnsWidths.join(' ')};
`;

const Table = styled.div`
  display: grid;
  ${generateColumns};
`;

const Th = styled(Stack)`
  font-weight: 600;
  border-bottom: 1px solid ${({ theme }) => theme.separator};
`;

Th.defaultProps = {
  paddingRight: 1,
  paddingLeft: 0.5,
  paddingVertical: 0.5,
};

const Td = styled(Stack)`
  border-bottom: 1px solid ${({ theme }) => theme.separator};
`;

Td.defaultProps = {
  paddingRight: 1,
  paddingLeft: 0.5,
  paddingVertical: 0.5,
};

const ColumnHeader = styled(ButtonReset)`
  svg {
    color: ${({ theme }) => theme.textLighter};
    transition: transform 150ms ease, color 150ms ease;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      svg {
        color: ${({ theme }) => theme.text};
      }
    `}

  ${({ svgShouldGetUpsideDown }) =>
    svgShouldGetUpsideDown &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}
`;

const Tr = styled.div`
  display: contents;
  position: relative;

  svg {
    font-size: 20px;
  }
`;

const TrLink = styled(Link)`
  display: contents;
  color: inherit;
  text-decoration: none;
  ${({ $isSelected, theme }) =>
    $isSelected &&
    css`
      span {
        color: ${theme.textLightRGB};
      }
    `}
`;

const TopActions = styled(Stack)`
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const Content = ({ render, value }) =>
  render ? render() : <span>{value}</span>;

const getInitialState = headings =>
  headings
    .map(({ defaultSort, defaultIsHidden, defaultIsActive, ...heading }) => ({
      isActive: Boolean(defaultIsActive),
      isHidden: Boolean(defaultIsHidden),
      sort: defaultSort,
      ...heading,
    }))
    // 👇👇👇
    // If  multiple headings have defaultIsActive: true, only the first one
    // is kept. The “isActive” property of the following ones then is then set
    // to false.
    .reduce((acc, curr) => {
      if (find(acc, ({ isActive }) => isActive)) {
        return acc.concat({
          ...curr,
          isActive: false,
        });
      }

      return acc.concat(curr);
    }, []);

const getNextSort = currentSort => {
  switch (currentSort) {
    case ASC:
      return DESC;
    case DESC:
      return null;
    default:
      return ASC;
  }
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'TOGGLE_SORT':
      return state.map(heading => {
        if (heading.slug !== payload) {
          return {
            ...heading,
            isActive: false,
          };
        }

        if (!heading.isActive) {
          return {
            ...heading,
            isActive: true,
            sort: Boolean(heading.sort) ? heading.sort : getNextSort(),
          };
        }

        return {
          ...heading,
          isActive: Boolean(getNextSort(heading.sort)),
          sort: getNextSort(heading.sort),
        };
      });

    case 'TOGGLE_VISIBILITY':
      return state.map(heading =>
        heading.slug === payload
          ? { ...heading, isHidden: !heading.isHidden }
          : heading,
      );

    case 'INITIALIZE':
      return payload;

    default:
      return state;
  }
};

const getNextSearchParam = column => {
  const nextSort = getNextSort(column?.sort);

  if (!nextSort) {
    return null;
  }

  return `${nextSort === DESC ? '-' : ''}${column.slug}`;
};

const getSearchParamsActiveHeading = ({ headings, sort }) => {
  const activeHeading = find(
    headings,
    ({ slug }) => slug === (sort || '').replace('-', ''),
  );

  if (!activeHeading || !sort) {
    return null;
  }

  return {
    ...activeHeading,
    isActive: true,
    sort: sort.charAt(0) === '-' ? DESC : ASC,
  };
};

const DataTable = ({
  isLoading,
  onSort,
  tableActions,
  tableSelections,
  tableBottom,
  sortingIsHandledBySearchQuery,
  ...props
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { sort } = useSearchParams();
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint === 'mobile';

  const [rawHeadings, dispatch] = useReducer(
    reducer,
    getInitialState(props.headings),
  );
  const activeHeading = sortingIsHandledBySearchQuery
    ? getSearchParamsActiveHeading({ headings: rawHeadings, sort })
    : find(rawHeadings, ({ isActive }) => isActive);
  const headings = rawHeadings
    .filter(({ isHidden }) => !isHidden)
    .map(heading =>
      heading?.slug === activeHeading?.slug ? activeHeading : heading,
    );

  const data = Boolean(activeHeading)
    ? orderBy(props.data, activeHeading.slug, activeHeading.sort)
    : props.data;
  const { selectedEntitiesId } = props;
  const columnsWidths = headings.map((column, index) => {
    if (Boolean(column.size)) {
      return column.size;
    }

    if (column.slug === 'actions') {
      return 'min-content';
    }

    return 'auto';
  });

  const linkColumnSpan =
    last(headings)?.slug === 'actions' ? headings.length - 1 : headings.length;

  const handleReorderColumn = column => {
    if (sortingIsHandledBySearchQuery) {
      const search =
        overrideSearchWithParams({
          location,
          sort: getNextSearchParam(
            find(headings, ({ slug }) => slug === column?.slug),
          ),
        }) || '';

      navigate({
        pathname: location.pathname,
        search,
      });
    } else {
      dispatch({ type: 'TOGGLE_SORT', payload: column.slug });
    }
  };

  useEffect(() => {
    dispatch({
      type: 'INITIALIZE',
      payload: getInitialState(props.headings),
    });
  }, [props.headings]);

  useDeepCompareEffect(() => {
    if (typeof onSort === 'function' && Boolean(activeHeading)) {
      onSort(activeHeading);
    }
  }, [{ activeHeading }]);

  return (
    <>
      <TopActions
        direction={isMobile ? 'column' : 'row'}
        alignX="space-between"
        alignY="center"
        gutterSize={isMobile && 1}
      >
        <div>
          <LocationAwareSearch size={isMobile && 'full'} disabled={isLoading} />
        </div>
        {tableActions}
      </TopActions>
      {data?.length > 0 && !Boolean(isLoading) && tableSelections}

      {Boolean(isLoading) ? (
        <Spinner />
      ) : (
        <TableWrapper variant="bordered">
          <Table columnsWidths={columnsWidths}>
            <Tr columnsWidths={columnsWidths}>
              {headings
                .filter(({ isHidden }) => !isHidden)
                .map(
                  ({
                    slug,
                    label,
                    cannotBeReordered,
                    sort,
                    isActive,
                    variant,
                    ...props
                  }) => (
                    <Th key={slug} variant={variant} {...props}>
                      {Boolean(label) && (
                        <ColumnHeader
                          onClick={() =>
                            handleReorderColumn({
                              slug,
                              label,
                              cannotBeReordered,
                              sort,
                              isActive,
                              variant,
                              ...props,
                            })
                          }
                          disabled={cannotBeReordered}
                          isActive={isActive}
                          svgShouldGetUpsideDown={sort === ASC}
                        >
                          {label}
                          {cannotBeReordered ? null : isActive &&
                            Boolean(sort) ? (
                            <SortDown
                              size={18}
                              style={{
                                marginLeft: theme.spacing(0.25),
                                marginTop: theme.spacing(-0.25),
                              }}
                            />
                          ) : (
                            <ChevronExpand
                              size={18}
                              style={{
                                marginLeft: theme.spacing(0.25),
                                marginTop: -1,
                              }}
                            />
                          )}
                        </ColumnHeader>
                      )}
                    </Th>
                  ),
                )}
            </Tr>

            {data.map((data, index) => (
              <Tr
                key={index}
                columnsWidths={columnsWidths}
                hasLink={data.path || data.href}
              >
                {data.path || data.href ? (
                  linkColumnSpan === columnsWidths.length ? (
                    <TrLink
                      to={data.path}
                      href={data.href}
                      target={data.href ? '_self' : null}
                      as={Boolean(data.href) ? 'a' : null}
                      columnSpan={linkColumnSpan}
                      columnsWidths={columnsWidths.slice(0, linkColumnSpan)}
                      onContextMenu={$event => {
                        $event.preventDefault();
                        data.onContextMenuEvent && data.onContextMenuEvent();
                      }}
                    >
                      {headings
                        .filter(({ isHidden }) => !isHidden)
                        .map(({ slug, variant, ...props }) => (
                          <Td
                            alignY="center"
                            key={slug}
                            variant={variant}
                            {...props}
                          >
                            <Content key={index} {...data[slug]} />
                          </Td>
                        ))}
                    </TrLink>
                  ) : (
                    <>
                      <TrLink
                        to={data.path}
                        href={data.href}
                        target={data.href ? '_self' : null}
                        as={Boolean(data.href) ? 'a' : null}
                        columnSpan={linkColumnSpan}
                        columnsWidths={columnsWidths.slice(0, linkColumnSpan)}
                        $isSelected={selectedEntitiesId.includes(
                          data.selection?.value,
                        )}
                        onContextMenu={$event => {
                          $event.preventDefault();
                          data.onContextMenuEvent && data.onContextMenuEvent();
                        }}
                      >
                        {headings
                          .slice(0, linkColumnSpan)
                          .filter(({ isHidden }) => !isHidden)
                          .map(({ slug, variant, ...props }) => (
                            <Td
                              alignY="center"
                              key={slug}
                              variant={variant}
                              {...props}
                            >
                              <Content key={index} {...data[slug]} />
                            </Td>
                          ))}
                      </TrLink>
                      <Td
                        alignY="center"
                        key={last(headings)?.slug}
                        variant={last(headings)?.variant}
                        {...props}
                        {...last(headings)}
                      >
                        <Content key={index} {...data[last(headings)?.slug]} />
                      </Td>
                    </>
                  )
                ) : (
                  headings
                    .filter(({ isHidden }) => !isHidden)
                    .map(({ slug, variant, ...props }) => (
                      <Td
                        alignY="center"
                        key={slug}
                        variant={variant}
                        {...props}
                      >
                        <Content key={index} {...data[slug]} />
                      </Td>
                    ))
                )}
              </Tr>
            ))}
          </Table>
          {tableBottom}
        </TableWrapper>
      )}
    </>
  );
};

export default DataTable;
